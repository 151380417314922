import React, { useState, useEffect } from "react";
import useStateRef from "react-usestateref";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import Moment from "moment";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import moment from "moment";
import io from "socket.io-client";
import { InfinitySpin } from "react-loader-spinner";

function Support() {

    const value = {
        Subject: "",
        Category: "Choose Category",
        text: "",
      };

      const [Formdata, setFormdata] = useState(value);

      const [SubjectErr, setSubjectErr] = useState(value);
      const [CategoryErr, setCategoryErr] = useState(value);
      const [textErr, settextErr] = useState(value);
    
      const [formErr, setformErr] = useState("");
    
      const [user, setuser] = useState([]);
      const [buttonLoader, setbuttonLoader] = useState(false);
      const [currentPage, setCurrentPage] = useState(1);
      const [totalPage, setTotalpages] = useState(0);
      const [supportCategories, setCategories] = useState(0);
      const [viewChat, setViewChat] = useState(false);
      const [replyMess, replyMessage, replyMessref] = useStateRef("");
      const [chatDatas, setChatDatas] = useState("");
      const [chatHistory, setchatHistory] = useState([]);
    
      const {Subject, Category, text} = Formdata;

      const getItem = (e) => {
        var {name, value} = e.target;
        let check = {...Formdata, ...{[name]: value}};
        setFormdata(check);
        validate(Formdata);
      };
    
      const recordPerPage = 5;
      const totalRecords = 15;
      const pageRange = 5;

      const handlePageChange = (pageNumber) => {
        viewData(pageNumber);
        setCurrentPage(pageNumber);
      };
      useEffect(() => {
        viewData();
        getCategory();
      }, [0]);
      const checkKeyDown = (e) => {
        if (e.key === 'Enter'){
          e.preventDefault();
          reply();
        }
    
      };

      const validate = async (condition) => {
        var error = {};
        if (condition.Subject == "") {
          error.Subject = "Subject is a required field";
          setSubjectErr(true);
        } else if (condition.Subject.length < 5) {
          error.Subject = "Minimum 5 Characters only allowed ";
          setSubjectErr(true);
        } else if (condition.Subject.length > 50) {
          error.Subject = "Maximum 50 Characters only allowed ";
          setSubjectErr(true);
        } else {
          setSubjectErr(false);
        }
    
        if (condition.Category == "Choose Category") {
          error.Category = "Category is Required";
          setCategoryErr(true);
        }
    
        if (condition.text == "") {
          error.text = "Message is a required field";
          settextErr(true);
        } else if (condition.text.length < 10) {
          error.text = "Minimum 10 Characters only allowed ";
          settextErr(true);
        } else if (condition.text.length > 250) {
          error.text = "Maximum 250 Characters only allowed ";
          settextErr(true);
        } else {
          settextErr(false);
        }
        setformErr(error);
      };
    
      const submit = async () => {
        validate(Formdata);
        // return false;
        if (
          Formdata.Subject != "" &&
          Formdata.Subject.length > 5 &&
          Formdata.Subject.length < 50 &&
          Formdata.Category != "Choose Category" &&
          Formdata.text != "" &&
          Formdata.text.length > 10 &&
          Formdata.text.length < 250
        ) {
          toast.success(
            "Your ticket created successfully, Please wait for admin reply"
          );
          var data = {
            apiUrl: apiService.createdSupport,
            payload: Formdata,
          };
          setbuttonLoader(true);
          var resp = await postMethod(data);
          setbuttonLoader(false);
          const obj = {
            Subject: "",
            Category: "Choose Category",
            text: "",
          };
          setFormdata(obj);
          viewData();
        } else {
          //toast.error("All are required fields");
        }
      };
      const viewData = async () => {
        try {
          var api = {
            apiUrl: apiService.findedSupport,
          };
          var view = await postMethod(api);
          console.log(view, "=-=-view=-=-=-");
          if (view.status) setuser(view.data.data);
          setTotalpages(view.data.total);
          console.log(view.data.total);
        } catch (error) {}
      };
    
      const getCategory = async () => {
        try {
          var api = {
            apiUrl: apiService.supportCategories,
          };
          var response = await getMethod(api);
          if (response.status) {
            setCategories(response.data);
          }
        } catch (error) {}
      };
    
      const userChat = async (data) => {
        try {
          console.log("=-=-==data=--=-=", data);
          setChatDatas(data);
          setViewChat(true);
          var obj = {
            _id: data._id,
          };
          var api = {
            apiUrl: apiService.getSupportDetails,
            payload: obj,
          };
          var view = await postMethod(api);
          if (view) {
            setchatHistory(view.Message.reply);
          } else {
            setchatHistory([]);
          }
          console.log(view, "=-=-view=-=-=-view=-=-=-view=-=");
          console.log(view.data.total);
        } catch (error) {}
      };
      const closeTicket = async (data) => {
        try {
          var obj = {
            _id: data._id,
          };
          var api = {
            apiUrl: apiService.closeTicket,
            payload: obj,
          };
          var view = await postMethod(api);
          if (view) {
            // userChat(chatDatas);
            viewData();
            toast.success(view.Message);
          } else {
            toast.error(view.Message);
          }
        } catch (error) {}
      };
    
      const back = async (data) => {
        try {
          setViewChat(false);
        } catch (error) {}
      };
    
      const reply = async () => {
        try {
          if (Object.keys(replyMessref.current).length > 0) {
            var obj = {
              message: replyMessref.current,
              chatId: chatDatas._id,
              tag: "user",
              image: "",
              status: "0",
            };
            var api = {
              apiUrl: apiService.addUserSupport,
              payload: obj,
            };
            var view = await postMethod(api);
            if (view) {
              console.log("**********");
              userChat(chatDatas);
              replyMessage("");
              toast.success(view.Message);
            }
          } else {
            toast.error("Please enter the message!");
          }
        } catch (error) {}
      };


  return (
    <div>
      <Header />
      <div className="container-fluid">
        <div className="row d-flex justify-content-center">
          <div className="col-lg-2 col-sm-3">
            <Sidebar />
          </div>
          <div className="col-lg-10 col-md-12">
            <div className="container dash_container">
              <span className="core_div_head">Support</span>
              <div className="row mt-5 justify-content-center">
                <div className="col-lg-8">
                     <div className="reg_right_main">
                                <div className="reg_in_main">
                                    <div className="log_in_top">
                                        <div className="reg_infields">
                                            <div className="sup_field_sin">
                                                <span className="earn_divs_insubhead">Subject</span>
                                                <input
                                                    type="text"
                                                    name="Subject"
                                                    value={Subject}
                                                    onChange={getItem}
                                                    className="sup_inp_field"
                                                    placeholder="Subject"
                                                />
                                                {SubjectErr == true ? (
                                                    <p className="errorcss">{formErr.Subject}</p>
                                                ) : ("")}
                                            </div>

                                            <div className="sup_field_sin">
                                                <span className="earn_divs_insubhead">Select Category</span>
                                                      <select
                                                        className="sup_inp_field"
                                                        name="Category"
                                                        value={Category}
                                                        onChange={getItem}
                                                        >
                                                            <option selected>Choose Category</option>
                                                            {supportCategories.length > 0 ? (
                                                                supportCategories.map((item, i) => (
                                                                <option selected>{item.category}</option>
                                                                ))
                                                            ) : (
                                                                <option selected>Choose Category</option>
                                                            )}
                                                        </select>
                                                {CategoryErr == true ?  (
                                                    <p className="errorcss">{formErr.Category}</p>
                                                ) : ("")}
                                            </div>

                                            <div className="sup_field_sin">
                                            <span className="earn_divs_insubhead">Message</span>
                                            <textarea
                                            className="support_textarea"
                                            maxLength="250"
                                            name="text"
                                            value={text}
                                            placeholder="Message"
                                            onChange={getItem}
                                            ></textarea>
                                             {textErr == true ? (
                                                <p className="errorcss">{formErr.text} </p>
                                            ) : (
                                                ""
                                            )}
                                            </div>
                                        </div>
                                        <div className="sup_sub mt-4">
                                        {buttonLoader == false ? (
                                            <div className="sup_submit py-3" onClick={submit}>
                                            <span
                                                className="hero_getstarted"
                                            >
                                                Submit
                                            </span>

                                            </div>
                                        ) : (
                                            <div className="sup_submit py-3">
                                            <span
                                                className="hero_getstarted"
                                            >
                                               Loading
                                            </span>
                                       
                                    </div>
                                        ) }
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                </div>
                 
              </div>
              <div className="swap_tab_main">
                  <div className="table-swap-change mt-5">
                    <div className="table-responsive table-borderless">
                      <table>
                        <thead className="assest_head">
                          <th>Ticket ID</th>
                          <th>Subject</th>
                          <th>Message</th>
                          <th>Category</th>
                          <th>Date / Time</th>
                          <th>Action</th>
                          <th>Ticket Close</th>
                        </thead>
                        <tbody>
                        {user.length > 0 ? (
                              user.map((data, i) => (
                                <tr>
                                  <td>
                                    <span className="slider_left_innerbot">
                                      #{data._id.substring(0, 8)}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="pop_in_frst">
                                    {data.subject}
                                    </span>
                                  </td>
                                  <td className="sup_msg_len">
                                    <span className="pop_in_frst">
                                    {data.message}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="pop_in_frst">
                                    {data.category}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="pop_in_frst">
                                    {moment(data.updated_at).format("lll")}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="pop_in_frst" onClick={() => userChat(data)}>
                                    View
                                    </span>
                                  </td>
                                  {data.status == "0" ? (
                                    <td
                                    onClick={() => closeTicket(data)}
                                    style={{color: "green"}}
                                  > Close </td>
                                  ) : (
                                    <td style={{color: "red"}}> Closed </td>
                                  )}
                                </tr>
                               ))
                          ) : (
                            <td colSpan={7} className="text-center py-5">
                              <div className="empty_data">
                                <div className="empty_data_img">
                                  <img
                                    src={require("../images/No-data.png")}
                                    width="100px"
                                  />
                                </div>

                                <div className="no_records_text">
                                  No Records Found
                                </div>
                              </div>
                            </td>
                          )}
                        </tbody>
                      </table>

                      {user.length > 0 ? (
                        <div className="pagination mt-5">
                          <Stack spacing={2}>
                            <Pagination
                              count={Math.ceil(totalPage / recordPerPage)}
                              page={currentPage}
                              onChange={handlePageChange}
                              renderItem={(item) => (
                                <PaginationItem
                                  slots={{
                                    previous: ArrowBackIcon,
                                    next: ArrowForwardIcon,
                                  }}
                                  {...item}
                                />
                              )}
                            />
                          </Stack>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Support;
