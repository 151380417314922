import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { Link, useNavigate } from "react-router-dom";
import apiService from "../core/service/detail";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getMethod, postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import moment from "moment";

function Dashboard() {
    const [myhistory, setmyhistory] = useState("openorder");

    const myhistoryfunction = (newValue) => {
        setmyhistory(newValue);
    };

    const [refferance, setrefferance] = useState("");
    const [refferHistoty, setRefferHistoty] = useState("");
    const [totalPage, setTotalPages] = useState("");
    const [totalRewards, setTotalRewards] = useState("");
    const [totalRefferal, setTotalRefferal] = useState("");
    const [currentPage, setCurrentPage] = useState("");

    useEffect(() => {
        getReferralDetails(0);
        getReward(0);
        referralHistory(0);
        get_rewardHistory(0);
    }, []);

    const title = "Welcome to Cashbit";
    const getReferralDetails = async () => {
        try {
            var data = {
                apiUrl: apiService.getUserDetails,
            };
            var resp = await getMethod(data);
            if (resp.status) {
                var link = resp.refer_link;
                setrefferance(link);
                console.log(refferance, "reffernce");
            } else {
            }
        } catch (error) { }
    };

    const copy = (content) => {
        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard.writeText(content);
            toast.success("Referral Link copied successfully");
        } else {
            toast.success("Link not copied, please try after sometimes!");
        }
    };
    const getReward = async () => {
        var get = {
            apiUrl: apiService.getReward,
        };
        var response = await getMethod(get);
        console.log(response, "response");
        setTotalRewards(response.data.total_bonus);
        setTotalRefferal(response.data.totalRefCount);
    };
    const referralHistory = async (page) => {
        try {
            var payload = {
                perpage: 5,
                page: page,
            };
            var data = {
                apiUrl: apiService.referralHistory,
                payload: payload,
            };
            var resp = await postMethod(data);
            console.log(resp);
            if (resp.status) {
                setRefferHistoty(resp.data.data);
                console.log(resp.data.data);
                setTotalPages(resp.data.total);
            }
        } catch (error) { }
    };
    const recordPerPage = 5;
    const pageRange = 5;

    const handlePageChange = (event, pageNumber) => {
        referralHistory(pageNumber);
        setCurrentPage(pageNumber);
    };

    const [rewardHistory, setrewardHistory] = useState([]);
    const [totalPage_reward, setTotalpages_reward] = useState(0);
    const [currentPage_reward, setCurrentPage_reward] = useState(1);

    const recordPerPage_reward = 5;
    const pageRange_reward = 5;

    const get_rewardHistory = async (page) => {
        try {
            var payload = {
                perpage: 5,
                page: page,
            };
            var data = {
                apiUrl: apiService.earningHistory,
                payload: payload,
            };
            var resp = await postMethod(data);
            if (resp.status) {
                setrewardHistory(resp.data.data);
                setTotalpages_reward(resp.data.total);
                // setTotalRewards(resp.data.totalearning)
            }
        } catch (error) { }
    };

    const handlePageChange_reward = (event, pageNumber) => {
        get_rewardHistory(pageNumber);
        setCurrentPage_reward(pageNumber);
    };

    return (
        <div>
            <Header />
            <div className="container-fluid px-0 innerpages">
                <div className=" lanchpool">
                    <section className="lanch-header ref-bg  px-5">
                        <div className="container-fluid ">
                            <div className="row  justify-content-between align-items-center ">
                                <div className="col-lg-6">
                                    <h2>
                                        Invite friends to get  <br />
                                        <span>Up to 50%  </span> <br />
                                        Trading Rebate each
                                    </h2>
                                </div>
                                <div className="col-lg-6 ">
                                    <div class="referral-card">
                                        <div class="Frame-129">
                                            <h6 class="Total-Referrals">Total Referrals</h6>

                                            <span>
                                                {totalRefferal != null &&
                                                    totalRefferal != undefined &&
                                                    totalRefferal != ""
                                                    ? totalRefferal
                                                    : 0}
                                            </span>
                                        </div>

                                        <div class="Frame-129 ">
                                            <h6 class="Total-Referrals">Total Rewards</h6>

                                            <div className="d-flex align-items-center">
                                                <span>
                                                    {totalRewards != null &&
                                                        totalRewards != undefined &&
                                                        totalRewards != ""
                                                        ? parseFloat(totalRewards).toFixed(6)
                                                        : 0.0}
                                                </span>
                                                <h6 class="Total-Referrals ml-2">USDT</h6>
                                            </div>
                                        </div>

                                        <div class="Frame-129 ">
                                            <h6 class="Total-Referrals">Referral Link</h6>

                                            <div className="d-flex align-items-center text-white">
                                                <p className="text-white m-0">{refferance}</p>
                                                <h6 class="Total-Referrals text-white ml-2">
                                                    <i
                                                        class="fa-regular fa-copy"
                                                        onClick={() => copy(refferance)}
                                                    ></i>
                                                </h6>
                                            </div>
                                        </div>

                                        <div class="d-flex align-items-center justify-content-between w-100">
                                            <button onClick={() => copy(refferance)}>Invite</button>
                                            <div className="Vector">
                                                <i class="fa-brands fa-facebook-f text-white"></i>
                                            </div>
                                            <div className="Vector">
                                                <i class="fa-brands fa-x-twitter text-white"></i>
                                            </div>
                                            <div className="Vector">
                                                <i class="fa-brands fa-linkedin-in text-white"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="container-fluid">
                            <div className=" m-0 p-5">
                                <span className="core_div_head mb-4">History</span>

                                <div className="dash_user_main referralpage mt-0">
                                    <div className="basictable tradeform">
                                        <ul class="nav nav-tabs mb-3" id="myTab" role="tablist">
                                            <li class="nav-item" role="presentation">
                                                <a
                                                    class={`nav-link pr-2 ${myhistory === "openorder" ? "active" : ""
                                                        }`}
                                                    id="openorder-tab"
                                                    onClick={() => myhistoryfunction("openorder")}
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#openorder"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="home"
                                                    aria-selected="true"
                                                >
                                                    Referral History
                                                </a>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <a
                                                    class={`nav-link px-2 ${myhistory === "order" ? "active" : ""
                                                        }`}
                                                    id="order-tab"
                                                    onClick={() => myhistoryfunction("order")}
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#order"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="home"
                                                    aria-selected="true"
                                                >
                                                    Earning History
                                                </a>
                                            </li>
                                        </ul>
                                        <div
                                            class="tab-content pair_details pading_oedar"
                                            id="myTabContent"
                                        >
                                            <div
                                                id="OpenOrders"
                                                className={`tab-pane fade ${myhistory === "openorder" ? "show active" : ""
                                                    }`}
                                                role="tabpanel"
                                                aria-labelledby="openorder-tab"
                                            >
                                                <div className="Depositcard my-4">
                                                    <div className="row">
                                                        <div className="col-lg-12  px-1">
                                                            <div className="table-responsive">
                                                                <table>
                                                                    <thead>
                                                                        <tr className="tabel_bg">
                                                                            <th className="starts">S.No</th>
                                                                            <th>Email </th>
                                                                            <th>Username </th>
                                                                            <th className="ends">Date </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {refferHistoty.length > 0 ? (
                                                                            refferHistoty.map((item, i) => {
                                                                                return (
                                                                                    <tr className="history_tabel_bottom_border">
                                                                                        <td>{item.id}</td>
                                                                                        <td>{item.email}</td>
                                                                                        <td>{item.username}</td>
                                                                                        <td>
                                                                                            {moment(item.createdDate).format(
                                                                                                "lll"
                                                                                            )}
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            })
                                                                        ) : (
                                                                            <tr>
                                                                                <td colSpan="8" className="text-center">
                                                                                    {" "}
                                                                                    <div className="empty_data_img">
                                                                                        <img
                                                                                            src={require("../images/No-data.png")}
                                                                                        />
                                                                                    </div>
                                                                                    No found open orders!
                                                                                </td>
                                                                            </tr>
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            {refferHistoty.length > 0 ? (


                                                                <div className="pagination">
                                                                    <Stack spacing={2}>
                                                                        <Pagination
                                                                            count={Math.ceil(totalPage / 5)}
                                                                            page={currentPage}
                                                                            onChange={handlePageChange}
                                                                            renderItem={(item) => (
                                                                                <PaginationItem
                                                                                    slots={{
                                                                                        previous: ArrowBackIcon,
                                                                                        next: ArrowForwardIcon,
                                                                                    }}
                                                                                    {...item}
                                                                                />
                                                                            )}
                                                                        />
                                                                    </Stack>
                                                                </div>

                                                            ) : ""}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* ==========OREDERS HISTORY========== */}

                                            <div
                                                id="OrderHistory"
                                                className={`tab-pane fade ${myhistory === "order" ? "show active" : ""
                                                    }`}
                                                role="tabpanel"
                                                aria-labelledby="order-tab"
                                            >
                                                <div className="Depositcard my-4">
                                                    <div className="row">
                                                        <div className="col-lg-12  px-1">
                                                            <div className="table-responsive">
                                                                <table>
                                                                    <thead>
                                                                        <tr className="tabel_bg">
                                                                            <th className="starts">S.NO</th>
                                                                            <th>Amount</th>
                                                                            <th>Currency</th>
                                                                            <th>Type</th>
                                                                            <th>From</th>
                                                                            <th className="ends">Date / time </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {rewardHistory.length > 0 ? (
                                                                            rewardHistory &&
                                                                            rewardHistory.map((item, i) => {
                                                                                return (
                                                                                    <tr className="history_tabel_bottom_border">
                                                                                        <td>{item.id}</td>
                                                                                        <td>{item.amount}</td>
                                                                                        <td>{item.currency}</td>
                                                                                        <td>{item.type}</td>
                                                                                        <td>{item.from}</td>
                                                                                        <td>{item.date}</td>
                                                                                    </tr>
                                                                                );
                                                                            })
                                                                        ) : (
                                                                            <tr>
                                                                                <td colSpan="8" className="text-center">
                                                                                    {" "}
                                                                                    <div className="empty_data_img">
                                                                                        <img
                                                                                            src={require("../images/No-data.png")}
                                                                                        />
                                                                                    </div>
                                                                                    No found open orders!
                                                                                </td>
                                                                            </tr>
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                            {rewardHistory.length > 0 ? (

                                                                <div className="pagination">
                                                                    <Stack spacing={2}>
                                                                        <Pagination
                                                                            count={Math.ceil(totalPage_reward / 5)}
                                                                            page={currentPage_reward}
                                                                            onChange={handlePageChange_reward}
                                                                            renderItem={(item) => (
                                                                                <PaginationItem
                                                                                    slots={{
                                                                                        previous: ArrowBackIcon,
                                                                                        next: ArrowForwardIcon,
                                                                                    }}
                                                                                    {...item}
                                                                                />
                                                                            )}
                                                                        />
                                                                    </Stack>
                                                                </div>
                                                            ) : ""}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* ==========OREDERS Book========== */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
