import React from "react";
import { Link } from "react-router-dom";

function LandingFooter() {
  return (
    <div className="footer_main">
      {/* <div className='footer_inner_main'> */}
      <div className="row">
        <div className="col-lg-4">
          <div className="foot_frst">
            <div className="foot_frst">
              <div className="foot_frst_head">
                <img
                  src={require("../images/Foot-logo.png")}
                  alt="Logo"
                  className="foot_logo_img"
                />
                <span className="pop_in_frst">Trade with confidence</span>
              </div>
              <div className="foot_frst_icons">
                <img
                  src={require("../images/Landing/footer-icon-one.png")}
                  alt="social media"
                  className="footer_social_img"
                />
                <img
                  src={require("../images/Landing/footer-icon-scnd.png")}
                  alt="social media"
                  className="footer_social_img"
                />
                <img
                  src={require("../images/Landing/footer-icon-third.png")}
                  alt="social media"
                  className="footer_social_img"
                />
                <img
                  src={require("../images/Landing/footer-icon-fourth.png")}
                  alt="social media"
                  className="footer_social_img"
                />
                <img
                  src={require("../images/Landing/footer-icon-fifth.png")}
                  alt="social media"
                  className="footer_social_img"
                />
                <img
                  src={require("../images/Landing/footer-icon-sixth.png")}
                  alt="social media"
                  className="footer_social_img"
                />
              </div>
            </div>
          </div>
          <div className="footer_download">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="footer_download_img"
              viewBox="0 0 20 22"
              id="Download"
            >
              <g
                fill="none"
                fill-rule="evenodd"
                stroke="#000000"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                class="colorStroke000000 svgStroke"
              >
                <path
                  d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M6 11l4 4 4-4M10 1v14"
                  fill="#151717"
                  class="color000000 svgShape"
                ></path>
              </g>
            </svg>
            <span className="hero_getstarted">Download App</span>
          </div>
        </div>

        <div className="col-lg-2 mt-2">
          <div className="foot_about mt-2">
            <span className="earn_divs_inhead"> About Us</span>
            <div className="foot_frst_head">
              <span className="earn_divs_insubhead">
                {" "}
                <Link to="/about" target="_blank">
                  About Nexus Pro
                </Link>{" "}
              </span>
              <span className="earn_divs_insubhead">Community</span>
              <span className="earn_divs_insubhead">Careers</span>
            </div>
          </div>
        </div>
        <div className="col-lg-2">
          <div className="foot_about mt-2">
            <span className="earn_divs_inhead mt-2"> Products </span>
            <div className="foot_frst_head">
              {/* <span className='earn_divs_insubhead'>Buy Crypto</span> */}
              <span className="earn_divs_insubhead">
                {" "}
                <Link to="/trade/BTC_USDT">Spot</Link>{" "}
              </span>
              <span className="earn_divs_insubhead">
                {" "}
                <Link to="/staking"> Staking</Link>{" "}
              </span>
              <span className="earn_divs_insubhead">
                {" "}
                <Link to="/lanchpad">Launchpad</Link>{" "}
              </span>
            </div>
          </div>
        </div>
        <div className="col-lg-2">
          <div className="foot_about mt-2">
            <span className="earn_divs_inhead mt-2"> User Support </span>
            <div className="foot_frst_head">
              <span className="earn_divs_insubhead">Customer Service</span>
              <span className="earn_divs_insubhead"> Futures Copy trading</span>
              <span className="earn_divs_insubhead"> Help Center</span>
            </div>
          </div>
        </div>
        <div className="col-lg-2">
          <div className="foot_about mt-2">
            <span className="earn_divs_inhead mt-2"> Contact Us </span>
            <div className="foot_frst_head">
              <span className="earn_divs_insubhead">
                {" "}
                <Link to="/privacy" target="_blank">
                  Privacy Policy
                </Link>{" "}
              </span>
              <span className="earn_divs_insubhead">
                {" "}
                <Link to="/terms" target="_blank">
                  Terms And Condition
                </Link>{" "}
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}

export default LandingFooter;
