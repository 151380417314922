import React, { useEffect } from "react";
import useState from "react-usestateref";
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { Button } from "@mui/material";
import { postMethod, getMethod } from "../core/service/common.api";
import { InfinitySpin } from "react-loader-spinner";
import apiService from "../core/service/detail";

function Dashboard() {
  const navigate = useNavigate();
  const [tfaDetails, setTfaDetails] = useState("");
  const [siteLoader, setSiteLoader] = useState(false);
  const [APcodes, setAPcodes] = useState("");

  const handleClick = (path) => {
    navigate(path);
  };

  useEffect(() => {
    fetchTfaData();
    FindData();
  }, []);

  const fetchTfaData = async () => {
    try {
      var data = {
        apiUrl: apiService.getTfaDetials,
      };
      setSiteLoader(true);
      var resp = await getMethod(data);
      setSiteLoader(false);

      setTfaDetails(resp.data.tfastatus);
    } catch (error) {}
  };

  const FindData = async () => {
    var data = {
      apiUrl: apiService.findDetails,
    };
    setSiteLoader(true);

    var responce = await postMethod(data);
    setSiteLoader(false);

    if (responce.data != null) {
      if (responce.data.APcode != "") {
        var str = responce.data.APcode;
        setAPcodes(str);
      } else {
      }
    }
  };

  return (
    <div>
      <Header />
      <div className="container-fluid">
        <div className="row">
          {siteLoader == true ? (
            <div className="dashboard_content_section loadercss">
              <InfinitySpin
                height="300"
                width="300"
                color="#12d5d5"
                ariaLabel="infinity-spin-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          ) : (
            <>
              <div className="col-lg-2 col-sm-3">
                <Sidebar />
              </div>
              <div className="col-lg-10 col-md-12">
                <div className="container dash_container">
                  <span className="core_div_head">Security</span>
                  <p className="pop_bot_left1 mt-2">
                    Please configure the following verification method(s) as
                    soon as possible
                  </p>

                  <div className="row">
                    <div className="col-lg-4">
                      <div className="dash_user_main my-4">
                        <div className="Security-card">
                          <div className="sec-card-header">
                            <img
                              src={require("../images/Securitty/2FA.png")}
                              className="bgs"
                            />
                            {tfaDetails === 0 ? (
                              <Button>
                                {" "}
                                <img
                                  src={require("../images/deactive_toggle.png")}
                                  width="5px"
                                  className="disable-image mr-2"
                                />{" "}
                                Disable
                              </Button>
                            ) : (
                              <Button>
                                {" "}
                                <img
                                  src={require("../images/active_toggle.png")}
                                  width="5px"
                                  className="disable-image mr-2"
                                />{" "}
                                Enable
                              </Button>
                            )}
                          </div>

                          <h5>Google Authenticator</h5>
                          <p>
                            Google Authenticator codes help guarantee the
                            security of your account and transactions. Changing
                            your bound Google Authenticator will disable payment
                            and withdrawal for 24 hours, providing an additional
                            layer of protection to prevent unauthorized access
                            during the change.
                          </p>
                          <Button
                            className="configure"
                            onClick={() => handleClick("/security/create-tfa")}
                          >
                            configure
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="dash_user_main my-4">
                        <div className="Security-card">
                          <div className="sec-card-header">
                            <img
                              src={require("../images/Securitty/anti.jpg")}
                              className="bgs"
                            />
                            {APcodes === "" || APcodes === undefined ? (
                              <Button>
                                {" "}
                                <img
                                  src={require("../images/deactive_toggle.png")}
                                  width="5px"
                                  className="disable-image mr-2"
                                />{" "}
                                Disable
                              </Button>
                            ) : (
                              <Button>
                                {" "}
                                <img
                                  src={require("../images/active_toggle.png")}
                                  width="5px"
                                  className="disable-image mr-2"
                                />{" "}
                                Enable
                              </Button>
                            )}
                          </div>

                          <h5>Anti-phishing code</h5>
                          <p>
                            All emails sent by Bitget will include your unique
                            anti-phishing code. This ensures that the email is
                            genuinely from us, helping to protect you from
                            phishing attempts and enhancing the overall security
                            of your account communications.
                          </p>
                          <Button
                            className="configure"
                            onClick={() =>
                              handleClick("/security/anti-phising")
                            }
                          >
                            configure
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="dash_user_main my-4">
                        <div className="Security-card">
                          <div className="sec-card-header">
                            <img
                              src={require("../images/Securitty/pass.webp")}
                              className="bgs"
                            />
                          </div>

                          <h5>Login password</h5>
                          <p>
                            Your login password is crucial for maintaining
                            account and transaction security. If you change your
                            login password, payment and withdrawal functions
                            will be disabled for 24 hours as a security measure
                            to protect against unauthorized access.
                          </p>
                          <Button
                            className="configure"
                            onClick={() =>
                              handleClick("/security/change-password")
                            }
                          >
                            configure
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="dash_user_main my-4">
                        <div className="Security-card">
                          <div className="sec-card-header">
                            <img
                              src={require("../images/Securitty/active.png")}
                              className="bgs"
                            />
                          </div>

                          <h5>Trusted device management</h5>
                          <p>
                            Manage the devices allowed to access your account
                            through trusted device management. This feature
                            helps you monitor login history and ensure that only
                            authorized devices are used, enhancing the security
                            of your account.
                          </p>
                          <Button
                            className="configure"
                            onClick={() =>
                              handleClick("/security/user-activity")
                            }
                          >
                            configure
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
