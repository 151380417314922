import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { toast } from "react-toastify";
import { getMethod, postMethod } from "../core/service/common.api";
import { env } from "../core/service/envconfig";
import apiService from "../core/service/detail";
import { InfinitySpin } from "react-loader-spinner";

function Kyc() {
  const options = ["one", "two", "three"];

  const [idproof, setidproof, idproofref] = useState("");
  const [valididproof, setvalididproof] = useState(0);
  const [idproofLoad, setidproofLoad] = useState(false);

  const [addressProof, setaddressProof, addressProofref] = useState("");
  const [validaddressProof, setvalidaddressProof] = useState(0);
  const [addressProofLoad, setaddressProofLoad] = useState(false);

  const [addresIDFront, setaddresIDFront] = useState("");
  const [validaddresIDFront, setvalidaddresIDFront] = useState(0);
  const [addresIDFrontLoad, setaddresIDFrontLoad] = useState(false);

  const [addresIDBack, setaddresIDBack] = useState("");
  const [validaddresIDBack, setvalidaddresIDBack] = useState(0);
  const [addresIDBackLoad, setaddresIDBackLoad] = useState(false);

  const [photoproof, setphotoproof, photoproofref] = useState("");
  const [photoproofname, setphotoproofname, photoproofnameref] = useState("");
  const [idproofname, setidproofname, idproofnameref] = useState("");
  const [addressproofname, setaddressproofname, addressproofnameref] =
    useState("");

  const [validphotoproof, setvalidphotoproof] = useState(0);
  const [photoproofLoad, setphotoproofLoad] = useState(false);
  const [getKYCData, setgetKYCData] = useState("");
  const [siteLoader, setSiteLoader] = useState(false);
  const [kycLoader, setkycLoader] = useState(false);
  const [user, Setuser] = useState("");

  const [activeStatus, setActiveStatus] = useState(1);

  const imageUpload = (type, val) => {
    try {
      const fileExtension = val.name.split(".").at(-1);
      const fileSize = val.size;
      const fileName = val.name;
      if (
        fileExtension != "png" &&
        fileExtension != "jpg" &&
        fileExtension != "jpeg"
      ) {
        toast.error(
          "File does not support. You must use .png or .jpg or .jpeg "
        );
      } else if (fileSize > 10000000) {
        toast.error("Please upload a file smaller than 1 MB");
      } else {
        console.log("------");
        type == "idproof"
          ? setidproofLoad(true)
          : type == "addressProof"
          ? setaddressProofLoad(true)
          : type == "addressProofFront"
          ? setaddresIDFrontLoad(true)
          : type == "addressProofBack"
          ? setaddresIDBackLoad(true)
          : setphotoproofLoad(true);
        const data = new FormData();
        data.append("file", val);
        data.append("upload_preset", env.upload_preset);
        data.append("cloud_name", env.cloud_name);
        fetch(
          "https://api.cloudinary.com/v1_1/" + env.cloud_name + "/auto/upload",
          { method: "post", body: data }
        )
          .then((resp) => resp.json())
          .then((data) => {
            if (type == "idproof") {
              setidproofLoad(false);
              setvalididproof(2);
              if (
                fileExtension == "pdf" ||
                fileExtension == "odt" ||
                fileExtension == "doc"
              ) {
                setvalididproof(1);
              }

              setidproof(data.secure_url);
              setidproofname(val.name);
            }
            if (type == "addressProof") {
              setaddressProofLoad(false);
              setvalidaddressProof(2);
              if (
                fileExtension == "pdf" ||
                fileExtension == "odt" ||
                fileExtension == "doc"
              ) {
                setvalidaddressProof(1);
              }
              setaddressproofname(val.name);

              setaddressProof(data.secure_url);
            }
            if (type == "photoproof") {
              setphotoproofLoad(false);
              setvalidphotoproof(2);
              if (
                fileExtension == "pdf" ||
                fileExtension == "odt" ||
                fileExtension == "doc"
              ) {
                setvalidphotoproof(1);
              }
              setphotoproofname(val.name);

              setphotoproof(data.secure_url);
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error("Please try again later");
          });
      }
    } catch (error) {
      toast.error("Please try again later");
    }
  };

  const cancelData = () => {
    try {
      setphotoproofname("");
      setidproofname("");
      setaddressproofname("");
      setidproof("");
      setaddressProof("");
      setphotoproof("");
      setvalididproof(0);
      setvalidaddressProof(0);
      setvalidphotoproof(0);
    } catch (error) {}
  };
  const cancelidproof = () => {
    try {
      setidproofname("");
      setidproof("");
      setvalididproof(0);
    } catch (error) {}
  };
  const canceladdressproof = () => {
    try {
      setaddressproofname("");
      setaddressProof("");
      setvalidaddressProof(0);
    } catch (error) {}
  };
  const cancelphotoproof = () => {
    try {
      setphotoproofname("");
      setphotoproof("");
      setvalidphotoproof(0);
    } catch (error) {}
  };
  const uploadKYC = async () => {
    try {
      if (
        idproofref.current != "" &&
        addressProofref.current != "" &&
        photoproofref.current != ""
      ) {
        var obj = {
          addressProof: idproofref.current,
          idproof: addressProofref.current,
          photoproof: photoproofref.current,
        };

        var data = {
          apiUrl: apiService.savekyc,
          payload: obj,
        };
        setkycLoader(true);
        var resp = await postMethod(data);
        if (resp.status) {
          setkycLoader(false);
          getKyc();
          toast.success(resp.Message);
          setActiveStatus(1);
        } else {
          toast.error(resp.Message);
          setkycLoader(false);
        }
      } else {
        toast.error("Please give all proof");
      }
    } catch (error) {}
  };

  const getKyc = async () => {
    try {
      var data = {
        apiUrl: apiService.getKYC,
      };
      setSiteLoader(true);
      var resp = await getMethod(data);
      setSiteLoader(false);
      if (resp.status) {
        Setuser(resp.datas.userDetails);
        console.log(user, "user");
        if (resp.datas.kycDetails) {
          setvalididproof(2);
          setvalidaddressProof(2);
          setvalidaddresIDFront(2);
          setvalidaddresIDBack(2);
          setvalidphotoproof(2);
        }
        if (Object.keys(resp.datas.kycDetails).length > 0) {
          var kycData = resp.datas.kycDetails;

          setgetKYCData(kycData);
          console.log(kycData.proof1, "kycData.proof1");
          setidproof(kycData.proof1);
          console.log(idproofref.current, "kycData.proof1");

          setaddressProof(kycData.proof2);
          setaddresIDFront(kycData.proof3);
          setaddresIDBack(kycData.proof4);
          setphotoproof(kycData.proof3);
          var filetype_front = kycData.proof1.split(".").pop().trim();
          var filetype_back = kycData.proof2.split(".").pop().trim();
          var filetype_photoproof = kycData.proof3.split(".").pop().trim();
          if (
            filetype_front == "pdf" ||
            filetype_front == "doc" ||
            filetype_front == "docx"
          ) {
            setvalididproof(1);
          }
          if (
            filetype_back == "pdf" ||
            filetype_back == "doc" ||
            filetype_back == "docx"
          ) {
            setvalidaddressProof(1);
          }
          if (
            filetype_photoproof == "pdf" ||
            filetype_photoproof == "doc" ||
            filetype_photoproof == "docx"
          ) {
            setvalidphotoproof(1);
          }

          if (kycData.kycStatus == 3) {
            setvalididproof(2);
            setvalidaddressProof(2);
            setvalidphotoproof(2);
          }
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    getKyc();
  }, [0]);

  return (
    <div>
      <Header />
      {siteLoader == true ? (
        <div className="dashboard_content_section loadercss">
          <InfinitySpin
            height="300"
            width="300"
            color="#12d5d5"
            ariaLabel="infinity-spin-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2 col-sm-3">
              <Sidebar />
            </div>
            <div className="col-lg-10 col-md-12">
              <div className="container dash_container">
                <span className="core_div_head">Identity verification</span>
                {activeStatus == 1 ? (
                  <div className="row mt-4">
                    <div className="col-lg-7 my-2">
                      <div className="iden_left_main">
                        <div className="iden_in_top">
                          <div className="iden_one_out">
                            <span className="iden_one">1</span>
                          </div>
                          <span className="trade_divs_inhead">
                            Level 1 identity verification
                          </span>
                        </div>
                        <div className="iden_inner_card mt-3">
                          <table className="iden_frst_table">
                            <thead className="iden_in_head">
                              <th>Account perks</th>
                              <th className="text-center">Current limit</th>
                              <th className="text-end">After verification</th>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <span className="bals_divs_insubhead">
                                    Withdrawals
                                  </span>
                                </td>
                                <td className="text-center">
                                  <span className="slider_thrsleft_innerbot">
                                    20K USDT
                                  </span>
                                </td>
                                <td className="text-end">
                                  <span className="slider_thrsleft_innerbot">
                                    3M USDT
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <span className="bals_divs_insubhead">
                                    Crypto deposit
                                  </span>
                                </td>
                                <td className="text-center">
                                  <div>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="iden_tab_svg"
                                      fill-rule="evenodd"
                                      stroke-linejoin="round"
                                      stroke-miterlimit="2"
                                      clip-rule="evenodd"
                                      viewBox="0 0 500 500"
                                      id="prohibited"
                                    >
                                      <path
                                        fill="#8e8e92"
                                        d="M250,43.956C363.719,43.956 456.044,136.281 456.044,250C456.044,363.719 363.719,456.044 250,456.044C136.281,456.044 43.956,363.719 43.956,250C43.956,136.281 136.281,43.956 250,43.956ZM382.476,140.774C406.991,170.465 421.726,208.525 421.726,250C421.726,344.778 344.778,421.726 250,421.726C208.525,421.726 170.465,406.991 140.774,382.476L382.476,140.774ZM359.226,117.524L117.524,359.226C93.009,329.535 78.274,291.475 78.274,250C78.274,155.222 155.222,78.274 250,78.274C291.475,78.274 329.535,93.009 359.226,117.524Z"
                                        class="colorf12222 svgShape"
                                      ></path>
                                    </svg>
                                  </div>
                                </td>
                                <td className="text-end">
                                  <div>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="iden_tab_svg"
                                      viewBox="0 0 32 32"
                                      id="Tick"
                                    >
                                      <path
                                        d="M16,30A14,14,0,1,1,30,16,14,14,0,0,1,16,30ZM16,4A12,12,0,1,0,28,16,12,12,0,0,0,16,4ZM14.66,20.75l9-8-1.32-1.5L14,18.63,9.71,14.29,8.29,15.71l5,5A1,1,0,0,0,14,21,1,1,0,0,0,14.66,20.75Z"
                                        fill="#4bb543"
                                        class="color000000 svgShape"
                                      ></path>
                                    </svg>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <span className="bals_divs_insubhead">
                                    Fiat deposit
                                  </span>
                                </td>
                                <td className="text-center">
                                  <div>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="iden_tab_svg"
                                      fill-rule="evenodd"
                                      stroke-linejoin="round"
                                      stroke-miterlimit="2"
                                      clip-rule="evenodd"
                                      viewBox="0 0 500 500"
                                      id="prohibited"
                                    >
                                      <path
                                        fill="#8e8e92"
                                        d="M250,43.956C363.719,43.956 456.044,136.281 456.044,250C456.044,363.719 363.719,456.044 250,456.044C136.281,456.044 43.956,363.719 43.956,250C43.956,136.281 136.281,43.956 250,43.956ZM382.476,140.774C406.991,170.465 421.726,208.525 421.726,250C421.726,344.778 344.778,421.726 250,421.726C208.525,421.726 170.465,406.991 140.774,382.476L382.476,140.774ZM359.226,117.524L117.524,359.226C93.009,329.535 78.274,291.475 78.274,250C78.274,155.222 155.222,78.274 250,78.274C291.475,78.274 329.535,93.009 359.226,117.524Z"
                                        class="colorf12222 svgShape"
                                      ></path>
                                    </svg>
                                  </div>
                                </td>
                                <td className="text-end">
                                  <div>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="iden_tab_svg"
                                      viewBox="0 0 32 32"
                                      id="Tick"
                                    >
                                      <path
                                        d="M16,30A14,14,0,1,1,30,16,14,14,0,0,1,16,30ZM16,4A12,12,0,1,0,28,16,12,12,0,0,0,16,4ZM14.66,20.75l9-8-1.32-1.5L14,18.63,9.71,14.29,8.29,15.71l5,5A1,1,0,0,0,14,21,1,1,0,0,0,14.66,20.75Z"
                                        fill="#4bb543"
                                        class="color000000 svgShape"
                                      ></path>
                                    </svg>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <span className="bals_divs_insubhead">
                                    P2P trading
                                  </span>
                                </td>
                                <td className="text-center">
                                  <div>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="iden_tab_svg"
                                      fill-rule="evenodd"
                                      stroke-linejoin="round"
                                      stroke-miterlimit="2"
                                      clip-rule="evenodd"
                                      viewBox="0 0 500 500"
                                      id="prohibited"
                                    >
                                      <path
                                        fill="#8e8e92"
                                        d="M250,43.956C363.719,43.956 456.044,136.281 456.044,250C456.044,363.719 363.719,456.044 250,456.044C136.281,456.044 43.956,363.719 43.956,250C43.956,136.281 136.281,43.956 250,43.956ZM382.476,140.774C406.991,170.465 421.726,208.525 421.726,250C421.726,344.778 344.778,421.726 250,421.726C208.525,421.726 170.465,406.991 140.774,382.476L382.476,140.774ZM359.226,117.524L117.524,359.226C93.009,329.535 78.274,291.475 78.274,250C78.274,155.222 155.222,78.274 250,78.274C291.475,78.274 329.535,93.009 359.226,117.524Z"
                                        class="colorf12222 svgShape"
                                      ></path>
                                    </svg>
                                  </div>
                                </td>
                                <td className="text-end">
                                  <div>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="iden_tab_svg"
                                      viewBox="0 0 32 32"
                                      id="Tick"
                                    >
                                      <path
                                        d="M16,30A14,14,0,1,1,30,16,14,14,0,0,1,16,30ZM16,4A12,12,0,1,0,28,16,12,12,0,0,0,16,4ZM14.66,20.75l9-8-1.32-1.5L14,18.63,9.71,14.29,8.29,15.71l5,5A1,1,0,0,0,14,21,1,1,0,0,0,14.66,20.75Z"
                                        fill="#4bb543"
                                        class="color000000 svgShape"
                                      ></path>
                                    </svg>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <hr className="my-4" />
                          <div className="iden_btm">
                            <div className="dash_frst_rght">
                              <span className="pop_in_scnd">
                                Verification requirements
                              </span>
                              <div className="hero_left_bottom">
                                <i class="fa-solid fa-id-card iden_fontawe"></i>
                                <span className="slider_thrsleft_innerbot">
                                  Government-issued documents
                                </span>
                              </div>{" "}
                              <div className="hero_left_bottom">
                                <i class="fa-regular fa-user iden_fontawe"></i>
                                <span className="slider_thrsleft_innerbot">
                                  Face recognition verification
                                </span>
                              </div>{" "}
                              <div className="hero_left_bottom">
                                <i class="fa-regular fa-clock iden_fontawe"></i>
                                <span className="slider_thrsleft_innerbot">
                                  60m
                                </span>
                              </div>
                            </div>
                            <div
                              className="land_header_signup py-3"
                              onClick={() => setActiveStatus(2)}
                            >
                              <span className="land-sign-letter">Verify</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5 my-2">
                      <div className="iden_left_main">
                        <span className="core_div_head">FAQ</span>
                        <div className="FAQ-questions">
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1-content"
                              id="panel1-header"
                            >
                              Why do I need to undergo identity verification ?
                            </AccordionSummary>
                            <AccordionDetails>
                              Identity verification is a process used by
                              financial institutions and other regulated
                              organizations to confirm your identity. Nexus Pro
                              will verify your identity and conduct risk
                              assessment to mitigate risks.
                            </AccordionDetails>
                          </Accordion>
                          {/* <Accordion>
                                    <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                    >
                                    Why can't I select my country/region?
                                    </AccordionSummary>
                                    <AccordionDetails>
                                    Nexus Pro does not provide services to users in the following countries/regions: Canada (Alberta), Crimea, Cuba, Hong Kong, Iran, North Korea, Singapore, Sudan, Syria, the United States, Iraq, Libya, Yemen, Afghanistan, Central African Republic, Democratic Republic of the Congo, Guinea-Bissau, Haiti, Lebanon, Somalia, South Sudan, and the Netherlands.                                    </AccordionDetails>
                                </Accordion> */}
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel3-content"
                              id="panel3-header"
                            >
                              What documents can I submit for identity
                              verification ?
                            </AccordionSummary>
                            <AccordionDetails>
                              Level 1: ID card, passport, driver's license, and
                              proof of residence.
                              <br /> Level 2: Bank statements, utility bills
                              (within the last three months),
                              internet/cable/home phone bills, tax returns,
                              council tax bills, and government-issued proof of
                              residence.{" "}
                            </AccordionDetails>
                          </Accordion>
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1-content"
                              id="panel1-header"
                            >
                              What are the requirements for identity
                              verification ?
                            </AccordionSummary>
                            <AccordionDetails>
                              1. You must be at least 18 years old.
                              <br /> 2. You can only complete the identity
                              verification on one account.{" "}
                            </AccordionDetails>
                          </Accordion>
                          {/* <Accordion>
                                    <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                    >
                                    Why can't I select my country/region?
                                    </AccordionSummary>
                                    <AccordionDetails>
                                    Nexus Pro does not provide services to users in the following countries/regions: Canada (Alberta), Crimea, Cuba, Hong Kong, Iran, North Korea, Singapore, Sudan, Syria, the United States, Iraq, Libya, Yemen, Afghanistan, Central African Republic, Democratic Republic of the Congo, Guinea-Bissau, Haiti, Lebanon, Somalia, South Sudan, and the Netherlands.                                    </AccordionDetails>
                                </Accordion> */}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row mt-4">
                    {getKYCData.kycStatus == 3 ? (
                      <div className="col-lg-12 p-0">
                        <label className="text-danger trade_divs_inhead">
                          {" "}
                          {getKYCData.rejectReson}{" "}
                        </label>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="col-lg-4">
                      <div className="upl_main">
                        <div className="iden_upd_main">
                          <div className="iden_upd_inner">
                            <div className="iden_upd_top">
                              {user.kycstatus == 0 ? (
                                <input
                                  type="file"
                                  name="image"
                                  className="image_upload_kyc"
                                  onChange={(e) =>
                                    imageUpload("idproof", e.target.files[0])
                                  }
                                />
                              ) : (
                                ""
                              )}

                              {idproofLoad == false ? (
                                valididproof == 0 ? (
                                  <div>
                                    <img
                                      src={require("../images/upload-image.png")}
                                      alt="image-upload"
                                      className="up_im_past"
                                    />
                                  </div>
                                ) : valididproof == 1 ? (
                                  <span className="">
                                    <i class="bi bi-file-earmark-bar-graph"></i>
                                  </span>
                                ) : (
                                  <img
                                    src={idproofref.current}
                                    className="up_im_past"
                                    alt="National Id Front"
                                  />
                                )
                              ) : (
                                <div class="kyc_load_sub" role="status" f>
                                  <InfinitySpin
                                    height="80"
                                    width="80"
                                    color="#12d5d5"
                                    ariaLabel="infinity-spin-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                  />
                                </div>
                              )}

                              {/* <img src={require('../images/upload-image.png')} alt="image-upload" className="up_im_past" /> */}
                            </div>
                            <div className="iden_btm_scnd">
                              <hr />
                              <span className="trade_divs_inhead">
                                National ID
                              </span>
                            </div>
                          </div>
                        </div>
                        {idproofnameref.current == "" ? (
                          ""
                        ) : (
                          <div className="iden_upd_filnam mt-4">
                            <input
                              className="upd_fl_nam"
                              disabled
                              value={idproofnameref.current}
                            ></input>
                            <i
                              class="fa-solid fa-xmark ipd_img_can"
                              onClick={cancelidproof}
                            ></i>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="upl_main">
                        <div className="iden_upd_main">
                          <div className="iden_upd_inner">
                            <div className="iden_upd_top">
                              {user.kycstatus == 0 && (
                                <input
                                  type="file"
                                  name="image"
                                  className="image_upload_kyc"
                                  onChange={(e) =>
                                    imageUpload(
                                      "addressProof",
                                      e.target.files[0]
                                    )
                                  }
                                />
                              )}

                              {addressProofLoad == false ? (
                                validaddressProof == 0 ? (
                                  <div>
                                    <img
                                      src={require("../images/upload-image.png")}
                                      alt="image-upload"
                                      className="up_im_past"
                                    />
                                  </div>
                                ) : validaddressProof == 1 ? (
                                  <span className="">
                                    <i class="bi bi-file-earmark-bar-graph"></i>
                                  </span>
                                ) : (
                                  <img
                                    src={addressProofref.current}
                                    className="up_im_past"
                                    alt="National Id Front"
                                  />
                                )
                              ) : (
                                <div class="kyc_load_sub" role="status">
                                  <InfinitySpin
                                    height="80"
                                    width="80"
                                    color="#12d5d5"
                                    ariaLabel="infinity-spin-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                  />
                                </div>
                              )}

                              {/* <img src={require('../images/upload-image.png')} alt="image-upload" className="up_im_past" /> */}
                            </div>
                            <div className="iden_btm_scnd">
                              <hr />
                              <span className="trade_divs_inhead">
                                Proof Of Address
                              </span>
                            </div>
                          </div>
                        </div>
                        {addressproofnameref.current == "" ? (
                          ""
                        ) : (
                          <div className="iden_upd_filnam mt-4">
                            <input
                              value={addressproofnameref.current}
                              className="upd_fl_nam"
                              disabled
                            />
                            <i
                              class="fa-solid fa-xmark ipd_img_can"
                              onClick={canceladdressproof}
                            ></i>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="upl_main">
                        <div className="iden_upd_main">
                          <div className="iden_upd_inner">
                            <div className="iden_upd_top">
                              {user.kycstatus == 0 ? (
                                <input
                                  type="file"
                                  name="image"
                                  className="image_upload_kyc"
                                  onChange={(e) =>
                                    imageUpload("photoproof", e.target.files[0])
                                  }
                                />
                              ) : (
                                ""
                              )}

                              {photoproofLoad == false ? (
                                validphotoproof == 0 ? (
                                  <div>
                                    <img
                                      src={require("../images/upload-image.png")}
                                      alt="image-upload"
                                      className="up_im_past"
                                    />
                                  </div>
                                ) : validphotoproof == 1 ? (
                                  <span className="">
                                    <i class="bi bi-file-earmark-bar-graph"></i>
                                  </span>
                                ) : (
                                  <img
                                    src={photoproofref.current}
                                    className="up_im_past"
                                    alt="National Id Front"
                                  />
                                )
                              ) : (
                                <div class="kyc_load_sub" role="status">
                                  <InfinitySpin
                                    height="80"
                                    width="80"
                                    color="#12d5d5"
                                    ariaLabel="infinity-spin-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                  />
                                </div>
                              )}

                              {/* <img src={require('../images/upload-image.png')} alt="image-upload" className="up_im_past" /> */}
                            </div>
                            <div className="iden_btm_scnd">
                              <hr />
                              <div className="iden_btm_scnd">
                                <span className="trade_divs_inhead">
                                  Photo Proof{" "}
                                </span>
                                <span className="slider_left_innerbot">
                                  ( Take Selfie With Your Document )
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        {photoproofnameref.current == "" ? (
                          ""
                        ) : (
                          <div className="iden_upd_filnam mt-4">
                            <input
                              value={photoproofnameref.current}
                              className="upd_fl_nam"
                              disabled
                            ></input>
                            <i
                              class="fa-solid fa-xmark ipd_img_can"
                              onClick={cancelphotoproof}
                            ></i>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="upd_sub mt-5">
                      {getKYCData.kycStatus == 2 ||
                      getKYCData.kycStatus == 1 ? (
                        ""
                      ) : (
                        <>
                          {kycLoader == false ? (
                            <div
                              onClick={uploadKYC}
                              className="kyc_submit py-3"
                            >
                              <span className="land-sign-letter">Submit</span>
                            </div>
                          ) : (
                            <div className="kyc_submit py-3">
                              <span className="land-sign-letter">
                                Loading ...
                              </span>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Kyc;
