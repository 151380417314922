import React, { useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import useState from "react-usestateref";
import { Link, useNavigate } from "react-router-dom";
import { postMethod, getMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { toast } from "react-toastify";
import { InfinitySpin } from "react-loader-spinner";

function Dashboard() {
  const [loaderButton, setloaderButton] = useState(false);
  const [tfaDetails, setTfaDetails] = useState({});
  const [tfaCode, setTfaCode] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [addDetails, setaddDetails] = useState({});
  const [siteLoader, setSiteLoader] = useState(false);

  useEffect(() => {
    fetchTfaData();
  }, []);

  const preventInvalidKeys = async (event)=>{
      if(event.target.value.length >6){
      event.preventDefault();
      }
  }
  const fetchTfaData = async () => {
    try {
      var data = {
        apiUrl: apiService.getTfaDetials,
      };
      setSiteLoader(true);
      var resp = await getMethod(data);
      setSiteLoader(false);
      setTfaDetails(resp.data.tfastatus);
      setaddDetails(resp.data);
    } catch (error) {}
  };

  const handleSubmit = async () => {
    setValidationErrors({});
    if (!tfaCode || tfaCode === "") {
      toast("2FA code is required");
    } else {
      let tfaStatus = tfaDetails;
      var data = {
        apiUrl: apiService.changeTfaStatus,
        payload: {
          userToken: tfaCode,
          tfaStatus: tfaStatus,
        },
      };
      var resp = await postMethod(data);
      if (resp.status) {
        showsuccessToast(resp.Message);
        setTfaCode("");
        fetchTfaData();
        localStorage.setItem("tfa_status", resp.result.tfastatus);
        if (typeof resp?.errors !== "undefined") {
          const isErrorEmpty = Object.keys(resp?.errors).length === 0;
          if (!isErrorEmpty) {
            setValidationErrors(resp?.errors);
          }
        } else {
        }
      } else {
        showerrorToast(resp.Message);
      }
    }
  };
  const copy = async (text) => {
    navigator.clipboard.writeText(text);
    showsuccessToast("Secret Key Copied");
  };

  const showsuccessToast = (message) => {
    toast.dismiss();
    toast.success(message);
  };

  const showerrorToast = (message) => {
    toast.dismiss();
    toast.error(message);
  };

  return (
    <div>
      <Header />
      <div className="container-fluid">
        <div className="row">
          {siteLoader == true ? (
            <div className="dashboard_content_section loadercss">
              <InfinitySpin
                height="300"
                width="300"
                color="#12d5d5"
                ariaLabel="infinity-spin-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          ) : (
            <>
              <div className="col-lg-2 col-sm-3">
                <Sidebar />
              </div>
              <div className="col-lg-10 col-md-12">
                <div className="container dash_container">
                  <span className="core_div_head">
                    <Link to="/security">
                      {" "}
                      <i class="fa-solid text-white dash_hai_icon fa-arrow-left mt-2 ml-0 mr-2"></i>
                    </Link>{" "}
                    Link Google Authenticator
                    <h3>
                      {" "}
                      {/* {tfaDetails === 0 ? (
                    <h3>Enable 2FA </h3>
                  ) : (
                    <h3>Disable 2FA </h3>
                  )} */}
                    </h3>
                  </span>
                  <p className="pop_bot_left1  mt-2">
                    Google Authenticator is a dynamic password tool that works
                    like SMS dynamic verification. A dynamic verification code
                    is generated every 30s after binding, and the verification
                    code can be used for security verification of operations
                    such as login, coin withdrawal, and modification of security
                    settings.
                  </p>
                  <div className=" row mt-4 ">
                    <div className="col-lg-6">
                      <div className="Tfa-section mt-4">
                        <h5> 1. Download the Google Authenticator app </h5>
                        <p className="pop_bot_left1 my-4">
                          iOS users log in to the App Store to search for
                          "Authenticator". Android users log in to the app store
                          or use the mobile browser to search for "Google
                          Authenticator".
                        </p>
                      </div>

                      {tfaDetails === 0 ? (
                        <div className="Tfa-section">
                          <h5>
                            {" "}
                            2. Add a key and back up in Google Authenticator
                          </h5>
                          <p className="pop_bot_left1 my-4">
                            i) Open Google Authenticator, scan the QR code below
                            or manually enter the following key to add a
                            verification token. <br /> <br />
                            <span className="mt-4">
                              ii) The key is used to retrieve the Google
                              Authenticator when the phone is replaced or lost.
                              Be sure to save the following key backup before
                              binding.
                            </span>
                          </p>
                          <div className="tfa-cards">
                            <img src={addDetails.tfa_url} />
                            <div>
                              <h5> Seceret Key </h5>

                              <div className="earn_view_ass">
                                <div className="dash_asset_search">
                                  <input
                                    type="text"
                                    value={
                                      addDetails.tfaenablekey == undefined
                                        ? ""
                                        : addDetails.tfaenablekey
                                    }
                                    readOnly
                                    className="order-srch-input"
                                  />
                                  <i
                                    class="fa-regular fa-copy search-icon text-white"
                                    id="Eye"
                                    onClick={() =>
                                      copy(addDetails.tfaenablekey)
                                    }
                                  ></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        " "
                      )}

                      <div className="Tfa-section mt-4">
                        {tfaDetails === 0 ? (
                          <h5>
                            {" "}
                            3. Enter the 6-digit Google Authenticator code to
                            Enable the 2FA
                          </h5>
                        ) : (
                          <h5>
                            {" "}
                            2. Enter the 6-digit Google Authenticator code to
                            Disable the 2FA
                          </h5>
                        )}
                        <p className="pop_bot_left1 my-4"></p>
                        <div className="tfa-cards">
                          <div className="w_100">
                            <h5> 2FA Code </h5>
                            <div className="earn_view_ass">
                              <div className="dash_asset_search w_100 mt-3">
                                <input
                                  className="order-srch-input"
                                  type="number"
                                  pattern="\S*"
                                  min="0"
                                  placeholder="Enter Your Code"
                                  name="tfaCode"
                                  onKeyDown={preventInvalidKeys}
                                  value={tfaCode}
                                  onChange={(e) => setTfaCode(e.target.value)}
                                />
                              </div>
                            </div>

                            <div className="reg_bot_div">
                              {tfaDetails === 0 ? (
                                loaderButton == false ? (
                                  <div
                                    className="sup_submit py-3 mt-4"
                                    onClick={handleSubmit}
                                  >
                                    <span className="hero_getstarted">
                                      Enable
                                    </span>
                                  </div>
                                ) : (
                                  <div className="sup_submit py-3 mt-4">
                                    <span className="hero_getstarted">
                                      loading...
                                    </span>
                                  </div>
                                )
                              ) : loaderButton == false ? (
                                <div
                                  className="sup_submit py-3 mt-4"
                                  onClick={handleSubmit}
                                >
                                  <span className="hero_getstarted">
                                    Disable
                                  </span>
                                </div>
                              ) : (
                                <div className="sup_submit py-3 mt-4">
                                  <span className="hero_getstarted">
                                    loading...
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
