
import Header from "./Header";
import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import { setAuthorization } from "../core/service/axios";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";

function Login() {

  const initialFormValue = {
    tfa: "",
  };
  const [formValue, setFormValue] = useState(initialFormValue);
  const [tfaValidate, settfaValidate] = useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [buttonLoader, setbuttonLoader] = useState(false);
  const navigate = useNavigate();

  const { tfa } = formValue;
  const { state } = useLocation();
  const [siteLoader, setSiteLoader] = useState(false);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    validate(formData);
  };
  const formSubmit = async () => {
    validate(formValue);
    if (formValue.tfa !== "") {
      var data = {
        apiUrl: apiService.tfaVerify,
        payload: {
          userToken: tfa,
          socketToken: state?.socketToken,
        },
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setbuttonLoader(false);
      console.log("tfa login===", resp);
      if (resp.status) {
        toast.success(resp.Message);
        await setAuthorization(resp.token);
        localStorage.setItem("user_token", resp.token);
        localStorage.setItem("tfa_status", resp.tfa);
        localStorage.setItem("socket_token", resp.socketToken);
        navigate("/dashboard");
      } else {
        toast.error(resp.Message);
      }
    }
  };
  const validate = async (values) => {
    const errors = {};
    if (!values.tfa) {
      errors.tfa = "2FA is Required";
      settfaValidate(true);
    }
    setvalidationnErr(errors);
    return errors;
  };

  return (
    <div>
      <Header />
      <div className="reg_main">
        <div className="container">
          <div className="row reg_row">
            <div className="col-lg-6 mt-2">
              <img
                src={require("../images/login-image.png")}
                alt="Core products"
                width="100%"
              />
            </div>
            <div className="col-lg-6 mt-2">
              <div className="reg_right_main">
                <span className="reg_left_head">Verify 2FA Code </span>
                <div className="reg_in_main">
                  <div className="log_in_top">
                    <div className="reg_infields">
                      <div className="req_field_sin">
                        <span className="earn_divs_insubhead">
                          2FA Authentication Code
                        </span>
                        <input
                          type="number"
                          className="reg_inp_field"
                          placeholder="Enter Your 2FA Code "
                          min={100000}
                          name="tfa"
                          pattern="\S*"
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-"].includes(
                              evt.key
                            ) && evt.preventDefault()
                          }
                          value={tfa}

                          onChange={(e) => {
                            const value = e.target.value;
                            if (value.length <= 6) {
                              setFormValue({ "tfa": value });
                            }
                          }}
                        // onChange={handleChange}
                        />
                        {tfaValidate == true ? (
                          <p className="text-danger">
                            {" "}
                            {validationnErr.tfa}{" "}
                          </p>
                        ) : (
                          ""
                        )}

                      </div>
                    </div>
                    <div className="reg_bot_div">
                      <div className="reg_submit mt-3" onClick={formSubmit}>

                        {buttonLoader == false ? (
                          <span className="hero_getstarted">Verify</span>

                        ) : (
                          <span className="hero_getstarted">Loading ...</span>
                        )}
                      </div>
                    </div>

                    <div className="reg_bottm_glg">
                      <span className="slider_left_innerbot">
                        Back to
                        <Link to="/signup"> Login page  </Link>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
