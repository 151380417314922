import Header from "./Header";
import React, { useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "../../src/App.css";
import useState from "react-usestateref";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";

function Register() {
    const initialFormValue = {
        name: "",
        email: "",
        password: "",
        confirmPassword: "",
        referral_code: "",

        // isTerms: ""
    };

    const [validationnErr, setvalidationnErr] = useState("");
    const [formValue, setFormValue] = useState(initialFormValue);
    const [nameValidate, setnameValidate, nameValidateref] = useState(false);
    const [emailValidate, setemailValidate, emailValidateref] = useState(false);
    const [passwordValidate, setpasswordValidate, passwordValidateref] =
        useState(false);
    const [
        confirmPasswordValidate,
        setconfirmPasswordValidate,
        confirmPasswordValidateref,
    ] = useState(false);
    const [isChecked, setIschecked] = useState(false);
    const [Terms, setTerms, Termsref] = useState(false);
    const [termsValidation, setTermsValidation] = useState(false);
    const [buttonLoader, setbuttonLoader] = useState(false);
    const [passHide, setPasshide] = useState(false);
    const [inputType, setinputType] = useState("password");
    const [passHidconf, setPasshideconf] = useState(false);
    const [inputTypeconf, setinputTypeconf] = useState("password");

    const { name, email, password, confirmPassword, referral_code } = formValue;
    const [siteLoader, setSiteLoader] = useState(false);

    const navigate = useNavigate();

    const createUid = uuidv4();
    const createdUuid = createUid.split("-")[0].toString();

    const handleChange = async (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/\s/g, "");
        let formData = { ...formValue, ...{ [name]: sanitizedValue } };
        setFormValue(formData);
        validate(formData);
    };

    const handleTerms = (event) => {
        setIschecked(event.target.checked);
        setTerms(event.target.checked);
        setTermsValidation(!event.target.checked);
    };

    const validate = (values) => {
        let errors = {};
        const username = values.email.split("@")[0];

        if (!values.name) {
            errors.name = "Name is a required field !";
            setnameValidate(true);
        } else if (!/^[a-zA-Z]/.test(values.name)) {
            errors.name = "Username must start with a letter !";
            setnameValidate(true);
        } else if (values.name.length < 3 || values.name.length > 25) {
            errors.name = "Name must have an  3 to 25 characters !";
            setnameValidate(true);
        } else if (!/^[a-zA-Z0-9_]+$/.test(values.name)) {
            errors.name =
                "Username can only contain letters, numbers, and underscores !";
            setnameValidate(true);
        } else if (!values.email) {
            setnameValidate(false);
            errors.email = "Email is a required field!";
            setemailValidate(true);
        } else if (
            !/^[a-zA-Z0-9.]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(values.email)
        ) {
            errors.email = "Invalid email address!";
            setemailValidate(true);
        } else if (username.length < 4 || username.length > 30) {
            errors.email = "Email Username must be between 4-30 characters long";
            setemailValidate(true);
        } else if (!/^[a-zA-Z0-9.]+$/i.test(username)) {
            errors.email =
                " Only letters (a-z), numbers (0-9), and periods (.) are allowed in the username";
            setemailValidate(true);
        } else if (!/[a-zA-Z]/.test(username)) {
            errors.email = "Email username must contain at least one letter (a-z)";
            setemailValidate(true);
        } else if (values.email.length > 50) {
            errors.email = "Email address is too long!";
            setemailValidate(true);
        } else if (values.password == "") {
            setemailValidate(false);
            setpasswordValidate(true);
            errors.password = "password is required !";
        } else if (values.password.length < 5 || values.password.length > 25) {
            setpasswordValidate(true);
            errors.password = "Password should not below 5 above 25 letters !";
        } else if (!values.password.match(/[a-z]/g)) {
            setpasswordValidate(true);
            errors.password = "Please enter at least lower character !";
        } else if (!values.password.match(/[A-Z]/g)) {
            setpasswordValidate(true);
            errors.password = "Please enter at least upper character !";
        } else if (!values.password.match(/[0-9]/g)) {
            setpasswordValidate(true);
            errors.password = "Please enter at One digit character !";
        } else if (!values.password.match(/[!@#$%^&*]/g)) {
            setpasswordValidate(true);
            errors.password = "Please enter at least one special character !";
        } else if (!values.confirmPassword) {
            setpasswordValidate(false);
            errors.confirmPassword = "Confirm password is a required field";
            setconfirmPasswordValidate(true);
        } else if (
            values.password &&
            values.confirmPassword &&
            values.password !== values.confirmPassword
        ) {
            errors.confirmPassword = "Password and Confirm password does not match";
            setconfirmPasswordValidate(true);
        } else if (!Terms) {
            setconfirmPasswordValidate(false);
            errors.terms = "Terms is a required field ";
            setTermsValidation(true);
        } else {
            errors.terms = "";
            setconfirmPasswordValidate(false);
            setpasswordValidate(false);
            setemailValidate(false);
            setnameValidate(false);

            setTermsValidation(false);
        }
        setvalidationnErr(errors);

        return errors;
    };

    const passwordHide = (data) => {
        if (data == "hide") {
            setPasshide(true);
            setinputType("text");
        } else {
            setPasshide(false);
            setinputType("password");
        }
    };

    const passwordHideconf = (data) => {
        if (data == "hide") {
            setPasshideconf(true);
            setinputTypeconf("text");
        } else {
            setPasshideconf(false);
            setinputTypeconf("password");
        }
    };

    const formSubmit = async (payload) => {
        let errros = validate(formValue);

        console.log(errros, "errros---");

        formValue["UUID"] = createdUuid;
        console.log(formValue, "=-=-=-formvalue=-=-");
        if (
            nameValidateref.current === false &&
            emailValidateref.current === false &&
            passwordValidateref.current === false &&
            confirmPasswordValidateref.current === false &&
            Terms == true
        ) {
            console.log(formValue);
            var data = {
                apiUrl: apiService.signup,
                payload: formValue,
            };
            setbuttonLoader(true);
            var resp = await postMethod(data);
            setFormValue(initialFormValue);
            localStorage.setItem("useremail", resp.email);
            setbuttonLoader(false);
            if (resp.status) {
                toast.success(resp.Message);
                navigate("/verify-email");
            } else {
                toast.error(resp.Message);
            }
        } else {
        }
    };

    const location = useLocation();
    useEffect(() => {
        var currURL = window.location.href;
        var refferalId = currURL.split("invite=")[1];

        formValue.referral_code = refferalId;

        const params = new URLSearchParams(location.search);
        const emailFromQuery = params.get('email');
        if (emailFromQuery) {
            console.log(emailFromQuery,"emailFromQuery");
            setFormValue(st => ({
                ...st, 
                email: emailFromQuery
            }))
          }
      
    }, [location]);
    return (
        <div>
            <Header />
            <div className="reg_main">
                <div className="container">
                    <div className="row reg_row">
                        <div className="col-lg-6 mt-2">
                            <img
                                src={require("../images/login-image.png")}
                                alt="Core products"
                                width="100%"
                            />
                        </div>
                        <div className="col-lg-6 mt-2">
                            <div className="reg_right_main">
                                <span className="reg_left_head">Welcome to Nexus Pro</span>
                                <div className="reg_in_main">
                                    <div className="reg_in_top">
                                        <div className="reg_infields">
                                            <div className="req_field_sin">
                                                <span className="earn_divs_insubhead">Name</span>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    minLength={3}
                                                    maxLength={25}
                                                    value={name}
                                                    onChange={handleChange}
                                                    className="reg_inp_field"
                                                    placeholder="Enter your Name"
                                                />
                                                {validationnErr && validationnErr.name && (
                                                    <p className="errorcss">{validationnErr.name}</p>
                                                )}
                                            </div>

                                            <div className="req_field_sin">
                                                <span className="earn_divs_insubhead">Email</span>
                                                <input
                                                    type="text"
                                                    name="email"
                                                    value={email}
                                                    onChange={handleChange}
                                                    className="reg_inp_field"
                                                    placeholder="Enter your email"
                                                />
                                                {validationnErr && validationnErr.email && (
                                                    <p className="errorcss">{validationnErr.email}</p>
                                                )}
                                            </div>

                                            <div className="req_field_sin">
                                                <span className="earn_divs_insubhead">Password</span>
                                                <div className="flex_input_posion">
                                                    <input
                                                        type={inputType}
                                                        name="password"
                                                        value={password}
                                                        minLength={6}
                                                        maxLength={15}
                                                        onChange={handleChange}
                                                        className="reg_inp_field"
                                                        placeholder="Enter your password"
                                                    />

                                                    {passHide == true ? (
                                                        <i class="fa-regular fa-eye reg_eye" onClick={() => passwordHide("show")}></i>
                                                      
                                                    ) : (
                                                        <i class="fa-regular fa-eye-slash reg_eye" onClick={() => passwordHide("hide")}></i>
                                                    )}
                                                </div>
                                                {validationnErr && validationnErr.password && (
                                                    <p className="errorcss">{validationnErr.password}</p>
                                                )}
                                            </div>

                                            <div className="req_field_sin">
                                                <span className="earn_divs_insubhead">
                                                    Confirm Password
                                                </span>
                                                <div className="flex_input_posion">
                                                    <input
                                                        type={inputTypeconf}
                                                        name="confirmPassword"
                                                        value={confirmPassword}
                                                        minLength={6}
                                                        maxLength={15}
                                                        onChange={handleChange}
                                                        className="reg_inp_field"
                                                        placeholder="Enter your email"
                                                    />

                                                    {passHidconf == true ? (
                                                         <i class="fa-regular fa-eye reg_eye" onClick={() => passwordHideconf("show")}></i>
                                                    ) : (
                                                        <i class="fa-regular fa-eye-slash reg_eye" onClick={() => passwordHideconf("hide")}></i>
                                                    )}
                                                </div>
                                                {validationnErr && validationnErr.confirmPassword && (
                                                    <p className="errorcss">
                                                        {validationnErr.confirmPassword}
                                                    </p>
                                                )}
                                            </div>

                                            <div className="req_field_sin">
                                                <span className="earn_divs_insubhead">
                                                    Refferal code
                                                </span>
                                                <input
                                                    type="text"
                                                    className="reg_inp_field"
                                                    placeholder="Refferal code ( Optional )"
                                                    name="referral_code"
                                                    maxLength={6}
                                                    value={referral_code}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="reg_bot_div">
                                            <div className="reg_bug_chn">
                                                <input
                                                    type="checkbox"
                                                    className="reg_check"
                                                    value={isChecked}
                                                    name="check"
                                                    onChange={handleTerms}
                                                    id="customCheck"
                                                />

                                                <span
                                                    className="slider_left_innerbot"
                                                    for="customCheck"
                                                >
                                                    {" "}
                                                    I have read and agree to the
                                                    <Link to="/terms" target="_blank"> Terms & Conditions </Link> and{" "}
                                                    <Link to="/privacy" target="_blank"> Privacy Policy</Link>
                                                </span>
                                            </div>

                                            {termsValidation ? (
                                                <p className="errorcss">
                                                    Terms and Conditions are required
                                                </p>
                                            ) : (
                                                ""
                                            )}
                                            <div className="reg_submit" onClick={formSubmit}>
                                                {buttonLoader == false ? (
                                                    <span
                                                        className="hero_getstarted"
                                                    >
                                                        Create Account
                                                    </span>
                                                ) : (
                                                    <span className="hero_getstarted">Loading ...</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="reg_bottm_glg">
                                        <span className="slider_left_innerbot">
                                            Already have an account ?<Link to="/login"> Log in </Link>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Register;
