import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { postMethod, getMethod } from "../core/service/common.api";
import useState from "react-usestateref";
import apiService from "../core/service/detail";
import { InfinitySpin } from "react-loader-spinner";
import { toast } from "react-toastify";

function Dashboard() {
  const navigate = useNavigate();

  const back = () => {
    navigate("/security");
  };
  const initialFormValue = {
    oldpassword: "",
    password: "",
    confirmPassword: "",
  };
  const [formValue, setFormValue] = useState(initialFormValue);

  const { oldpassword, password, confirmPassword } = formValue;
  const [passwordValidate, setpasswordValidate, passwordValidateref] =
    useState(false);
  const [
    confirmPasswordValidate,
    setconfirmPasswordValidate,
    confirmPasswordValidateref,
  ] = useState(false);
  const [oldpassvalidate, setoldpassvalidate, oldpassvalidateref] =
    useState(false);
  const [passHide, setPasshide] = useState(false);
  const [inputType, setinputType] = useState("password");
  const [passHidconf, setPasshideconf] = useState(false);
  const [inputTypeconf, setinputTypeconf] = useState("password");
  const [passHidnew, setPasshidenew] = useState(false);
  const [inputTypenew, setinputTypenew] = useState("password");
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [validationnErr, setvalidationnErr] = useState("");

  const validate = async (values) => {
    const errors = {};
    if (values.oldpassword == "") {
      errors.oldpassword = "Old Password is required";
      setoldpassvalidate(true);
    } else {
      setoldpassvalidate(false);
    }

    if (values.password == "") {
      setpasswordValidate(true);
      errors.password = "Password is required";
    } else if (values.password.length < 8 || values.password.length > 45) {
      setpasswordValidate(true);
      errors.password =
        "New Password is required and shouldnot below 8 above 45letters";
    } else if (!values.password.match(/[a-z]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least lower character";
    } else if (!values.password.match(/[A-Z]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least upper character";
    } else if (!values.password.match(/[0-9]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter alone digit character";
    } else if (values.password == values.oldpassword) {
      errors.password = "Old Password and New password should not be same";
      setpasswordValidate(true);
    } else {
      setpasswordValidate(false);
    }

    if (values.confirmPassword == "") {
      errors.confirmPassword = "Confirm password is a required";
      setconfirmPasswordValidate(true);
    } else if (values.confirmPassword != values.password) {
      setconfirmPasswordValidate(true);
      errors.confirmPassword = "Password and confirm password does not match";
    } else {
      setconfirmPasswordValidate(false);
    }
    setvalidationnErr(errors);
    return errors;
  };

  const [buttonStatus, setButtonstatus] = useState(false);
  const [siteLoader, setSiteLoader] = useState(false);

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    validate(formData);
    if (
      confirmPasswordValidateref.current == false &&
      passwordValidateref.current == false &&
      oldpassvalidateref.current == false
    ) {
      setButtonstatus(true);
    } else {
      setButtonstatus(false);
    }
  };

  const formSubmit = async () => {
    validate(formValue);
    console.log(formValue, "formValue");
    if (
      confirmPasswordValidateref.current == false &&
      passwordValidateref.current == false &&
      oldpassvalidateref.current == false
    ) {
      var obj = {
        oldPass: formValue.oldpassword,
        password: formValue.password,
        cpass: formValue.confirmPassword,
      };

      var data = {
        apiUrl: apiService.changePassword,
        payload: obj,
      };
      setbuttonLoader(true);
      setSiteLoader(true);
      var resp = await postMethod(data);
      localStorage.setItem("useremail", resp.email);
      setbuttonLoader(false);
      setSiteLoader(false);
      if (resp.status) {
        toast(resp.Message);
        localStorage.clear();
        navigate("/login");
        window.location.reload(true);
      } else {
        toast(resp.Message);
      }
    }
  };

  const passwordHide = (data) => {
    if (data == "hide") {
      setPasshide(true);
      setinputType("text");
    } else {
      setPasshide(false);
      setinputType("password");
    }
  };

  const passwordHideconf = (data) => {
    if (data == "hide") {
      setPasshideconf(true);
      setinputTypeconf("text");
    } else {
      setPasshideconf(false);
      setinputTypeconf("password");
    }
  };

  const passwordHidenewP = (data) => {
    if (data == "hide") {
      setPasshidenew(true);
      setinputTypenew("text");
    } else {
      setPasshidenew(false);
      setinputTypenew("password");
    }
  };

  return (
    <div>
      <Header />
      <div className="container-fluid innerpages">
        <div className="row">
          {siteLoader == true ? (
            <div className="dashboard_content_section loadercss">
              <InfinitySpin
                height="40"
                width="40"
                color="#1062fe"
                ariaLabel="infinity-spin-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          ) : (
            <>
              <div className="col-lg-2 col-sm-3">
                <Sidebar />
              </div>
              <div className="col-lg-10 col-md-12">
                <div className="container dash_container">
                  <span className="core_div_head">
                    <Link to="/security">
                      {" "}
                      <i class="fa-solid text-white ml-0 dash_hai_icon fa-arrow-left mt-2 mr-2"></i>
                    </Link>{" "}
                    Set Login Password
                  </span>
                  <div className=" row mt-4 justify-content-center">
                    <div className="col-lg-6 Innercard">
                      <span className="reg_left_head text-center">
                        Change Password
                      </span>
                      <div className="reg_in_main">
                        <div className="log_in_top">
                          <div className="reg_infields">
                            <div className="req_field_sin">
                              <span className="earn_divs_insubhead">
                                Old Password
                              </span>
                              <div className="flex_input_posion">
                                <input
                                  type={inputType}
                                  name="oldpassword"
                                  className="reg_inp_field"
                                  placeholder="Enter Your Old Password"
                                  maxLength={40}
                                  value={oldpassword}
                                  onChange={handleChange}
                                />

                                {passHide == true ? (
                                  <i class="fa-regular fa-eye reg_eye"  onClick={() => passwordHide("show")}></i>
                                 
                                ) : (

                                  <i class="fa-regular fa-eye-slash reg_eye"  onClick={() => passwordHide("hide")}></i>
                             
                                )}

                                {oldpassvalidate == true ? (
                                  <small className="errorcss">
                                    {validationnErr.oldpassword}
                                  </small>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="req_field_sin">
                              <span className="earn_divs_insubhead">
                                New Password
                              </span>
                              <div className="flex_input_posion">
                                <input
                                  type={inputTypenew}
                                  name="password"
                                  maxLength={40}
                                  value={password}
                                  onChange={handleChange}
                                  className="reg_inp_field"
                                  placeholder="Enter Your New Password"
                                />

                                {passHidnew == true ? (
                                  <i class="fa-regular fa-eye reg_eye" onClick={() => passwordHidenewP("show")}></i>

                               
                                ) : (
                                  <i class="fa-regular fa-eye-slash reg_eye" onClick={() => passwordHidenewP("hide")}></i>

                                
                                )}
                                {passwordValidate == true ? (
                                  <small className="errorcss">
                                    {" "}
                                    {validationnErr.password}{" "}
                                  </small>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>

                            <div className="req_field_sin">
                              <span className="earn_divs_insubhead">
                                Confirm Password
                              </span>
                              <div className="flex_input_posion">
                                <input
                                  className="reg_inp_field"
                                  placeholder="Enter your confirm password"
                                  type={inputTypeconf}
                                  name="confirmPassword"
                                  maxLength={40}
                                  value={confirmPassword}
                                  onChange={handleChange}
                                />
                                {passHidconf == true ? (
                                  <i class="fa-regular fa-eye reg_eye" onClick={() => passwordHideconf("show")}></i>

                                 
                                ) : (
                                  <i class="fa-regular fa-eye-slash reg_eye" onClick={() => passwordHideconf("hide")}></i>

                           
                                )}

                                {confirmPasswordValidate == true ? (
                                  <small className="errorcss">
                                    {" "}
                                    {validationnErr.confirmPassword}{" "}
                                  </small>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="reg_bot_div">
                            <div className="reg_submit mt-3" onClick={formSubmit}>
                              {buttonLoader == false ? (
                                <span
                                  className="hero_getstarted"
                                >
                                  Change Password
                                </span>
                              ) : (
                                <span className="hero_getstarted">
                                  Loading ...
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
