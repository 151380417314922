import React,{useEffect,useState} from 'react';
import Header from "./Header";
import { toast } from "react-toastify";
import { getMethod , postMethod } from "../core/service/common.api";
import { env } from "../core/service/envconfig";
import apiService from "../core/service/detail";
import moment from "moment"

const PrivacyPolicy = () => {
    const currentDate = new Date().toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });


  useEffect(() => {
    getDetails();
  }, []);
  const [pageDetails, setpageDetails] = useState("");
  var getDetails = async () => {
    try {
      var obj = {
        key: "privacypolicy",
      };

      var data = {
        apiUrl: apiService.terms,
        payload: obj,
      };
      var resp = await postMethod(data);
      setpageDetails(resp.data)
    } catch (err) {
      console.log(err, "terms");
    }
  };

    return (

        <div>

            <Header />

            <div className='container'>
                <div className="privacy">
                    <h2>Privacy Policy</h2>
                    <p>Last Updated: {moment(pageDetails.updated_at).format("lll")}</p>,
          <p  dangerouslySetInnerHTML={{ __html: pageDetails.content_description }}></p>

                    </div>


                    {/* <p>At X-Change, we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data when you use our services.</p>

                    <ol>
                        <li>
                            <strong>Information We Collect</strong>
                            <ul>
                                <li><strong>Personal Information:</strong> When you create an account, we collect personal information such as your name, email address, phone number, and payment information.</li>
                                <li><strong>Usage Data:</strong> We collect data on how you use our platform, including your IP address, browser type, pages visited, and time spent on our site.</li>
                                <li><strong>Cookies:</strong> We use cookies to enhance your experience and gather information about your interactions with our services.</li>
                            </ul>
                        </li>
                        <li>
                            <strong>How We Use Your Information</strong>
                            <ul>
                                <li><strong>Service Delivery:</strong> To provide, maintain, and improve our services, including trading, swapping, staking, and participating in our launchpad and launchpool.</li>
                                <li><strong>Security:</strong> To implement security measures like 2FA and Anti-Phishing to protect your account.</li>
                                <li><strong>Communication:</strong> To send you updates, notifications, and other information related to your account and our services.</li>
                                <li><strong>Compliance:</strong> To comply with legal and regulatory requirements.</li>
                            </ul>
                        </li>
                        <li>
                            <strong>Sharing Your Information</strong>
                            <p>We do not share your personal information with third parties except:</p>
                            <ul>
                                <li><strong>Service Providers:</strong> We may share your data with third-party service providers who assist us in operating our platform.</li>
                                <li><strong>Legal Requirements:</strong> When required by law or to protect our rights and users.</li>
                            </ul>
                        </li>
                        <li>
                            <strong>Data Security</strong>
                            <p>We implement robust security measures, including encryption and multi-factor authentication, to protect your data from unauthorized access.</p>
                        </li>
                        <li>
                            <strong>Your Rights</strong>
                            <p>You have the right to access, update, and delete your personal information. You can manage your preferences and privacy settings through your account.</p>
                        </li>
                        <li>
                            <strong>Changes to This Policy</strong>
                            <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on our website.</p>
                        </li>
                    </ol>

                    <h3>Contact Us</h3>
                    <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:support@xchange.com">support@xchange.com</a>.</p> */}
                </div>
        </div>



    );
}

export default PrivacyPolicy;
