import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import moment from "moment";
import Button from "@mui/material/Button";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import apiService from "../core/service/detail";
import { Link, useNavigate } from "react-router-dom";
import { getMethod, postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";

function Dashboard() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "#fff0",
    border: "2px solid #000",
    boxShadow: 0,
    p: 0,
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [stakestage, setstakestage] = useState("flexible");

  const stakestagefunction = async (data) => {
    setstakestage(data);
    getStakingHistory(1);
  };

  const [perpage, setperpage] = useState(10);
  const [stakeDetails, setstakeDetails] = useState([]);
  const [totalactive, settotalactive] = useState(0);
  const [staking1, setstaking1] = useState({});
  const [staking2, setstaking2, staking2ref] = useState({});
  const [staking3, setstaking3] = useState("");
  const [staking4, setstaking4] = useState("");
  const [userBalance, setuserBalance] = useState([]);
  const [currentPack, setcurrentPack] = useState("");
  const [stakeBalance, setStakeBalance] = useState("");
  const [fromDates, setfromData] = useState("");
  const [toDates, settoDate] = useState("");
  const [apy, setAPY] = useState(0);
  const [apr, setAPR] = useState(0);
  const [FlexibleEarn, setFlexibleEarn] = useState(0);

  const [authToken, setauthToken] = useState(false);
  const [stakeValue, setstakeValue] = useState(0);
  const [currentDuration, setcurrentDuration] = useState(0);
  const [currentDurationFlex, setcurrentDurationFlex] = useState(0);
  const [TotalFlexible, setTotalFlexible] = useState(0);

  const [userTotlaInterest, setuserTotlaInterest] = useState(0);
  const [usetDailyInterest, setusetDailyInterest] = useState(0);
  const [stakeHistory, setstakeHistory, stakeHistoryref] = useState([]);
  const [validation, setValidation] = useState(false);
  const [validationErr, setValidationErr] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [fixedvalue, setFixedValue] = useState(8);
  const [totalStakedValue, settotalStakedValue] = useState(0);
  const [totallockedValue, settotallockedValue] = useState(0);
  const [totalStakedValueINR, settotalStakedValueINR] = useState(0);
  const [totallockedValueINR, settotallockedValueINR] = useState(0);
  const [stakedcurrency, setStakedCurrency] = useState(0);
  const [interest, setInterest] = useState(0);
  const [stakingType, setstakingType] = useState("fixed");
  const [selectedFlex, setselectedFlex] = useState("");
  const [adminProfit, setAdminprofit] = useState(0);
  const [flexibleStartDate, setflexibleStartDate] = useState("");
  const [flexibleEndDate, setflexibleEndDate] = useState("");

  const [yieldDuration, setyieldDuration] = useState(0);
  const [yiledSelectDur, setyiledSelectDur] = useState("");

  const [yieldID, setyieldID] = useState("");
  const [yieldData, setYieldData] = useState("");
  const [currentYieldYear, setcurrentYieldYear] = useState(365);
  const [yieldAPY, setyieldAPY] = useState(0);
  const [yiledToDate, setyiledToDate] = useState("");
  const [yiledstartDate, setyiledstartDate] = useState("");
  const [yiledStakeType, setyiledStakeType] = useState(1);
  const [yiledDays, setYiledDays] = useState(0);
  const [YieldEstimation, setYieldEstimation] = useState(0);
  const [YieldEstimation_interest, setYieldEstimation_interest] = useState(0);
  const [YieldValue, setYieldValue] = useState(0);

  const [YieldFirst, setYieldFirst] = useState(0);
  const [YieldSecond, setYieldSecond] = useState(0);
  const [YieldThird, setYieldThird] = useState(0);
  const [YieldFourth, setYieldFourth] = useState(0);

  const [stake_type, set_type, stake_typeref] = useState("");

  const [stakeType, setstakeType, stakeTyperef] = useState("fixed");

  const [next_claim, setnext_claim, next_claimref] = useState("");

  const navigate = useNavigate();

  const data = [
    {
      value: "",
      label: "All",
    },
    {
      value: "fixed",
      label: "Fixed",
    },
    {
      value: "Flexible",
      label: "Flexible",
    },
    {
      value: "yield",
      label: "Yield",
    },
  ];

  const [pageLoader, setpageLoader] = useState(false);

  const [stakeHistory_fixed, setstakeHistory_fixed, stakeHistory_fixedref] =
    useState([]);

  const [
    stakeHistory_flexible,
    setstakeHistory_flexible,
    stakeHistory_flexibleref,
  ] = useState([]);

  const [stakeHistory_yield, setstakeHistory_yield, stakeHistory_yieldref] =
    useState([]);

  const [stakePage, setstakePage] = useState(false);

  useEffect(() => {
    let getToken = localStorage.getItem("user_token");
    if (getToken != "" && getToken != undefined && getToken != null) {
      setauthToken(true);
      getBalance();
      getStakingHistory(1);
      getStakingHistory_fixed(1);
      getStakingHistory_flexible(1);
      getStakingHistory_yield(1);
      getStakeTotalDetails();
      getTodayDate();
    } else {
      setauthToken(false);
    }

    getStakeDetails(1);
  }, [0]);

  const getTodayDate = async () => {
    var d = new Date();
    var fromDate =
      (await d.getDate()) +
      "/" +
      (d.getMonth() + 1) +
      "/" +
      d.getFullYear() +
      " " +
      d.getHours() +
      ":" +
      d.getMinutes();
    setfromData(fromDate);
    setflexibleStartDate(fromDate);

    var myDate = new Date(d.getTime() + 365 * 24 * 60 * 60 * 1000);

    var toDate =
      (await myDate.getDate()) +
      "/" +
      (myDate.getMonth() + 1) +
      "/" +
      myDate.getFullYear() +
      " " +
      myDate.getHours() +
      ":" +
      myDate.getMinutes();
    setflexibleEndDate(toDate);
  };

  const getStakeDetails = async (pages) => {
    var obj = {
      FilPerpage: perpage,
      FilPage: pages,
      search: "",
    };

    var data = {
      apiUrl: apiService.getStatkingDetails,
      payload: obj,
    };
    setpageLoader(true);

    var resp = await postMethod(data);
    setpageLoader(false);
    if (resp.status == true) {
      var datas = resp.data.result;
      settotalactive(resp.data.count);
      console.log(datas, "=-=-=-=-data");
      setstakeDetails(datas);
    } else {
      setstakeDetails([]);
    }
  };

  const chooseDuration = async (selectedduration, data, duration) => {
    try {
      console.log(
        selectedduration,
        "=-==-selectedduration-=-= ",
        data,
        duration
      );
      getStakeTotalDetails(data.currencySymbol);
      setcurrentDuration(+duration);
      var obj = {
        status: selectedduration,
        id: data._id,
      };
      setstaking1(obj);
      if (selectedduration == "stake1") {
        setAPY(data.FistDurationAPY);
      } else if (selectedduration == "stake2") {
        setAPY(data.SecondDurationAPY);
      } else if (selectedduration == "stake3") {
        setAPY(data.ThirdDurationAPY);
      } else if (selectedduration == "stake4") {
        setAPY(data.FourthDurationAPY);
      } else {
        setAPY(0);
      }

      dataCalculation(selectedduration, data, duration);
    } catch (error) {}
  };

  const dataCalculation = async (stakePosition, statkeDetails, duration) => {
    console.log("=-=-duration=-=", duration);
    try {
      var plans = parseFloat(duration);
      var d = new Date();
      var fromDate =
        (await d.getDate()) +
        "/" +
        (d.getMonth() + 1) +
        "/" +
        d.getFullYear() +
        " " +
        d.getHours() +
        ":" +
        d.getMinutes();
      setfromData(fromDate);

      var myDate = new Date(new Date().getTime() + plans * 24 * 60 * 60 * 1000);

      var toDate =
        (await myDate.getDate()) +
        "/" +
        (myDate.getMonth() + 1) +
        "/" +
        myDate.getFullYear() +
        " " +
        myDate.getHours() +
        ":" +
        myDate.getMinutes();
      if (stakingType == "yield") {
        setyiledToDate(toDate);
      } else {
        settoDate(toDate);
      }
    } catch (error) {
      showerrorToast("Please try again later");
    }
  };

  // const getBalance = async () => {
  //   try {
  //     // setpageLoader(true)
  //     var data = {
  //       apiUrl: apiService.getUserBalanceAll,
  //     };
  //     var resp = await getMethod(data);
  //     // setpageLoader(false)

  //     if (resp.status) {
  //       setuserBalance(resp.data);
  //       console.log(resp.data, "=-=-resp-=-=-=resp-=-resp.data");
  //     }
  //   } catch (error) {
  //     // showerrorToast("Please try again later");
  //   }
  // };
  const [currencyBalance, setcurrencyBalance] = useState(0);
  const stake_currency_balance = async (currencysymbol) => {
    try {
      var obj = {
        symbol: currencysymbol,
      };
      var data = {
        apiUrl: apiService.stake_currency_balance,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        setcurrencyBalance(resp.balance.currencyBalance);
        console.log(resp.balance, "=-=-resp-=-=-=resp-=-resp.data");
      }
    } catch (err) {}
  };

  const getBalance = async () => {
    try {
      // setpageLoader(true)
      var data = {
        apiUrl: apiService.portfolio_balancestake,
      };
      var resp = await getMethod(data);
      // setpageLoader(false)

      if (resp.status) {
        setuserBalance(resp.balance);
        console.log(resp.balance, "=-=-resp-=-=-=resp-=-resp.data");
      }
    } catch (error) {
      // showerrorToast("Please try again later");
    }
  };

  const off = async () => {
    setstakePage(false);
  };

  // const getStakingHistory = async () => {
  //   try {
  //     var data = {
  //       apiUrl: apiService.getAllstakingHistory,
  //     };
  //     var resp = await getMethod(data);
  //     if (resp.status) {
  //       // setuserBalance(resp.data);
  //       console.log(resp.data, "=-=-resp-=-=-=resp-=-resp.data");
  //       setstakeHistory(resp.data);
  //     }
  //   } catch (error) {
  //     // showerrorToast("Please try again later");
  //   }
  // };

  const [historyLoader, sethistoryLoader] = useState(false);
  const getStakingHistory = async (page) => {
    try {
      sethistoryLoader(true);

      var data = {
        apiUrl: apiService.getAllstakingHistory,
        payload: { type: stake_typeref.current, FilPerpage: 5, FilPage: page },
      };
      var resp = await postMethod(data);
      sethistoryLoader(false);

      if (resp.status) {
        console.log(resp.data, "=-=-resp-=-=-=resp-=-resp.data");
        setstakeHistory(resp.data);
        settotal(resp.total);
      }
    } catch (error) {
      // showerrorToast("Please try again later");
    }
  };

  const getStakingHistory_fixed = async (page) => {
    try {
      var data = {
        apiUrl: apiService.stakingHistory_fixed,
        payload: { type: stake_typeref.current, FilPerpage: 5, FilPage: page },
      };
      var resp = await postMethod(data);

      if (resp.status) {
        console.log(resp.data, "=-=-resp-=-=-=resp-=-resp.data");
        setstakeHistory_fixed(resp.data);
        settotal_fixed(resp.total);
      }
    } catch (error) {
      // showerrorToast("Please try again later");
    }
  };

  const getStakingHistory_flexible = async (page) => {
    try {
      var data = {
        apiUrl: apiService.stakingHistory_flexible,
        payload: { type: stake_typeref.current, FilPerpage: 5, FilPage: page },
      };
      var resp = await postMethod(data);

      if (resp.status) {
        console.log(resp.data, "=-=-resp-=-=-=resp-=-resp.data");
        setstakeHistory_flexible(resp.data);
        settotal_flexible(resp.total);
      }
    } catch (error) {
      // showerrorToast("Please try again later");
    }
  };

  const getStakingHistory_yield = async (page) => {
    try {
      var data = {
        apiUrl: apiService.stakingHistory_yield,
        payload: { type: stake_typeref.current, FilPerpage: 5, FilPage: page },
      };
      var resp = await postMethod(data);

      if (resp.status) {
        console.log(resp.data, "=-=-resp-=-=-=resp-=-resp.data");
        setstakeHistory_yield(resp.data);
        settotal_yield(resp.total);
      }
    } catch (error) {
      // showerrorToast("Please try again later");
    }
  };

  const stakeNow = (currentPack, type) => {
    console.log("UIBUJHHIUHUHUh", currentPack, type);

    try {
      setstakePage(true);

      // handleOpen();
      setstakeValue(0);
      setuserTotlaInterest(0);
      setFlexibleEarn(0);
      setInterest(currentPack.APRinterest);
      setstakingType(type);
      setcurrentPack(currentPack);
      stake_currency_balance(currentPack.currencySymbol);
      var obj = {
        status: "",
        id: currentPack._id,
      };
      console.log(obj, "staking2ref.current");
      setstaking2(obj);
      var index = userBalance.findIndex(
        (x) => x.currencySymbol == currentPack.currencySymbol
      );
      if (index != -1) {
        let findCurrency = userBalance[index];
        setStakeBalance(findCurrency);
      }
      getStakingHistory(1);
    } catch (error) {
      // showerrorToast("Please try again later");
    }
  };

  const preventInvalidKeys = (event) => {
    console.log("--")
    if (["e", "E", "+", "-"].includes(event.key)) {
      event.preventDefault();
    }
    if (event.key === " " || event.key === "Tab") {
      event.preventDefault();
    }
    if(event.target.value.length >9){
      event.preventDefault();
      }

  };
  const stakeAmount = (e) => {
    try {
      const value = e.target.value;
      if (value.length <= 25) {
        setstakeValue(value);
      }
      setYieldValue(e.target.value);
      var amount = parseFloat(e.target.value);
      if (stakingType == "fixed") {
        var dailyinterest = amount * (+apy / 100 / 365);
        var totalInterest = parseFloat(dailyinterest) * currentDuration;
        setuserTotlaInterest(totalInterest);
        setusetDailyInterest(dailyinterest);
      } else if (stakingType == "flexible") {
        console.log("amount::::", amount, "interest:::", interest);
        var dailyinterestDate = amount * (+interest / 100 / 365);
        var totalInterestFlex = parseFloat(dailyinterestDate) * 1;
        setFlexibleEarn(dailyinterestDate);
        setTotalFlexible(totalInterestFlex);
      } else if (stakingType == "yield") {
      }
    } catch (error) {
      // showerrorToast("Please try again later");
    }
  };
  const showsuccessToast = (message) => {
    toast.dismiss();
    toast.success(message);
  };

  const showerrorToast = (message) => {
    toast.dismiss();
    toast.error(message);
  };

  const choosePlan = async () => {
    try {
      showerrorToast("Please choose staking plan");
    } catch (error) {
      // showerrorToast("Please try again later");
    }
  };

  const confirmStack = async () => {
    try {
      if (stakeValue > 0) {
        setValidation(false);
        console.log("confirm staking");
        if (stakingType == "fixed") {
          var obj = {
            stakingPlan: currentDuration,
            totalInterest: userTotlaInterest,
            dailyinterest: usetDailyInterest,
            startDate: fromDates,
            endDate: toDates,
            currentAPY: apy,
            stakeMore: staking1,
            stakeAmont: stakeValue,
            type: "fixed",
          };
        } else {
          console.log(staking2ref.current, "staking2ref.current");
          var obj = {
            //stakingPlan: currentDurationFlex,
            stakingPlan: 0,
            totalInterest: TotalFlexible,
            dailyinterest: FlexibleEarn,
            startDate: fromDates,
            endDate: flexibleEndDate,
            currentAPY: interest,
            stakeMore: staking2ref.current,
            stakeAmont: stakeValue,
            type: "flexible",
          };
        }
        console.log(obj, "****stakingTypeyieldyieldstakingType*****8");

        var data = {
          apiUrl: apiService.confirmStaking,
          payload: obj,
        };
        setButtonLoader(true);
        var resp = await postMethod(data);

        setButtonLoader(false);
        getStakingHistory(1);
        if (resp.status == true) {
          toast.success(resp.Message);
          setstep2("step2 d-none");
          setstep3("step3");
          setnext_claim(resp.next_claim);
          //  setyiledToDate(resp.endDate)
          //  setyiledstartDate(resp.startDate)
          setstakePage(false);
        } else {
          setstakePage(false);
          showerrorToast(resp.Message);
        }
      } else {
        setValidation(true);
        showerrorToast("Enter stake amount");
      }
    } catch (error) {
      // showerrorToast("Please try again later");
    }
  };

  const confirmStackYield = async () => {
    try {
      if (stakeValue > 0) {
        setValidation(false);
        console.log("confirm staking");
        if (YieldEstimation > 0) {
          var plan = {
            id: currentPack._id,
            stats: "Yield Staking",
          };
          var obj = {
            stakingPlan: yiledSelectDur,
            totalInterest: YieldEstimation,
            dailyinterest: 0,
            startDate: fromDates,
            endDate: yiledToDate,
            currentAPY: yieldAPY,
            stakeMore: plan,
            stakeAmont: +YieldValue,
            type: "yield",
            totalPlan: yiledStakeType,
            yieldDuration: yieldDuration,
            currentYieldYear: currentYieldYear,
          };
          console.log(obj, "=-=-=obj-=-=");
          //return false
          var data = {
            apiUrl: apiService.confirmStaking,
            payload: obj,
          };
          setButtonLoader(true);
          // return false;
          var resp = await postMethod(data);
          getStakingHistory(1);
          setButtonLoader(false);
          if (resp.status == true) {
            toast.success(resp.Message);
            setstep4("step4 d-none");
            setstep3("step3");
            setyiledToDate(resp.endDate);
            setyiledstartDate(resp.startDate);
            setnext_claim(resp.next_claim);
          } else {
            showerrorToast(resp.Message);
          }
        } else {
          showerrorToast("Calculate your amount!");
        }
      } else {
        setValidation(true);
        showerrorToast("Enter stake amount");
      }
    } catch (error) {
      // showerrorToast("Please try again later");
    }
  };

  // const maximum = async () => {
  //   try {
  //     setstakeValue(
  //       parseFloat(stakeBalance.currencyBalance).toFixed(fixedvalue)
  //     );
  //     var amount = parseFloat(stakeBalance.currencyBalance);
  //     console.log(amount, "9090");
  //     if (amount > 0) {
  //       var dailyinterest = amount * (+apy / 100 / 365);
  //       var totalInterest = parseFloat(dailyinterest) * currentDuration;
  //       setuserTotlaInterest(totalInterest);
  //       setusetDailyInterest(dailyinterest);
  //     } else {
  //       showerrorToast("Insufficient balance");
  //     }
  //   } catch (error) {
  //     showerrorToast("Please try again later");
  //   }
  // };

  const getStakeTotalDetails = async (currency) => {
    try {
      var obj = {
        currency: currency,
      };
      var data = {
        apiUrl: apiService.getStakingTotal,
        payload: obj,
      };
      // setpageLoader(true)

      var resp = await postMethod(data);
      // setpageLoader(false)

      if (resp.status) {
        console.log(resp.data);
        settotallockedValue(resp.data.totalLocked);
        settotalStakedValue(resp.data.totalStaked);
        settotalStakedValueINR(resp.data.totalStakedINR);
        settotallockedValueINR(resp.data.totalLockedINR);
        setStakedCurrency(currency);
      }
    } catch (error) {
      // showerrorToast("Please try again later");
    }
  };

  const claimNow = async (claimData) => {
    console.log("0998098908908");
    try {
      var obj = {
        _id: claimData._id,
      };
      var data = {
        apiUrl: apiService.claimNowapi,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        toast.success(resp.Message);
        getStakingHistory(1);
        getStakingHistory_fixed(1);
        getStakingHistory_flexible(1);
        getStakingHistory_yield(1);
      } else {
        showerrorToast(resp.Message);
      }
    } catch (error) {
      // showerrorToast("Please try again later");
    }
  };

  const claimNowFlexible = async (claimData) => {
    try {
      var obj = {
        _id: claimData._id,
      };
      var data = {
        apiUrl: apiService.claimNowapiFlexible,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        toast.success(resp.Message);
        getStakingHistory(1);
        getStakingHistory_fixed(1);
        getStakingHistory_flexible(1);
        getStakingHistory_yield(1);
      } else {
        showerrorToast(resp.Message);
      }
    } catch (error) {
      // showerrorToast("Please try again later");
    }
  };

  const yieldYear = async (yearType) => {
    try {
      var year =
        yearType == "oneyear" ? 365 : yearType == "threeyear" ? 1095 : 1825;
      setcurrentYieldYear(year);
      var statkeType =
        yearType == "oneyear" ? 1 : yearType == "threeyear" ? 3 : 5;
      setyiledStakeType(statkeType);
      console.log(year, "==-=year=-=-=year=-=-=-year");
      // dataCalculation("","",year);
      var myDate = new Date(new Date().getTime() + year * 24 * 60 * 60 * 1000);
      console.log(myDate, "==-=-myDate--=-=");
      var toDate =
        (await myDate.getDate()) +
        "/" +
        (myDate.getMonth() + 1) +
        "/" +
        myDate.getFullYear() +
        " " +
        myDate.getHours() +
        ":" +
        myDate.getMinutes();
      console.log(toDate, "=-=-=-=-===-==-=-=toDate==-=-=-==-=-=--=");
      setyiledToDate(toDate);
    } catch (error) {}
  };

  const activity = async (activityType) => {
    console.log("]]]]]]]]]", "activityType", activityType);
    sethistoryLoader(true);
    setstakeType(activityType);
    sethistoryLoader(false);
    if (activityType == "yield") {
      yieldYear("oneyear");
    }
  };

  const yieldCalculate = async () => {
    console.log("]]]]]]]]]", "activityType", stakeValue);

    try {
      if (stakeValue > 0) {
        var obj = {
          investValue: Number(stakeValue),
          APRPercentage: yieldAPY,
          days: yieldDuration,
          yiledStakeType: yiledStakeType * 365,
        };
        var data = {
          apiUrl: apiService.yieldcalculation,
          payload: obj,
        };
        var resp = await postMethod(data);
        console.log(resp, "-=-=-=obj-=-=-=-=-");
        setYieldEstimation(resp.Message);
        setYieldEstimation_interest(resp.estimateInterest);
        console.log(YieldEstimation);
        if (resp.status) {
          // toast.success(resp.Message);
          // getStakingHistory();
        } else {
          showerrorToast(resp.Message);
        }
      } else {
        showerrorToast("Please enter stake amount");
      }
    } catch (error) {}
  };

  const claimNow_yield = async (claimData) => {
    try {
      var obj = {
        _id: claimData._id,
      };
      var data = {
        apiUrl: apiService.claimNow_yield,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        toast.success(resp.Message);
        getStakingHistory(1);
        getStakingHistory_fixed(1);
        getStakingHistory_flexible(1);
        getStakingHistory_yield(1);
      } else {
        showerrorToast(resp.Message);
      }
    } catch (error) {
      // showerrorToast("Please try again later");
    }
  };

  const onSelect = async (option) => {
    console.log("handleOnChange_from", option);
    set_type(option);
    getStakingHistory(1);
    getStakingHistory_fixed(1);
    getStakingHistory_flexible(1);
    getStakingHistory_yield(1);
  };

  const [step1, setstep1] = useState("step1");
  const [step2, setstep2] = useState("step2 d-none");
  const [step3, setstep3] = useState("step3 d-none");
  const [step4, setstep4] = useState("step4 d-none");
  const backfunction = (value) => {
    if (value == "back") {
      setstep1("step1");
      setstep2("step2 d-none");
      // setstep3("step4 d-none");
      setstep4("step4 d-none");
    } else if (value == "stake") {
      setstep1("step1");
      setstep3("step3 d-none");
    } else if (value == "history") {
      setstep1("step1");
      setstep3("step3 d-none");
      getStakingHistory(1);
      getStakingHistory_fixed(1);
      getStakingHistory_flexible(1);
      getStakingHistory_yield(1);
    }
  };

  const searchToken = async (value) => {
    if (stakeTyperef.current != "") {
      var obj = {
        FilPerpage: perpage,
        FilPage: 1,
        search: value,
      };
      var data = {
        apiUrl: apiService.getStatkingDetails,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status == true) {
        var datas = resp.data.result;
        settotalactive(resp.data.count);
        console.log(datas, "=-=-=-=-data");
        setstakeDetails(datas);
      } else {
        setstakeDetails([]);
      }
    } else {
      var data = {
        apiUrl: apiService.getAllstakingHistory,
        payload: { type: stake_typeref.current, search: value },
      };
      var resp = await postMethod(data);
      if (resp.status) {
        // setuserBalance(resp.data);
        console.log(resp.data, "=-=-resp-=-=-=resp-=-resp.data");
        setstakeHistory(resp.data);
      }
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [total, settotal] = useState(5);
  const recordPerPage = 5;
  const pageRange = 5;
  const handlePageChange = (event, pageNumber) => {
    setCurrentPage(pageNumber);
    getStakingHistory(pageNumber);
    console.log("Page Number:", pageNumber);
  };

  const [currentPage_fixed, setCurrentPage_fixed] = useState(1);
  const [total_fixed, settotal_fixed] = useState(5);
  const recordPerPage_fixed = 5;
  const pageRange_fixed = 5;
  const handlePageChange_fixed = (pageNumber) => {
    setCurrentPage_fixed(pageNumber); // call API to get data based on pageNumber
    getStakingHistory_fixed(pageNumber);
  };

  const [currentPage_flexible, setCurrentPage_flexible] = useState(1);
  const [total_flexible, settotal_flexible] = useState(5);
  const recordPerPage_flexible = 5;
  const pageRange_flexible = 5;
  const handlePageChange_flexible = (pageNumber) => {
    setCurrentPage_flexible(pageNumber); // call API to get data based on pageNumber
    getStakingHistory_flexible(pageNumber);
  };

  const [currentPage_yield, setCurrentPage_yield] = useState(1);
  const [total_yield, settotal_yield] = useState(5);
  const recordPerPage_yield = 5;
  const pageRange_yield = 5;
  const handlePageChange_yield = (pageNumber) => {
    setCurrentPage_yield(pageNumber); // call API to get data based on pageNumber
    getStakingHistory_yield(pageNumber);
  };

  const navlog = (pageNumber) => {
    navigate("/login");
  };

  return (
    <div>
      <Header />
      <div className="container-fluid px-0 innerpages">
        <div className=" lanchpool">
          {stakePage == true ? (
            <section className="container ">
              <div className="purchase-card">
                <div className="heads">
                  <i
                    class="fa-solid cursor-pointer text-white dash_hai_icon fa-arrow-left mt-2 mr-2"
                    onClick={off}
                  ></i>
                  <img src={currentPack.currencyImage} width="40px" />
                  <h4>Stake ETH</h4>
                </div>
                <div className="bodys p-0">
                  <div className="row px-3">
                    <div className="col-lg-12 mb-3">
                      <h4>Fill In The Details</h4>

                      {stakingType == "fixed" ? (
                        <div className="step-5 ">
                          <div className="profile_content_image">
                            <div className="select_duration dayss">
                              <ul class="nav nav-tabs">
                                {staking1 && staking1.status == "stake1" ? (
                                  <li class="active">
                                    <a
                                      data-toggle="tab"
                                      href="#Fixed"
                                      className="30 active"
                                      onClick={() =>
                                        chooseDuration(
                                          "stake1",
                                          currentPack,
                                          currentPack.firstDuration
                                        )
                                      }
                                    >
                                      {currentPack.firstDuration} D
                                    </a>
                                  </li>
                                ) : (
                                  <li class="active">
                                    <a
                                      data-toggle="tab"
                                      href="#Fixed"
                                      className="30"
                                      onClick={() =>
                                        chooseDuration(
                                          "stake1",
                                          currentPack,
                                          currentPack.firstDuration
                                        )
                                      }
                                    >
                                      {currentPack.firstDuration} D
                                    </a>
                                  </li>
                                )}
                                {staking1 && staking1.status == "stake2" ? (
                                  <li class="active">
                                    <a
                                      data-toggle="tab"
                                      href="#Fixed"
                                      className="30 active"
                                      onClick={() =>
                                        chooseDuration(
                                          "stake2",
                                          currentPack,
                                          currentPack.secondDuration
                                        )
                                      }
                                    >
                                      {currentPack.secondDuration} D
                                    </a>
                                  </li>
                                ) : (
                                  <li class="active">
                                    <a
                                      data-toggle="tab"
                                      href="#Fixed"
                                      className="30"
                                      onClick={() =>
                                        chooseDuration(
                                          "stake2",
                                          currentPack,
                                          currentPack.secondDuration
                                        )
                                      }
                                    >
                                      {currentPack.secondDuration} D
                                    </a>
                                  </li>
                                )}
                                {staking1 && staking1.status == "stake3" ? (
                                  <li class="active">
                                    <a
                                      data-toggle="tab"
                                      href="#Fixed"
                                      className="30 active"
                                      onClick={() =>
                                        chooseDuration(
                                          "stake3",
                                          currentPack,
                                          currentPack.thirdDuration
                                        )
                                      }
                                    >
                                      {currentPack.thirdDuration} D
                                    </a>
                                  </li>
                                ) : (
                                  <li class="active">
                                    <a
                                      data-toggle="tab"
                                      href="#Fixed"
                                      className="30"
                                      onClick={() =>
                                        chooseDuration(
                                          "stake3",
                                          currentPack,
                                          currentPack.thirdDuration
                                        )
                                      }
                                    >
                                      {currentPack.thirdDuration} D
                                    </a>
                                  </li>
                                )}
                                {staking1 && staking1.status == "stake4" ? (
                                  <li class="active">
                                    <a
                                      data-toggle="tab"
                                      href="#Fixed"
                                      className="30 active"
                                      onClick={() =>
                                        chooseDuration(
                                          "stake4",
                                          currentPack,
                                          currentPack.fourthDuration
                                        )
                                      }
                                    >
                                      {currentPack.fourthDuration} D
                                    </a>
                                  </li>
                                ) : (
                                  <li class="active">
                                    <a
                                      data-toggle="tab"
                                      href="#Fixed"
                                      className="30"
                                      onClick={() =>
                                        chooseDuration(
                                          "stake4",
                                          currentPack,
                                          currentPack.fourthDuration
                                        )
                                      }
                                    >
                                      {currentPack.fourthDuration} D
                                    </a>
                                  </li>
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="col-lg-6 mtb  p-3">
                      <span> APY</span>
                      <h4 className="text-prim">
                        {stakingType == "fixed" ? apy : interest} %{" "}
                      </h4>

                      <span>Stake Amount</span>
                      <h4>
                        {" "}
                        <input
                          className="stakeinput"
                          name="password"
                          value={stakeValue}
                          onChange={stakeAmount}
                            pattern="\S*"
                          min="0"
                          type="number"
                          onKeyDown={preventInvalidKeys}
                        />{" "}
                      </h4>

                      <span>Available:</span>
                      <h4>
                        {currencyBalance == "" ||
                        currencyBalance == null ||
                        currencyBalance == undefined ||
                        currencyBalance == NaN
                          ? 0
                          : parseFloat(currencyBalance).toFixed(6)}{" "}
                        {currentPack.currencySymbol}
                      </h4>

                      <span>Minimum Stake:</span>
                      <h4>
                        {" "}
                        {parseFloat(currentPack.minimumStaking).toFixed(8)}{" "}
                        {currentPack.currencySymbol}
                      </h4>

                      <span>Maximum Stake:</span>
                      <h4>
                        {" "}
                        {currentPack.maximumStaking}{" "}
                        {currentPack.currencySymbol}
                      </h4>
                    </div>
                    <div className="col-lg-6 p-3 rightsides">
                      <span> Stake Date</span>
                      <h4 className="text-pri">
                        {" "}
                        {stakingType == "fixed"
                          ? fromDates
                          : flexibleStartDate}{" "}
                      </h4>

                      <span>Interest End Date:</span>
                      <h4>{stakingType == "fixed" ? toDates : "-"} </h4>

                      <span>Stake Amount:</span>
                      <h4>{stakeValue}</h4>

                      <span>Estimated Interest:</span>
                      <h4>
                        {" "}
                        {stakingType == "fixed"
                          ? parseFloat(userTotlaInterest).toFixed(6)
                          : parseFloat(FlexibleEarn).toFixed(10)}{" "}
                        {stakeBalance.currencySymbol}{" "}
                      </h4>

                      {authToken && authToken == true ? (
                        buttonLoader == false ? (
                          <button onClick={confirmStack}>Confirm</button>
                        ) : (
                          <button>Loading...</button>
                        )
                      ) : (
                        <button>login to continue</button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            <>
              <section className="lanch-header">
                <div className="container-fluid px-5">
                  <div className="row justify-content-between">
                    <div className="col-lg-6">
                      <h2>Staking</h2>
                      <h5>Boost Your Earnings with Crypto Staking</h5>
                      {/* <div className="d-flex">
                    <i class="fa-solid fa-book text-white mr-2"></i>
                    <h6 className="shares"> Beginner’s guide</h6>
                  </div> */}
                      <div className="d-flex gap-40 stake">
                        <div>
                          <p>
                            <span>Staking assets(USDT) </span>
                          </p>
                          <h5 className="b500">
                            {userBalance &&
                            userBalance.availableusdt_balance !== undefined
                              ? userBalance.availableusdt_balance.toFixed(4)
                              : 0.0}
                          </h5>
                        </div>
                      </div>

                      <button>
                        <Link to="/history/stake-history" className="text-dark">
                          <i class="fa-solid fa-calculator"></i> View
                        </Link>{" "}
                      </button>
                    </div>
                    <div className="col-lg-6">
                      <img
                        src={require("../images/staking.png")}
                        width="100%"
                      />
                    </div>
                  </div>
                </div>
              </section>

              <section>
                <div className="container-fluid staking">
                  <div className=" m-0 p-5">
                    <div className="lanch-body-head">
                      <span className="core_div_head mt-3 mb-4">
                        All Products
                      </span>
                      <div className="matchs">
                        {/* <i class="fa-regular fa-square"></i> Match my assets */}
                      </div>
                    </div>

                    <ul class="nav nav-tabs mb-3 " id="myTab" role="tablist">
                      <li class="nav-item" role="presentation">
                        <a
                          class={`nav-link pr-2 ${
                            stakestage === "flexible" ? "active" : ""
                          }`}
                          id="openorder-tab"
                          onClick={() => stakestagefunction("flexible")}
                          data-bs-toggle="tab"
                          data-bs-target="#openorder"
                          type="button"
                          role="tab"
                          aria-controls="home"
                          aria-selected="true"
                        >
                          Flexible
                        </a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a
                          class={`nav-link px-2 ${
                            stakestage === "fixed" ? "active" : ""
                          }`}
                          id="order-tab"
                          onClick={() => stakestagefunction("fixed")}
                          data-bs-toggle="tab"
                          data-bs-target="#order"
                          type="button"
                          role="tab"
                          aria-controls="home"
                          aria-selected="true"
                        >
                          Fixed
                        </a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a
                          class={`nav-link px-2 ${
                            stakestage === "history" ? "active" : ""
                          }`}
                          id="cancel-tab"
                          onClick={() => stakestagefunction("history")}
                          data-bs-toggle="tab"
                          data-bs-target="#cancel"
                          type="button"
                          role="tab"
                          aria-controls="home"
                          aria-selected="true"
                        >
                          Stake History
                        </a>
                      </li>
                    </ul>

                    <div class="tab-content pair_details " id="myTabContent">
                      {/* ==========ACTIVE OREDERS========== */}

                      <div
                        id="OpenOrders"
                        className={`tab-pane fade ${
                          stakestage === "flexible" ? "show active" : ""
                        }`}
                        role="tabpanel"
                        aria-labelledby="openorder-tab"
                      >
                        <div className="row">
                          {stakeDetails.length > 0
                            ? stakeDetails.map((item, i) => {
                                return (
                                  <div className="col-lg-3 my-3">
                                    <div className="stake-cards">
                                      <h2>{item.currencyname}</h2>

                                      <img
                                        src={item.currencyImage}
                                        className="stake-card-img"
                                        width="60px"
                                      />
                                      <h6> {item.currencySymbol} </h6>

                                      <h2 className="text-white">
                                        {item.APRinterest} %
                                      </h2>
                                      <p className="text-white m-0 text-center">
                                        Minimum Stake :{" "}
                                        {item.minimumStakingflex}{" "}
                                        {item.currencySymbol}{" "}
                                      </p>

                                      {authToken == false ? (
                                        <button onClick={navlog}>
                                          {" "}
                                          Login to continue!
                                        </button>
                                      ) : (
                                        <button
                                          onClick={() =>
                                            stakeNow(item, "flexible")
                                          }
                                        >
                                          Stake Now
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                );
                              })
                            : 0}
                        </div>
                      </div>

                      {/* ==========OREDERS HISTORY========== */}

                      <div
                        id="OrderHistory"
                        className={`tab-pane fade ${
                          stakestage === "fixed" ? "show active" : ""
                        }`}
                        role="tabpanel"
                        aria-labelledby="order-tab"
                      >
                        <div className="row">
                          {stakeDetails.length > 0
                            ? stakeDetails.map((item, i) => {
                                return (
                                  <div className="col-lg-3 my-3">
                                    <div className="stake-cards">
                                      <h2>{item.currencyname}</h2>

                                      <img
                                        src={item.currencyImage}
                                        className="stake-card-img"
                                        width="60px"
                                      />
                                      <h6> {item.currencySymbol} </h6>

                                      <h2 className="text-white">
                                        {staking1 && staking1.id == item._id ? (
                                          <small>{apy} % </small>
                                        ) : (
                                          <small>0 %</small>
                                        )}
                                      </h2>

                                      <p className="text-white m-0 text-center">
                                        Minimum Stake :{" "}
                                        {parseFloat(
                                          item.minimumStaking
                                        ).toFixed(8)}{" "}
                                        {item.currencySymbol}
                                      </p>

                                      {authToken == false ? (
                                        <button onClick={navlog}>
                                          {" "}
                                          Login to continue!{" "}
                                        </button>
                                      ) : staking1 &&
                                        staking1.id == item._id ? (
                                        <button
                                          onClick={() =>
                                            stakeNow(item, "fixed")
                                          }
                                        >
                                          Stake Now
                                        </button>
                                      ) : (
                                        <button
                                          onClick={() => choosePlan(item)}
                                        >
                                          Stake Now
                                        </button>
                                      )}

                                      <div className="select_duration">
                                        <p>Select Duration:</p>
                                        <ul class="nav nav-tabs justify-content-between dayss">
                                          <li class="active">
                                            {staking1 &&
                                            staking1.status == "stake1" &&
                                            staking1.id == item._id ? (
                                              <a
                                                className="30"
                                                data-toggle="tab"
                                                onClick={() =>
                                                  chooseDuration(
                                                    "stake1",
                                                    item,
                                                    item.firstDuration
                                                  )
                                                }
                                              >
                                                {item.firstDuration} D
                                              </a>
                                            ) : (
                                              <a
                                                className="30"
                                                data-toggle="tab"
                                                onClick={() =>
                                                  chooseDuration(
                                                    "stake1",
                                                    item,
                                                    item.firstDuration
                                                  )
                                                }
                                              >
                                                {item.firstDuration} D
                                              </a>
                                            )}
                                          </li>
                                          <li>
                                            {staking1 &&
                                            staking1.status == "stake2" &&
                                            staking1.id == item._id ? (
                                              <a
                                                className="30"
                                                data-toggle="tab"
                                                onClick={() =>
                                                  chooseDuration(
                                                    "stake2",
                                                    item,
                                                    item.secondDuration
                                                  )
                                                }
                                              >
                                                {item.secondDuration} D
                                              </a>
                                            ) : (
                                              <a
                                                className="30"
                                                data-toggle="tab"
                                                selected
                                                onClick={() =>
                                                  chooseDuration(
                                                    "stake2",
                                                    item,
                                                    item.secondDuration
                                                  )
                                                }
                                              >
                                                {item.secondDuration} D
                                              </a>
                                            )}
                                          </li>
                                          <li>
                                            {staking1 &&
                                            staking1.status == "stake3" &&
                                            staking1.id == item._id ? (
                                              <a
                                                className="30"
                                                data-toggle="tab"
                                                selected
                                                onClick={() =>
                                                  chooseDuration(
                                                    "stake3",
                                                    item,
                                                    item.thirdDuration
                                                  )
                                                }
                                              >
                                                {item.thirdDuration} D
                                              </a>
                                            ) : (
                                              <a
                                                className="30"
                                                data-toggle="tab"
                                                onClick={() =>
                                                  chooseDuration(
                                                    "stake3",
                                                    item,
                                                    item.thirdDuration
                                                  )
                                                }
                                              >
                                                {item.thirdDuration} D
                                              </a>
                                            )}
                                          </li>{" "}
                                          <li>
                                            {staking1 &&
                                            staking1.status == "stake4" &&
                                            staking1.id == item._id ? (
                                              <a
                                                className="30"
                                                data-toggle="tab"
                                                selected
                                                onClick={() =>
                                                  chooseDuration(
                                                    "stake4",
                                                    item,
                                                    item.fourthDuration
                                                  )
                                                }
                                              >
                                                {item.fourthDuration} D
                                              </a>
                                            ) : (
                                              <a
                                                className="30"
                                                data-toggle="tab"
                                                onClick={() =>
                                                  chooseDuration(
                                                    "stake4",
                                                    item,
                                                    item.fourthDuration
                                                  )
                                                }
                                              >
                                                {item.fourthDuration} D
                                              </a>
                                            )}
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            : 0}
                        </div>
                      </div>

                      <div
                        id="OpenOrders"
                        className={`tab-pane fade ${
                          stakestage === "history" ? "show active" : ""
                        }`}
                        role="tabpanel"
                        aria-labelledby="openorder-tab"
                      >
                        <div className="table-history mt-5">
                          <div className="table-responsive table-borderless">
                            <table>
                              <thead>
                                <th scope="col" className="starts">
                                  Package
                                </th>
                                <th scope="col">Total Amount</th>
                                <th scope="col">APY/APR</th>
                                <th scope="col">Type</th>
                                <th scope="col">Interest Cycle</th>
                                <th scope="col">Stake Date</th>
                                <th scope="col"> Stake End Date</th>
                                <th scope="col">Next Claim Date</th>
                                <th scope="col">Total Interest</th>

                                <th scope="col" className="ends">
                                  Int. per Cycle
                                </th>
                              </thead>
                              <tbody>
                                {stakeHistoryref.current &&
                                stakeHistoryref.current?.length > 0 ? (
                                  stakeHistoryref.current.map((item, i) => {
                                    var startdate = moment(
                                      item.startDate
                                    ).format("DD/MM/YYYY");
                                    var endtdate = moment(item.endDate).format(
                                      "DD/MM/YYYY"
                                    );

                                    var get_time = new Date(
                                      item.date
                                    ).getTime();
                                    var interest_cycle =
                                      item.type == "fixed"
                                        ? item.stakingPlan
                                        : "";

                                    var added_date =
                                      get_time +
                                      +interest_cycle * 24 * 60 * 60 * 1000;

                                    var claim_date = "";
                                    if (item.type == "fixed") {
                                      claim_date = item.endDate;
                                    } else {
                                      claim_date = "-";
                                    }

                                    claim_date =
                                      moment(claim_date).format("DD/MM/YYYY");

                                    return (
                                      <tr className="history_tabel_bottom_border">
                                        <td>
                                          <img
                                            src={item.currencyImage}
                                            width="30px"
                                          />{" "}
                                          {item.stakeCurrencsymbol}
                                        </td>
                                        <td>
                                          {item.stakeAmont}{" "}
                                          {item.stakeCurrencsymbol}
                                        </td>

                                        <td>{item.currentAPY}% </td>
                                        <td>{item.type}</td>
                                        <td>
                                          {item.type == "fixed"
                                            ? item.stakingPlan + " days"
                                            : "-"}{" "}
                                          {/* {item.type == "flexible" ? "-" : "-"}{" "} */}
                                        </td>
                                        <td>{startdate}</td>

                                        <td>
                                          {item.type == "fixed"
                                            ? endtdate
                                            : "-"}
                                        </td>
                                        <td>
                                          {item.type == "fixed"
                                            ? claim_date
                                            : "-"}
                                        </td>
                                        <td>
                                          {parseFloat(
                                            item.totalInterest
                                          ).toFixed(6)}{" "}
                                          {item.stakeCurrencsymbol}
                                        </td>
                                        <td>
                                          {item.type == "fixed" ? (
                                            <div className="minimum">
                                              <p>
                                                {parseFloat(
                                                  item.totalInterest
                                                ).toFixed(6)}{" "}
                                                {item.stakeCurrencsymbol}
                                              </p>
                                              {item.status == 1 ? (
                                                <button
                                                  className="active"
                                                  onClick={() => claimNow(item)}
                                                >
                                                  Claim Now
                                                </button>
                                              ) : item.status == 0 ? (
                                                <button className="notactive">
                                                  {" "}
                                                  Claim
                                                </button>
                                              ) : (
                                                <button className="notactive">
                                                  {" "}
                                                  Claimed
                                                </button>
                                              )}
                                            </div>
                                          ) : (
                                            <div className="minimum">
                                              <p>
                                                {parseFloat(
                                                  item.totalInterest
                                                ).toFixed(6)}{" "}
                                                {item.stakeCurrencsymbol}
                                              </p>
                                              {item.status == 0 ? (
                                                <button
                                                  className="active"
                                                  onClick={() =>
                                                    claimNowFlexible(item)
                                                  }
                                                >
                                                  Claim Now
                                                </button>
                                              ) : (
                                                <button className="notactive">
                                                  Claimed
                                                </button>
                                              )}
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <td colSpan={9}>
                                    <div className="empty_data">
                                      <div className="empty_data_img">
                                        <img
                                          src={require("../images/No-data.png")}
                                        />
                                      </div>

                                      <div className="no_records_text">
                                        No Records Found
                                      </div>
                                    </div>
                                  </td>
                                )}
                              </tbody>
                            </table>
                          </div>
                          {stakeHistoryref.current &&
                          stakeHistoryref.current.length > 0 ? (
                            <div className="pagination">
                              <Stack spacing={2}>
                                <Pagination
                                  count={Math.ceil(total / recordPerPage)}
                                  page={currentPage}
                                  onChange={handlePageChange}
                                  renderItem={(item) => (
                                    <PaginationItem
                                      components={{
                                        previous: ArrowBackIcon,
                                        next: ArrowForwardIcon,
                                      }}
                                      {...item}
                                    />
                                  )}
                                />
                              </Stack>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>

                    {/* <div className="lanch-body-head justify-content-center mt-4">
                  <button>
                    More <i class="fa-solid fa-chevron-down"></i>
                  </button>
                </div> */}
                  </div>
                </div>
              </section>

              <section>
                <div className="container-fluid">
                  <div className=" m-0 p-5">
                    <div className="lanch-body-head">
                      <span className="core_div_head mb-4">
                        Earn effortlessly through staking!
                      </span>
                    </div>

                    <div className="row justify-content-between align-content-center mt-5">
                      <div className="col-lg-3 my-3">
                        <div className="Earn-cards">
                          <img
                            src={require("../images/pos.png")}
                            width="80px"
                            className="mx-auto d-block"
                          />
                          <h4>Bringing together top PoS networks</h4>
                          <p>
                            Nexus Pro brings together high-quality and
                            industry-recognized PoS networks for staking.
                          </p>
                        </div>
                      </div>

                      <div className="col-lg-3 my-3 d-flex flex-column justify-content-around">
                        <div className="Earn-cards">
                          <img
                            src={require("../images/Time.png")}
                            width="80px"
                            className="mx-auto d-block"
                          />
                          <h4>Flexible redemption times</h4>
                          <p>
                            Fast and flexible redemption options supported to
                            help optimize your staking strategies.
                          </p>
                        </div>
                      </div>

                      <div className="col-lg-3 my-3">
                        <div className="Earn-cards">
                          <img
                            src={require("../images/risk (1).png")}
                            width="80px"
                            className="mx-auto d-block"
                          />
                          <h4>Low-risk and stable earnings</h4>
                          <p>
                            Staked assets are directly locked into PoS networks
                            to allow investors to earn returns without running
                            their own validator node.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="container-fluid pb-4  px-5">
                <div className="iden_left_main mx-3 ">
                  <span className="core_div_head">Staking dictionary</span>
                  <div className="FAQ-questions">
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        1. What is Proof-of-Stake (PoS) ?
                      </AccordionSummary>
                      <AccordionDetails>
                        While proof-of-work (PoW) is a consensus mechanism where
                        miners rely on hardware and software to validate
                        transactions on the blockchain, PoS consensuses use
                        staked tokens. In PoS, validators have a higher chance
                        to obtain the right to validate new transactions based
                        on their staked amount or staking duration.
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        2. Why choose Bitget Staking ?
                      </AccordionSummary>
                      <AccordionDetails>
                        1. Interest is credited every day. If you stake on D
                        (date), interest starts to accrue on D+1 and is paid on
                        D+2. Bitget will pay interest to your spot account each
                        day for as long as you keep staking.
                        <br />
                        <br />
                        2. You can stake at any time and redeem before the end
                        of a staking period. Staking offers a flexible, free,
                        and smooth experience.
                        <br />
                        <br />
                        3. You get as much as you earn on the chain. Bitget does
                        not take a cut of your earnings, and the ROI exactly
                        matches that of the nodes.
                        <br />
                        <br />
                        4. Earn principal-protected and low-risk income. Your
                        assets will only grow during staking. No setup required,
                        and no penalty (such as slashing) or other on-chain node
                        staking risks. Bitget makes you an effortless earner!
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                      >
                        3. How is APR calculated ?
                      </AccordionSummary>
                      <AccordionDetails>
                        The ROI displayed is the exact same as offered by the
                        respective nodes and is not modified by Bitget in any
                        way.
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        4. How are my rewards calculated if I've subscribed to
                        multiple PoS products ?
                      </AccordionSummary>
                      <AccordionDetails>
                        Bitget aggregates your rewards from each network
                        according to their respective APR, shown as your total
                        earnings.
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        5. When can I redeem my staking rewards ?
                      </AccordionSummary>
                      <AccordionDetails>
                        If you stake on day D, interest starts to accrue on D+1
                        and is paid on D+2.
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        6. What's the difference between Express Redeem and
                        Redeem ?
                      </AccordionSummary>
                      <AccordionDetails>
                        Standard redemption: If you stake on D (date), you can
                        request a standard redemption on D+1. The redemption
                        period aligns with the on-chain nodes. The staked tokens
                        and interest will be distributed to your spot account
                        after the redemption period (no interest will be
                        generated during the redemption period). <br /> <br />
                        Express redemption: If you stake on D (date), the staked
                        assets and the interest will be distributed to your spot
                        account within 10 minutes, and the platform will deduct
                        10% of the return as a service fee. This amount has a
                        daily limit, and the portion that exceeds the limit can
                        only be redeemed the next day.
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
              </section>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
