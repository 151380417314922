import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { InfinitySpin } from "react-loader-spinner";

function Dashboard() {
  const [siteLoader, setSiteLoader] = useState(false);

  useEffect(() => {
    FindData();
  }, [0]);

  const data = {
    APcode: "",
    changeAPcode: "",
    Status: "",
  };

  const [formData, setformData] = useState(data);
  const [otpPage, setotpPage] = useState(true);
  const [codePage, setcodePage] = useState(true);
  const [changeCode, setchangeCode] = useState(false);
  const [Anticode, setAnticode] = useState("");
  const [originalcode, setoriginalcode] = useState("");
  const [checked, setChecked] = useState(false);
  const [AntiPcode, setAntiPcode] = useState(false);
  const [changeAnticode, setchangeAnticode] = useState(false);
  const [APcodevalied, setAPcodevalied] = useState("");
  const [APcodes, setAPcodes] = useState("");
  const [OTP, setOTP] = useState("");

  const getValue = async (e) => {
    e.preventDefault();
    console.log(e.target.value);
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/\s/g, "");
    let formData1 = { ...formData, ...{ [name]: sanitizedValue } };
    setformData(formData1);
    condition(formData1);
  };
  const preventInvalidKeys = (event) => {
    console.log("--")
    if (["e", "E", "+", "-"].includes(event.key)) {
      event.preventDefault();
    }
    if (event.key === " " || event.key === "Tab") {
      event.preventDefault();
    }
    if(event.target.value.length >6){
      event.preventDefault();
      }

  };

  const removeWhitespace = (input) => {
    input.value = input.value.replace(/\s/g, "");
  };
  const fetchOTP = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    if (value.length <= 4) {
      setOTP(value);
    }
    console.log(OTP, "hujbujbjbhbs");
  };

  const condition = (formData) => {
    console.log("o00000");
    const Err = {};
    if (formData.APcode == "") {
      setAntiPcode(true);
      Err.APcode = "Anti phishing code is required";
    } else if (formData.APcode.length < 4) {
      Err.APcode = "Minimum 4 characters Only allowed";
      setAntiPcode(true);
    } else if (formData.APcode.length > 20) {
      Err.APcode = "Maximum 20 characters Only allowed";
      setAntiPcode(true);
    } else if (!formData.APcode.match(/[!@#$%^&*(),.?":{}|<>]/g)) {
      setAntiPcode(true);
      Err.APcode = "Please enter at least one special character";
    } else if (/\s/.test(!formData.APcode)) {
      console.log("---=--=");
      setAntiPcode(true);
      Err.APcode = "No whitespace allowed";
    } else {
      setAntiPcode(false);
    }

    if (formData.changeAPcode == "") {
      Err.changeAPcode = "Anti phishing code required";
      setchangeAnticode(true);
    } else if (formData.changeAPcode.length < 4) {
      Err.changeAPcode = "Minimum 4 characters Only allowed";
      setchangeAnticode(true);
    } else if (formData.changeAPcode.length > 20) {
      Err.changeAPcode = "Maximum 20 characters Only allowed";
      setchangeAnticode(true);
    } else if (!formData.changeAPcode.match(/[!@#$%^&*(),.?":{}|<>]/g)) {
      setchangeAnticode(true);
      Err.changeAPcode = "Please enter at least one special character";
    } else {
      setchangeAnticode(false);
    }
    setAPcodevalied(Err);
    return Err;
  };

  const createCode = async () => {
    if (APcodes == "") {
      condition(formData);
      if (
        formData.APcode != "" &&
        formData.APcode.length > 4 &&
        formData.APcode.length < 21 &&
        AntiPcode == false
      ) {
        formData["Status"] = checked;
        var data = {
          apiUrl: apiService.antiphishingcode,
          payload: formData,
        };
        var responce = await postMethod(data);
        if (responce.Status == true) {
          showsuccessToast(responce.Message);
          setotpPage(false);
        }
      }
    } else {
      showerrorToast("Already code created");
    }
  };

  const verifyOTP = async () => {
    console.log(formData.APcode, "formData.APcode");
    console.log(formData.changeAPcode, "formData.changeAPcode");
    if (OTP != "") {
      var obj = {
        OTP: OTP,
        changeAPcode: formData.changeAPcode,
        APcode: formData.APcode,
      };
      var data = {
        apiUrl: apiService.verificationOtp,
        payload: obj,
      };
      var responce = await postMethod(data);
      if (responce.Status == true) {
        showsuccessToast(responce.Message);
        window.location.reload();
        setchangeCode(true);
        FindData();
      }
    } else {
      showerrorToast("Enter OTP");
    }
  };

  const changeAntiCode = async () => {
    condition(formData);

    console.log(formData.changeAPcode, "ihihihiuhuhuhhiuu", changeAnticode);
    if (
      formData.changeAPcode != "" &&
      formData.changeAPcode.length > 4 &&
      formData.changeAPcode.length < 21 &&
      changeAnticode == false
    ) {
      console.log("Inside");
      var data = {
        apiUrl: apiService.changeAntiPhising,
        payload: formData,
      };
      var responce = await postMethod(data);
      if (responce.Status == true) {
        showsuccessToast(responce.Message);
        setcodePage(false);
        console.log(formData.changeAPcode, "formData.changeAPcode");
      } else {
        showerrorToast(responce.Message);
        setcodePage(true);
      }
    }
  };

  const FindData = async () => {
    var data = {
      apiUrl: apiService.findDetails,
      payload: formData,
    };
    setSiteLoader(true);
    var responce = await postMethod(data);
    setSiteLoader(false);
    if (responce.data != null) {
      if (responce.data.APcode != "") {
        var str = responce.data.APcode;
        setAPcodes(str);
        var replaced = str.replace(/.(?=.{4,}$)/g, "*");
        setAnticode(replaced);
        setoriginalcode(responce.data.APcode)
        setchangeCode(true);
      } else {
        setchangeCode(false);
      }
    }
  };

  const showsuccessToast = (message) => {
    toast.dismiss();
    toast.success(message);
  };

  const showerrorToast = (message) => {
    toast.dismiss();
    toast.error(message);
  };

  const copy = async (text) => {
    navigator.clipboard.writeText(originalcode);
    showsuccessToast("Anti-phising Code Copied");
  };

  return (
    <div>
      <Header />
      <div className="container-fluid">
        <div className="row">
          {siteLoader == true ? (
            <div className="dashboard_content_section loadercss">
              <InfinitySpin
                height="300"
                width="300"
                color="#12d5d5"
                ariaLabel="infinity-spin-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          ) : (
            <>
              <div className="col-lg-2 col-sm-3">
                <Sidebar />
              </div>
              <div className="col-lg-10 col-md-12">
                <>
                  <div className="container dash_container">
                    <span className="core_div_head">
                      <Link to="/security">
                        {" "}
                        <i class="fa-solid text-white dash_hai_icon fa-arrow-left ml-0 mt-2 mr-2"></i>
                      </Link>{" "}
                      Set Anti-phishing Code
                    </span>
                    <div className=" row mt-4 ">
                      <div className="col-lg-6">
                        <div className="Tfa-section mt-4">
                          <h5>What is an anti-phishing code?</h5>
                          <p className="pop_bot_left1 my-4">
                            The anti-phishing code is a string of characters
                            that you set yourself, which can help you identify
                            websites and emails that are counterfeit.
                          </p>
                        </div>

                        <div className="Tfa-section">
                          <h5> Where will the anti-phishing code appear?</h5>
                          <p className="pop_bot_left1 my-4">
                            When set, this code will be included in emails from
                            Bitget. Any other emails without this code are scams
                          </p>
                        </div>

                        {changeCode == false ? (
                          <div className="Tfa-section mt-4">
                            <h5> Create Anti-phishing Code </h5>
                            <p className="pop_bot_left1 my-4"></p>
                            <div className="tfa-cards">
                              <div className="w_100">
                                <h5>New Anti-phishing Code </h5>
                                <div className="">
                                  {otpPage == true ? (
                                    <>
                                      <div className="earn_view_ass">
                                        <div className="dash_asset_search w_100 mt-3">
                                          <input
                                            type="text"
                                            name="APcode"
                                            maxLength={20}
                                            autoComplete="off"
                                            placeholder="Enter Anti-Phishing code"
                                            className="order-srch-input"
                                            pattern="\S*"
                                            onKeyDown={(e) => {
                                              if (e.key === " ") {
                                                e.preventDefault();
                                              }
                                            }}
                                            onChange={getValue}
                                          />
                                        </div>
                                      </div>
                                      {AntiPcode == true ? (
                                        <p className="errorcss">
                                          {" "}
                                          {APcodevalied.APcode}{" "}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  ) : (
                                    <div className="earn_view_ass">
                                      <div className="dash_asset_search w_100 mt-3">
                                        <input
                                          type="Number"
                                          value={OTP}
                                          pattern="\S*"
                                          placeholder="Enter the OTP"
                                          className="order-srch-input"
                                          onKeyDown={preventInvalidKeys}
                                          onInput={(event) =>
                                            removeWhitespace(event.target)
                                          }
                                          onChange={fetchOTP}
                                          min={1000}
                                          max={9999}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>

                                <div className="reg_bot_div">
                                  {otpPage == true ? (
                                    <div
                                      className="reg_submit mt-4"
                                      onClick={createCode}
                                    >
                                      <span className="hero_getstarted">
                                        Create
                                      </span>
                                    </div>
                                  ) : (
                                    <div
                                      className="reg_submit mt-4"
                                      onClick={verifyOTP}
                                    >
                                      <span className="hero_getstarted">
                                        {" "}
                                        Verify OTP
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="Tfa-section mt-4">
                            <h5> Old Anti-Phishing Code </h5>

                            <div className="earn_view_ass">
                              <div className="dash_asset_search">
                                <input
                                  type="text"
                                  value={Anticode}
                                  readOnly
                                  className="order-srch-input"
                                />
                                <i
                                  class="fa-regular fa-copy search-icon text-white"
                                  id="Eye"
                                  onClick={() => copy(Anticode)}
                                ></i>
                              </div>
                            </div>

                            <p className="pop_bot_left1 my-4"></p>
                            <div className="tfa-cards">
                              <div className="w_100">
                                <h5>Change Anti-phishing Code </h5>
                                <div className="">
                                  {codePage == true ? (
                                    <>
                                      <div className="earn_view_ass">
                                        <div className="dash_asset_search w_100 mt-3">
                                          <input
                                            type="text"
                                            name="changeAPcode"
                                            maxLength={20}
                                            autoComplete="off"
                                            placeholder="Enter Anti-Phishing code"
                                            className="order-srch-input"
                                            pattern="\S*"
                                            onKeyDown={(e) => {
                                              if (e.key === " ") {
                                                e.preventDefault();
                                              }
                                            }}
                                            onChange={getValue}
                                          />
                                        </div>
                                      </div>
                                      {changeAnticode == true ? (
                                        <p className="errorcss">
                                          {" "}
                                          {APcodevalied.changeAPcode}{" "}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  ) : (
                                    <div className="earn_view_ass">
                                      <div className="dash_asset_search w_100 mt-3">
                                        <input
                                          type="Number"
                                          value={OTP}
                                          className="order-srch-input"
                                          placeholder="Enter The OTP"
                                          pattern="\S*"
                                          onKeyDown={(evt) =>
                                            ["e", "E", "+", "-"].includes(
                                              evt.key
                                            ) && evt.preventDefault()
                                          }
                                          onChange={fetchOTP}
                                          min={1000}
                                          max={9999}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div className="reg_bot_div">
                                  {codePage == true ? (
                                    <div
                                      className="reg_submit mt-4"
                                      onClick={changeAntiCode}
                                    >
                                      <span className="hero_getstarted">
                                        Change
                                      </span>
                                    </div>
                                  ) : (
                                    <div
                                      className="reg_submit mt-4"
                                      onClick={verifyOTP}
                                    >
                                      <span className="hero_getstarted">
                                        {" "}
                                        Verify OTP
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
