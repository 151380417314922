import React,{useEffect,useState} from 'react';
import Header from "./Header";
import { toast } from "react-toastify";
import { getMethod, postMethod } from "../core/service/common.api";
import { env } from "../core/service/envconfig";
import apiService from "../core/service/detail";
import moment from "moment"

const TermsAndConditions = () => {
  const currentDate = new Date().toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  useEffect(() => {
    getDetails();
  }, []);  
  const [pageDetails, setpageDetails] = useState("");
  var getDetails = async () => {
    try {
      var obj = {
        key: "Terms",
      };

      var data = {
        apiUrl: apiService.terms,
        payload: obj,
      };
      var resp = await postMethod(data);
      setpageDetails(resp.data)
      console.log(pageDetails,"pageDetails")
    } catch (err) {
      console.log(err, "terms");
    }
  };

  return (
    <div>
      <Header />
      <div className="container">
        {/* <div className="privacy2">
          <h2>Terms and Conditions</h2>
          <p>Last Updated: {moment(pageDetails.updated_at).format("lll")}</p>
        </div> */}

        <div className="privacy">
        <h2> Terms and condition</h2>
        <p>Last Updated: {moment(pageDetails.updated_at).format("lll")}</p>

<p   dangerouslySetInnerHTML={{ __html: pageDetails.content_description }}></p>

        </div>

      {/* <p>
            Welcome to Nexus Pro. By using our platform, you agree to comply
            with and be bound by the following terms and conditions. Please
            review them carefully.
          </p>

          <ol>
            <li>
              <strong>Acceptance of Terms</strong>
              <p>
                By accessing or using Nexus Pro, you agree to be bound by these
                terms and conditions. If you do not agree with any part of these
                terms, please do not use our services.
              </p>
            </li>
            <li>
              <strong>Eligibility</strong>
              <p>
                You must be at least 18 years old to use our services. By using
                Nexus Pro, you represent that you meet this age requirement.
              </p>
            </li>
            <li>
              <strong>Account Registration</strong>
              <ul>
                <li>
                  <strong>Account Creation:</strong> You must create an account
                  to access our services. You agree to provide accurate and
                  complete information during the registration process.
                </li>
                <li>
                  <strong>Security:</strong> You are responsible for maintaining
                  the confidentiality of your account information and for all
                  activities that occur under your account.
                </li>
              </ul>
            </li>
            <li>
              <strong>Services</strong>
              <p>
                We offer various services including trading and swapping
                cryptocurrencies, staking options (flexible and fixed), and
                participation in launchpad and launchpool activities.
              </p>
            </li>
            <li>
              <strong>Fees</strong>
              <p>
                We charge fees for certain services. Details of our fees are
                available on our website. We reserve the right to change our
                fees at any time.
              </p>
            </li>
            <li>
              <strong>Security</strong>
              <p>
                We provide enhanced security measures, including 2FA and
                Anti-Phishing protection. However, you are responsible for
                safeguarding your account and personal information.
              </p>
            </li>
            <li>
              <strong>Prohibited Activities</strong>
              <p>
                You agree not to engage in any activities that violate laws or
                regulations, involve fraudulent or deceptive practices, or
                compromise the security of our platform.
              </p>
            </li>
            <li>
              <strong>Limitation of Liability</strong>
              <p>
                Nexus Pro is not liable for any direct, indirect, incidental, or
                consequential damages arising from your use of our services.
              </p>
            </li>
            <li>
              <strong>Changes to Terms</strong>
              <p>
                We may update these terms from time to time. We will notify you
                of any changes by posting the new terms on our website.
              </p>
            </li>
          </ol>

          <h3>Contact Us</h3>
          <p>
            If you have any questions about these Terms and Conditions, please
            contact us at{" "}
            <a href="mailto:support@xchange.com">support@xchange.com</a>.
          </p> */}
      </div>
    </div>
  );
};

export default TermsAndConditions;
