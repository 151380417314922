import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import { InfinitySpin } from "react-loader-spinner";

function Market() {
  const [currencylistData, setcurrencylistData] = useState([]);
  const [currencyCount, setcurrencyCount] = useState(0);
  const [siteLoader, setSiteLoader] = useState(false);

  useEffect(() => {
    gethomeCurrency();
  }, []);

  const navtradepage = async (symbol) => {
    window.location.href = `trade/${symbol}_USDT`;
  };

  const gethomeCurrency = async () => {
    var data = {
      apiUrl: apiService.homeCurrency,
    };
    setSiteLoader(true);

    var resp = await getMethod(data);
    setSiteLoader(false);

    if (resp.status) {
      setcurrencylistData(resp.Message);
    } else {
    }
  };

  return (
    <div>
      <Header />
      {siteLoader == true ? (
        <div className="dashboard_content_section loadercss">
          <InfinitySpin
            height="300"
            width="300"
            color="#12d5d5"
            ariaLabel="infinity-spin-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <section className="market_section">
          <div className="container">
            <h2>Markets</h2>
            <div>
              <div className="table-responsive">
                <div className="border-tab mt-3">
                  <table className="popular_table">
                    <thead className="pop-head">
                      <th>Name</th>
                      <th>Price (USD)</th>
                      <th className="text-center">Trade</th>
                    </thead>
                    <tbody>
                      {currencylistData && currencylistData.length > 0 ? (
                        currencylistData.map((obj, i) => {
                          return (
                            <tr>
                              <td>
                                <div className="pop_td_frst">
                                  <img
                                    src={obj.Currency_image}
                                    alt="Tether"
                                    className="pop_in_img"
                                  />
                                  <span className="pop_in_frst">
                                    {obj.currencyName}
                                  </span>
                                  <span className="earn_divs_insubhead">
                                    {obj.currencySymbol}
                                  </span>
                                </div>
                              </td>
                              {obj.marketprice <= 0 ? (
                                <td className="text-danger">
                                  {obj.currencySymbol == "SHIB" ? (
                                    <span className="pop_in_thrdplus">
                                      {parseFloat(
                                        obj.estimatedValueInUSDT
                                      ).toFixed(8)}
                                    </span>
                                  ) : (
                                    <span className="pop_in_thrdplus">
                                      {parseFloat(
                                        obj.estimatedValueInUSDT
                                      ).toFixed(2)}
                                    </span>
                                  )}
                                </td>
                              ) : (
                                <td className="text-success">
                                  {obj.currencySymbol == "SHIB" ? (
                                    <span className="pop_in_thrdplus">
                                      {parseFloat(
                                        obj.estimatedValueInUSDT
                                      ).toFixed(8)}
                                    </span>
                                  ) : (
                                    <span className="pop_in_thrdplus">
                                      {parseFloat(
                                        obj.estimatedValueInUSDT
                                      ).toFixed(2)}
                                    </span>
                                  )}
                                </td>
                              )}
                              <td>
                                <div
                                  className="mark_in_last"
                                  onClick={() =>
                                    navtradepage(obj.currencySymbol)
                                  }
                                >
                                  <span className="pop_in_lastspan">Trade</span>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={3}>
                            <div className="text-white text-center">
                              No Records Found{" "}
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
}

export default Market;
