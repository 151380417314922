import React from "react";
import {Link, useNavigate, NavLink} from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";

function SidebarNew() {

    const theme = useTheme();
    // const isMatch = useMediaQuery(theme.breakpoints.down("lg"));
    const navigate = useNavigate();


    return (
      <>
          <aside className="asidemeni">
              <div className="mennu_side">
               <NavLink to="/dashboard" className="navlink_new">
                <div className="chat-optionside">
                    <div className="menu_items_fex">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-side" viewBox="0 0 45 45" id="Compass"><path fill="#ffffff" d="M23.76 41.231c8.822 0 16-7.178 16-16s-7.178-16-16-16-16 7.178-16 16 7.178 16 16 16zm0-30c7.72 0 14 6.28 14 14s-6.28 14-14 14-14-6.28-14-14 6.28-14 14-14z" class="color231f20 svgShape"></path><path fill="#ffffff" d="M16.76 33.231a.986.986 0 0 0 .438-.102l9.403-4.594a.996.996 0 0 0 .459-.459l4.597-9.406a.999.999 0 0 0-1.337-1.337l-9.406 4.597a.996.996 0 0 0-.459.459l-4.594 9.403a.999.999 0 0 0 .899 1.439zm5.341-9.655l6.481-3.167-3.167 6.481-6.479 3.165 3.165-6.479z" class="color231f20 svgShape"></path></svg>
                    </div>
                    <span className="side-name">Dashboard</span>
                </div>
                </NavLink>
                <NavLink to="/security" className="navlink_new">
                <div className="chat-optionside">
                    <div className="menu_items_fex">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-side" fill="none" viewBox="0 0 24 24" id="Security"><path fill="#ffffff" fill-rule="evenodd" d="M11.8712 2.51688C11.9556 2.49437 12.0444 2.49437 12.1288 2.51688L19.6288 4.51688C19.8477 4.57525 20 4.77347 20 5V13.8593C20 15.3639 19.248 16.7689 17.9962 17.6035L13.3868 20.6764C12.547 21.2363 11.453 21.2363 10.6132 20.6764L6.00385 17.6035C4.75195 16.7689 4 15.3639 4 13.8593V5C4 4.77347 4.15229 4.57525 4.37117 4.51688L11.8712 2.51688ZM5 5.38414V13.8593C5 15.0295 5.58485 16.1223 6.55855 16.7714L11.1679 19.8444C11.6718 20.1803 12.3282 20.1803 12.8321 19.8444L17.4415 16.7714C18.4151 16.1223 19 15.0295 19 13.8593V5.38414L12 3.51747L5 5.38414Z" clip-rule="evenodd" class="color000000 svgShape"></path><path fill="#ffffff" fill-rule="evenodd" d="M15.3892 10.1861C15.5626 10.4011 15.5288 10.7158 15.3139 10.8892L12.2139 13.3892C12.0284 13.5388 11.763 13.5367 11.5799 13.3841L9.17992 11.3841C8.96778 11.2073 8.93912 10.892 9.1159 10.6799C9.29268 10.4678 9.60797 10.4391 9.8201 10.6159L11.9052 12.3535L14.6861 10.1108C14.9011 9.93743 15.2159 9.97116 15.3892 10.1861Z" clip-rule="evenodd" class="color000000 svgShape"></path></svg>
                    </div>
                    <span className="side-name">Security</span>
                </div>
                </NavLink>
                <NavLink to="/kyc" className="navlink_new">
                <div className="chat-optionside">
                    <div className="menu_items_fex">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-side" viewBox="0 0 1000 1000" id="IdentityCard"><path d="M833.08,793.54H166.92A83,83,0,0,1,84,710.62V289.38a83,83,0,0,1,82.92-82.92H833.08A83,83,0,0,1,916,289.38V710.62A83,83,0,0,1,833.08,793.54ZM166.92,238.46A51,51,0,0,0,116,289.38V710.62a51,51,0,0,0,50.92,50.92H833.08A51,51,0,0,0,884,710.62V289.38a51,51,0,0,0-50.92-50.92Z" fill="#ffffff" class="color000000 svgShape"></path><path d="M361.49 703.86H210.37a54.34 54.34 0 01-54.28-54.28V600.72A110.64 110.64 0 01266.61 490.21h38.65A110.63 110.63 0 01415.77 600.72v48.86A54.34 54.34 0 01361.49 703.86zM266.61 522.21a78.61 78.61 0 00-78.52 78.51v48.86a22.31 22.31 0 0022.28 22.28H361.49a22.31 22.31 0 0022.28-22.28V600.72a78.6 78.6 0 00-78.51-78.51zM285.93 471.72a87.79 87.79 0 1187.79-87.79A87.89 87.89 0 01285.93 471.72zm0-143.58a55.79 55.79 0 1055.79 55.79A55.86 55.86 0 00285.93 328.14zM822.37 355.31h-341a16 16 0 010-32H822.37a16 16 0 010 32zM822.37 462.44h-341a16 16 0 010-32H822.37a16 16 0 010 32zM822.37 569.56h-341a16 16 0 110-32H822.37a16 16 0 010 32zM822.37 676.69h-341a16 16 0 110-32H822.37a16 16 0 010 32z" fill="#ffffff" class="color000000 svgShape"></path></svg>
                    </div>
                    <span className="side-name">Identity verification</span>
                </div>
                </NavLink>
               <NavLink to="/deposit" className="navlink_new">
                <div className="chat-optionside">
                    <div className="menu_items_fex">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-side-coupen" fill="none" viewBox="0 0 128 128" id="coupon"><path stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="7" d="M49 42C53.2887 42 56.8768 39.0002 57.7805 34.9844 58.0125 33.9536 58.1284 33.4382 58.4024 33.2191 58.6763 33 59.1175 33 60 33H81C95.1146 33 102.172 33 106.745 37.1448 107.133 37.4965 107.504 37.867 107.855 38.2551 112 42.8281 112 49.8854 112 64 112 78.1146 112 85.1719 107.855 89.7449 107.504 90.133 107.133 90.5035 106.745 90.8552 102.172 95 95.1146 95 81 95H60C59.1175 95 58.6763 95 58.4024 94.7809 58.1284 94.5618 58.0125 94.0464 57.7805 93.0156 56.8768 88.9998 53.2887 86 49 86M49 42C44.7261 42 41.1479 39.0209 40.2289 35.0259 39.9896 33.9856 39.87 33.4654 39.5894 33.247 39.3088 33.0286 38.8658 33.0383 37.9798 33.0576 29.6355 33.2396 24.7287 33.9965 21.2551 37.1448 20.867 37.4965 20.4965 37.867 20.1448 38.2551 16 42.8281 16 49.8854 16 64 16 78.1146 16 85.1719 20.1448 89.745 20.4965 90.133 20.867 90.5035 21.2551 90.8552 24.7287 94.0035 29.6355 94.7604 37.9798 94.9424 38.8658 94.9617 39.3088 94.9714 39.5894 94.753 39.87 94.5346 39.9896 94.0144 40.2289 92.9741 41.1479 88.9792 44.7261 86 49 86" class="colorStroke000000 svgStroke"></path><path stroke="#ffffff" stroke-dasharray="9 9" stroke-linecap="round" stroke-width="7" d="M49 45L49 83" class="colorStroke000000 svgStroke"></path></svg>
                    </div>
                    <span className="side-name">Deposit</span>
                </div>
                </NavLink>
                <NavLink to="/withdraw" className="navlink_new">
                <div className="chat-optionside">
                    <div className="menu_items_fex">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-side" fill="none" viewBox="0 0 48 48" id="TrophyCup"><path fill="#ffffff" fill-rule="evenodd" d="M12 7C12 6.44772 12.4477 6 13 6H35C35.5523 6 36 6.44772 36 7V8H41C41.5523 8 42 8.44772 42 9V15C42 17.7614 39.7614 20 37 20H35.3172C33.776 24.3604 29.7862 27.5641 25 27.9589V34H32C32.5523 34 33 34.4477 33 35V41C33 41.5523 32.5523 42 32 42H16C15.4477 42 15 41.5523 15 41V35C15 34.4477 15.4477 34 16 34H23V27.9589C18.2138 27.5641 14.224 24.3604 12.6828 20H11C8.23858 20 6 17.7614 6 15V9C6 8.44772 6.44772 8 7 8H12V7ZM34 16V9V8H14V9V16C14 21.5228 18.4772 26 24 26C29.5228 26 34 21.5228 34 16ZM36 10V16V18H37C38.6569 18 40 16.6569 40 15V10H36ZM8 10H12V16V18H11C9.34315 18 8 16.6569 8 15V10ZM17 36V40H31V36H17Z" clip-rule="evenodd" class="color333333 svgShape"></path></svg>
                    </div>
                    <span className="side-name">Withdraw</span>
                </div>
                </NavLink>
                <NavLink to="/support" className="navlink_new">
                <div className="chat-optionside">
                    <div className="menu_items_fex">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-side-sup mx-1" viewBox="0 0 92 92" id="Information"><path d="M43.3 73.8c-.8 0-1.6-.3-2.2-.8-1-.8-1.5-2.1-1.2-3.4l4.9-25-2.7 1.5c-1.7.9-3.8.4-4.8-1.3-.9-1.7-.4-3.8 1.3-4.8l9.3-5.3c1.2-.7 2.7-.6 3.8.2 1.1.8 1.6 2.2 1.4 3.5l-5.1 26 4.2-1.8c1.8-.8 3.8 0 4.6 1.8.8 1.8 0 3.8-1.8 4.6l-10.3 4.5c-.4.2-.9.3-1.4.3zM53.2 26c.9-.9 1.5-2.2 1.5-3.5 0-1.3-.5-2.6-1.5-3.5-.9-.9-2.2-1.5-3.5-1.5-1.3 0-2.6.5-3.5 1.5-.9.9-1.5 2.2-1.5 3.5 0 1.3.5 2.6 1.5 3.5.9.9 2.2 1.5 3.5 1.5 1.3 0 2.6-.5 3.5-1.5zM92 46C92 20.6 71.4 0 46 0S0 20.6 0 46s20.6 46 46 46 46-20.6 46-46zm-8 0c0 21-17 38-38 38S8 67 8 46 25 8 46 8s38 17 38 38z" fill="#ffffff" class="color000000 svgShape"></path></svg>
                    </div>
                    <span className="side-name">Support</span>
                </div>
                </NavLink>
                {/*  <NavLink to="/" className="navlink_new">
                <div className="chat-optionside">
                    <div className="menu_items_fex">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-side-adress" viewBox="0 0 32 32" id="AddressBook"><path d="M8,30H25a3,3,0,0,0,3-3V5a3,3,0,0,0-3-3H8A3,3,0,0,0,5,5V7H4V9H5v2H4v2H5v2H4v2H5v2H4v2H5v2H4v2H5v2A3,3,0,0,0,8,30ZM7,25H8V23H7V21H8V19H7V17H8V15H7V13H8V11H7V9H8V7H7V5A1,1,0,0,1,8,4H25a1,1,0,0,1,1,1V27a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1Z" fill="#ffffff" class="color000000 svgShape"></path><path d="M12.4,23.3h9.8a2.41,2.41,0,0,0,2.4-2.4V18.8a5.17,5.17,0,0,0-3.1-4.74,4.25,4.25,0,0,0,.3-1.56,4.5,4.5,0,0,0-9,0,4.34,4.34,0,0,0,.3,1.57A5.14,5.14,0,0,0,10,18.8v2.1A2.41,2.41,0,0,0,12.4,23.3ZM17.3,10a2.5,2.5,0,1,1-2.5,2.5A2.5,2.5,0,0,1,17.3,10ZM12,18.8a3.18,3.18,0,0,1,2.22-3,4.45,4.45,0,0,0,6.16,0,3.18,3.18,0,0,1,2.22,3v2.1a.4.4,0,0,1-.4.4H12.4a.4.4,0,0,1-.4-.4Z" fill="#ffffff" class="color000000 svgShape"></path></svg>
                    </div>
                    <span className="side-name">Address book</span>
                </div>
                </NavLink>
                <NavLink to="/" className="navlink_new">
                <div className="chat-optionside">
                    <div className="menu_items_fex">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-side" fill="none" viewBox="0 0 24 24" id="BankBuilding"><path stroke="#595b5e" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 7.21901C11.9669 7.21901 11.9352 7.23222 11.9119 7.2557C11.8886 7.27918 11.8757 7.31099 11.8759 7.34406C11.8759 7.41313 11.9319 7.46911 12.001 7.46911C12.0701 7.46911 12.126 7.41313 12.126 7.34406C12.125 7.27506 12.069 7.21954 12 7.21901" class="colorStroke323232 svgStroke"></path><path stroke="#595b5e" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M20.9757 10.3323H3.02425C2.88425 10.3326 2.74989 10.2771 2.65081 10.1782C2.55172 10.0793 2.49603 9.94509 2.49603 9.80509V7.82826C2.49636 7.39784 2.77195 7.01588 3.18032 6.87987L11.6659 4.05069C11.8828 3.97866 12.1172 3.97866 12.3341 4.05069L20.8197 6.87987C21.228 7.01588 21.5036 7.39784 21.504 7.82826V9.80509C21.504 9.94509 21.4483 10.0793 21.3492 10.1782C21.2501 10.2771 21.1157 10.3326 20.9757 10.3323Z" clip-rule="evenodd" class="colorStroke323232 svgStroke"></path><path stroke="#595b5e" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3.99667 18.0025V10.3323M20.0033 10.3323V18.0025M7.99833 10.3323V18.0025M12 10.3323V18.0025M16.0017 10.3323V18.0025" class="colorStroke323232 svgStroke"></path><path stroke="#595b5e" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M20.3855 18.0025H3.61451C3.23569 18.0027 2.88947 18.2169 2.72014 18.5557L2.10188 19.7922C2.03206 19.931 1.99583 20.0841 1.99583 20.2394V21.0037H22.0042V20.2394C22.0042 20.0841 21.9679 19.931 21.8981 19.7922L21.2799 18.5557C21.1105 18.2169 20.7643 18.0027 20.3855 18.0025Z" clip-rule="evenodd" class="colorStroke323232 svgStroke"></path><path stroke="#595b5e" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M23.0046 21.0037H0.995407" class="colorStroke323232 svgStroke"></path></svg>
                    </div>
                    <span className="side-name">API keys</span>
                </div>
                </NavLink>
                <NavLink to="/" className="navlink_new">
                <div className="chat-optionside">
                    <div className="menu_items_fex">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-side" fill="none" viewBox="0 0 24 24" id="BankBuilding"><path stroke="#595b5e" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 7.21901C11.9669 7.21901 11.9352 7.23222 11.9119 7.2557C11.8886 7.27918 11.8757 7.31099 11.8759 7.34406C11.8759 7.41313 11.9319 7.46911 12.001 7.46911C12.0701 7.46911 12.126 7.41313 12.126 7.34406C12.125 7.27506 12.069 7.21954 12 7.21901" class="colorStroke323232 svgStroke"></path><path stroke="#595b5e" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M20.9757 10.3323H3.02425C2.88425 10.3326 2.74989 10.2771 2.65081 10.1782C2.55172 10.0793 2.49603 9.94509 2.49603 9.80509V7.82826C2.49636 7.39784 2.77195 7.01588 3.18032 6.87987L11.6659 4.05069C11.8828 3.97866 12.1172 3.97866 12.3341 4.05069L20.8197 6.87987C21.228 7.01588 21.5036 7.39784 21.504 7.82826V9.80509C21.504 9.94509 21.4483 10.0793 21.3492 10.1782C21.2501 10.2771 21.1157 10.3326 20.9757 10.3323Z" clip-rule="evenodd" class="colorStroke323232 svgStroke"></path><path stroke="#595b5e" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3.99667 18.0025V10.3323M20.0033 10.3323V18.0025M7.99833 10.3323V18.0025M12 10.3323V18.0025M16.0017 10.3323V18.0025" class="colorStroke323232 svgStroke"></path><path stroke="#595b5e" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M20.3855 18.0025H3.61451C3.23569 18.0027 2.88947 18.2169 2.72014 18.5557L2.10188 19.7922C2.03206 19.931 1.99583 20.0841 1.99583 20.2394V21.0037H22.0042V20.2394C22.0042 20.0841 21.9679 19.931 21.8981 19.7922L21.2799 18.5557C21.1105 18.2169 20.7643 18.0027 20.3855 18.0025Z" clip-rule="evenodd" class="colorStroke323232 svgStroke"></path><path stroke="#595b5e" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M23.0046 21.0037H0.995407" class="colorStroke323232 svgStroke"></path></svg>
                    </div>
                    <span className="side-name">Sub-accounts</span>
                </div>
                </NavLink>
                <NavLink to="/" className="navlink_new">
                <div className="chat-optionside">
                    <div className="menu_items_fex">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-side" fill="none" viewBox="0 0 24 24" id="BankBuilding"><path stroke="#595b5e" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 7.21901C11.9669 7.21901 11.9352 7.23222 11.9119 7.2557C11.8886 7.27918 11.8757 7.31099 11.8759 7.34406C11.8759 7.41313 11.9319 7.46911 12.001 7.46911C12.0701 7.46911 12.126 7.41313 12.126 7.34406C12.125 7.27506 12.069 7.21954 12 7.21901" class="colorStroke323232 svgStroke"></path><path stroke="#595b5e" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M20.9757 10.3323H3.02425C2.88425 10.3326 2.74989 10.2771 2.65081 10.1782C2.55172 10.0793 2.49603 9.94509 2.49603 9.80509V7.82826C2.49636 7.39784 2.77195 7.01588 3.18032 6.87987L11.6659 4.05069C11.8828 3.97866 12.1172 3.97866 12.3341 4.05069L20.8197 6.87987C21.228 7.01588 21.5036 7.39784 21.504 7.82826V9.80509C21.504 9.94509 21.4483 10.0793 21.3492 10.1782C21.2501 10.2771 21.1157 10.3326 20.9757 10.3323Z" clip-rule="evenodd" class="colorStroke323232 svgStroke"></path><path stroke="#595b5e" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3.99667 18.0025V10.3323M20.0033 10.3323V18.0025M7.99833 10.3323V18.0025M12 10.3323V18.0025M16.0017 10.3323V18.0025" class="colorStroke323232 svgStroke"></path><path stroke="#595b5e" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M20.3855 18.0025H3.61451C3.23569 18.0027 2.88947 18.2169 2.72014 18.5557L2.10188 19.7922C2.03206 19.931 1.99583 20.0841 1.99583 20.2394V21.0037H22.0042V20.2394C22.0042 20.0841 21.9679 19.931 21.8981 19.7922L21.2799 18.5557C21.1105 18.2169 20.7643 18.0027 20.3855 18.0025Z" clip-rule="evenodd" class="colorStroke323232 svgStroke"></path><path stroke="#595b5e" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M23.0046 21.0037H0.995407" class="colorStroke323232 svgStroke"></path></svg>
                    </div>
                    <span className="side-name">Settings</span>
                </div>
                </NavLink> */}
              </div>
          </aside>
      </>
    );
  }
  
  export default SidebarNew;