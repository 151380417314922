import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { Link, useNavigate } from "react-router-dom";
import apiService from "../core/service/detail";
import axios from "axios";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getMethod, postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import moment from "moment";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { InfinitySpin } from "react-loader-spinner";

function Dashboard() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "#fff0",
    border: "2px solid #000",
    boxShadow: 0,
    p: 0,
  };
  const [authToken, setauthToken] = useState(false);
  const [usdtvalue, setUsdtvalue] = useState();
  const [upcommingToken, setupcommingToken] = useState([]);
  const [inprogressToken, setinprogressToken] = useState([]);
  const [expiredToken, setexpiredToken] = useState([]);
  const [totalToken, settotalToken] = useState(0);
  const [totalUsdtStake, settotalUsdtStake] = useState(0);
  const [kycstatus, setkycstatus] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    getAllLaunchpad();

    let getToken = localStorage.getItem("user_token");
    if (getToken != "" && getToken != undefined && getToken != null) {
      setauthToken(true);
    } else {
      setauthToken(false);
    }
  }, [0]);
  const [stakeAmount, setStatkeAmount] = useState(0);
  const [toastId, setToastId] = useState(null);
  const showSuccessToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.success(message);
    setToastId(newToastId);
  };

  const showErrorToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.error(message);
    setToastId(newToastId);
  };

  const getAllLaunchpad = async () => {
    try {
      setSiteLoader(true);

      var data = {
        apiUrl: apiService.getAllLaunchpad,
      };
      var resp = await getMethod(data);
      var responseData = resp.data;
      setSiteLoader(false);
      if (resp.status) {
        settotalToken(responseData.getlaunchpadTotal);
        settotalUsdtStake(responseData.totalStackAmount);
        setupcommingToken(responseData.UpcomingTokens);
        setinprogressToken(responseData.inprogressToken);
        setexpiredToken(responseData.expiredTokens);
        if (
          responseData.UpcomingTokens.length == 0 &&
          responseData.expiredTokens.length == 0 &&
          responseData.inprogressToken.length == 0
        ) {
          setselectedTable("progress");
        } else if (
          responseData.UpcomingTokens.length > 0 &&
          responseData.expiredTokens.length == 0 &&
          responseData.inprogressToken.length == 0
        ) {
          setselectedTable("upcome");
        } else if (
          responseData.UpcomingTokens.length == 0 &&
          responseData.expiredTokens.length > 0 &&
          responseData.inprogressToken.length == 0
        ) {
          setselectedTable("complete");
        } else if (
          responseData.UpcomingTokens.length == 0 &&
          responseData.expiredTokens.length == 0 &&
          responseData.inprogressToken.length > 0
        ) {
          setselectedTable("progress");
        } else {
          setselectedTable("progress");
        }

        // toast.success(resp.Message);
      } else {
        // toast.error(resp.Message);
      }
    } catch (error) {}
  };
  function calculateDaysBetweenDates(startDate, endDate) {
    // Convert dates to Date objects
    const start = new Date(startDate);
    const end = new Date(endDate);
    if (isNaN(start.getTime()) || isNaN(end.getTime())) {
      return "Invalid date format. Please use YYYY-MM-DD.";
    }
    // Calculate the difference in milliseconds
    const differenceInMilliseconds = end - start;

    // Convert milliseconds to days
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
    return differenceInDays;
  }

  const [open, setOpen] = React.useState(false);
  const [popupdata, setpopupdata] = useState("progress");
  const [days, setDays] = useState("");
  const [buttonStatus, setbuttonStatus] = useState(false);

  const handleOpen = (itemdata) => {
    const currentDateTimestamp = new Date().getTime();
    const endingDate = new Date(itemdata.endDate);
    const startingDate = new Date(itemdata.startDate);
    if (currentDateTimestamp > endingDate) {
      setbuttonStatus(true);
    } else if (currentDateTimestamp < startingDate) {
      setbuttonStatus(true);
    } else {
      setbuttonStatus(false);
    }
    setpopupdata(itemdata);
    const formingdays = calculateDaysBetweenDates(
      itemdata.startDate,
      itemdata.endDate
    );
    setDays(formingdays);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);
  const [selectedTable, setselectedTable] = useState("complete");
  const selecttab = (data) => {
    setselectedTable(data);
  };
  const [price, setPrice] = useState(0);
  const [rewards, setRewards] = useState(0);
  const [siteLoader, setSiteLoader] = useState(false);

  const stakechange = (e) => {
    console.log(e.target.value, "---");
    console.log(e.target.value > popupdata.stakelimit);
    setStatkeAmount(+e.target.value);
    setRewards((stakeAmount * popupdata.ROI) / 100);
    console.log((stakeAmount * popupdata.ROI) / 100);
  };

  const preventInvalidKeys = (event) => {
    console.log("--");
    if (["e", "E", "+", "-"].includes(event.key)) {
      event.preventDefault();
    }
    if (event.key === " " || event.key === "Tab") {
      event.preventDefault();
    }
    if (event.target.value.length > 6) {
      event.preventDefault();
    }
  };
  const purchaseUsdt = async () => {
    try {
      console.log(popupdata, "popupdata");
      setSiteLoader(true);

      var obj = {
        stakeAmount: stakeAmount,
        rewardAmount: rewards,
        tokenId: popupdata.currencyId,
        // orderid: popupdata._id,
        tokenName: popupdata.currency_name,
        startDate: popupdata.startDate,
        endDate: popupdata.endDate,
      };
      console.log(obj, "obj");
      var data = {
        apiUrl: apiService.purchaseToken,
        payload: obj,
      };
      var resp = await postMethod(data);
      setSiteLoader(false);

      if (resp.status == true) {
        showSuccessToast(resp.Message);
        handleClose();
      } else {
        showErrorToast(resp.Message);
      }
    } catch (err) {
      console.log(err, "ererer");
    }
  };
  return (
    <div>
      <Header />
      {siteLoader == true ? (
        <div className="dashboard_content_section loadercss">
          <InfinitySpin
            height="300"
            width="300"
            color="#12d5d5"
            ariaLabel="infinity-spin-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : open == false ? (
        <div className="container-fluid px-0 innerpages">
          <div className=" lanchpool">
            <section className="lanch-header">
              <div className="container">
                <div className="row justify-content-between">
                  <div className="col-lg-6">
                    <h2>Lanchpool</h2>
                    <h5>Stake to reveal the next big tokens!</h5>
                    {/* <div className="d-flex">
          <i class="fa-solid fa-share-nodes text-white mr-2"></i>
          <h6 className="shares"> Share</h6>
        </div> */}
                    <div className="d-flex gap-40">
                      <div>
                        <p>
                          <span>Projects</span>
                        </p>
                        <h5 className="b500">{totalToken}</h5>
                      </div>
                      <div>
                        <p>
                          <span>Total Staked (USDT) </span>
                        </p>
                        <h5 className="b500">$ {totalUsdtStake}</h5>
                      </div>
                    </div>

                    {/* <button>
          {" "}
          <i class="fa-solid fa-calculator"></i> View Profits
        </button> */}
                  </div>
                  <div className="col-lg-6">
                    <img
                      src={require("../images/Lanchpool.png")}
                      className="launchpad_main_img"
                      width="400px"
                    />
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div className="container-fluid">
                <div className=" m-0 p-5">
                  <span className="core_div_head mb-4">All Products</span>

                  <div className="lanch-body-head">
                    {inprogressToken && inprogressToken.length > 0 ? (
                      <button
                        className={
                          selectedTable == "progress"
                            ? " launchbuttonactive"
                            : "launchbutton"
                        }
                        onClick={() => selecttab("progress")}
                      >
                        {" "}
                        <span className="hero_getstarted text-white">
                          {" "}
                          In Progress{" "}
                        </span>{" "}
                      </button>
                    ) : (
                      ""
                    )}{" "}
                    {upcommingToken && upcommingToken.length > 0 ? (
                      <button
                        className={
                          selectedTable == "upcome"
                            ? " launchbuttonactive"
                            : "launchbutton"
                        }
                        onClick={() => selecttab("upcome")}
                      >
                        {" "}
                        <span className="hero_getstarted text-white">
                          Upcomming
                        </span>
                      </button>
                    ) : (
                      ""
                    )}{" "}
                    {expiredToken && expiredToken.length > 0 ? (
                      <button
                        className={
                          selectedTable == "complete"
                            ? " launchbuttonactive"
                            : "launchbutton"
                        }
                        onClick={() => selecttab("complete")}
                      >
                        {" "}
                        <span className="hero_getstarted text-white">
                          Completed
                        </span>{" "}
                      </button>
                    ) : (
                      ""
                    )}
                  </div>

                  {selectedTable == "progress" ? (
                    inprogressToken && inprogressToken.length > 0 ? (
                      inprogressToken.map((item, i) => {
                        var startDate = moment(item.startDate).format(
                          "DD-MM-YYYY"
                        );
                        var enddate = moment(item.endDate).format("DD-MM-YYYY");
                        const formingdays = calculateDaysBetweenDates(
                          item.startDate,
                          item.endDate
                        );

                        return (
                          <div className="Depositcard my-4">
                            <div className="row">
                              <div className="col-lg-3 px-2">
                                <div className="lanch-card-borderless">
                                  <div>
                                    <div className="d-flex justify-content-between gap16">
                                      <button className="completed">
                                        {" "}
                                        <i class="fa-regular fa-circle-check mr-2"></i>{" "}
                                        Inprogress
                                      </button>
                                      {/* <p>
                              <i class="fa-solid fa-share-from-square"></i>
                            </p> */}
                                    </div>
                                    <h3>
                                      {" "}
                                      <img
                                        src={item.currency_image}
                                        className="img"
                                      />{" "}
                                      {item.symbol} POLL
                                    </h3>
                                    <div className="d-flex gap16 mt-4 text-white matic_pool_list">
                                      <p className="cursor">
                                        {" "}
                                        <i class="fa-regular fa-file-lines mr-2"></i>{" "}
                                        <a
                                          href={item.whitepaper_link}
                                          target="_blank"
                                          class="text-white"
                                        >
                                          White paper
                                        </a>
                                      </p>
                                      <p className="cursor">
                                        {" "}
                                        <i class="fa-solid fa-globe mr-2"></i>{" "}
                                        <a
                                          href={item.official_website}
                                          target="_blank"
                                          class="text-white"
                                        >
                                          Website
                                        </a>
                                      </p>
                                      <p className="cursor">
                                        {" "}
                                        <i class="fa-solid fa-link"></i>{" "}
                                        <a
                                          href={item.rules}
                                          target="_blank"
                                          class="text-white"
                                        >
                                          Rules
                                        </a>
                                      </p>
                                    </div>
                                  </div>

                                  <div>
                                    <div className="d-flex justify-content-between gap16">
                                      <p>Total Rewards</p>
                                      <span>
                                        {" "}
                                        {item.totalReward == null ||
                                        item.totalReward == undefined ||
                                        item.totalReward == ""
                                          ? "0"
                                          : item.totalReward}{" "}
                                        {item.symbol}
                                      </span>
                                    </div>

                                    <div className="d-flex justify-content-between gap16">
                                      <p>Farming period</p>
                                      <span>{formingdays} days</span>
                                    </div>

                                    <div className="d-flex justify-content-between gap16">
                                      <p>End date</p>
                                      <span>
                                        {" "}
                                        {enddate == null ||
                                        enddate == undefined ||
                                        enddate == ""
                                          ? "--"
                                          : enddate}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 px-2">
                                <div
                                  className="lanch-card"
                                  onClick={() => handleOpen(item)}
                                >
                                  <h3>
                                    {" "}
                                    {/* <img
                                      // src={require("../images/Bitcoin-Logo.png")}
                                      src="https://res.cloudinary.com/taikonz-com/image/upload/v1664014268/b15qia164vomylxkmqfp.png"
                                      className="img"
                                    />{" "} */}
                                    {item.stake_currency} POLL
                                  </h3>
                                  <h4>Stake {item.stake_currency} to earn {item.symbol}</h4>

                                  <div className="d-flex justify-content-center">
                                    <div className="lan_coin_chn">
                                      <img
                                        src={item.currency_image}
                                        alt="Ethereum logo"
                                        className="lanpol_img_main"
                                      />
                                      <img
                                        src="https://res.cloudinary.com/taikonz-com/image/upload/v1664014268/b15qia164vomylxkmqfp.png"
                                        alt="BNB logo"
                                        className="lan_pos_img"
                                      />
                                    </div>
                                  </div>

                                  <div className="d-flex justify-content-between gap32">
                                    <p>Prize pool ROI</p>
                                    <span>
                                      {item.ROI == null ||
                                      item.ROI == undefined ||
                                      item.ROI == ""
                                        ? "0"
                                        : item.ROI}{" "}
                                      %
                                    </span>
                                  </div>

                                  <div className="d-flex justify-content-between gap16">
                                    <p>Total Staked</p>
                                    <span>
                                      {" "}
                                      {item.totalStakeAmount == null ||
                                      item.totalStakeAmount == undefined ||
                                      item.totalStakeAmount == ""
                                        ? "0"
                                        : item.totalStakeAmount}{" "}
                                      USDT
                                    </span>
                                  </div>

                                  <div className="d-flex justify-content-between gap16">
                                    <p>Total rewards</p>
                                    <span>
                                      {item.totalReward == null ||
                                      item.totalReward == undefined ||
                                      item.totalReward == ""
                                        ? "0"
                                        : item.totalReward}{" "}
                                      {item.symbol}
                                    </span>
                                  </div>

                                  <div className="d-flex justify-content-between gap16">
                                    <p>Individual staking limit</p>
                                    <span>
                                      {item.stakelimit == null ||
                                      item.stakelimit == undefined ||
                                      item.stakelimit == ""
                                        ? "0"
                                        : item.stakelimit}
                                      USDT
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      " "
                    )
                  ) : selectedTable == "upcome" ? (
                    <>
                      {upcommingToken && upcommingToken.length > 0
                        ? upcommingToken.map((item, i) => {
                            var startDate = moment(item.startDate).format(
                              "DD-MM-YYYY"
                            );
                            var enddate = moment(item.endDate).format(
                              "DD-MM-YYYY"
                            );
                            const formingdays = calculateDaysBetweenDates(
                              item.startDate,
                              item.endDate
                            );

                            return (
                              <div className="Depositcard my-4">
                                <div className="row">
                                  <div className="col-lg-3 px-2">
                                    <div className="lanch-card-borderless">
                                      <div>
                                        <div className="d-flex justify-content-between gap16">
                                          <button className="completed">
                                            {" "}
                                            <i class="fa-regular fa-circle-check mr-2"></i>{" "}
                                            Comming Soon
                                          </button>
                                          {/* <p>
                                  <i class="fa-solid fa-share-from-square"></i>
                                </p> */}
                                        </div>
                                        <h3>
                                          {" "}
                                          <img
                                            className="img"
                                            // src={require("../images/Bitcoin-Logo.png")}
                                            src={item.currency_image}
                                          />{" "}
                                          {item.symbol} POLL
                                        </h3>
                                        <div className="d-flex gap16 mt-4 text-white">
                                          <p className="cursor">
                                            {" "}
                                            <i class="fa-regular fa-file-lines mr-2"></i>{" "}
                                            <a
                                              href={item.whitepaper_link}
                                              target="_blank"
                                              class="text-white"
                                            >
                                              White paper
                                            </a>
                                          </p>
                                          <p className="cursor">
                                            {" "}
                                            <i class="fa-solid fa-globe mr-2"></i>{" "}
                                            <a
                                              href={item.official_website}
                                              target="_blank"
                                              class="text-white"
                                            >
                                              Website
                                            </a>
                                          </p>
                                          <p className="cursor">
                                            {" "}
                                            <i class="fa-solid fa-link"></i>{" "}
                                            <a
                                              href={item.rules}
                                              target="_blank"
                                              class="text-white"
                                            >
                                              Rules
                                            </a>
                                          </p>
                                        </div>
                                      </div>

                                      <div>
                                        <div className="d-flex justify-content-between gap16">
                                          <p>Total Rewards</p>
                                          <span>
                                            {" "}
                                            {item.totalReward == null ||
                                            item.totalReward == undefined ||
                                            item.totalReward == ""
                                              ? "0"
                                              : item.totalReward}{" "}
                                            {item.symbol}
                                          </span>
                                        </div>

                                        <div className="d-flex justify-content-between gap16">
                                          <p>Farming period</p>
                                          <span>{formingdays} days</span>
                                        </div>

                                        <div className="d-flex justify-content-between gap16">
                                          <p>End date</p>
                                          <span>
                                            {" "}
                                            {enddate == null ||
                                            enddate == undefined ||
                                            enddate == ""
                                              ? "--"
                                              : enddate}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-lg-3 px-2">
                                    <div
                                      className="lanch-card"
                                      onClick={() => handleOpen(item)}
                                    >
                                      <h3>
                                        {" "}
                                        <img
                                          className="img"
                                          // src={require("../images/Bitcoin-Logo.png")}
                                          src="https://res.cloudinary.com/taikonz-com/image/upload/v1664014268/b15qia164vomylxkmqfp.png"
                                        />{" "}
                                        {item.stake_currency} POLL
                                      </h3>
                                      <h4>Stake {item.stake_currency} to earn {item.symbol}</h4>
                                      <div className="d-flex justify-content-center">
                                        <div className="lan_coin_chn">
                                          <img
                                            // src={require("../images/eth.png")}
                                            src={item.currency_image}
                                            alt="Ethereum logo"
                                            className="lanpol_img_main"
                                          />
                                          <img
                                            // src={require("../images/bnb.png")}
                                            src="https://res.cloudinary.com/taikonz-com/image/upload/v1664014268/b15qia164vomylxkmqfp.png"
                                            alt="BNB logo"
                                            className="lan_pos_img"
                                          />
                                        </div>
                                      </div>

                                      <div className="d-flex justify-content-between gap32">
                                        <p>Prize pool ROI</p>
                                        <span>
                                          {item.ROI == null ||
                                          item.ROI == undefined ||
                                          item.ROI == ""
                                            ? "0"
                                            : item.ROI}{" "}
                                          %
                                        </span>
                                      </div>

                                      <div className="d-flex justify-content-between gap16">
                                        <p>Total Staked</p>
                                        <span>
                                          {item.totalStakeAmount == null ||
                                          item.totalStakeAmount == undefined ||
                                          item.totalStakeAmount == ""
                                            ? "0"
                                            : item.totalStakeAmount}{" "}
                                          USDT
                                        </span>
                                      </div>

                                      <div className="d-flex justify-content-between gap16">
                                        <p>Total rewards</p>
                                        <span>
                                          {item.totalReward == null ||
                                          item.totalReward == undefined ||
                                          item.totalReward == ""
                                            ? "0"
                                            : item.totalReward}{" "}
                                          {item.symbol}
                                        </span>
                                      </div>

                                      <div className="d-flex justify-content-between gap16">
                                        <p>Individual staking limit</p>
                                        <span>
                                          {item.stakelimit == null ||
                                          item.stakelimit == undefined ||
                                          item.stakelimit == ""
                                            ? "0"
                                            : item.stakelimit}
                                          USDT
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        : ""}
                    </>
                  ) : selectedTable == "complete" ? (
                    <>
                      {expiredToken && expiredToken.length > 0
                        ? expiredToken.map((item, i) => {
                            var startDate = moment(item.startDate).format(
                              "DD-MM-YYYY"
                            );
                            var enddate = moment(item.endDate).format(
                              "DD-MM-YYYY"
                            );
                            const formingdays = calculateDaysBetweenDates(
                              item.startDate,
                              item.endDate
                            );

                            return (
                              <div className="Depositcard my-4">
                                <div className="row">
                                  <div className="col-lg-3 px-2">
                                    <div className="lanch-card-borderless">
                                      <div>
                                        <div className="d-flex justify-content-between gap16">
                                          <button className="completed">
                                            {" "}
                                            <i class="fa-regular fa-circle-check mr-2"></i>{" "}
                                            Completed
                                          </button>
                                          {/* <p>
                                  <i class="fa-solid fa-share-from-square"></i>
                                </p> */}
                                        </div>
                                        <h3>
                                          {" "}
                                          <img
                                            className="img"
                                            // src={require("../images/Bitcoin-Logo.png")}
                                            src={item.currency_image}
                                          />{" "}
                                          {item.symbol} POLL
                                        </h3>
                                        <div className="d-flex gap16 mt-4 text-white">
                                          <p className="cursor">
                                            {" "}
                                            <i class="fa-regular fa-file-lines mr-2"></i>{" "}
                                            <a
                                              href={item.whitepaper_link}
                                              target="_blank"
                                              class="text-white"
                                            >
                                              White paper
                                            </a>
                                          </p>
                                          <p className="cursor">
                                            {" "}
                                            <i class="fa-solid fa-globe mr-2"></i>{" "}
                                            <a
                                              href={item.official_website}
                                              target="_blank"
                                              class="text-white"
                                            >
                                              Website
                                            </a>
                                          </p>
                                          <p className="cursor">
                                            {" "}
                                            <i class="fa-solid fa-link"></i>{" "}
                                            <a
                                              href={item.rules}
                                              target="_blank"
                                              class="text-white"
                                            >
                                              Rules
                                            </a>
                                          </p>
                                        </div>
                                      </div>

                                      <div>
                                        <div className="d-flex justify-content-between gap16">
                                          <p>Total Rewards</p>
                                          <span>
                                            {" "}
                                            {item.totalReward} {item.symbol}
                                          </span>
                                        </div>

                                        <div className="d-flex justify-content-between gap16">
                                          <p>Farming period</p>
                                          <span>{formingdays} days</span>
                                        </div>

                                        <div className="d-flex justify-content-between gap16">
                                          <p>End date</p>
                                          <span>
                                            {" "}
                                            {enddate == null ||
                                            enddate == undefined ||
                                            enddate == ""
                                              ? "--"
                                              : enddate}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-lg-3 px-2">
                                    <div
                                      className="lanch-card"
                                      onClick={() => handleOpen(item)}
                                    >
                                      <h3>
                                        {" "}
                                        {/* <img
                                          src="https://res.cloudinary.com/taikonz-com/image/upload/v1664014268/b15qia164vomylxkmqfp.png"
                                          className="img"
                                          // src={require("../images/Bitcoin-Logo.png")}
                                        />{" "} */}
                                        {} POLL
                                      </h3>
                                      <h4>Stake USDT to earn {item.symbol}</h4>

                                      <div className="d-flex justify-content-center">
                                        <div className="lan_coin_chn">
                                          <img
                                            // src={require("../images/eth.png")}
                                            src={item.currency_image}
                                            alt="Ethereum logo"
                                            className="lanpol_img_main"
                                          />
                                          <img
                                            // src={require("../images/bnb.png")}
                                            src="https://res.cloudinary.com/taikonz-com/image/upload/v1664014268/b15qia164vomylxkmqfp.png"
                                            alt="BNB logo"
                                            className="lan_pos_img"
                                          />
                                        </div>
                                        {/* <img
                              src={require("../images/lanch-pool-token.png")}
                              // USDTsrc="https://res.cloudinary.com/taikonz-com/image/upload/v1664014268/b15qia164vomylxkmqfp.png"
                              // Symbolsrc={item.currency_image}
                              width="100px"
                              className="d-block mx-auto"
                            /> */}
                                      </div>

                                      <div className="d-flex justify-content-between gap32">
                                        <p>Prize pool ROI</p>
                                        <span>
                                          {item.ROI == null ||
                                          item.ROI == undefined ||
                                          item.ROI == ""
                                            ? "0"
                                            : item.ROI}
                                          %
                                        </span>
                                      </div>

                                      <div className="d-flex justify-content-between gap16">
                                        <p>Total Staked</p>
                                        <span>
                                          {item.totalStakeAmount == null ||
                                          item.totalStakeAmount == undefined ||
                                          item.totalStakeAmount == ""
                                            ? "0"
                                            : item.totalStakeAmount}
                                          USDT
                                        </span>
                                      </div>

                                      <div className="d-flex justify-content-between gap16">
                                        <p>Total rewards</p>
                                        <span>
                                          {item.totalReward} {item.symbol}
                                        </span>
                                      </div>

                                      <div className="d-flex justify-content-between gap16">
                                        <p>Individual staking limit</p>
                                        <span>{item.stakelimit} USDT</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        : " "}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </section>

            <section className="container-fluid px-5">
              <div className="iden_left_main mx-3 ">
                <span className="core_div_head">FAQ</span>
                <div className="FAQ-questions">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      1. What is Nexus Pro Launchpool ?
                    </AccordionSummary>
                    <AccordionDetails>
                      Nexus Pro Launchpool is a free platform for users to stake and
                      farm popular or newly listed tokens. Simply stake with
                      USDT or a designated token in the pool for free!
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      2. Who is eligible to participate in Launchpool ?
                    </AccordionSummary>
                    <AccordionDetails>
                      Only Nexus Pro users who have completed identity verification
                      and hold USDT are eligible to participate. Sign up on Nexus Pro
                      now! cannot participate.
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3-content"
                      id="panel3-header"
                    >
                      3. What do I need to do to participate in Launchpool ?
                    </AccordionSummary>
                    <AccordionDetails>
                      Nexus Pro Launchpool allows most USDT holders to participate.
                      We have prepared a beginner pool and a general pool for
                      you to choose based on your participation history in
                      Launchpool and the amount of USDT you hold. The two pools
                      have different staking thresholds. Check the rules of each
                      promotion for specific details.
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      4. What's the maximum amount of tokens I can stake ?
                    </AccordionSummary>
                    <AccordionDetails>
                      To protect the rights and interests of users, users
                      participating in the general pool can stake an amount
                      equal to the average USDT holdings for X days prior to the
                      promotion (excluding the start date of the promotion), and
                      the amount that users can stake cannot be higher than the
                      individual maximum staking amount for the current period.
                      Users participating in the beginner pool are not subject
                      to the X-day average USDT holdings, but the staking amount
                      cannot be higher than the individual maximum staking
                      amount for the current period. Refer to the promotion
                      rules for individual maximum staking amount.
                    </AccordionDetails>
                  </Accordion>
                  {/* <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            5. When do I receive the tokens I've farmed?
          </AccordionSummary>
          <AccordionDetails>
            Returns on your stake will be calculated in T+1 hours and
            rewards will be issued every hour. You can view your
            rewards at any time by navigating to Assets - Earn account
            - Transaction history - Launchpool
          </AccordionDetails>
        </Accordion> */}
                  {/* <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            5. How to calculate ROI?
          </AccordionSummary>
          <AccordionDetails>
            Your yields will be distributed every hour, and your ROI
            will also be updated every hour. You can head to
            Launchpool page and view the Estimated individual ROI.
            Note that the ROI for unlisted projects will not be
            displayed until trading is open. The prize pool ROI is
            different from estimated individual ROI. (Estimated
            individual ROI = token amount rewarded as of the current
            hour × token price ÷ (average number of USDT accruing
            interest per hour × current USDT price)
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            6. Are my staked assets locked?
          </AccordionSummary>
          <AccordionDetails>
            No. You can freely withdraw them whenever you like.
          </AccordionDetails>
        </Accordion> */}
                </div>
              </div>
            </section>
          </div>
        </div>
      ) : (
        <section className="container ">
          <div className="purchase-card">
            <div className="bodys p-0">
              <div className="row px-3">
                <div></div>
                <div className="col-lg-12 mb-3">
                  <div className="text-white d-flex launchpadpadd">
                    <i
                      class="fa-solid text-white dash_hai_icon fa-arrow-left mt-2 mr-2 cursor"
                      onClick={handleClose}
                    ></i>
                    {/* <img
                      src={popupdata.currency_image}
                      className="imagesize"
                    ></img>{" "} */}
                    <p className="launchpadpadd">{popupdata.symbol}</p>
                  </div>
                  <div className="step-5 ">
                    <div className="profile_content_image">
                      <div className="select_duration dayss">
                        <ul class="nav nav-tabs"></ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 mtb  p-3">
                  {/* <div className="launhcpadpop"> */}
                  <span>Stake Amount</span>
                  {/* <h4 className="launhcpadpoph4">wqewqe</h4> */}
                  {/* </div> */}
                  <h4>
                    {" "}
                    <input
                      type="number"
                      className="stakeinput"
                      pattern="\S*"
                      name="stakeAmount"
                      value={stakeAmount}
                      onChange={stakechange}
                      onKeyDown={preventInvalidKeys}
                    />{" "}
                  </h4>

                  <div className="launhcpadpop">
                    <span>Farming period</span>
                    <h4 className="launhcpadpoph4">{days} days</h4>
                  </div>
                  <div className="launhcpadpop">
                    <span>Total rewards</span>
                    <h4 className="launhcpadpoph4">
                      {popupdata.totalReward == null ||
                      popupdata.totalReward == undefined ||
                      popupdata.totalReward == ""
                        ? "0"
                        : popupdata.totalReward}
                    </h4>
                  </div>
                  <div className="launhcpadpop">
                    <span>Total Stake</span>
                    <h4 className="launhcpadpoph4">
                      {popupdata.totalStakeAmount == null ||
                      popupdata.totalStakeAmount == undefined ||
                      popupdata.totalStakeAmount == ""
                        ? "0"
                        : popupdata.totalStakeAmount}
                    </h4>
                  </div>
                  <div className="launhcpadpop">
                    <span>Individual staking limit</span>
                    <h4 className="launhcpadpoph4">
                      {popupdata.stakelimit == null ||
                      popupdata.stakelimit == undefined ||
                      popupdata.stakelimit == ""
                        ? "0"
                        : popupdata.stakelimit}
                    </h4>
                  </div>

                  <div className="launhcpadpop">
                    <span>Prize pool ROI</span>
                    <h4 className="launhcpadpoph4">
                      {popupdata.ROI == null ||
                      popupdata.ROI == undefined ||
                      popupdata.ROI == ""
                        ? "0"
                        : popupdata.ROI}{" "}
                      %
                    </h4>
                  </div>
                </div>
                <div className="col-lg-6 p-3 rightsides">
                  <div className="text-white d-flex">
                    {/* <img
                      src="https://res.cloudinary.com/taikonz-com/image/upload/v1664014268/b15qia164vomylxkmqfp.png"
                      className="imagesize"
                    ></img>{" "} */}
                    <p className="launchpadpadd">{popupdata.stake_currency}</p>
                  </div>
                  <div className="d-flex justify-content-between gap16 mt-4 text-white">
                    <p>
                      {" "}
                      <i class="fa-regular fa-file-lines mr-2"></i>
                      <a
                        href={popupdata.whitepaper_link}
                        target="_blank"
                        class="text-white"
                      >
                        White paper{" "}
                      </a>
                    </p>
                    <p>
                      {" "}
                      <i class="fa-solid fa-globe mr-2"></i>{" "}
                      <a
                        href={popupdata.official_website}
                        target="_blank"
                        class="text-white"
                      >
                        Website{" "}
                      </a>
                    </p>
                    <p>
                      {" "}
                      <i class="fa-solid fa-ruler mr-2"></i>
                      <a
                        href={popupdata.terms}
                        target="_blank"
                        class="text-white"
                      >
                        Rules{" "}
                      </a>
                    </p>
                  </div>
                  <hr />
                  <div className="col-lg-12   p-3">
                    <div className="launhcpadpop">
                      <span>Total rewards:</span>
                      <h4 className="launhcpadpoph4">
                        {popupdata.totalReward == null ||
                        popupdata.totalReward == undefined ||
                        popupdata.totalReward == ""
                          ? "0"
                          : popupdata.totalReward}
                      </h4>
                    </div>
                    <div className="launhcpadpop">
                      <span>Today's pool rewards:</span>
                      <h4 className="launhcpadpoph4">
                        {/* {(popupdata.totalReward == null ||
                        popupdata.totalReward == undefined ||
                        popupdata.totalReward == ""
                          ? "0"
                          : popupdata.totalReward / days
                        ).toFixed(3)} */}
                        {popupdata.totalReward / days}
                      </h4>
                    </div>

                    <div className="launhcpadpop">
                      <span>Prize pool ROI</span>
                      <h4 className="launhcpadpoph4">
                        {" "}
                        {popupdata.ROI == null ||
                        popupdata.ROI == undefined ||
                        popupdata.ROI == ""
                          ? "0"
                          : popupdata.ROI}{" "}
                        %
                      </h4>
                    </div>
                    <div className="launhcpadpop">
                      <span>Individual staking limit</span>
                      <h4 className="launhcpadpoph4">
                        {" "}
                        {popupdata.stakelimit == null ||
                        popupdata.stakelimit == undefined ||
                        popupdata.stakelimit == ""
                          ? "0"
                          : popupdata.stakelimit}
                      </h4>
                    </div>
                  </div>
                  <h6>
                    Note: Your staked amount will be transferred from your spot
                    account to the pool during the staking period.
                  </h6>
                  {authToken == true ? (
                    buttonStatus === true ? (
                      <button className="disablecursor"> Stake {popupdata.stake_currency} </button>
                    ) : (
                      <button onClick={purchaseUsdt}> Stake {popupdata.stake_currency} </button>
                    )
                  ) : (
                    <button>
                      <a href="/login" class="text-dark">
                        Login
                      </a>{" "}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="purchase-card"> */}
      {/* <div className="heads">
              <img
              src=""
                // src={currentPack.currencyImage}
                width="40px"
              />
              <h4>Stake ETH</h4>
            </div> */}
      {/* <div className="bodys p-0">
              <div className="row px-3">
                <div className="col-lg-12 mb-3">
                  <div className="text-white d-flex launchpadpadd">
                    <img
                      src={popupdata.currency_image} */}
      {/* // src="https://res.cloudinary.com/taikonz-com/image/upload/v1664014268/b15qia164vomylxkmqfp.png"
                      className="imagesize"
                    ></img>{" "}
                    <p className="launchpadpadd">{popupdata.symbol}</p>
                  </div>
                  <div className="step-5 ">
                    <div className="profile_content_image">
                      <div className="select_duration dayss">
                        <ul class="nav nav-tabs"></ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 mtb  p-3"> */}
      {/* <div className="launhcpadpop"> */}
      {/* <span>Stake Amount</span> */}
      {/* <h4 className="launhcpadpoph4">wqewqe</h4> */}
      {/* </div> */}
      {/* <h4>
                    {" "}
                    <input
                      type="number"
                      className="stakeinput"
                      name="stakeAmount"
                      value={stakeAmount}
                      onChange={stakechange}
                    />{" "}
                  </h4>

                  <div className="launhcpadpop">
                    <span>Farming period</span>
                    <h4 className="launhcpadpoph4">{days} days</h4>
                  </div>
                  <div className="launhcpadpop">
                    <span>Total rewards</span>
                    <h4 className="launhcpadpoph4">
                      {popupdata.totalReward == null ||
                      popupdata.totalReward == undefined ||
                      popupdata.totalReward == ""
                        ? "0"
                        : popupdata.totalReward}
                    </h4>
                  </div>
                  <div className="launhcpadpop">
                    <span>Total Stake</span>
                    <h4 className="launhcpadpoph4">
                      {popupdata.totalStakeAmount == null ||
                      popupdata.totalStakeAmount == undefined ||
                      popupdata.totalStakeAmount == ""
                        ? "0"
                        : popupdata.totalStakeAmount}
                    </h4>
                  </div>
                  <div className="launhcpadpop">
                    <span>Individual staking limit</span>
                    <h4 className="launhcpadpoph4">
                      {popupdata.stakelimit == null ||
                      popupdata.stakelimit == undefined ||
                      popupdata.stakelimit == ""
                        ? "0"
                        : popupdata.stakelimit}
                    </h4>
                  </div>

                  <div className="launhcpadpop">
                    <span>Prize pool ROI</span>
                    <h4 className="launhcpadpoph4">
                      {popupdata.ROI == null ||
                      popupdata.ROI == undefined ||
                      popupdata.ROI == ""
                        ? "0"
                        : popupdata.ROI}{" "}
                      %
                    </h4>
                  </div>
                </div>
                <div className="col-lg-6 p-3 rightsides">
                  <div className="text-white d-flex">
                    <img
                      src="https://res.cloudinary.com/taikonz-com/image/upload/v1664014268/b15qia164vomylxkmqfp.png"
                      className="imagesize"
                    ></img>{" "}
                    <p className="launchpadpadd">USDT</p>
                  </div>
                  <div className="d-flex justify-content-between gap16 mt-4 text-white">
                    <p>
                      {" "}
                      <i class="fa-regular fa-file-lines mr-2"></i>
                      <a
                        href={popupdata.whitepaper_link}
                        target="_blank"
                        class="text-white"
                      >
                        White paper{" "}
                      </a>
                    </p>
                    <p>
                      {" "}
                      <i class="fa-solid fa-globe mr-2"></i>{" "}
                      <a
                        href={popupdata.official_website}
                        target="_blank"
                        class="text-white"
                      >
                        Website{" "}
                      </a>
                    </p>
                    <p>
                      {" "}
                      <i class="fa-solid fa-ruler mr-2"></i>
                      <a
                        href={popupdata.terms}
                        target="_blank"
                        class="text-white"
                      >
                        Rules{" "}
                      </a>
                    </p>
                  </div>
                  <hr />
                  <div className="col-lg-12   p-3">
                    <div className="launhcpadpop">
                      <span>Total rewards:</span>
                      <h4 className="launhcpadpoph4">
                        {popupdata.totalReward == null ||
                        popupdata.totalReward == undefined ||
                        popupdata.totalReward == ""
                          ? "0"
                          : popupdata.totalReward}
                      </h4>
                    </div>
                    <div className="launhcpadpop">
                      <span>Today's pool rewards:</span>
                      <h4 className="launhcpadpoph4"> */}
      {/* {(popupdata.totalReward == null ||
                        popupdata.totalReward == undefined ||
                        popupdata.totalReward == ""
                          ? "0"
                          : popupdata.totalReward / days
                        ).toFixed(3)} */}
      {/* {popupdata.totalReward / days}
                      </h4>
                    </div>

                    <div className="launhcpadpop">
                      <span>Prize pool ROI</span>
                      <h4 className="launhcpadpoph4">
                        {" "}
                        {popupdata.ROI == null ||
                        popupdata.ROI == undefined ||
                        popupdata.ROI == ""
                          ? "0"
                          : popupdata.ROI}{" "}
                        %
                      </h4>
                    </div>
                    <div className="launhcpadpop">
                      <span>Individual staking limit</span>
                      <h4 className="launhcpadpoph4">
                        {" "}
                        {popupdata.stakelimit == null ||
                        popupdata.stakelimit == undefined ||
                        popupdata.stakelimit == ""
                          ? "0"
                          : popupdata.stakelimit}
                      </h4>
                    </div>
                  </div>
                  <h6>
                    Note: Your staked amount will be transferred from your spot
                    account to the pool during the staking period.
                  </h6>
                  {authToken == true ? (
                    buttonStatus === true ? (
                      <button className="disablecursor"> Purchase USDT </button>
                    ) : (
                      <button onClick={purchaseUsdt}> Purchase USDT </button>
                    )
                  ) : (
                    <button >
                      <a href="/login" class="text-dark">Login</a>{" "}
                    </button>
                  )} 
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal> */}
    </div>
  );
}

export default Dashboard;
