import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landing from "./pages/Landing";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Kyc from "./pages/Kyc";
import Deposit from "./pages/Deposit";
import "semantic-ui-css/semantic.min.css";
import Forgot from "./pages/Forgot";
import OTP_verify from "./pages/OTP_verify";
import Change_password from "./pages/Change_password";
import Create_TFA from "./pages/Create_TFA";
import Trade from "./pages/Trade";
import Withdraw from "./pages/Withdraw";
import Loginhistory from "./pages/Loginhistory";
import Tfahistory from "./pages/Tfahistory";
import Deposithistory from "./pages/Deposithistory";
import Stakehistory from "./pages/staking-history";

import About from "./pages/about";
import Privacy from "./pages/privacy";
import Terms from "./pages/Terms";

import Withdrawhistory from "./pages/Withdrawhistory";
import Tradehistory from "./pages/Tradehistory";
import Openorderhistory from "./pages/Openorder-history";
import Stoporderhistory from "./pages/Stoporder-history";
import Cancelorderhistory from "./pages/Cancelorder-history";
import LaunchpadHistory from "./pages/launchpadHistory";
import Profile from "./pages/Profile";
import Lanchpool from "./pages/Lanchpool";
import Lanchpad from "./pages/Lanchpad";
import TFA_Verify from "./pages/TFA_Verify";
import Anti_phising from "./pages/Anti-phising";
import Security from "./pages/Security";
import Staking from "./pages/Staking";
import Refferal from "./pages/Refferal";
import Swap from "./pages/Swap";
import Support from "./pages/Support";

import Market from "./pages/Market";
import Lanchpad_detail from "./pages/Lanchpad-detail";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { removeAuthToken } from "../src/core/lib/localStorage";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.css";

function App() {
  function RequireAuth({ children }) {
    var data = localStorage.getItem("user_token");
    return data ? children : removeAuthToken();
  }

  return (
    <>
      <BrowserRouter>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route
            path="/profile"
            element={
              <RequireAuth>
                {" "}
                <Profile />{" "}
              </RequireAuth>
            }
          />

          <Route
            path="/referral"
            element={
              <RequireAuth>
                {" "}
                <Refferal />{" "}
              </RequireAuth>
            }
          />

          <Route
            path="/history/user-activity"
            element={
              <RequireAuth>
                {" "}
                <Loginhistory />{" "}
              </RequireAuth>
            }
          />
          <Route
            path="/history/stake-history"
            element={
              <RequireAuth>
                {" "}
                <Stakehistory />{" "}
              </RequireAuth>
            }
          />
          <Route
            path="/security/user-activity"
            element={
              <RequireAuth>
                {" "}
                <Loginhistory />{" "}
              </RequireAuth>
            }
          />

          <Route
            path="/history/tfa-history"
            element={
              <RequireAuth>
                {" "}
                <Tfahistory />{" "}
              </RequireAuth>
            }
          />
          <Route
            path="/history/deposit-history"
            element={
              <RequireAuth>
                {" "}
                <Deposithistory />{" "}
              </RequireAuth>
            }
          />
          <Route
            path="/history/withdraw-history"
            element={
              <RequireAuth>
                {" "}
                <Withdrawhistory />
              </RequireAuth>
            }
          />
          <Route
            path="/history/trade-history"
            element={
              <RequireAuth>
                {" "}
                <Tradehistory />{" "}
              </RequireAuth>
            }
          />
          <Route
            path="/history/Openorder-history"
            element={
              <RequireAuth>
                {" "}
                <Openorderhistory />{" "}
              </RequireAuth>
            }
          />
          <Route
            path="/history/Stoporder-history"
            element={
              <RequireAuth>
                {" "}
                <Stoporderhistory />{" "}
              </RequireAuth>
            }
          />
          <Route
            path="/history/cancelorder-history"
            element={
              <RequireAuth>
                {" "}
                <Cancelorderhistory />{" "}
              </RequireAuth>
            }
          />
          <Route
            path="/history/launchpad-history"
            element={
              <RequireAuth>
                {" "}
                <LaunchpadHistory />{" "}
              </RequireAuth>
            }
          />

          <Route path="/signup" element={<Register />} />
          <Route path="/lanchpad/detail/:id" element={<Lanchpad_detail />} />

          <Route path="/lanchpool" element={<Lanchpool />} />
          <Route path="/lanchpad" element={<Lanchpad />} />
          <Route path="/staking" element={<Staking />} />
          <Route path="/market" element={<Market />} />

          <Route path="/about" element={<About />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />

          <Route
            path="/swap"
            element={
              <RequireAuth>
                <Swap />
              </RequireAuth>
            }
          />

          <Route path="/login" element={<Login />} />
          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                {" "}
                <Dashboard />{" "}
              </RequireAuth>
            }
          />
          <Route path="/kyc" element={<Kyc />} />
          <Route
            path="/deposit"
            element={
              <RequireAuth>
                {" "}
                <Deposit />{" "}
              </RequireAuth>
            }
          />
          <Route
            path="/withdraw"
            element={
              <RequireAuth>
                {" "}
                <Withdraw />{" "}
              </RequireAuth>
            }
          />
          <Route path="/signup" element={<Register />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/verify-email" element={<OTP_verify />} />
          <Route path="/verify-2fa" element={<TFA_Verify />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/security"
            element={
              <RequireAuth>
                <Security />
              </RequireAuth>
            }
          />
          <Route
            path="/support"
            element={
              <RequireAuth>
                <Support />
              </RequireAuth>
            }
          />
          <Route path="/trade/:id" element={<Trade />} />
          <Route
            path="/security/change-password"
            element={<Change_password />}
          />
          <Route
            path="/security/create-tfa"
            element={
              <RequireAuth>
                {" "}
                <Create_TFA />{" "}
              </RequireAuth>
            }
          />
          <Route
            path="/security/anti-phising"
            element={
              <RequireAuth>
                {" "}
                <Anti_phising />{" "}
              </RequireAuth>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
