import { useEffect } from "react";
import useState from "react-usestateref";
import { Link, useNavigate } from "react-router-dom";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import axios from "axios";
import Header from "./Header";
import Slider from "react-slick";
import Footer from "./LandingFooter";
import { toast } from "react-toastify";

function Landing() {
    var settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };





    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        // Function to check if the device is a mobile device
        const detectMobile = () => {
            const mobileCheck = window.matchMedia("(max-width: 768px)").matches;
            setIsMobile(mobileCheck);
        };
        home_currency();
        api();
        api2();
        api3();
        api4();
        // Initial check on component mount
        detectMobile();

        // Event listener for window resize
        const handleResize = () => {
            detectMobile();
        };
        window.addEventListener("resize", handleResize);

        // Cleanup function
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    const navigate = useNavigate();

    const [currencylistData, setcurrencylistData] = useState([]);
    const [currencydesendingdata, setcurrencydesendingdata] = useState([]);
    const [gainers, setgainers] = useState([]);
    const [losers, setlosers] = useState([]);
    const [data2, setdata2] = useState([]);
    const [loader, setloader, loaderref] = useState(false);

    const [loser, setloser, loserref] = useState("hot");

    const [email, setEmail] = useState('');

    const handleGetStarted = () => {
        // if (email) {
          navigate(`/login`);
        // } else {
        //   toast.error("Please enter your email address.")
        // }
      };


    const home_currency = async () => {
        setloser("hot");
        try {
            var data = {
                apiUrl: apiService.home_currency,
            };
            setloader(true);
            console.log(loaderref.current, "loader");
            var resp = await getMethod(data);

            setcurrencylistData(resp.Message);

            setdata2(resp.Message);
            setloader(false);
            console.log(loaderref.current, "loader1");
        } catch (err) { }
    };

    const [top_gainers, set_top_gainers] = useState([]);
    const [top_losers, set_top_losers] = useState([]);
    const [top_volume, set_top_volume] = useState([]);
    const [top_new_list, set_top_new_list] = useState([]);

    var topgainers = async () => {
        setloser("topgain");

        let highArray = [];
        data2.forEach((item) => {
            let priceChange = parseFloat(Number(item.coin_change));
            console.log(priceChange, "priceChange");
            if (priceChange > 0) {
                var data = {
                    currencyName: item.currencyName,
                    currencySymbol: item.currencySymbol,
                    estimatedValueInUSDT: item.estimatedValueInUSDT,
                    coin_change: item.coin_change,
                    coin_volume: item.coin_volume,
                    Currency_image: item.Currency_image,
                };
                highArray.push(data);
                setgainers(highArray);
                console.log(highArray, "highArray");
            } else {
                console.log("===");
            }
        });
    };

    const toplosers = async () => {
        setloser("toplose");

        let lowArray = [];
        console.log("-----");
        data2.forEach((item) => {
            let priceChange = parseFloat(Number(item.coin_change));
            console.log(priceChange, "priceChange");
            if (priceChange < 0) {
                var data = {
                    currencyName: item.currencyName,
                    currencySymbol: item.currencySymbol,
                    estimatedValueInUSDT: item.estimatedValueInUSDT,
                    coin_change: item.coin_change,
                    coin_volume: item.coin_volume,
                    Currency_image: item.Currency_image,
                };
                lowArray.push(data);
                setlosers(lowArray);
            } else {
                console.log("---");
            }
        });
    };
    var descending_order = async () => {
        setloser("new");

        var data = data2.reverse();
        setcurrencydesendingdata(data);
    };

    const redirect_url = async (url) => {
        console.log("redirect url===", url);
        navigate(url);
    };
    var api = async () => {
        var data = {
            apiUrl: apiService.topgainers,
        };
        var resp = await getMethod(data);
        console.log(resp, "res");
        set_top_gainers(resp.topgainers);
        // var data = {
        //     apiUrl: apiService.test,
        // };
        // var resp = await getMethod(data);
    };
    var api2 = async () => {
        var data = {
            apiUrl: apiService.toplosers,
        };
        var resp = await getMethod(data);
        set_top_losers(resp.toplosers);
    };
    var api3 = async () => {
        var data = {
            apiUrl: apiService.hotPairs,
        };
        var resp = await getMethod(data);
        set_top_volume(resp.topgainers);
    };
    var api4 = async () => {
        var data = {
            apiUrl: apiService.newPairs,
        };
        var resp = await getMethod(data);
        set_top_new_list(resp.hotpairs);
    };

    // const apiKey = "0d007089-ba49-4934-8fef-2abdaee0d4d2";
    // const url =
    //     "https://pro-api.coinmarketcap.com/v1/cryptocurrency/trending/gainers-losers";

    // axios
    //     .get(url, {
    //         headers: {
    //             "X-CMC_PRO_API_KEY": apiKey,
    //         },
    //     })
    //     .then((response) => {
    //         console.log(response.data, "--0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-");
    //     })
    //     .catch((error) => {
    //         console.error("Error:", error);
    //     });

    return (
        <div>
            <Header />
            <section className="hero_section">
                <div className="container">
                    <div className="row hero_main">
                        <div className="col-lg-6 hero_left">
                            <div className="hero_left_top">
                                <span className="hero_left_head">
                                    Crypto Trading, Made{" "}
                                    <span className="hero_left_headsecond">Simple Exchange</span>
                                </span>
                                <span className="hero_left_subhead">
                                    Crypto trading made easy and secure. Trade confidently.
                                </span>
                            </div>
                            <div className="hero_left_bottom">
                                {/* <input
                                 className="hero_input" 
                                 placeholder="Enter your email" 
                                 value={email}
                                  onChange={(e) => setEmail(e.target.value)} /> */}
                                <div className="hero_get" onClick={handleGetStarted}>
                                    <span className="hero_getstarted">Get Started</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 hero_right">
                            <img
                                src={require("../images/Landing/hero-right.png")}
                                alt="Trading spot"
                                className="hero_right_img"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className="slider_section">
                <div className="container">
                    <div className="slider-container">
                        <Slider {...settings}>
                            <div className="slider_card">
                                <div className="slider_inner_left">
                                    <span className="slider_left_innertop">Pizza Day Ruffle</span>
                                    <span className="slider_left_innermid">
                                        Win exclusive rugs,BgB, PEPE, NOT,and up to{" "}
                                        <span className="slider_left_innerin">0.1 BTC</span>
                                    </span>
                                    <span className="slider_left_innerbot">
                                        Trade just 1USDT to enter
                                    </span>
                                </div>
                                <div className="slider_inner_right">
                                    <img
                                        src={require("../images/Landing/slider/slider-1.png")}
                                        alt="pizza day"
                                        className="slider_1"
                                    />
                                </div>
                            </div>
                            <div className="slider_card">
                                <div className="slider_inner_left">
                                    <span className="slider_left_innermid">
                                        Unified{" "}
                                        <span className="slider_scndleft_innerin"> Trading </span>{" "}
                                        Account
                                    </span>
                                    <span className="slider_left_innerbot">
                                        Trade Spot, Derivative and Options from one account
                                    </span>
                                </div>
                                <div className="slider_inner_right">
                                    <img
                                        src={require("../images/Landing/slider/slider-2.png")}
                                        alt="Unified Trading"
                                        className="slider_2"
                                    />
                                </div>
                            </div>
                            <div className="slider_card">
                                <div className="slider_inner_left">
                                    <span className="slider_thrdleft_innertop">Reward Hub</span>
                                    <span className="slider_thrsleft_innerbot">
                                        Complete simple task and earn loads of rewards
                                    </span>
                                </div>
                                <div className="slider_inner_right">
                                    <img
                                        src={require("../images/Landing/slider/slider-3.png")}
                                        alt="Reward hub"
                                        className="slider_3"
                                    />
                                </div>
                            </div>
                            <div className="slider_card_fourth">
                                <div className="slider_card_infourth">
                                    <div className="slider_inner_left">
                                        <span className="slider_fourthleft_innertop">
                                            Complete KYC verification for the first time now
                                        </span>
                                    </div>
                                    <div className="slider_inner_right">
                                        <img
                                            src={require("../images/Landing/slider/slider-4.png")}
                                            alt="Complete KYC"
                                            className="slider_4"
                                        />
                                    </div>
                                </div>
                                <span className="slider_fourthleft_innerbot">
                                    Earn up to
                                    <span className="slider_fourthleftin_innerbot">
                                        {" "}
                                        1,000 USDT{" "}
                                    </span>
                                    Trial Fund
                                </span>
                            </div>

                            <div className="slider_card">
                                <div className="slider_inner_left">
                                    <span className="slider_fifth_innertop">WELCOME GIFTS</span>
                                    <span className="slider_thrsleft_innerbot">
                                        Complete Your Taska
                                    </span>
                                    <div className="slider_fifth_div">
                                        <span className="slider_fif_innerbot">
                                            $10,000 <span className="slider_fif_inin">Rewards</span>
                                        </span>
                                    </div>
                                </div>
                                <div className="slider_inner_right">
                                    <img
                                        src={require("../images/Landing/slider/slider-5.png")}
                                        alt="pizza day"
                                        className="slider_5"
                                    />
                                </div>
                            </div>
                            <div className="slider_card">
                                <div className="slider_inner_left">
                                    <span className="slider_six_let">
                                        Equal <span className="six_color">Opportunity</span> Up To{" "}
                                        <span className="six_color"> 100 USDT</span>
                                    </span>
                                    <span className="slider_left_innerbot">No Trading Limit</span>
                                </div>
                                <div className="slider_inner_right">
                                    <img
                                        src={require("../images/Landing/slider/slider-6.png")}
                                        alt="Reward hub"
                                        className="slider_6"
                                    />
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </section>
            <section className="popular_section">
                <div className="container">
                    <div className="pop_main">
                        <div className="pop_top_main">
                            <span className="pop_head_head">Popular Cryptocurrencies</span>
                            <span className="pop_head_subhead">
                                {" "}
                                Discover top cryptos. Trade smarter.
                            </span>
                        </div>
                        <div className="pop_bot_main">
                            <div className="pop_bot_top">
                                <ul class="nav nav-tabs mt-2">
                                    <li class="active">
                                        <a data-toggle="tab" href="#home" className="active">
                                            <button className="pop_btn">
                                                <div className="pop_bot_topleft">
                                                    <span className="pop_bot_left1">Top Futures</span>
                                                    <div className="pop_bot_left2">
                                                        <span className="pop_bot_leftin">
                                                            {" "}
                                                            Maker 0.00% / Taker 0.01%{" "}
                                                        </span>
                                                    </div>
                                                </div>
                                            </button>
                                        </a>
                                    </li>
                                    <li>
                                        <a data-toggle="tab" href="#hot" onClick={home_currency}>
                                            <button className="pop_btn">
                                                <span className={loserref.current == "hot" ? "pop_bot_mid" :  "pop_bot_left1" }>Hot</span>
                                            </button>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            data-toggle="tab"
                                            href="#newest"
                                            onClick={descending_order}
                                        >
                                            <button className="pop_btn">
                                                <span className={loserref.current == "new" ? "pop_bot_mid" :  "pop_bot_left1" }>Newest</span>
                                            </button>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            data-toggle="tab"
                                            href="#topgainers"
                                            onClick={topgainers}
                                        >
                                            <button className="pop_btn">
                                                <span className={loserref.current == "topgain" ? "pop_bot_mid" :  "pop_bot_left1" }>Top Gainers</span>
                                            </button>
                                        </a>
                                    </li>
                                    <li>
                                        <a data-toggle="tab" href="#toplosers" onClick={toplosers}>
                                            <button className="pop_btn">
                                                <span className={loserref.current == "toplose" ? "pop_bot_mid" :  "pop_bot_left1" }>Top Losers</span>

                                            </button>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="tab-content">
                                <div id="hot" class="tab-pane fade in active show">
                                    <div className="pop_bot_bot">
                                        <div className="table-responsive">
                                            <div className="border-tab">
                                            <table className="popular_table">
                                                <thead className="pop-head">
                                                    <th>Name</th>
                                                    <th>Last Price</th>
                                                    <th>24h Change</th>
                                                    <th>Volume</th>
                                                    <th>Action</th>
                                                </thead>
                                                <tbody>
                                                    {top_volume && top_volume.length > 0 ? (
                                                        top_volume.map((obj, i) => {
                                                            return (
                                                                <tr>
                                                                    <td>
                                                                        <div className="pop_td_frst">
                                                                            <img
                                                                                src={obj.imageUrl}
                                                                                alt="BTC"
                                                                                className="pop_in_img"
                                                                            />
                                                                            <span className="pop_in_frst">
                                                                                {" "}
                                                                                {obj.name}
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <span className="pop_in_scnd">
                                                                            ${obj.price}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        {obj.change24Hour <= 0 ? (
                                                                            <span className="pop_in_thrdplus text-danger">
                                                                                {obj.change24Hour}%
                                                                            </span>
                                                                        ) : (
                                                                            <span className="pop_in_thrdplus">
                                                                                +{obj.change24Hour}%
                                                                            </span>
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        <span className="pop_in_scnd">
                                                                            {obj.volume}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <div className="pop_in_last">
                                                                            <span
                                                                                className="pop_in_lastspan"
                                                                                onClick={() =>
                                                                                    redirect_url("/trade/BTC_USDT")
                                                                                }
                                                                            >
                                                                                Trade
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    ) : (
                                                        <tr>
                                                            <td colSpan={5}>
                                                                <div className="empty_data">
                                                                    <div className="empty_data_img">
                                                                        {/* <img
                                              src={require("../../Image/Table_image/no-record-light.png")}
                                            /> */}
                                                                    </div>

                                                                    <div className="no_records_text">
                                                                        No Records found
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                            </div>
                                           
                                        </div>
                                    </div>
                                </div>

                                <div id="newest" class="tab-pane">
                                    <div className="pop_bot_bot">
                                        <div className="table-responsive">
                                        <div className="border-tab">
                                        <table className="popular_table">
                                                <thead className="pop-head">
                                                    <th>Name</th>
                                                    <th>Last Price</th>
                                                    <th>24h Change</th>
                                                    <th>Volume</th>
                                                    <th>Action</th>
                                                </thead>
                                                <tbody>
                                                    {top_new_list && top_new_list.length > 0 ? (
                                                        top_new_list.map((obj, i) => {
                                                            return (
                                                                <tr>
                                                                    <td>
                                                                        <div className="pop_td_frst">
                                                                            <img
                                                                                src={obj.imageUrl}
                                                                                alt="BTC"
                                                                                className="pop_in_img"
                                                                            />
                                                                            <span className="pop_in_frst">
                                                                                {" "}
                                                                                {obj.fullName}
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <span className="pop_in_scnd">
                                                                            ${obj.price}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        {obj.change24Hour <= 0 ? (
                                                                            <span className="pop_in_thrdplus text-danger">
                                                                                {obj.change24Hour}%
                                                                            </span>
                                                                        ) : (
                                                                            <span className="pop_in_thrdplus">
                                                                                +{obj.change24Hour}%
                                                                            </span>
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        <span className="pop_in_scnd">
                                                                            {obj.volume24Hour}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <div className="pop_in_last">
                                                                            <span
                                                                                className="pop_in_lastspan"
                                                                                onClick={() =>
                                                                                    redirect_url("/trade/BTC_USDT")
                                                                                }
                                                                            >
                                                                                Trade
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    ) : (
                                                        <tr>
                                                            <td colSpan={5}>
                                                                <div className="empty_data">
                                                                    <div className="empty_data_img">
                                                                        {/* <img
                                              src={require("../../Image/Table_image/no-record-light.png")}
                                            /> */}
                                                                    </div>

                                                                    <div className="no_records_text">
                                                                        No Records found
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                          
                                        </div>
                                    </div>
                                </div>

                                <div id="topgainers" class="tab-pane">
                                    <div className="pop_bot_bot">
                                        <div className="table-responsive">
                                        <div className="border-tab">
                                        <table className="popular_table">
                                                <thead className="pop-head">
                                                    <th>Name</th>
                                                    <th>Price</th>
                                                    <th>24h Change</th>
                                                </thead>
                                                <tbody>
                                                    {top_gainers && top_gainers.length > 0 ? (
                                                        top_gainers.map((obj, i) => {
                                                            return (
                                                                <tr>
                                                                    <td>
                                                                        <div className="pop_td_frst">
                                                                            <img
                                                                                src={obj.imageUrl}
                                                                                alt="BTC"
                                                                                className="pop_in_img"
                                                                            />
                                                                            <span className="pop_in_frst">
                                                                                {" "}
                                                                                {obj.name}
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <span className="pop_in_scnd">
                                                                            ${obj.price}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        {obj.change24Hour <= 0 ? (
                                                                            <span className="pop_in_thrdplus text-danger">
                                                                                {obj.change24Hour}%
                                                                            </span>
                                                                        ) : (
                                                                            <span className="pop_in_thrdplus">
                                                                                +{obj.change24Hour}%
                                                                            </span>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    ) : (
                                                        <tr>
                                                            <td colSpan={3}>
                                                                <div className="empty_data">
                                                                    <div className="empty_data_img">
                                                                        {/* <img
                                              src={require("../../Image/Table_image/no-record-light.png")}
                                            /> */}
                                                                    </div>

                                                                    <div className="no_records_text">
                                                                        No Records found
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                           
                                        </div>
                                    </div>
                                </div>

                                <div id="toplosers" class="tab-pane">
                                    <div className="pop_bot_bot">
                                        <div className="table-responsive">
                                        <div className="border-tab">
                                        <table className="popular_table">
                                                <thead className="pop-head">
                                                    <th>Name</th>
                                                    <th>Price</th>
                                                    <th>24h Change</th>
                                                </thead>
                                                <tbody>
                                                    {top_losers && top_losers.length > 0 ? (
                                                        top_losers.map((obj, i) => {
                                                            return (
                                                                <tr>
                                                                    <td>
                                                                        <div className="pop_td_frst">
                                                                            <img
                                                                                src={obj.imageUrl}
                                                                                alt="BTC"
                                                                                className="pop_in_img"
                                                                            />
                                                                            <span className="pop_in_frst">
                                                                                {" "}
                                                                                {obj.name}
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <span className="pop_in_scnd">
                                                                            ${obj.price}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        {obj.change24Hour <= 0 ? (
                                                                            <span className="pop_in_thrdplus text-danger">
                                                                                {obj.change24Hour}%
                                                                            </span>
                                                                        ) : (
                                                                            <span className="pop_in_thrdplus">
                                                                                +{obj.change24Hour}%
                                                                            </span>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    ) : (
                                                        <tr>
                                                            <td colSpan={3}>
                                                                <div className="empty_data">
                                                                    <div className="empty_data_img">
                                                                        {/* <img
                                              src={require("../../Image/Table_image/no-record-light.png")}
                                            /> */}
                                                                    </div>

                                                                    <div className="no_records_text">
                                                                        No Records found
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="earn_section">
                <div className="container">
                    <div className="earn_main">
                        <div className="earn_top">
                            <span className="pop_head_head">
                                Earn up to <span className="hero_left_headsecond">300%APR</span>
                            </span>
                            <span className="pop_head_subhead">
                                {" "}
                                Earn from a selection of top cryptos
                            </span>
                        </div>
                        <div className="earn_bot">
                            <div className="earn_view_main">

                            <Link to="/staking">
                            <div className="earn_view" >
                                    <span className="earn_view_let"> View More</span>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="earn_svg"
                                        viewBox="0 0 24 24"
                                        id="RightArrow"
                                    >
                                        <path
                                            d="M22.707,12.707a1,1,0,0,0,0-1.414l-6-6a1,1,0,0,0-1.414,1.414L19.586,11H2a1,1,0,0,0,0,2H19.586l-4.293,4.293a1,1,0,0,0,1.414,1.414Z"
                                            fill="#12d5d5"
                                            class="color000000 svgShape"
                                        ></path>
                                    </svg>
                                </div>
                            </Link>
                               
                            </div>
                            <div className="">
                                <div className="row">
                                    <div className="col-lg-3 mt-2">
                                        <div className="earn_divs_frst">
                                            <div className="earn_divs_frstin">
                                                <div className="earn_divs_frsttop">
                                                    <div className="earn_divs_frsttop">
                                                        <img
                                                            src={require("../images/Landing/Tether-logo.png")}
                                                            alt="Tether"
                                                            className="earn_tether"
                                                        />
                                                        <span className="earn_divs_inhead">USDT</span>
                                                    </div>
                                                    <span className="earn_divs_insubhead">
                                                        Savings | Flexible
                                                    </span>
                                                </div>
                                                <span className="earn_divs_frstbot">
                                                    00.00%{" "}
                                                    <span className="earn_divs_frstbotscnd">APR</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 mt-2">
                                        <div className="earn_divs_frst">
                                            <div className="earn_divs_frstin">
                                                <div className="earn_divs_frsttop">
                                                    <div className="earn_divs_frsttop">
                                                        <img
                                                            src={require("../images/Landing/Tether-logo.png")}
                                                            alt="Tether"
                                                            className="earn_tether"
                                                        />
                                                        <span className="earn_divs_inhead">USDT</span>
                                                    </div>
                                                    <span className="earn_divs_insubhead">
                                                        Savings | 7 days
                                                    </span>
                                                </div>
                                                <span className="earn_divs_frstbot">
                                                    00.00%{" "}
                                                    <span className="earn_divs_frstbotscnd">APR</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 mt-2">
                                        <div className="earn_divs_frst">
                                            <div className="earn_divs_frstin">
                                                <div className="earn_divs_frsttop">
                                                    <div className="earn_divs_frsttop">
                                                        <img
                                                            src={require("../images/Landing/FURY-logo.png")}
                                                            alt="Tether"
                                                            className="earn_tether"
                                                        />
                                                        <span className="earn_divs_inhead">FURY</span>
                                                    </div>
                                                    <span className="earn_divs_insubhead">
                                                        Savings | 7 days
                                                    </span>
                                                </div>
                                                <span className="earn_divs_frstbot">
                                                    00.00%{" "}
                                                    <span className="earn_divs_frstbotscnd">APR</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 mt-2">
                                        <div className="earn_divs_frst">
                                            <div className="earn_divs_frstin">
                                                <div className="earn_divs_frsttop">
                                                    <div className="earn_divs_frsttop">
                                                        <img
                                                            src={require("../images/Landing/pop-td-img.png")}
                                                            alt="Tether"
                                                            className="earn_tether"
                                                        />
                                                        <span className="earn_divs_inhead">BTC</span>
                                                    </div>
                                                    <span className="earn_divs_insubhead">
                                                        Savings | Flexible
                                                    </span>
                                                </div>
                                                <span className="earn_divs_frstbot">
                                                    00.00%{" "}
                                                    <span className="earn_divs_frstbotscnd">APR</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="core_section">
                <div className="container">
                    <div className="row core_main">
                        <div className="col-lg-5">
                            <div className="core_left_main">
                                <div className="earn_top">
                                    <span className="pop_head_head">
                                        Our Core{" "}
                                        <span className="hero_left_headsecond">Products</span>
                                    </span>
                                    <span className="pop_head_subhead">
                                        {" "}
                                        A wide variety of trading tools to choose from
                                    </span>
                                </div>
                                <div className="core_left_divs">
                                    <div className="core_left_divsfrst">
                                        <img
                                            src={require("../images/Landing/Core-frst.png")}
                                            alt="spot"
                                            className="core_divs_imgs"
                                        />
                                        <div className="earn_top">
                                            <span className="core_div_head">Spot</span>
                                            <span className="earn_divs_insubhead">
                                                Over 700 cryptos available for trading
                                            </span>
                                        </div>
                                    </div>
                                    <div className="core_left_divsfrst">
                                        <img
                                            src={require("../images/Landing/Core-scnd.png")}
                                            alt="Launchpad"
                                            className="core_divs_imgs"
                                        />
                                        <div className="earn_top">
                                            <span className="core_div_head">Launchpad</span>
                                            <span className="earn_divs_insubhead">
                                                Upgrade your experience by trading futures
                                            </span>
                                        </div>
                                    </div>
                                    <div className="core_left_divsfrst">
                                        <img
                                            src={require("../images/Landing/Core-thrd.png")}
                                            alt="Earn"
                                            className="core_divs_imgs"
                                        />
                                        <div className="earn_top">
                                            <span className="core_div_head">Earn</span>
                                            <span className="earn_divs_insubhead">
                                                Industry- leading APYs
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 hero_right">
                            <img
                                src={require("../images/Landing/Core-right.png")}
                                alt="Core products"
                                className="core_right_img"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className="core_section mb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 trade_left">
                            <img
                                src={require("../images/Landing/Trade-left.png")}
                                alt="Trade Anywhere"
                                className="trade_left_img"
                            />
                        </div>
                        <div className="col-lg-4 px-4">
                            <div className="trade_rghtmain">
                                <div className="trade_rghtmain">
                                    <span className="pop_head_head">
                                        <span className="hero_left_headsecond">Trade</span>{" "}
                                        Anywhere, Anytime
                                    </span>
                                    <div className="trade_qr_main">
                                        <img
                                            src={require("../images/Landing/Trade-qr.png")}
                                            alt="Trade QR"
                                            className="Trade_qr_img"
                                        />
                                        <div className="trd_left_top">
                                            <span className="trade_divs_inhead">
                                                {" "}
                                                Scan to download the app
                                            </span>
                                            <span className="core_div_head"> IOS & Android</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="trade_right_bot">
                                    <div className="trade_right_botfrst">
                                        <div className="earn_divs_frsttop">
                                            <img
                                                src={require("../images/Landing/trade-apple.png")}
                                                alt="Apple Store"
                                                className="trade_bot_apps"
                                            />
                                            <span className="pop_in_frst">App Store</span>
                                        </div>
                                        <div className="earn_divs_frsttop">
                                            <img
                                                src={require("../images/Landing/trade-google.png")}
                                                alt="Google Play"
                                                className="trade_bot_apps"
                                            />
                                            <span className="pop_in_frst"> Google Play</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default Landing;
