import React, { useEffect } from "react";
import useState from "react-usestateref";
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import { InfinitySpin } from "react-loader-spinner";
import { Dropdown } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import useStateRef from "react-usestateref";
import moment from "moment";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function Swap() {
  const navigate = useNavigate();
  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  const [siteLoader, setSiteLoader] = useState(false);
  const [fromTab, setFromTab] = useState([]);
  const [toTab, setToTab] = useState([]);
  const [fromCurrency, setfromCurrency, fromref] = useState("");
  const [toCurrency, settoCurrency, toref] = useState("USDT");
  const [appendFromData, setappendFromData] = useState("");
  const [appendToData, setappendFToData] = useState("");
  const [fromcurrencyImage, setFromcurrencyImage] = useState("");
  const [tocurrencyImage, setTocurrencyImage] = useState("");
  const [swapTab, setswapTab] = useState(false);
  const [fromAmount, setfromAmount] = useState(0);
  const [toAmount, settoAmount,toAmountref] = useStateRef(0);
  const [minMax, setMinMax] = useState(10);
  const [price, setPrice, priceRef] = useStateRef(0);
  const [estimateFee, setEstimationFee] = useState(0);
  const [totatlAmount, setTotalAmount] = useState(0);
  const [sessionHistory, setsessionHistory] = useState([]);
  const [totalPage, setTotalpages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [sitekycStatus, setsitekycStatus] = useState("DeActive");
  const [kycStatus, setkycStatus, kycstatusref] = useState(0);
  const [ButtonLoader, setButtonLoader] = useState(false);

  const [allCurrencyFiat, setfromCurrencyRef, fromCurrencyRef] = useState([]);
  const [toCurrencyRefs, setToCurrencyRef, toCurrencyRef] = useState([]);

  const [fromSwap, setfromSwapRef, fromSwapRef] = useState([]);
  const [toSwap, settoSwapRef, toSwapRef] = useState([]);

  const recordPerPage = 5;
  const pageRange = 5;

  useEffect(() => {
    getUserbalance();
    swaphistory();
    getKYCstatus();
    getSiteSettingstatus();
  }, [0]);

  const getUserbalance = async () => {
    var data1 = {
      apiUrl: apiService.getUserBalanceSwap,
    };
    var resp = await getMethod(data1);
    console.log(resp, "resp");
    if (resp.status) {
      setFromTab(resp.data);
      setToTab(resp.data);
      var currArrayCrypto = [];
      //var currArrayFiat = [{value: "all", label: "Select"}];
      var currArrayFiat = [];
      var data = resp.data;
      for (var i = 0; i < data.length; i++) {
        if (data[i].currencySymbol) {
          var obj = {
            value: data[i].currid,
            label: data[i].currencySymbol,
            key: data[i].currencySymbol,
            text: data[i].currencySymbol,
            image: {
              avatar: true,
              src: data[i].image,
            },
          };
          currArrayFiat.push(obj);
          currArrayCrypto.push(obj);
        }
      }
      setToCurrencyRef(currArrayFiat);
      setfromCurrencyRef(currArrayCrypto);
    } else {
    }
  };

  const setAmount = async (value, type) => {
    console.log("value", value, "type", type);
    console.log(appendFromData, "appendFromData");
    try {
      if (!isNaN(value)) {
        type == "fromAmount" ? setfromAmount(value) : settoAmount(value);
        var obj = {
          from: appendFromData.currencySymbol,
          to: appendToData.currencySymbol,
        };
        var data = {
          apiUrl: apiService.currencyConversion,
          payload: obj,
        };
        if (fromCurrency != "" && toCurrency != "") {
          var resp = await postMethod(data);
          if (resp.status) {
            var fee = (+value * +appendFromData.swapFee) / 100;
            console.log("fee===", fee);
            setEstimationFee(fee);
            var total = +value + +fee;
            console.log("total===", total);
            //setTotalAmount(parseFloat(total).toFixed(8));
            setTotalAmount(total);

            setPrice(resp.Message);
            console.log("price===", resp.Message);
            if (type == "fromAmount") {
              var amount = Number(resp.Message) * Number(value);
              console.log("amount===", amount);
              // setfromAmount(parseFloat(value).toFixed(8));
              // settoAmount(parseFloat(amount).toFixed(8));
              setfromAmount(value);
              settoAmount(amount);
            } else if (type == "toAmount") {
              var amount = Number(value) / Number(resp.Message);
              // setfromAmount(parseFloat(amount).toFixed(8));
              // settoAmount(parseFloat(value).toFixed(8));
              setfromAmount(amount);
              settoAmount(value);
            }
          }
        } else {
        }
      } else {
        type == "fromAmount" ? setfromAmount(0) : settoAmount(0);
      }
    } catch (error) {}
  };

  const swapAmount = async () => {
    console.log(appendFromData.currencySymbol, appendToData.currencySymbol);
    try {
      if (
        appendFromData.currencySymbol != undefined &&
        appendToData.currencySymbol != undefined
      ) {
        if (appendFromData.currencySymbol != appendToData.currencySymbol) {
          if (fromAmount > 0 && toAmount > 0) {
            var obj = {
              from: appendFromData.currencySymbol,
              to: appendToData.currencySymbol,
              from_id: appendFromData.currid,
              to_id: appendToData.currid,
              fromAmount: +fromAmount,
              toAmount: +toAmount,
              fee: +estimateFee,
              withFee: +totatlAmount,
              currentPrice: +priceRef.current,
            };

            console.log(obj, "obj");

            var data = {
              apiUrl: apiService.swapping,
              payload: obj,
            };
            setButtonLoader(true);
            var resp = await postMethod(data);
            setButtonLoader(false);
            if (resp.status) {
              setfromAmount(0);
              settoAmount(0);
              swaphistory(1);
              getUserbalance();
              showsuccessToast(resp.Message);
              navigate("/dashboard");
            } else {
              swaphistory(1);
              showerrorToast(resp.Message);
            }
          } else {
            showerrorToast("Please enter amount");
          }
        } else {
          showerrorToast("Same currency should not allow the swapping");
        }
      } else {
        showerrorToast("Please choose the swapping currencies");
      }
    } catch (error) {}
  };

  const swaphistory = async (page) => {
    try {
      var payload = {
        perpage: 5,
        page: page,
      };
      var data = {
        apiUrl: apiService.swappingHistory,
        payload: payload,
      };

      var resp = await postMethod(data);
      if (resp.status) {
        setsessionHistory(resp.data.data);
        setTotalpages(resp.data.total);
      }
    } catch (error) {}
  };

  const handlePageChange = (pageNumber) => {
    swaphistory(pageNumber);
    setCurrentPage(pageNumber);
  };

  const swapPrice = async () => {
    try {
      console.log(toref.current);
      console.log(fromref.current);
      var obj = {
        from: fromref.current != undefined ? fromref.current : "BTC",
        to:
          toref.current != undefined ||
          toref.current != null ||
          toref.current != ""
            ? toref.current
            : "USDT",
      };
      console.log("swap===", obj);
      var data = {
        apiUrl: apiService.currencyConversion,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        setPrice(resp.Message);
      }
    } catch (error) {}
  };

  const handleOnChange_from = (e, data) => {
    console.log("handleOnChange_from", data);
    setAmount();
    setfromSwapRef(data.value);
    var findIndexing = fromTab.findIndex((x) => x.currid == data.value);
    console.log("findIndexing===", findIndexing);
    if (findIndexing != -1) {
      console.log("fromTab[findIndexing]", fromTab[findIndexing]);
      setappendFromData(fromTab[findIndexing]);
      setFromcurrencyImage(fromTab[findIndexing].image);
      setfromCurrency(fromTab[findIndexing].currencySymbol);
      swapPrice();
    }
  };

  const handleOnChange_to = (e, data) => {
    setAmount();
    console.log("handleOnChange_to", data);
    settoSwapRef(data.value);
    var findIndexingTo = toTab.findIndex((x) => x.currid == data.value);
    console.log("findIndexingTo===", findIndexingTo);
    if (findIndexingTo != -1) {
      settoCurrency(fromTab[findIndexingTo].currencySymbol);
      setappendFToData(fromTab[findIndexingTo]);
      setTocurrencyImage(fromTab[findIndexingTo].image);
      swapPrice();
    }
  };

  const getKYCstatus = async () => {
    var data = {
      apiUrl: apiService.getKYCStatus,
    };
    var getKYC = await getMethod(data);
    console.log(getKYC, "getkyc");
    if (getKYC.status == true) {
      console.log(getKYC.Message.kycstatus, "getkyc");

      setkycStatus(getKYC.Message.kycstatus);
    } else {
      setkycStatus(0);
    }
  };

  const getSiteSettingstatus = async () => {
    var data = {
      apiUrl: apiService.getSiteDatas,
    };
    var response = await getMethod(data);
    console.log("getkyc1===", response);
    if (response.status) {
      console.log(response.data.kycStatus, "==kyc======");
      setsitekycStatus(response.data.kycStatus);
    } else {
      setkycStatus(0);
    }
  };

  const showsuccessToast = (message) => {
    toast.dismiss();
    toast.success(message);
  };
  
  const showerrorToast = (message) => {
    toast.dismiss();
    toast.error(message);
  };


  const preventInvalidKeys = (event) => {
    console.log("--")
    if (["e", "E", "+", "-"].includes(event.key)) {
      event.preventDefault();
    }
    if (event.key === " " || event.key === "Tab") {
      event.preventDefault();
    }
    if(event.target.value.length >9){
      event.preventDefault();
      }

  };
  return (
    <div>
      <Header />
      {siteLoader == true ? (
        <div className="dashboard_content_section loadercss">
          <InfinitySpin
            height="300"
            width="300"
            color="#12d5d5"
            ariaLabel="infinity-spin-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2 col-sm-3">
              <Sidebar />
            </div>
            <div className="col-lg-10 col-md-12">
              <div className="container dash_container">
                <span className="core_div_head">Swap</span>
                <div className="row mt-5 justify-content-center">
                  <div className="col-lg-6 my-3">
                    <div className="swap_lft_main">
                      <div className="swap_lft_top">
                        <div className="foot_frst">
                          <span className="bals_divs_insubhead">From</span>
                          <input
                            type="number"
                            min="0"
                            pattern="\S*"
                            // placeholder="0.00"
                            onKeyDown={preventInvalidKeys}
                            value={fromAmount}
                            onChange={(e) =>
                              setAmount(e.target.value, "fromAmount")
                            }
                            className="swap_in_val"
                          />
                        </div>
                        <div className="swap_chng_frst">
                          <span className="bals_divs_insubhead">
                            Balance:{" "}
                            {appendFromData == ""
                              ? "0.0000"
                              : Number(appendFromData.currencyBalance).toFixed(
                                  8
                                )}{" "}
                          </span>
                          <div className=" Swapcard swap_drop_all">
                            <Dropdown
                              placeholder="Select Coin"
                              fluid
                              selection
                              options={fromCurrencyRef.current}
                              // onChange={(o) =>
                              //   onSelect(o, "fromTab")
                              // }
                              onChange={handleOnChange_from}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="swap_mid">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="trade_bot_apps"
                          viewBox="0 0 24 24"
                          id="swapvert"
                        >
                          <path fill="none" d="M0 0h24v24H0V0z"></path>
                          <path
                            d="M16 17.01V11c0-.55-.45-1-1-1s-1 .45-1 1v6.01h-1.79c-.45 0-.67.54-.35.85l2.79 2.78c.2.19.51.19.71 0l2.79-2.78c.32-.31.09-.85-.35-.85H16zM8.65 3.35L5.86 6.14c-.32.31-.1.85.35.85H8V13c0 .55.45 1 1 1s1-.45 1-1V6.99h1.79c.45 0 .67-.54.35-.85L9.35 3.35c-.19-.19-.51-.19-.7 0z"
                            fill="#ffffff"
                            class="color000000 svgShape"
                          ></path>
                        </svg>
                      </div>
                      <div className="swap_lft_top">
                        <div className="foot_frst">
                          <span className="bals_divs_insubhead">To</span>
                          <input
                            type="number"
                            pattern="\S*"
                            onKeyDown={preventInvalidKeys}
                            value={toAmountref.current.toFixed(4)}
                            onChange={(e) =>
                              setAmount(e.target.value, "toAmount")
                            }
                            className="swap_in_val"
                          />
                        </div>
                        <div className="swap_chng_second">
                          <div></div>
                          <div className=" Swapcard swap_drop_all">
                            <Dropdown
                              placeholder="Select Coin"
                              fluid
                              selection
                              options={toCurrencyRef.current}
                              // onChange={(o) =>
                              //   onSelect(o, "fromTab")
                              // }
                              onChange={handleOnChange_to}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="swap_end">
                        {ButtonLoader == false ? (
                          <div
                            className="swap_submit py-3 px-5"
                            onClick={swapAmount}
                          >
                            <span className="land-sign-letter">Swap</span>
                          </div>
                        ) : (
                          <div className="swap_submit py-3 px-5">
                            <span className="land-sign-letter">Loading </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 my-3 justify-content-center">
                    <span className="bal_divs_inhead"> Summary</span>
                    <hr />
                    <div className="swap_fst_rght">
                      <div className="swap_inner_main">
                        <span className="slider_thrsleft_innerbot">Price</span>
                        {fromref.current != "" && toref.current != "" ? (
                          <span className="slider_thrsleft_innerbot">
                            1 {fromref.current} ={" "}
                            {Number(priceRef.current).toFixed(8) == 0 ? (
                              <span className="slider_thrsleft_innerbot">
                                0
                              </span>
                            ) : (
                              Number(priceRef.current).toFixed(8)
                            )}{" "}
                            {toref.current}{" "}
                          </span>
                        ) : (
                          <span className="slider_thrsleft_innerbot">
                            0.0000
                          </span>
                        )}
                      </div>
                      <div className="swap_inner_main">
                        <span className="slider_thrsleft_innerbot">
                          Minimum Swap
                        </span>
                        <span className="slider_thrsleft_innerbot">
                          {appendFromData == "" ? (
                            <span className="slider_thrsleft_innerbot">
                              0.0000
                            </span>
                          ) : (
                            appendFromData.minSwap
                          )}
                        </span>
                      </div>
                      <div className="swap_inner_main">
                        <span className="slider_thrsleft_innerbot">
                          Maximum Swap
                        </span>
                        <span className="slider_thrsleft_innerbot">
                          {appendFromData == "" ? (
                            <span>0.0000</span>
                          ) : (
                            appendFromData.maxSwap
                          )}{" "}
                        </span>
                      </div>
                      <div className="swap_inner_main">
                        <span className="slider_thrsleft_innerbot">
                          Swap fee ({" "}
                          {appendFromData == ""
                            ? "0.0%"
                            : appendFromData.swapFee}{" "}
                          )
                        </span>
                        <span className="slider_thrsleft_innerbot">
                          {estimateFee == 0 ? (
                            <span>0.0000</span>
                          ) : (
                            Number(estimateFee).toFixed(8)
                          )}{" "}
                        </span>
                      </div>
                      <div className="swap_inner_main">
                        <span className="slider_thrsleft_innerbot">
                          Total Amount
                        </span>
                        <span className="slider_thrsleft_innerbot">
                          {" "}
                          {totatlAmount == 0 ? (
                            <span>0.0000</span>
                          ) : (
                            Number(totatlAmount).toFixed(4)
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swap_scnd_had mt-5">
                  <span className="trade_divs_inhead">
                    Recent Transfer Records
                  </span>
                </div>
                <div className="swap_tab_main">
                  <div className="table-swap-change mt-3">
                    <div className="table-responsive table-borderless">
                      <table>
                        <thead className="assest_head">
                          <th>S.No</th>
                          <th>Date/Time</th>
                          <th>From-Currency</th>
                          <th>To-Currency</th>
                          <th>Amount</th>
                          <th>Price</th>
                          <th>Total-Amount</th>
                          <th>Fee</th>
                        </thead>
                        <tbody>
                          {sessionHistory.length > 0 ? (
                            sessionHistory &&
                            sessionHistory.map((item, i) => {
                              return (
                                <tr>
                                  <td>
                                    <span className="slider_left_innerbot">
                                      {i + 1}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="pop_in_frst">
                                      {moment(item.createdDate).format("lll")}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="pop_in_frst">
                                      {item.fromCurrency}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="pop_in_frst">
                                      {item.toCurrency}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="pop_in_frst">
                                      {parseFloat(item.amount).toFixed(4)} {""}{" "}
                                      {item.fromCurrency}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="pop_in_frst">
                                      {parseFloat(item.price).toFixed(4)} {""}{" "}
                                      {item.toCurrency}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="pop_in_frst">
                                      {parseFloat(item.totalAmount).toFixed(6)}{" "}
                                      {""} {item.fromCurrency}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="pop_in_frst">
                                      {parseFloat(item.fee).toFixed(6)} {""}{" "}
                                      {item.fromCurrency}
                                    </span>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <td colSpan={8} className="text-center py-5">
                              <div className="empty_data">
                                <div className="empty_data_img">
                                  <img
                                    src={require("../images/No-data.png")}
                                    width="100px"
                                  />
                                </div>

                                <div className="no_records_text">
                                  No Records Found
                                </div>
                              </div>
                            </td>
                          )}
                        </tbody>
                      </table>

                      {sessionHistory && sessionHistory.length > 0 ? (
                        <div className="pagination mt-5">
                          <Stack spacing={2}>
                            <Pagination
                              count={Math.ceil(totalPage / recordPerPage)}
                              page={currentPage}
                              onChange={handlePageChange}
                              renderItem={(item) => (
                                <PaginationItem
                                  slots={{
                                    previous: ArrowBackIcon,
                                    next: ArrowForwardIcon,
                                  }}
                                  {...item}
                                />
                              )}
                            />
                          </Stack>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Swap;
