import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { Link, useNavigate } from "react-router-dom";
import apiService from "../core/service/detail";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getMethod, postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import { InfinitySpin } from "react-loader-spinner";
import Moment from "moment";

function Dashboard() {
    const [perpage, setperpage] = useState(5);
    const [activeOrderDatas, setActiveOders] = useState([]);
    const [siteLoader, setSiteLoader] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalactive, settotalactive] = useState(0);

    const recordPerPage = 5;

    useEffect(() => {
        getActiveOrders(1);
    }, [0]);

    const getActiveOrders = async (pages) => {
        try {
            var obj = {
                FilPerpage: perpage,
                FilPage: pages,
            };
            var data = {
                apiUrl: apiService.getActiveOrders,
                payload: obj,
            };

            setSiteLoader(true);
            var resp = await postMethod(data);
            setSiteLoader(false);
            if (resp.status) {
                settotalactive(resp.count);
                setActiveOders(resp.result);
            } else {
            }
        } catch (error) { }
    };

    const orderCancel = async (cancelDatas) => {
        try {
            var obj = {
                _id: cancelDatas._id,
            };
            var data = {
                apiUrl: apiService.cancelOrder,
                payload: obj,
            };
            var fetchTradeHisotory = await postMethod(data);
            if (fetchTradeHisotory) {
                toast.success(
                    "Order cancelled successfully, your amount credit to your wallet"
                );
                getActiveOrders(1);
            } else {
                toast.error("Please try again later");
            }
        } catch (error) {
            toast.error("Please try again later");
        }
    };

    const activePageChange = (event, pageNumber) => {
        setCurrentPage(pageNumber); // call API to get data based on pageNumber
        getActiveOrders(pageNumber);
    };

    return (
        <div>
            <Header />
            <div className="container-fluid innerpages">
                <div className="row">
                    {siteLoader == true ? (
                        <div className="dashboard_content_section loadercss">
                            <InfinitySpin
                                height="300"
                                width="300"
                                color="#12d5d5"
                                ariaLabel="infinity-spin-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                            />
                        </div>
                    ) : (

                        <>
                            <div className="col-lg-2 col-sm-3">
                                <Sidebar />
                            </div>
                            <div className="col-lg-10 col-md-12">
                                <div className=" mx-2 dash_container">
                                    <span className="core_div_head">Open-Order History</span>
                                    <div className="table-history mt-5">
                                        <div className="table-responsive table-borderless">
                                            <table>
                                                <thead>
                                                    <th scope="col" className="starts">
                                                        Date
                                                    </th>
                                                    <th scope="col">Pair</th>
                                                    <th scope="col">Type</th>
                                                    <th scope="col">Side</th>
                                                    <th scope="col">Quantity</th>
                                                    <th scope="col">Price</th>
                                                    <th scope="col">Total</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col" className="ends">
                                                        Action
                                                    </th>
                                                </thead>
                                                <tbody>
                                                    {activeOrderDatas?.length > 0 ? (
                                                        activeOrderDatas.map((item, i) => {
                                                            var dates = Moment(item.createddate).format(
                                                                "DD.MM.YYYY hh:mm a"
                                                            );
                                                            return (
                                                                <tr className="history_tabel_bottom_border">
                                                                    <td>{dates}</td>
                                                                    <td>{item.pairName}</td>
                                                                    <td>{item.ordertype}</td>
                                                                    <td>{item.tradeType}</td>
                                                                    <td>
                                                                        {parseFloat(item.filledAmount).toFixed(8)}{" "}
                                                                        {item.firstSymbol}
                                                                    </td>
                                                                    <td>
                                                                        {item.ordertype == "Stop"
                                                                            ? parseFloat(item.stoporderprice).toFixed(8)
                                                                            : parseFloat(item.price).toFixed(8)}{" "}
                                                                        {item.toSymbol}
                                                                    </td>
                                                                    <td>
                                                                        {item.ordertype == "Stop"
                                                                            ? parseFloat(
                                                                                item.filledAmount * item.stoporderprice
                                                                            ).toFixed(8)
                                                                            : parseFloat(
                                                                                item.filledAmount * item.price
                                                                            ).toFixed(8)}{" "}
                                                                        {item.toSymbol}
                                                                    </td>
                                                                    <td>
                                                                        {item.tradeType == "buy" ? (
                                                                            <span className="text-green">
                                                                                {item.status}
                                                                            </span>
                                                                        ) : (
                                                                            <span className="text-red">
                                                                                {item.status}
                                                                            </span>
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        <button onClick={() => orderCancel(item)}>
                                                                            Cancel
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    ) : (
                                                        <td colSpan={9}>
                                                            <div className="empty_data">
                                                                <div className="empty_data_img">
                                                                    <img src={require("../images/No-data.png")} />
                                                                </div>

                                                                <div className="no_records_text">
                                                                    No Records Found
                                                                </div>
                                                            </div>
                                                        </td>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        {activeOrderDatas && activeOrderDatas.length > 0 ? (
                                            <div className="pagination">
                                                <Stack spacing={2}>
                                                    <Pagination
                                                        count={Math.ceil(totalactive / recordPerPage)}
                                                        page={currentPage}
                                                        onChange={activePageChange}
                                                        renderItem={(item) => (
                                                            <PaginationItem
                                                                slots={{
                                                                    previous: ArrowBackIcon,
                                                                    next: ArrowForwardIcon,
                                                                }}
                                                                {...item}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
