import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import { InfinitySpin } from "react-loader-spinner";
import { toast } from "react-toastify";
import Moment from "moment";

function Dashboard() {
  const friendOptions = [
    {
      key: "Jenny Hess",
      text: "Jenny Hess",
      value: "Jenny Hess",
      image: { avatar: true, src: "/images/avatar/small/jenny.jpg" },
    },
    {
      key: "Elliot Fu",
      text: "Elliot Fu",
      value: "Elliot Fu",
      image: { avatar: true, src: "/images/avatar/small/elliot.jpg" },
    },
    {
      key: "Stevie Feliciano",
      text: "Stevie Feliciano",
      value: "Stevie Feliciano",
      image: { avatar: true, src: "/images/avatar/small/stevie.jpg" },
    },
    {
      key: "Christian",
      text: "Christian",
      value: "Christian",
      image: { avatar: true, src: "/images/avatar/small/christian.jpg" },
    },
  ];

  const navigate = useNavigate();

  const [allCurrency, setallCurrency, allCurrencyref] = useState([]);
  const [allCrypto, setallCrypto, allCryptoref] = useState([]);
  const [currency, setcurrency, currencyref] = useState("USDT");
  const [cointype, setcointype, cointyperef] = useState("");
  const [address, setAddress, addressref] = useState();
  const [view, setview, viewref] = useState("");
  const [bankwire, setBankwire] = useState("");
  const [depositHistory, setdepositHistory] = useState([]);
  const [kycStatus, setkycStatus, kycStatusref] = useState(1);
  const [sitekycStatus, setsitekycStatus] = useState("DeActive");
  const [cur_currency, setcur_currency, cur_currencyref] = useState("");
  const [network_currency, setcur_network, network_currencyref] = useState([]);
  const [network_default, setnet_default, net_defaultref] = useState("");
  const [Fullname, Setfullname, Fullnameref] = useState("Tether");
  const [Image, setImage, Imageref] = useState("");
  const [Networks, setNetworks, Networksref] = useState("");
  const [siteLoader, setSiteLoader] = useState(false);
  const [refreshStatus, setrefreshStatus] = useState(false);

  useEffect(() => {
    getAllcurrency();
    getKYCstatus();
    getdepositHistory();
    getTransaction();
  }, [0]);
  console.log(
    addressref.current,
    "--=-=-addressref=-=-=addressref=-=-=-=-addressref"
  );

  const refresh = () => {
    setrefreshStatus(true);
    getTransaction();
    getdepositHistory();
  };
  const getTransaction = async () => {
    var data = {
      apiUrl: apiService.transaction,
    };
    // setSiteLoader(true)

    var resp = await getMethod(data);
    // setSiteLoader(false)
    setrefreshStatus(false);

    if (resp.message == true) {
      getdepositHistory();
    }
  };

  const getAllcurrency = async () => {
    var data = {
      apiUrl: apiService.walletcurrency,
    };
    setSiteLoader(true);
    var resp = await getMethod(data);
    setSiteLoader(false);

    if (resp) {
      var currArrayCrypto = [];
      var data = resp.data;
      setallCrypto(data);
      console.log(allCryptoref.current, "allcrypto");
      for (var i = 0; i < data.length; i++) {
        if (data[i].depositStatus == "Active") {
          var obj = {
            value: data[i]._id,
            key: data[i]._id,
            text: data[i].currencySymbol,
            image: { avatar: true, src: data[i].Currency_image },
            label: data[i].currencySymbol,
            erc20token: data[i].erc20token,
            bep20token: data[i].bep20token,
            trc20token: data[i].trc20token,
            rptc20token: data[i].rptc20token,
            coinType: data[i].coinType,
            currencyName: data[i].currencyName,
            imgurl: data[i].Currency_image,
          };
          currArrayCrypto.push(obj);
        }
      }
      console.log("network_currencyref===", currArrayCrypto[0]);
      setallCurrency(currArrayCrypto);
      setcurrency(currArrayCrypto[0].label);
      Setfullname(currArrayCrypto[0].currencyName);
      setImage(currArrayCrypto[0].imgurl);
      setcointype(currArrayCrypto[0].coinType);
      // if (currArrayCrypto[0].coinType == "1") {
      //   onSelect(currArrayCrypto[0]);
      // }
    }
  };

  const getKYCstatus = async () => {
    var data = {
      apiUrl: apiService.getKYCStatus,
    };
    setSiteLoader(true);
    var getKYC = await getMethod(data);
    setSiteLoader(false);

    console.log("getkyrefreshc===", getKYC);

    if (getKYC.status) {
      console.log(getKYC.Message.kycstatus, "========");
      setkycStatus(getKYC.Message.kycstatus);
    } else {
      // setkycStatus(0);
    }
  };

  const getSiteSettingstatus = async () => {
    var data = {
      apiUrl: apiService.getSiteDatas,
    };
    var response = await getMethod(data);
    console.log("getkyc1===", response);
    if (response.status) {
      console.log(response.data.kycStatus, "==kyc======", kycStatus);
      setsitekycStatus(response.data.kycStatus);
    } else {
      // setkycStatus(0);
    }
  };

  const getdepositHistory = async () => {
    var obj = {
      apiUrl: apiService.deposit_history,
      payload: { FilPerpage: 5, FilPage: 1 },
    };
    var deposit_history_list = await postMethod(obj);
    if (deposit_history_list) {
      setdepositHistory(deposit_history_list.crypto_deposit);
    }
  };

  const onSelect = async (e, option) => {
    console.log(option, "-=-onSelecttop", e);

    setcur_network([]);
    setnet_default("");
    setcurrency(option.label);
    Setfullname(option.currencyName);
    setImage(option.imgurl);
    setcointype(option.coinType);
    let indexData = allCryptoref.current.findIndex(
      (x) => x._id == option.value
    );
    if (indexData != -1) {
      var currencydata = allCryptoref.current[indexData];
      console.log("currencydata===", currencydata);
      setcur_currency(currencydata);

      var network_cur = {};
      var network_names = [];
      if (currencydata.currencyType == "2") {
        if (currencydata.erc20token == "1") {
          network_cur = {
            value: "erc20token",
            label: "ERC20",
            text: "ERC20",

          };
          network_names.push(network_cur);
        }
        if (currencydata.bep20token == "1") {
          network_cur = {
            value: "bep20token",
            label: "BEP20",
            text: "BEP20",
            image: { avatar: true, src: "https://res.cloudinary.com/taikonz-com/image/upload/v1664014615/fd2vqjmjipjxvzt6g2re.png" }

          };
          network_names.push(network_cur);
        }
        if (currencydata.trc20token == "1") {
          network_cur = {
            value: "trc20token",
            label: "TRC20",
            text: "TRC20",

          };
          network_names.push(network_cur);
        }
        setcur_network(network_names);
        console.log("network_currencyref===", network_currencyref.current);
        setnet_default(network_currencyref.current[0].label);
      }

      if (currencydata.coinType == "1" && currencydata.currencyType == "1") {


        var obj = {
          currency: currencydata.currencySymbol,
          currId: option.value,
        };
        var data = {
          apiUrl: apiService.generateAddress,
          payload: obj,
        };
        setview("load");
        var resp = await postMethod(data);
        console.log(resp, "=-=-=resp-=-=--");
        if (resp.status) {
         setview("view");
          setAddress(resp.Message);
          console.log(
            addressref.current,
            "--=-=-addressref=-=-=addressref=-=-=-=-addressref"
          );
        } else {
          //showerrorToast("Something went wrong, please try again latersv");
        }
      } else {
        var obj = {
          currency: option.label,
        };
        var data = {
          apiUrl: apiService.bankwire,
          payload: obj,
        };

        var resp = await postMethod(data);
        console.log(resp, "=-=-=fiat deposit resp-=-=--");
        if (resp.status) {
          setBankwire(resp.data);
        } else {
          //showerrorToast("Something went wrong, please try again later");
        }
      }
    }
    //}
  };

  const copy = async (text) => {
    navigator.clipboard.writeText(text);
    showsuccessToast("Address copied");
  };

  const navigateKyc = async () => {
    navigate("/kyc");
  };
  const onSelect_network = async (e, option) => {
    setNetworks(option.value);
    console.log(option, "-=-onSelect_network");
    if (
      cur_currencyref.current.coinType == "1" &&
      cur_currencyref.current.currencyType == "2"
    ) {
      var obj = {
        currency: cur_currencyref.current.currencySymbol,
        currId: cur_currencyref.current._id,
        network: option.value,
      };
      console.log("call here 1111", obj);
      var data = {
        apiUrl: apiService.generateAddress_network,
        payload: obj,
      };
      setview("load");
      var resp = await postMethod(data);
      console.log(resp, "=-=-=resp-=-=--");
      if (resp.status) {
        setview("view");
        setAddress(resp.Message);
      } else {
        //showerrorToast("Something went wrong, please try again later");
      }
    }
  };

  const showsuccessToast = (message) => {
    toast.dismiss();
    toast.success(message);
  };
  
  const showerrorToast = (message) => {
    toast.dismiss();
    toast.error(message);

  };
  return (
    <div>
      <Header />
      <div className="container-fluid innerpages">
        <div className="row">

          {siteLoader == true ? (
            <div className="dashboard_content_section loadercss">
              <InfinitySpin
                height="300"
                width="300"
                color="#12d5d5"
                ariaLabel="infinity-spin-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          ) : (
            <>
              <div className="col-lg-2 col-sm-3">
                <Sidebar />
              </div>
              <div className="col-lg-10 col-md-12">
                <div className="mx-2 dash_container">
              {kycStatusref.current == 1 ? (
                <>
                  <span className="core_div_head">Deposit</span>
                  <div className=" row mt-4 justify-content-center">
                    <div className="col-lg-6 Depositcard">
                      <div className="">
                        <div className="input-section">
                          <h5 className="mt-0">Select currency</h5>
                          <div className="flex_input_posion">
                            <Dropdown
                              placeholder="Select Coin"
                              fluid
                              selection
                              options={allCurrencyref.current}
                              defaultValue={allCurrencyref.current[0]}
                              onChange={onSelect}
                            />
                          </div>
                        </div>

                        {cur_currencyref.current.currencyType == "2" ? (
                          <div className="input-section">
                            <h5>Choose a Network</h5>
                            <div className="flex_input_posion">
                              <Dropdown
                                placeholder="Select Coin"
                                fluid
                                selection
                                options={network_currencyref.current}
                                defaultValue={network_currencyref.current[0]}
                                onChange={onSelect_network}
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {addressref.current == undefined ? (
                          ""
                        ) : (
                          <>
                            <div className="input-section">
                              <h5>Scan QR code</h5>
                              <img
                                src={
                                  addressref.current == undefined
                                    ? ""
                                    : addressref.current.qrcode
                                }
                                className="d-block QRS mx-auto"

                              />
                            </div>

                            <div className="d-flex align-items-center justify-content-between">
                              <div class="Vector-12"></div>
                              <p className="orqrs">or copy address</p>
                              <div class="Vector-12"></div>
                            </div>

                            <div className="input-section">
                              <div className="flex_input_posion">
                                <input
                                  type="text"
                                  value={
                                    addressref.current == undefined
                                      ? ""
                                      : addressref.current.address
                                  }
                                  className="reg_inp_field"
                                  placeholder="Enter Your Old Password"
                                />
                                <span
                                  className="copy"
                                  onClick={() => copy(addressref.current.address)}
                                >
                                  copy
                                </span>
                              </div>
                            </div>

                            {cur_currencyref.current.currencyType == "2" ? (
                              <p className="text-white mt-3">
                                {" "}
                                <small>
                                  deposite only {Networksref.current} network{" "}
                                  {currencyref.current} otherwise your fund will
                                  loss...
                                </small>
                              </p>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <span className="core_div_head m8032">Recent Deposit</span>
                  <div className="table-history">
                    <div className="table-responsive ">
                      <table>
                        <thead>
                          <th scope="col" >
                            Date
                          </th>
                          <th scope="col">Time</th>
                          <th scope="col">Currency</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Transaction-Id</th>
                          <th scope="col" className="ends">
                            Status
                          </th>
                        </thead>
                        <tbody>
                          {depositHistory && depositHistory.length > 0 ? (
                            depositHistory.map((item, i) => {
                              return (
                                <tr className="history_tabel_bottom_border">
                                  <th scope="row">
                                    {" "}
                                    {Moment(item.date).format("YYYY-MM-DD")}
                                  </th>
                                  <td>{Moment(item.date).format("HH:mm:ss")}</td>
                                  <td>{item.currencySymbol}</td>
                                  <td>{parseFloat(item.amount).toFixed(8)}</td>
                                  <td>
                                    {" "}
                                    {item.txnid}{" "}
                                    <i
                                      class="fa fa-clone"
                                      aria-hidden="true"
                                      onClick={() => copy(item.txnid)}
                                      style={{ cursor: "pointer" }}
                                    ></i>
                                  </td>
                                  <td className="text-success">Completed</td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan={6} className="text-center py-5">
                                <div className="empty_data">
                                  <div className="empty_data_img">
                                  <img
                                    src={require("../images/No-data.png")}
                                    width="100px"
                                  />
                                  </div>
                                  <div className="no_records_text">
                                    No Records Found{" "}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>

                      </table>
                    </div>
                  </div>
              </>
              ) : (

                <div className="deposit"> 
            <div className="container">
              <h2 className="depositbuttonh">
                Please complete the KYC verification process to gain access to the
                page.
              </h2>
                 <div onClick={navigateKyc} className='verify_stst_submit py-3'>
                     <span className='land-sign-letter'>Verify KYC Now</span>
                     </div>
            </div>
          </div>
              )}
              </div>
</div>
            </>
          )}

        </div>
      </div>
    </div>
  );
}

export default Dashboard;
