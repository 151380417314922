import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useNavigate } from "react-router-dom";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import { InfinitySpin } from "react-loader-spinner";
import { toast } from "react-toastify";
import Moment from "moment";

function Dashboard() {
  const [perpage, setperpage] = useState(5);
  const [tradeHistoryData, settradeHistory] = useState([]);

  const [currentPageHis, setcurrentPageHis] = useState(1);
  const [totalHist, settotalHist] = useState(0);
  const [siteLoader, setSiteLoader] = useState(false);

  const recordPerPageHist = 5;

  useEffect(() => {
    tradeHistory(1);
  }, [0]);

  const tradeHistory = async (pages) => {
    try {
      var obj = {
        FilPerpage: perpage,
        FilPage: pages,
      };
      var data = {
        apiUrl: apiService.tradeHistory,
        payload: obj,
      };
      setSiteLoader(true);
      var resp = await postMethod(data);
      setSiteLoader(false);
      if (resp.status) {
        settradeHistory(resp.result);
        settotalHist(resp.count);
      } else {
      }
    } catch (error) { }
  };

  const orderhistoryactive = (event, pageNumber) => {
    setcurrentPageHis(pageNumber); // call API to get data based on pageNumber
    tradeHistory(pageNumber);
  };

  return (
    <div>
      <Header />
      <div className="container-fluid innerpages">
        <div className="row">

          {siteLoader == true ? (
            <div className="dashboard_content_section loadercss">
              <InfinitySpin
                height="300"
                width="300"
                color="#12d5d5"
                ariaLabel="infinity-spin-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          ) : (

            <>
              <div className="col-lg-2 col-sm-3">
                <Sidebar />
              </div>
              <div className="col-lg-10 col-md-12">
                <div className=" mx-2 dash_container">
                  <span className="core_div_head">Trade History</span>
                  <div className="table-history mt-5">
                    <div className="table-responsive table-borderless">
                      <table>
                        <thead>
                          <th scope="col" className="starts">
                            Date
                          </th>
                          <th scope="col">Pair</th>
                          <th scope="col">Type</th>
                          <th scope="col">Price</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Total</th>
                          <th scope="col" className="ends">
                            Status
                          </th>
                        </thead>
                        <tbody>
                          {tradeHistoryData.length > 0 ? (
                            tradeHistoryData.map((item, i) => {
                              var datesHis = Moment(item.created_at).format(
                                "DD.MM.YYYY hh:mm a"
                              );
                              return (
                                <tr className="history_tabel_bottom_border">
                                  <td>{datesHis} </td>
                                  <td>{item.pair} </td>
                                  <td>{item.type} </td>
                                  <td> {item.askPrice} </td>
                                  <td> {item.askAmount} </td>
                                  <td>{item.total}</td>
                                  <td>
                                    {item.type == "buy" ? (
                                      <span className="text-green">Filled</span>
                                    ) : (
                                      <span className="text-red">Filled</span>
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <td colSpan={7}>
                              <div className="empty_data">
                                <div className="empty_data_img">
                                  <img
                                    src={require("../images/No-data.png")}
                                    width="100px"
                                  />
                                </div>

                                <div className="no_records_text">
                                  No Records Found
                                </div>
                              </div>
                            </td>
                          )}
                        </tbody>
                      </table>
                    </div>

                    {tradeHistoryData && tradeHistoryData.length > 0 ? (
                      <div className="pagination">
                        <Stack spacing={2}>
                          <Pagination
                            count={Math.ceil(totalHist / recordPerPageHist)}
                            page={currentPageHis}
                            onChange={orderhistoryactive}
                            renderItem={(item) => (
                              <PaginationItem
                                slots={{
                                  previous: ArrowBackIcon,
                                  next: ArrowForwardIcon,
                                }}
                                {...item}
                              />
                            )}
                          />
                        </Stack>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

            </>
          )}

        </div>
      </div>
    </div>
  );
}

export default Dashboard;
