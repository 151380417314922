import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import moment from "moment";
import axios from "axios";
import { InfinitySpin } from "react-loader-spinner";

function Dashboard() {
  const [tokenDetails, setTokenDetails] = useState("");
  const [usdtvalue, setusdtvalue] = useState(0);
  const [siteLoader, setSiteLoader] = useState(false);

  useEffect(() => {
    let getToken = localStorage.getItem("user_token");
    if (getToken != "" && getToken != undefined && getToken != null) {
    } else {
    }
    var urls = window.location.href;
    var fetchsymbol = urls.split("lanchpad/detail/")[1];
    console.log(fetchsymbol);
    if (fetchsymbol) {
      var split = fetchsymbol.split("_symbol")[0];
      getTokenDetails(split);
    } else {
      // navigate("/");
    }
  }, [0]);

  const getTokenDetails = async (data) => {
    try {
      setSiteLoader(true);
      var obj = {
        symbol: data,
      };
      var data = {
        apiUrl: apiService.getTokenDetails,
        payload: obj,
      };
      var resp = await postMethod(data);
      setSiteLoader(false);
      if (resp) {
        console.log(resp.data);
        setTokenDetails(resp.data);
        var data = new Date(resp.data.endDate).getTime();
        const apiUrl = `https://min-api.cryptocompare.com/data/pricemulti?fsyms=USD&tsyms=USDT&api_key=93e3c5b6fe23291d2114acf508c57635e90100074cf42266f20cd231e5f8e854`;
        axios.get(apiUrl).then((response) => {
          if (response) {
            const value = response.data.USD.USDT;
            setusdtvalue(+resp.data.price);
            console.log(usdtvalue, "usdtvalue");
          }
        });
      }
    } catch (error) {}
  };

  return (
    <div>
      <Header />
      {siteLoader == true ? (
         <div className="dashboard_content_section loadercss">
         <InfinitySpin
           height="300"
           width="300"
           color="#12d5d5"
           ariaLabel="infinity-spin-loading"
           wrapperStyle={{}}
           wrapperClass=""
           visible={true}
         />
       </div>
      ) : (
      <div className="container-fluid px-0 innerpages">
        <div className="">
          <section>
            <div className="container-fluid">
              <div className=" m-0 p-5">
                <div className="lanchpad-details">
                  <section className="det-header">
                    <div className="d-flex align-items-center gap10">
                      {/* <img src={require("../images/Merl.png")} width="40px" /> */}
                      <img src={tokenDetails.currency_image} width="40px" />
                      <h2>{tokenDetails.currency_name}</h2>
                      <label>Ended</label>
                    </div>
                    <h6>
                      {tokenDetails.Project_description}
                      {/* Bitget Wallet is the ultimate Web3 multi-chain wallet,
                      offering a comprehensive platform that includes wallet,
                      Swap, NFT Market, DApp Browser, and Launchpad features. */}
                    </h6>
                    <div>
                      <div className="d-flex gap16 mt-4 text-white">
                        <p className="cursor">
                          {" "}
                          <i class="fa-regular fa-file-lines mr-2"></i>{" "}
                          <a
                            href={tokenDetails.whitepaper_link}
                            target="_blank"
                            class="text-white"
                          >
                            White paper
                          </a>
                        </p>
                        <p className="cursor">
                          {" "}
                          <i class="fa-solid fa-globe mr-2"></i>{" "}
                          <a
                            href={tokenDetails.official_website}
                            target="_blank"
                            class="text-white"
                          >
                            Website
                          </a>
                        </p>
                        <p className="cursor">
                          {" "}
                          <i class="fa-solid fa-link"></i>{" "}
                          <a
                            href={tokenDetails.twitterLink}
                            target="_blank"
                            class="text-white"
                          >
                            Twitter
                          </a>
                        </p>
                        <p className="cursor">
                          {" "}
                          <i class="fa-regular fa-note-sticky"></i>{" "}
                          <a
                            href={tokenDetails.telegram}
                            target="_blank"
                            class="text-white"
                          >
                            Telegram
                          </a>
                        </p>
                      </div>

                      <div className="d-flex gap32 lanchpad-details align-items-center">
                        <div className="lanch-body-head">
                          <button>
                            {/* 2024.06.01 08:30  */}
                            {moment(tokenDetails.startDate).format(
                              "DD.MM.YYYY HH:mm"
                            )}
                            {/* var startDate = moment(item.startDate).format(
                    "DD.MM.YYYY HH:mm"
                  ); */}
                            <span> - </span>
                            {moment(tokenDetails.endDate).format(
                              "DD.MM.YYYY HH:mm"
                            )}
                          </button>
                        </div>
                        <div>
                          <span>Total supply</span>
                          <h4>
                            {" "}
                            {tokenDetails.totalSupply} {tokenDetails.symbol}
                          </h4>
                        </div>
                        <div>
                          {/* <span>Number of participants</span>
                          <h4>0</h4> */}
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
                {/* <section className="duration">
                                    <div className="det-header">
                                        <h2>Project duration</h2>


                                        <div className="row ">
                                            <div className="col-lg-3 px-0">
                                                <div className="postion-card">
                                                <div className="d-flex">
                                                    <div className="darkline"></div>
                                                    <i class="fa-regular fa-circle-check"></i>
                                                    <div className="whiteline"></div>
                                                </div>
                                                <h5>Position calculation</h5>
                                                <p>2024.06.01 08:30</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 px-0">
                                                <div className="postion-card">
                                                <div className="d-flex">
                                                    <div className="whiteline"></div>
                                                    <i class="fa-regular fa-circle-check"></i>
                                                    <div className="whiteline"></div>
                                                </div>
                                                <h5>Investment</h5>
                                                <p>2024.06.01 08:30</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 px-0">
                                                <div className="postion-card">
                                                <div className="d-flex">
                                                    <div className="whiteline"></div>
                                                    <i class="fa-regular fa-circle-check"></i>
                                                    <div className="whiteline"></div>
                                                </div>
                                                <h5>Calculation</h5>
                                                <p>2024.06.01 08:30</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 px-0">
                                                <div className="postion-card">
                                                <div className="d-flex">
                                                    <div className="whiteline"></div>
                                                    <i class="fa-regular fa-circle-check"></i>
                                                    <div className="darkline"></div>
                                                </div>
                                                <h5>Distribution</h5>
                                                <p>2024.06.01 08:30</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="purchase-card">
                                                    <div className="heads">
                                                        <img
                                                            src={require("../images/eth.png")}
                                                            width="40px"
                                                        />
                                                        <h4>Purchase ETH</h4>
                                                    </div>
                                                    <div className="bodys">
                                                        <h6>
                                                            Settlement price <span>1 MERI = 0.0001 ETH</span>
                                                        </h6>

                                                        <div className="row px-3">
                                                            <div className="col-lg-6">
                                                                <span> supply</span>
                                                                <h4>0.0000 MERI</h4>

                                                                <span>Total purchase amount</span>
                                                                <h4>0.0000 ETH</h4>

                                                                <span>Participants</span>
                                                                <h4>0</h4>

                                                                <span>Individual cap</span>
                                                                <h4>0.0000 MERI</h4>
                                                            </div>
                                                            <div className="col-lg-6 rights">
                                                                <span>
                                                                    {" "}
                                                                    My purchase limit (average holding)
                                                                </span>
                                                                <h4 className="text-pri">0.0000 MERI</h4>

                                                                <span>Purchased</span>
                                                                <h4>0.0000 ETH</h4>

                                                                <span>My share</span>
                                                                <h4>00,000x</h4>
                                                            </div>
                                                        </div>

                                                        <button> Purchase ETH </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="purchase-card">
                                                    <div className="heads">
                                                        <img
                                                            src={require("../images/eth.png")}
                                                            width="40px"
                                                        />
                                                        <h4>Purchase ETH</h4>
                                                    </div>
                                                    <div className="bodys">
                                                        <h6>
                                                            Settlement price <span>1 MERI = 0.0001 ETH</span>
                                                        </h6>

                                                        <div className="row px-3">
                                                            <div className="col-lg-6">
                                                                <span> supply</span>
                                                                <h4>0.0000 MERI</h4>

                                                                <span>Total purchase amount</span>
                                                                <h4>0.0000 ETH</h4>

                                                                <span>Participants</span>
                                                                <h4>0</h4>

                                                                <span>Individual cap</span>
                                                                <h4>0.0000 MERI</h4>
                                                            </div>
                                                            <div className="col-lg-6 rights">
                                                                <span>
                                                                    {" "}
                                                                    My purchase limit (average holding)
                                                                </span>
                                                                <h4 className="text-pri">0.0000 MERI</h4>

                                                                <span>Purchased</span>
                                                                <h4>0.0000 ETH</h4>

                                                                <span>My share</span>
                                                                <h4>00,000x</h4>
                                                            </div>
                                                        </div>

                                                        <button> Purchase ETH </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section> */}

                <section className="project-details">
                  <div className="det-header mt-5">
                    <h2>Project Details</h2>

                    <img
                      src={tokenDetails.Project_banner}
                      //   src="https://res.cloudinary.com/dpnvbcrn2/image/upload/v1719385491/py40oidww5k6gaptgmsd.jpg"
                      className="d-block  launchpad_main_img"
                      width="400px"
                    />

                    <div className="row">
                      <div className="col-lg-6">
                        <div className="pro-details">
                          <h5>Project Introduction</h5>
                          <p>
                            {tokenDetails.Project_description}

                            {/* Bitget Wallet is the ultimate Web3 multi-chain
                            wallet, offering a comprehensive platform that
                            includes wallet, Swap, NFT Market, DApp Browser, and
                            Launchpad features. Supporting over 100 public
                            chains, such as Ethereum, BNB Chain, Arbitrum,
                            Polygon, and Avalanche, Bitget Wallet aggregates top
                            DEXes and NFT marketplaces to provide traders with
                            the best trading prices */}
                          </p>

                          <h5>Launchpad details</h5>
                          <div className="launch-table">
                            <>
                              <>
                                <div className="odd">
                                  <td>Token</td>

                                  <th>{tokenDetails.symbol}</th>
                                </div>
                                <div className="even">
                                  <td>Total {tokenDetails.symbol} supply</td>
                                  <th>
                                    {" "}
                                    {tokenDetails.totalSupply}{" "}
                                    {tokenDetails.symbol}
                                  </th>
                                </div>{" "}
                                <div className="odd">
                                  <td>
                                    Launchpad pool for {tokenDetails.symbol}{" "}
                                    holders
                                  </td>
                                  <th>5,000,000 {tokenDetails.symbol}</th>
                                </div>{" "}
                                <div className="even">
                                  <td>Launchpad pool for USDT holders</td>
                                  <th>5,000,000 {tokenDetails.symbol}</th>
                                </div>{" "}
                                <div className="odd">
                                  <td>Exchange rate</td>
                                  <th>
                                    1 {tokenDetails.symbol} = $ {+usdtvalue}
                                  </th>
                                </div>{" "}
                                {/* <div className="even">
                                  <td>Individual cap for BGB holders</td>
                                  <th>50,000 BWB (1% of the pool)</th>
                                </div>{" "}
                                <div className="odd">
                                  <td>Individual cap for USDT holders</td>
                                  <th>25,000 BWB (0.5% of the pool)</th>
                                </div> */}
                              </>
                            </>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </section>
        </div>
      </div>
      )}
    </div>
  );
}

export default Dashboard;
