import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { Link, useNavigate } from "react-router-dom";
import apiService from "../core/service/detail";
import moment from "moment";
import { getMethod, postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import axios from "axios";
import launchpadDetails from "./Lanchpad-detail";
import { InfinitySpin } from "react-loader-spinner";

function Dashboard() {
  const [authToken, setauthToken] = useState(false);
  const [usdtvalue, setUsdtvalue] = useState();
  const [upcommingToken, setupcommingToken] = useState([]);
  const [inprogressToken, setinprogressToken] = useState([]);
  const [expiredToken, setexpiredToken] = useState([]);
  const [kycstatus, setkycstatus] = useState(0);
  const [siteLoader, setSiteLoader] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [days, setDays] = useState("");
  const [buttonStatus, setbuttonStatus] = useState(false);
  const [popupdata, setpopupdata] = useState("progress");
  const [stakeAmount, setStatkeAmount] = useState(0);

  const [toastId, setToastId] = useState(null);
  const showSuccessToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.success(message);
    setToastId(newToastId);
  };

  const showErrorToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.error(message);
    setToastId(newToastId);
  };

  function calculateDaysBetweenDates(startDate, endDate) {
    // Convert dates to Date objects
    const start = new Date(startDate);
    const end = new Date(endDate);
    if (isNaN(start.getTime()) || isNaN(end.getTime())) {
      return "Invalid date format. Please use YYYY-MM-DD.";
    }
    // Calculate the difference in milliseconds
    const differenceInMilliseconds = end - start;

    // Convert milliseconds to days
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
    return differenceInDays;
  }

  const handleOpen = (itemdata) => {
    const currentDateTimestamp = new Date().getTime();
    const endingDate = new Date(itemdata.endDate);
    const startingDate = new Date(itemdata.startDate);
    if (currentDateTimestamp > endingDate) {
      setbuttonStatus(true);
    } else if (currentDateTimestamp < startingDate) {
      setbuttonStatus(true);
    } else {
      setbuttonStatus(false);
    }
    setpopupdata(itemdata);
    const formingdays = calculateDaysBetweenDates(
      itemdata.startDate,
      itemdata.endDate
    );
    setDays(formingdays);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const navigate = useNavigate();

  useEffect(() => {
    getAllLaunchpad();

    let getToken = localStorage.getItem("user_token");
    if (getToken != "" && getToken != undefined && getToken != null) {
      setauthToken(true);
    } else {
      setauthToken(false);
    }
  }, [0]);

  const getAllLaunchpad = async () => {
    try {
      setSiteLoader(true);

      var data = {
        apiUrl: apiService.getAllLaunchpad,
      };
      var resp = await getMethod(data);
      var responseData = resp.data;
      console.log(responseData, "-=-=-=responseData-=-");
      if (resp.status) {
        setSiteLoader(false);
        setupcommingToken(responseData.UpcomingTokens);
        setinprogressToken(responseData.inprogressToken);
        setexpiredToken(responseData.expiredTokens);
        if (
          responseData.UpcomingTokens.length == 0 &&
          responseData.expiredTokens.length == 0 &&
          responseData.inprogressToken.length == 0
        ) {
          setselectedTable("progress");
        } else if (
          responseData.UpcomingTokens.length > 0 &&
          responseData.expiredTokens.length == 0 &&
          responseData.inprogressToken.length == 0
        ) {
          setselectedTable("upcome");
        } else if (
          responseData.UpcomingTokens.length == 0 &&
          responseData.expiredTokens.length > 0 &&
          responseData.inprogressToken.length == 0
        ) {
          setselectedTable("complete");
        } else if (
          responseData.UpcomingTokens.length == 0 &&
          responseData.expiredTokens.length == 0 &&
          responseData.inprogressToken.length > 0
        ) {
          setselectedTable("progress");
        } else {
          setselectedTable("progress");
        }

        // toast.success(resp.Message);
      } else {
        // toast.error(resp.Message);
      }
    } catch (error) {}
  };
  const handleViewDetails = (item) => {
    console.log(item);
    navigate(`/lanchpad/detail/${item.currency_name}`);
  };

  const [selectedTable, setselectedTable] = useState("complete");
  const selecttab = (data) => {
    setselectedTable(data);
  };

  const stakechange = (e) => {
    setStatkeAmount(+e.target.value);
  };

  const preventInvalidKeys = (event) => {
    console.log("--");
    if (["e", "E", "+", "-"].includes(event.key)) {
      event.preventDefault();
    }
    if (event.key === " " || event.key === "Tab") {
      event.preventDefault();
    }
    if (event.target.value.length > 6) {
      event.preventDefault();
    }
  };
  const purchaseUsdt = async () => {
    try {
      if(+stakeAmount > 0)
      {
        setSiteLoader(true);
        var obj = {
          stakeAmount: stakeAmount,
          tokenName: popupdata.currency_name,
          tokenSymbol: popupdata.symbol,
          startDate: popupdata.startDate,
          endDate: popupdata.endDate,
          token_currency: popupdata.currencyId,
          token_id: popupdata._id,
          commit_currency: popupdata.hold_currency,
        };
        console.log(obj, "obj");
        var data = {
          apiUrl: apiService.commitToken,
          payload: obj,
        };
        var resp = await postMethod(data);
        setSiteLoader(false);
  
        if (resp.status == true) {
          showSuccessToast(resp.Message);
          handleClose();
        } else {
          showErrorToast(resp.Message);
        }
      }
      else
      {
        showErrorToast("Enter valid amount");
      }
    
    } catch (err) {
      console.log(err, "ererer");
    }
  };

  
  return (
    <div>
      <Header />
      {siteLoader == true ? (
        <div className="dashboard_content_section loadercss">
          <InfinitySpin
            height="300"
            width="300"
            color="#12d5d5"
            ariaLabel="infinity-spin-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <>
        <launchpadDetails/>
      <div className="container-fluid px-0 innerpages">
        <div className=" lanchpool">
        {open == false ? (
          <>
          <section className="lanch-header">
            <div className="container-fluid">
              <div className="row justify-content-between align-items-center ">
                <div className="col-lg-6">
                  <h2>Nexus Pro launchpad</h2>
                  <h5>
                    We identify promising projects where you can invest in
                    trending coins early on.
                  </h5>

                      <button>
                        {authToken == true ? (
                          <span className="hero_getstarted">
                            <a
                              href="https://forms.gle/zqeK2yAQM38Hmjkr6"
                              target="_blank"
                              class="text-white"
                            >
                              List Your Token
                            </a>
                          </span>
                        ) : (
                          <span className="hero_getstarted">
                            <a href="/login" class="text-white">Login</a>{" "}
                          </span>
                        )}
                      </button>
                    </div>
                    <div className="col-lg-6">
                      <img
                        src={require("../images/Lanchpad.png")}
                        className="d-block mx-auto launchpad_main_img"
                        width="400px"
                      />
                    </div>
                  </div>
                </div>
              </section>
              <section>
                <div className="container-fluid">
                  <div className=" m-0 p-5">
                    <div className="lanch-body-head">
                      {inprogressToken && inprogressToken.length > 0 ? (
                        <button
                          className={
                            selectedTable == "progress"
                              ? " launchbuttonactive"
                              : "launchbutton"
                          }
                          onClick={() => selecttab("progress")}
                        >
                          {" "}
                          <span className="hero_getstarted text-white">
                            {" "}
                            In Progress{" "}
                          </span>{" "}
                        </button>
                      ) : (
                        ""
                      )}{" "}
                      {upcommingToken && upcommingToken.length > 0 ? (
                        <button
                          className={
                            selectedTable == "upcome"
                              ? " launchbuttonactive"
                              : "launchbutton"
                          }
                          onClick={() => selecttab("upcome")}
                        >
                          {" "}
                          <span className="hero_getstarted text-white">
                            Upcomming
                          </span>
                        </button>
                      ) : (
                        ""
                      )}{" "}
                      {expiredToken && expiredToken.length > 0 ? (
                        <button
                          className={
                            selectedTable == "complete"
                              ? " launchbuttonactive"
                              : "launchbutton"
                          }
                          onClick={() => selecttab("complete")}
                        >
                          {" "}
                          <span className="hero_getstarted text-white">
                            Completed
                          </span>{" "}
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                    {selectedTable == "progress"
                      ? inprogressToken && inprogressToken.length > 0
                        ? inprogressToken.map((item, i) => {
                            var startDate = moment(item.startDate).format(
                              "DD.MM.YYYY HH:mm"
                            );
                            var endDate = moment(item.endDate).format(
                              "DD.MM.YYYY HH:mm"
                            );

                            // const apiUrl = `https://min-api.cryptocompare.com/data/pricemulti?fsyms=USD&tsyms=USDT&api_key=93e3c5b6fe23291d2114acf508c57635e90100074cf42266f20cd231e5f8e854`;
                            // axios.get(apiUrl).then((response) => {
                            //   if (response) {
                            //     const value = response.data.USD.USDT;
                            //     setUsdtvalue((value * 1) / +item.price);
                            //   }
                            // });

                            return (
                              <>
                                <span className="core_div_head mb-4">
                                  Present projects
                                </span>
                                <div className="Depositcard my-4">
                                  <div className="row">
                                    <div className="col-lg-4 px-2">
                                      <div className="lanch-card-borderless">
                                        <div>
                                          <div className="d-flex justify-content-between gap16">
                                            <button className="completed">
                                              {" "}
                                              <i class="fa-regular fa-circle-check mr-2"></i>{" "}
                                              Progress
                                            </button>
                                            <p>
                                              {/* <i class="fa-solid fa-share-from-square"></i> */}
                                            </p>
                                          </div>
                                          <h3>
                                            {" "}
                                            {/* <img
                                      src={require("../images/Bitcoin-Logo.png")}
                                    />{" "} */}
                                            <img
                                              src={item.currency_image}
                                              class="imagesize"
                                            />{" "}
                                            {item.coinName} {item.symbol}
                                          </h3>
                                          <p>
                                            {item.Project_description.substring(
                                              0,
                                              100
                                            )}{" "}
                                            ...
                                            {/* Bitget Wallet is the ultimate Web3 multi-chain
                                    wallet, offering a comprehensive platform that
                                    includes wallet, Swap, NFT Market, */}
                                          </p>
                                          <div className="d-flex justify-content-between gap10 mt-4 process_flex-deration">
                                            <p className="cursor">
                                              {" "}
                                              <i class="fa-regular fa-file-lines mr-2"></i>{" "}
                                              <a
                                                href={item.whitepaper_link}
                                                target="_blank"
                                                class="text-white"
                                              >
                                                White paper
                                              </a>
                                            </p>
                                            <p className="cursor">
                                              {" "}
                                              <i class="fa-solid fa-globe mr-2"></i>{" "}
                                              <a
                                                href={item.official_website}
                                                target="_blank"
                                                class="text-white"
                                              >
                                                Website
                                              </a>
                                            </p>
                                            <p className="cursor">
                                              {" "}
                                              <i class="fa-solid fa-link"></i>{" "}
                                              <a
                                                href={item.rules}
                                                target="_blank"
                                                class="text-white"
                                              >
                                                Rules
                                              </a>
                                            </p>
                                          </div>
                                        </div>

                                        <div>
                                          <p>Total Supply</p>
                                          <span>
                                            {item.totalSupply} {item.symbol}
                                          </span>
                                        </div>

                                        <div className="lanch-body-head">
                                          <button className="px-2">
                                            {/* 2024.06.01 08:30 */}
                                            {startDate} <span> - </span>
                                            {/* 2024.06.01 08:30 */}
                                            {endDate}
                                          </button>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-lg-4 px-2">
                                      <div className="lanch-card">
                                        <div className="lanchpad-head">
                                          <img
                                            src="https://res.cloudinary.com/taikonz-com/image/upload/v1664014268/b15qia164vomylxkmqfp.png"
                                            className="imagesize"
                                          />
                                          {/* <img src={require("../images/Bitcoin-Logo.png")} />{" "} */}
                                          <div>
                                            <h3 className="m-0">
                                              Purchase {item.symbol}
                                            </h3>
                                            <h4 className="m-0">
                                              Commit {item.hold_currency} to purchase{" "}
                                              {item.symbol}
                                            </h4>
                                          </div>
                                        </div>

                                        <div className="d-flex justify-content-between gap32">
                                          <p>Settlement price</p>
                                          <span>
                                            1 {item.symbol} = {parseFloat(item.price).toFixed(6)} USDT
                                          </span>
                                        </div>

                                        <div className="d-flex justify-content-between gap24">
                                          <p>Supply</p>
                                          <span>
                                            {item.totalSupply} {item.symbol}
                                          </span>
                                        </div>

                                        <div className="d-flex justify-content-between gap24">
                                          <p>Participants</p>
                                          <span>20,603</span>
                                        </div>

                                        <div className="d-flex justify-content-between gap24">
                                          <p>Total purchase amount</p>
                                          <span>80,828,403.98 ETH</span>
                                        </div>
                                        <div className="d-flex justify-content-between gap24">
                                          <p>Average holding</p>
                                          <span className="text-pri">
                                            00.00 {item.symbol}
                                          </span>
                                        </div>

                                        <button
                                          className="white-btn"
                                          // onClick={() =>
                                          //   handleViewDetails(item)
                                          // }
                                          onClick={() => handleOpen(item)}
                                        >
                                          {" "}
                                          View Details
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })
                        : ""
                      : selectedTable == "upcome"
                      ? upcommingToken && upcommingToken.length > 0
                        ? upcommingToken.map((item, i) => {
                            var startDate = moment(item.startDate).format(
                              "DD.MM.YYYY HH:mm"
                            );
                            var endDate = moment(item.endDate).format(
                              "DD.MM.YYYY HH:mm"
                            );

                            // const apiUrl = `https://min-api.cryptocompare.com/data/pricemulti?fsyms=USD&tsyms=USDT&api_key=93e3c5b6fe23291d2114acf508c57635e90100074cf42266f20cd231e5f8e854`;
                            // axios.get(apiUrl).then((response) => {
                            //   if (response) {
                            //     const value = response.data.USD.USDT;
                            //     setUsdtvalue((value * 1) / +item.price);
                            //   }
                            // });

                            return (
                              <>
                                <span className="core_div_head mb-4">
                                  Upcoming projects
                                </span>
                                <div className="Depositcard my-4">
                                  <div className="row">
                                    <div className="col-lg-4 px-2">
                                      <div className="lanch-card-borderless">
                                        <div>
                                          <div className="d-flex justify-content-between gap16">
                                            <button className="completed">
                                              {" "}
                                              <i class="fa-regular fa-circle-check mr-2"></i>{" "}
                                              Progress
                                            </button>
                                            <p>
                                              {/* <i class="fa-solid fa-share-from-square"></i> */}
                                            </p>
                                          </div>
                                          <h3>
                                            {" "}
                                            {/* <img
                           src={require("../images/Bitcoin-Logo.png")}
                         />{" "} */}
                                            <img
                                              src={item.currency_image}
                                              class="imagesize"
                                            />{" "}
                                            {item.coinName} {item.symbol}
                                          </h3>
                                          <p>
                                            {item.Project_description.substring(
                                              0,
                                              100
                                            )}{" "}
                                            ...
                                            {/* Bitget Wallet is the ultimate Web3 multi-chain
                         wallet, offering a comprehensive platform that
                         includes wallet, Swap, NFT Market, */}
                                          </p>
                                          <div className="d-flex justify-content-between gap16 mt-4">
                                            <p>
                                              {" "}
                                              <i class="fa-regular fa-file-lines mr-2"></i>{" "}
                                              {/* White paper */}
                                              <a
                                                href= {item.whitepaper_link}
                                                target="_blank"
                                                class="text-white"
                                              >
                                                White Papper
                                              </a>
                                             
                                            </p>
                                            <p>
                                              {" "}
                                              <i class="fa-solid fa-globe mr-2"></i>{" "}
                                              <a
                                                href= {item.official_website}
                                                target="_blank"
                                                class="text-white"
                                              >
                                                Website
                                              </a>
                                              
                                            </p>
                                            <p>
                                              {" "}
                                              <i class="fa-solid fa-ruler mr-2"></i>
                                              
                                              <a
                                                href= {item.terms}
                                                target="_blank"
                                                class="text-white"
                                              >
                                                Terms
                                              </a>
                                            </p>
                                          </div>
                                        </div>

                                        <div>
                                          <p>Total Supply</p>
                                          <span>
                                            {item.totalSupply} {item.symbol}
                                          </span>
                                        </div>

                                        <div className="lanch-body-head">
                                          <button className="px-2">
                                            {/* 2024.06.01 08:30 */}
                                            {startDate} <span> - </span>
                                            {/* 2024.06.01 08:30 */}
                                            {endDate}
                                          </button>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-lg-4 px-2">
                                      <div className="lanch-card">
                                        <div className="lanchpad-head">
                                          <img
                                            src="https://res.cloudinary.com/taikonz-com/image/upload/v1664014268/b15qia164vomylxkmqfp.png"
                                            className="imagesize"
                                          />
                                          {/* <img src={require("../images/Bitcoin-Logo.png")} />{" "} */}
                                          <div>
                                            <h3 className="m-0">
                                              Purchase USDT
                                            </h3>
                                            <h4 className="m-0">
                                              Commit USDT to purchase{" "}
                                              {item.symbol}
                                            </h4>
                                          </div>
                                        </div>

                                        <div className="d-flex justify-content-between gap32">
                                          <p>Settlement price</p>
                                          <span>
                                            1 {item.symbol} =  {parseFloat(item.price).toFixed(6)} USDT
                                          </span>
                                        </div>

                                        <div className="d-flex justify-content-between gap24">
                                          <p>Supply</p>
                                          <span>
                                            {item.totalSupply} {item.symbol}
                                          </span>
                                        </div>

                                        <div className="d-flex justify-content-between gap24">
                                          <p>Participants</p>
                                          <span>20,603</span>
                                        </div>

                                        <div className="d-flex justify-content-between gap24">
                                          <p>Total purchase amount</p>
                                          <span>80,828,403.98 ETH</span>
                                        </div>
                                        <div className="d-flex justify-content-between gap24">
                                          <p>Average holding</p>
                                          <span className="text-pri">
                                            00.00 {item.symbol}
                                          </span>
                                        </div>

                                        <button
                                          className="white-btn"
                                          onClick={() =>
                                            handleViewDetails(item)
                                          }
                                        >
                                          {" "}
                                          View Details
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })
                        : ""
                      : selectedTable == "complete"
                      ? expiredToken && expiredToken.length > 0
                        ? expiredToken.map((item, i) => {
                            var startDate = moment(item.startDate).format(
                              "DD.MM.YYYY HH:mm"
                            );
                            var endDate = moment(item.endDate).format(
                              "DD.MM.YYYY HH:mm"
                            );

                            // const apiUrl = `https://min-api.cryptocompare.com/data/pricemulti?fsyms=USD&tsyms=USDT&api_key=93e3c5b6fe23291d2114acf508c57635e90100074cf42266f20cd231e5f8e854`;
                            // axios.get(apiUrl).then((response) => {
                            //   if (response) {
                            //     const value = response.data.USD.USDT;
                            //     setUsdtvalue((value * 1) / +item.price);
                            //   }
                            // });

                            return (
                              <div className="Depositcard my-4">
                                <div className="row">
                                  <div className="col-lg-3 px-2">
                                    <div className="lanch-card-borderless">
                                      <div>
                                        <div className="d-flex justify-content-between gap16">
                                          <button className="completed">
                                            {" "}
                                            <i class="fa-regular fa-circle-check mr-2"></i>{" "}
                                            Progress
                                          </button>
                                          <p>
                                            {/* <i class="fa-solid fa-share-from-square"></i> */}
                                          </p>
                                        </div>
                                        <h3>
                                          {" "}
                                          {/* <img
                                      src={require("../images/Bitcoin-Logo.png")}
                                    />{" "} */}
                                          <img
                                            src={item.currency_image}
                                            class="imagesize"
                                          />{" "}
                                          {item.coinName} {item.symbol}
                                        </h3>
                                        <p>
                                          {item.Project_description.substring(
                                            0,
                                            100
                                          )}{" "}
                                          ...
                                          {/* Bitget Wallet is the ultimate Web3 multi-chain
                                    wallet, offering a comprehensive platform that
                                    includes wallet, Swap, NFT Market, */}
                                        </p>
                                        {/* <div className="d-flex justify-content-between gap16 mt-4">
                                    <p>
                                      {" "}
                                      <i class="fa-regular fa-file-lines mr-2"></i>{" "}
                                      White paper
                                      {item.whitepaper_link}
                                    </p>
                                    <p>
                                      {" "}
                                      <i class="fa-solid fa-globe mr-2"></i>{" "}

                                      {item.official_website}
                                    </p>
                                    <p>
                                      {" "}
                                      <i class="fa-solid fa-ruler mr-2"></i>
                                      {item.terms}
                                    </p>
                                  </div> */}
                                        <div className="d-flex gap16 mt-4 text-white">
                                          <p className="cursor">
                                            {" "}
                                            <i class="fa-regular fa-file-lines mr-2"></i>{" "}
                                            <a
                                              href={item.whitepaper_link}
                                              target="_blank"
                                              class="text-white"
                                            >
                                              White paper
                                            </a>
                                          </p>
                                          <p className="cursor">
                                            {" "}
                                            <i class="fa-solid fa-globe mr-2"></i>{" "}
                                            <a
                                              href={item.official_website}
                                              target="_blank"
                                              class="text-white"
                                            >
                                              Website
                                            </a>
                                          </p>
                                          <p className="cursor">
                                            {" "}
                                            <i class="fa-solid fa-link"></i>{" "}
                                            <a
                                              href={item.rules}
                                              target="_blank"
                                              class="text-white"
                                            >
                                              Rules
                                            </a>
                                          </p>
                                        </div>
                                      </div>

                                      <div>
                                        <p>Total Supply</p>
                                        <span>
                                          {item.totalSupply} {item.symbol}
                                        </span>
                                      </div>

                                      <div className="lanch-body-head">
                                        <button className="px-2">
                                          {/* 2024.06.01 08:30 */}
                                          {startDate} <span> - </span>
                                          {/* 2024.06.01 08:30 */}
                                          {endDate}
                                        </button>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-lg-3 px-2">
                                    <div className="lanch-card">
                                      <div className="lanchpad-head">
                                        <img
                                          src="https://res.cloudinary.com/taikonz-com/image/upload/v1664014268/b15qia164vomylxkmqfp.png"
                                          className="imagesize"
                                        />
                                        {/* <img src={require("../images/Bitcoin-Logo.png")} />{" "} */}
                                        <div>
                                          <h3 className="m-0">Purchase USDT</h3>
                                          <h4 className="m-0">
                                            Commit USDT to purchase{" "}
                                            {item.symbol}
                                          </h4>
                                        </div>
                                      </div>

                                      <div className="d-flex justify-content-between gap32">
                                        <p>Settlement price</p>
                                        <span>
                                          1 {item.symbol} =  {item.price.toFixed(6)} USDT
                                        </span>
                                      </div>

                                      <div className="d-flex justify-content-between gap24">
                                        <p>Supply</p>
                                        <span>
                                          {item.totalSupply} {item.symbol}
                                        </span>
                                      </div>

                                      <div className="d-flex justify-content-between gap24">
                                        <p>Participants</p>
                                        <span>20,603</span>
                                      </div>

                                      <div className="d-flex justify-content-between gap24">
                                        <p>Total purchase amount</p>
                                        <span>80,828,403.98 ETH</span>
                                      </div>
                                      <div className="d-flex justify-content-between gap24">
                                        <p>Average holding</p>
                                        <span className="text-pri">
                                          00.00 {item.symbol}
                                        </span>
                                      </div>

                                      <button
                                        className="white-btn"
                                        onClick={() => handleViewDetails(item)}
                                      >
                                        {" "}
                                        View Details
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        : ""
                      : ""}
                  </div>
                </div>
              </section>
          </>
          
              ) : (
                <section className="container ">
                <div className="purchase-card">
                  <div className="bodys p-0">
                    <div className="row px-3">
                      <div></div>
                      <div className="col-lg-12 mb-3">
                        <div className="text-white d-flex launchpadpadd">
                          <i
                            class="fa-solid text-white dash_hai_icon fa-arrow-left mt-2 mr-2 cursor"
                            onClick={handleClose}
                          ></i>
                          <img
                            src={popupdata.currency_image}
                            className="imagesize"
                          ></img>{" "}
                          <p className="launchpadpadd">{popupdata.symbol}</p>
                        </div>
                        <div className="step-5 ">
                          <div className="profile_content_image">
                            <div className="select_duration dayss">
                              <ul class="nav nav-tabs"></ul>
                            </div>
                          </div>
                        </div>
                      </div>
      
                      <div className="col-lg-6 mtb  p-3">
                        {/* <div className="launhcpadpop"> */}
                        <span>Commit {popupdata.hold_currency}</span>
                        {/* <h4 className="launhcpadpoph4">wqewqe</h4> */}
                        {/* </div> */}
                        <h4>
                          {" "}
                          <input
                            type="number"
                            className="stakeinput"
                            pattern="\S*"
                            name="stakeAmount"
                            value={stakeAmount}
                            onChange={stakechange}
                            onKeyDown={preventInvalidKeys}
                          />{" "}
                        </h4>
      
                        <div className="launhcpadpop">
                          <span>Expires In</span>
                          <h4 className="launhcpadpoph4">{days} days</h4>
                        </div>
                        <div className="launhcpadpop">
                          <span>Total Supply</span>
                          <h4 className="launhcpadpoph4">
                            {popupdata.totalSupply == null ||
                            popupdata.totalSupply == undefined ||
                            popupdata.totalSupply == ""
                              ? "0"
                              : popupdata.totalSupply}
                          </h4>
                        </div>
                        <div className="launhcpadpop">
                          <span>Start Date</span>
                          <h4 className="launhcpadpoph4">
                          {moment(popupdata.startDate).format(
                              "DD.MM.YYYY HH:mm"
                            )}
                          </h4>
                        </div>
                        <div className="launhcpadpop">
                          <span>End Date</span>
                          <h4 className="launhcpadpoph4">
                          {moment(popupdata.endDate).format(
                              "DD.MM.YYYY HH:mm"
                            )}
                          </h4>
                        </div>
                      </div>
                      <div className="col-lg-6 p-3 rightsides">
                        <div className="d-flex justify-content-between gap16 mt-4 text-white">
                          <p>
                            {" "}
                            <i class="fa-regular fa-file-lines mr-2"></i>
                            <a
                              href={popupdata.whitepaper_link}
                              target="_blank"
                              class="text-white"
                            >
                              White paper{" "}
                            </a>
                          </p>
                          <p>
                            {" "}
                            <i class="fa-solid fa-globe mr-2"></i>{" "}
                            <a
                              href={popupdata.official_website}
                              target="_blank"
                              class="text-white"
                            >
                              Website{" "}
                            </a>
                          </p>
                          <p>
                            {" "}
                            <i class="fa-solid fa-ruler mr-2"></i>
                            <a
                              href={popupdata.terms}
                              target="_blank"
                              class="text-white"
                            >
                              Rules{" "}
                            </a>
                          </p>
                        </div>
                        <hr />
                        <h6>
                          Note: {popupdata.symbol} will be transferred from your spot
                          account.
                        </h6>
                        {authToken == true ? (
                          buttonStatus === true ? (
                            <button className="disablecursor"> Commit {popupdata.hold_currency} </button>
                          ) : (
                            <button onClick={purchaseUsdt}> Commit {popupdata.hold_currency} </button>
                          )
                        ) : (
                          <button>
                            <a href="/login" class="text-dark">
                              Login
                            </a>{" "}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Dashboard;
