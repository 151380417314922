import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { Link, useNavigate } from "react-router-dom";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import Moment from "moment";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { InfinitySpin } from "react-loader-spinner";

function Dashboard() {
  const [TfaHistory, setTfaHistory] = useState([]);

  const [tfacurrentpage, settfacurrentpage] = useState(1);
  const [tfatotalpage, settfaTotalpages] = useState(0);
  const [siteLoader, setSiteLoader] = useState(false);

  useEffect(() => {
    Tfa_History(1);
  }, [0]);

  const Tfa_History = async (page) => {
    var data = {
      apiUrl: apiService.Tfa_History,
      payload: { perpage: 5, page: page },
    };
    setSiteLoader(true);
    var Tfa_History = await postMethod(data);
    setSiteLoader(false);
    if (Tfa_History) {
      setTfaHistory(Tfa_History.data.data);
      settfaTotalpages(Tfa_History.data.total);
    }
  };
  const tfarecordpage = 5;
  const tfapagerange = 5;
  const handlepagetfachange = (event, pageNumber) => {
    Tfa_History(pageNumber);
    settfacurrentpage(pageNumber);
  };

  return (
    <div>
      <Header />
      <div className="container-fluid innerpages">
        <div className="row">

        {siteLoader == true ? (
                        <div className="dashboard_content_section loadercss">
                            <InfinitySpin
                                height="300"
                                width="300"
                                color="#12d5d5"
                                ariaLabel="infinity-spin-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                            />
                        </div>
                    ) : (

                        <>
          <div className="col-lg-2 col-sm-3">
            <Sidebar />
          </div>
          <div className="col-lg-10 col-md-12">
            <div className=" mx-2 dash_container">
              <span className="core_div_head">2FA History</span>
              <div className="table-history mt-5">
                <div className="table-responsive table-borderless">
                  <table>
                    <thead>
                      <th scope="col" className="starts">
                        Data & Time
                      </th>
                      <th scope="col">Ip Address</th>
                      <th scope="col">Browser</th>
                      <th scope="col" className="ends">
                        Status
                      </th>
                    </thead>
                    <tbody>
                      {TfaHistory.length > 0 ? (
                        TfaHistory.map((item, i) => {
                          return (
                            <tr className="history_tabel_bottom_border">
                              <td>{item.createdDate}</td>
                              <td>{item.ipAddress}</td>
                              <td>{item.browser}</td>
                              <td>
                                {item.status == "disabled" ? (
                                  <span className="text-red">Disabled</span>
                                ) : (
                                  <span className="text-green">enabled</span>
                                )}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <td colSpan={4}>
                          <div className="empty_data">
                            <div className="empty_data_img">
                              <img
                                src={require("../images/No-data.png")}
                                width="100px"
                              />
                            </div>

                            <div className="no_records_text">
                              No Records Found
                            </div>
                          </div>
                        </td>
                      )}
                    </tbody>
                  </table>
                </div>
                {TfaHistory && TfaHistory.length > 0 ? (
                  <div className="pagination">
                    <Stack spacing={2}>
                      <Pagination
                        count={Math.ceil(tfatotalpage / tfarecordpage)}
                        page={tfacurrentpage}
                        onChange={handlepagetfachange}
                        renderItem={(item) => (
                          <PaginationItem
                            slots={{
                              previous: ArrowBackIcon,
                              next: ArrowForwardIcon,
                            }}
                            {...item}
                          />
                        )}
                      />
                    </Stack>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          </>
                    )}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
