import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { Link, useNavigate } from "react-router-dom";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Moment from "moment";
import { InfinitySpin } from "react-loader-spinner";

function Dashboard() {
  const [withdrawHistory, setwithdrawHistory] = useState([]);
  const [siteLoader, setSiteLoader] = useState(false);

  const [withdrawcurrentpage, setwithdrawcurrentpage] = useState(1);
  const [withdrawtotalpage, setwithdrawTotalpages] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    getwithdrawHistory(1);
  }, [0]);

  const getwithdrawHistory = async (page) => {
    var data = {
      apiUrl: apiService.withdraw_history,
      payload: { FilPerpage: 5, FilPage: page },
    };
    setSiteLoader(true);
    var withdraw_history_list = await postMethod(data);
    setSiteLoader(false);
    console.log(withdraw_history_list.pages,"withdraw_history_list.pages")
    if (withdraw_history_list) {
      setwithdrawHistory(withdraw_history_list.result);
      setwithdrawTotalpages(withdraw_history_list.pages);
    }
  };

  const withdrawrecordpage = 5;
  const withdrawpagerange = 5;

  const handlepagewithdraw = (event, page) => {
    getwithdrawHistory(page);
    setwithdrawcurrentpage(page);
  };

  const copy = async (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Address copied");
  };
  return (
    <div>
      <Header />
      <div className="container-fluid innerpages">
        <div className="row">


        {siteLoader == true ? (
                        <div className="dashboard_content_section loadercss">
                            <InfinitySpin
                                height="300"
                                width="300"
                                color="#12d5d5"
                                ariaLabel="infinity-spin-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                            />
                        </div>
                    ) : (

                        <>


          <div className="col-lg-2 col-sm-3">
            <Sidebar />
          </div>
          <div className="col-lg-10 col-md-12">
            <div className=" mx-2 dash_container">
              <span className="core_div_head">Withdraw History</span>
              <div className="table-history mt-5">
                <div className="table-responsive table-borderless">
                  <table>
                    <thead>
                      <th scope="col" className="starts">
                        Transaction ID
                      </th>
                      <th scope="col">Amount</th>
                      <th scope="col">Currency</th>
                      <th scope="col">Date</th>
                      <th scope="col" className="ends">
                        Status
                      </th>
                    </thead>
                    <tbody>
                      {withdrawHistory.length > 0 ? (
                        withdrawHistory.map((data, i) => {
                          console.log(data)
                          return (
                            <tr className="history_tabel_bottom_border">
                              <td>
                                {" "}
                                {data.txn_id != undefined
                                  ? data.txn_id.slice(0, 10)
                                  : "-"}{" "}
                                {""}
                                <i
                                  class="fa fa-clone"
                                  aria-hidden="true"
                                  onClick={() => copy(data.txn_id)}
                                  style={{
                                    cursor: "pointer",
                                  }}
                                ></i>
                              </td>
                              <td>
                                {" "}
                                {data.amount == undefined
                                  ? 0
                                  : data.amount.toFixed(4)}
                              </td>
                              <td>{data.currency}</td>
                              <td>{Moment(data.created_at).format("lll")}</td>
                              <td>
                                {data.txn_id != "--------" ? (
                                  <span className="text-green">Completed</span>
                                ) : (
                                  <span className="text-red">Pending</span>
                                )}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <td colSpan={5}>
                          <div className="empty_data">
                            <div className="empty_data_img">
                              <img src={require("../images/No-data.png")} width='100px' />
                            </div>

                            <div className="no_records_text">
                              No Records Found
                            </div>
                          </div>
                        </td>
                      )}
                    </tbody>
                  </table>
                </div>
                {withdrawHistory && withdrawHistory.length > 0 ? (
                  <div className="pagination">
                    <Stack spacing={2}>
                      <Pagination
                        count={Math.ceil(
                          withdrawtotalpage / withdrawrecordpage
                        )}
                        page={withdrawcurrentpage}
                        onChange={handlepagewithdraw}
                        renderItem={(item) => (
                          <PaginationItem
                            slots={{
                              previous: ArrowBackIcon,
                              next: ArrowForwardIcon,
                            }}
                            {...item}
                          />
                        )}
                      />
                    </Stack>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          </>
                    )}

        </div>
      </div>
    </div>
  );
}

export default Dashboard;
