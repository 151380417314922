import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { Link, useNavigate } from "react-router-dom";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Moment from "moment";
import { InfinitySpin } from "react-loader-spinner";

function Dashboard() {
  const [launchpadHistory, setlaunchpadHistory] = useState([]);
  const [siteLoader, setSiteLoader] = useState(false);

  const [launchpadcurrentpage, setlaunchpadcurrentpage] = useState(1);
  const [launchpadtotalpage, setlaunchpadTotalpages] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    getLaunchpadHistory(1);
  }, [0]);

  const getLaunchpadHistory = async (page) => {
    var data = {
      apiUrl: apiService.launchpad_history,
      payload: { FilPerpage: 5, FilPage: page },
    };
    setSiteLoader(true);
    var withdraw_history_list = await postMethod(data);
    setSiteLoader(false);
    if (withdraw_history_list) {
      setlaunchpadHistory(withdraw_history_list.result);
      setlaunchpadTotalpages(withdraw_history_list.pages);
    }
  };

  const launchpadrecordpage = 5;
  const launchpadpagerange = 5;

  const handlepagelaunchpad = (event, page) => {
    setlaunchpadHistory(page);
    setlaunchpadcurrentpage(page);
  };
  const showsuccessToast = (message) => {
    toast.dismiss();
    toast.success(message);
  };

  const showErrorToast = (message) => {
    toast.dismiss();
    toast.error(message);
  };
  const claimNow = async (data) => {
    console.log(data);
    var obj = {
      clamingstatus: data.clamingstatus,
      date: data.date,
      enddate: data.enddate,
      rewardAmount: data.rewardAmount,
      stakeAmout: data.stakeAmout,
      stakecurrencyName: data.stakecurrencyName,
      tokenName: data.tokenName,
      id: data.id,
      tokenId:data.rewardcurrencyid
    };
    var data = {
      apiUrl: apiService.claimlaunchtoken,
      payload: obj,
    };
    var resp = await postMethod(data);
    if (resp.status == true) {
      getLaunchpadHistory(1);
      showsuccessToast(resp.Message);
    } else {
      showErrorToast(resp.Message);
    }
  };

  return (
    <div>
      <Header />
      <div className="container-fluid innerpages">
        <div className="row">
          {siteLoader == true ? (
            <div className="dashboard_content_section loadercss">
              <InfinitySpin
                height="300"
                width="300"
                color="#12d5d5"
                ariaLabel="infinity-spin-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          ) : (
            <>
              <div className="col-lg-2 col-sm-3">
                <Sidebar />
              </div>
              <div className="col-lg-10 col-md-12">
                <div className=" mx-2 dash_container">
                  <span className="core_div_head">launchPad History</span>
                  <div className="table-history mt-5">
                    <div className="table-responsive table-borderless">
                      <table>
                        <thead>
                          <th scope="col" className="starts">
                            Token Name
                          </th>
                          <th scope="col">Reward Amount</th>
                          <th scope="col">Stake Amount</th>
                          <th scope="col">Start Date</th>
                          <th scope="col">End Date</th>
                          <th scope="col">Date</th>
                          <th scope="col" className="ends">
                            Status
                          </th>
                        </thead>
                        <tbody>
                          {launchpadHistory.length > 0 ? (
                            launchpadHistory.map((data, i) => {
                              console.log(data, "==-=-=-=-=");
                              return (
                                <tr className="history_tabel_bottom_border">
                                  <td>
                                    {" "}
                                    {data.tokenName}
                                    {""}
                                  </td>
                                  <td>
                                    {" "}
                                    {data.rewardAmount} {""} {data.tokenName}
                                  </td>
                                  <td>
                                    {data.stakeAmout} {""}
                                    {data.stakecurrencyName}
                                  </td>
                                  <td>
                                    {Moment(data.startdate).format("lll")}
                                  </td>
                                  <td>{Moment(data.enddate).format("lll")}</td>
                                  <td>{Moment(data.date).format("lll")}</td>

                                  {data.clamingstatus == "Pending" ? (
                                    <td className="pendingcolor">Pending</td>
                                  ) : data.clamingstatus == "Claim Now" ? (
                                    <td
                                      className="claimcolor"
                                      onClick={() => claimNow(data)}
                                    >
                                      Claim Now
                                    </td>
                                  ) : (
                                    <td className="claimedcolor">Claimed</td>
                                  )}
                                </tr>
                              );
                            })
                          ) : (
                            <td colSpan={5}>
                              <div className="empty_data">
                                <div className="empty_data_img">
                                  <img
                                    src={require("../images/No-data.png")}
                                    width="100px"
                                  />
                                </div>

                                <div className="no_records_text">
                                  No Records Found
                                </div>
                              </div>
                            </td>
                          )}
                        </tbody>
                      </table>
                    </div>
                    {launchpadHistory && launchpadHistory.length > 0 ? (
                      <div className="pagination">
                        <Stack spacing={2}>
                          <Pagination
                            count={Math.ceil(
                              launchpadtotalpage / launchpadrecordpage
                            )}
                            page={launchpadcurrentpage}
                            onChange={handlepagelaunchpad}
                            renderItem={(item) => (
                              <PaginationItem
                                slots={{
                                  previous: ArrowBackIcon,
                                  next: ArrowForwardIcon,
                                }}
                                {...item}
                              />
                            )}
                          />
                        </Stack>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
