import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { Link, useNavigate } from "react-router-dom";
import apiService from "../core/service/detail";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getMethod, postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import Moment from "moment";

function Dashboard() {
    const [siteLoader, setSiteLoader] = useState(false);

    const [perpage, setperpage] = useState(5);
    const [activeOrderDatas, setActiveOders] = useState([]);
    const [tradeHistoryData, settradeHistory] = useState([]);
    const [cancelOrders, setcancelOrders] = useState([]);
    const [stopOrders, setstopOrders] = useState([]);
    const [withdrawHistory, setwithdrawHistory] = useState([]);
    const [depositHistory, setdepositHistory] = useState([]);
    const [sessionHistory, setsessionHistory] = useState([]);
    const [TfaHistory, setTfaHistory] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalpages] = useState(0);

    const [totalactive, settotalactive] = useState(0);
    const [currentPagecan, setCurrentPagecan] = useState(1);
    const [totalcan, settotalcan] = useState(0);
    const [currentPageHis, setcurrentPageHis] = useState(1);
    const [totalHist, settotalHist] = useState(0);
    const [currentPagestop, setCurrentPagestop] = useState(1);
    const [totalStop, settotalStop] = useState(0);

    const [depositcurrentpage, setdepositcurrentpage] = useState(1);
    const [deposittotalpage, setdepositTotalpages] = useState(0);

    const [withdrawcurrentpage, setwithdrawcurrentpage] = useState(1);
    const [withdrawtotalpage, setwithdrawTotalpages] = useState(0);

    const [logincurrentpage, setlogincurrentpage] = useState(1);
    const [logintotalpage, setloginTotalpages] = useState(0);

    const [tfacurrentpage, settfacurrentpage] = useState(1);
    const [tfatotalpage, settfaTotalpages] = useState(0);

    const recordPerPage = 5;
    const pageRange = 5;
    const recordPerPagecan = 5;
    const pageRangecan = 5;

    const recordPerPageHist = 5;
    const pageRangeHis = 5;

    const recordPerPageStop = 5;
    const pageRangeStop = 5;

    const recordPerPageLogin = 5;
    const pageRangeLogin = 5;

    const navigate = useNavigate();

    useEffect(() => {
        getCancelOrders(1);
    }, [0]);

    const getCancelOrders = async (pages) => {
        try {
            var obj = {
                FilPerpage: perpage,
                FilPage: pages,
            };
            var data = {
                apiUrl: apiService.getCancelOrders,
                payload: obj,
            };
            var resp = await postMethod(data);
            if (resp.status) {
                setcancelOrders(resp.result);
                settotalcan(resp.count);
            } else {
            }
        } catch (error) { }
    };

    const cancelPageChange = (event, pageNumber) => {
        setCurrentPagecan(pageNumber); // call API to get data based on pageNumber
        getCancelOrders(pageNumber);
    };

    return (
        <div>
            <Header />
            <div className="container-fluid innerpages">
                <div className="row">
                    <div className="col-lg-2 col-sm-3">
                        <Sidebar />
                    </div>
                    <div className="col-lg-10 col-md-12">
                        <div className=" mx-2 dash_container">
                            <span className="core_div_head">Cancel-Order History</span>
                            <div className="table-history mt-5">
                                <div className="table-responsive table-borderless">
                                    <table>
                                        <thead>
                                            <th scope="col" className="starts">
                                                Date
                                            </th>
                                            <th scope="col">Pair</th>
                                            <th scope="col">Type</th>
                                            <th scope="col">Side</th>
                                            <th scope="col">Quantity</th>
                                            <th scope="col">Price</th>
                                            <th scope="col">Total</th>
                                            <th scope="col" className="ends">
                                                Status
                                            </th>
                                        </thead>
                                        <tbody>
                                            {cancelOrders?.length > 0 ? (
                                                cancelOrders.map((item, i) => {
                                                    var total =
                                                        item.ordertype == "Stop"
                                                            ? +item.filledAmount * +item.stoporderprice
                                                            : +item.filledAmount * +item.price;
                                                    var price =
                                                        item.ordertype == "Stop"
                                                            ? +item.stoporderprice
                                                            : +item.price;
                                                    return (
                                                        <tr className="history_tabel_bottom_border">
                                                            <td>{item.createddate}</td>
                                                            <td>{item.pairName}</td>
                                                            <td>{item.ordertype}</td>
                                                            <td>{item.tradeType}</td>
                                                            <td>
                                                                {item.filledAmount} {item.firstSymbol}
                                                            </td>
                                                            <td>
                                                                {price} {item.toSymbol}
                                                            </td>
                                                            <td>
                                                                {total} {item.toSymbol}
                                                            </td>
                                                            <td>
                                                                <span className="text-red">Cancelled</span>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <td colSpan={9}>
                                                    <div className="empty_data">
                                                        <div className="empty_data_img">
                                                            <img src={require("../images/No-data.png")} />
                                                        </div>

                                                        <div className="no_records_text">
                                                            No Records Found
                                                        </div>
                                                    </div>
                                                </td>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                {cancelOrders && cancelOrders.length > 0 ? (
                                    <div className="pagination">
                                        <Stack spacing={2}>
                                            <Pagination
                                                count={Math.ceil(totalcan / recordPerPagecan)}
                                                page={currentPagecan}
                                                onChange={cancelPageChange}
                                                renderItem={(item) => (
                                                    <PaginationItem
                                                        slots={{
                                                            previous: ArrowBackIcon,
                                                            next: ArrowForwardIcon,
                                                        }}
                                                        {...item}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
