import React from "react";
import Header from "./Header";
import { Link, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import { postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { toast } from "react-toastify";

function Login() {
  const [OTP, setOTP] = useState("");
  const [buttonLoader, setbuttonLoader] = useState(false);

  const navigate = useNavigate();

  const preventInvalidKeys = (event) => {
    console.log("--");
    if (["e", "E", "+", "-"].includes(event.key)) {
      event.preventDefault();
    }
    if (event.key === " " || event.key === "Tab") {
      event.preventDefault();
    }
    if (event.target.value.length > 6) {
      event.preventDefault();
    }
  };

  const submit = async () => {
    try {
      if (OTP !== "") {
        console.log(OTP, "otp-=-=-");
        var obj = {
          emailOtp: OTP,
          email: localStorage.getItem("useremail"),
        };

        var data = {
          apiUrl: apiService.emailotpverify,
          payload: obj,
        };
        setbuttonLoader(true);
        var resp = await postMethod(data);
        setbuttonLoader(false);
        if (resp.status == true) {
          showsuccessToast(resp.Message);
          navigate("/login");
        } else {
          showerrorToast(resp.Message);
        }
      } else {
        showerrorToast("Enter OTP");
      }
    } catch (error) {
      setbuttonLoader(false);
    }
  };

  const resendOTP = async (payload) => {
    try {
      var obj = {
        email: localStorage.getItem("useremail"),
      };

      var data = {
        apiUrl: apiService.resendCode,
        payload: obj,
      };
      setbuttonLoader(true);

      var resp = await postMethod(data);
      setbuttonLoader(false);
      if (resp.status) {
        showsuccessToast(resp.Message);
      } else {
        showerrorToast(resp.Message);
      }
    } catch (error) {
      setbuttonLoader(false);
    }
  };

  const showsuccessToast = (message) => {
    toast.dismiss();
    toast.success(message);
  };

  const showerrorToast = (message) => {
    toast.dismiss();
    toast.error(message);
  };

  const [siteLoader, setSiteLoader] = useState(false);

  return (
    <div>
      <Header />
      <div className="reg_main">
        <div className="container">
          <div className="row reg_row">
            <div className="col-lg-6 mt-2">
              <img
                src={require("../images/login-image.png")}
                alt="Core products"
                width="100%"
              />
            </div>
            <div className="col-lg-6 mt-2">
              <div className="reg_right_main">
                <span className="reg_left_head">Verify Your Account</span>
                <div className="reg_in_main">
                  <div className="log_in_top">
                    <div className="reg_infields">
                      <div className="req_field_sin">
                        <span className="earn_divs_insubhead">Email OTP</span>
                        <input
                          type="number"
                          name="otp"
                          min={1000}
                          max={9999}
                          pattern="\S*"
                          value={OTP}
                          onKeyDown={preventInvalidKeys}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value.length <= 4) {
                              setOTP(value);
                            }
                          }}
                          className="reg_inp_field"
                          placeholder="Enter your OTP "
                        />
                      </div>
                    </div>
                    <div className="reg_bot_div">
                      <div>
                        <span className="slider_left_innerbot">
                          <Link to="" onClick={resendOTP}>
                            {" "}
                            Resent OTP ?{" "}
                          </Link>
                        </span>
                      </div>
                      <div className="reg_submit mt-3" onClick={submit}>
                        {buttonLoader == false ? (
                          <span className="hero_getstarted">Verify</span>
                        ) : (
                          <span className="hero_getstarted">Loading ...</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
