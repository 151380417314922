import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import Sidebar from "./Sidebar";

import { InfinitySpin } from "react-loader-spinner";
import moment from "moment";
import Button from "@mui/material/Button";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import apiService from "../core/service/detail";
import { Link, useNavigate } from "react-router-dom";
import { getMethod, postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";

function Dashboard() {
    const [stakestage, setstakestage] = useState("flexible");

    const [stakeHistory, setstakeHistory, stakeHistoryref] = useState([]);
    const [siteLoader, setSiteLoader] = useState(false);


    useEffect(() => {
        getStakingHistory(1);
    }, [0]);

    const [historyLoader, sethistoryLoader] = useState(false);

    const getStakingHistory = async (page) => {
        try {
            sethistoryLoader(true);

            var data = {
                apiUrl: apiService.getAllstakingHistory,
                payload: { type: "Fixed", FilPerpage: 5, FilPage: page },
            };
            setSiteLoader(true);
            var resp = await postMethod(data);
            setSiteLoader(false);
            sethistoryLoader(false);

            if (resp.status) {
                console.log(resp.data, "=-=-resp-=-=-=resp-=-resp.data");
                setstakeHistory(resp.data);
                settotal(resp.total);
            }
        } catch (error) {
            // toast.error("Please try again later");
        }
    };

    const claimNow = async (claimData) => {
        console.log("0998098908908");
        try {
            var obj = {
                _id: claimData._id,
            };
            var data = {
                apiUrl: apiService.claimNowapi,
                payload: obj,
            };
            var resp = await postMethod(data);
            if (resp.status) {
                toast.success(resp.Message);
                getStakingHistory(1);
            } else {
                toast.error(resp.Message);
            }
        } catch (error) {
            // toast.error("Please try again later");
        }
    };

    const claimNowFlexible = async (claimData) => {
        try {
            var obj = {
                _id: claimData._id,
            };
            var data = {
                apiUrl: apiService.claimNowapiFlexible,
                payload: obj,
            };
            var resp = await postMethod(data);
            if (resp.status) {
                toast.success(resp.Message);
                getStakingHistory(1);
            } else {
                toast.error(resp.Message);
            }
        } catch (error) {
            // toast.error("Please try again later");
        }
    };

    const [currentPage, setCurrentPage] = useState(1);
    const [total, settotal] = useState(5);
    const recordPerPage = 5;
    const pageRange = 5;
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber); // call API to get data based on pageNumber
        getStakingHistory(pageNumber);
    };

    return (
        <div>
            <Header />
            <div className="container-fluid innerpages">
                <div className="row">
                    {siteLoader == true ? (
                        <div className="dashboard_content_section loadercss">
                            <InfinitySpin
                                height="300"
                                width="300"
                                color="#12d5d5"
                                ariaLabel="infinity-spin-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                            />
                        </div>
                    ) : (
                        <>
                            <div className="col-lg-2 col-sm-3">
                                <Sidebar />
                            </div>
                            <div className="col-lg-10 col-md-12">
                                <div className=" mx-2 dash_container">
                                    <span className="core_div_head">Staking History</span>
                                    <div className="table-history mt-5">
                                        <div className="table-responsive table-borderless">
                                            <table>
                                                <thead>
                                                    <th scope="col" className="starts">
                                                        Package
                                                    </th>
                                                    <th scope="col">Total Amount</th>
                                                    <th scope="col">APY/APR</th>
                                                    <th scope="col">Type</th>
                                                    <th scope="col">Interest Cycle</th>
                                                    <th scope="col">Stake Date</th>
                                                    <th scope="col"> Stake End Date</th>
                                                    <th scope="col">Next Claim Date</th>
                                                    <th scope="col">Total Interest</th>

                                                    <th scope="col" className="ends">
                                                        Int. per Cycle
                                                    </th>
                                                </thead>
                                                <tbody>
                                                    {stakeHistoryref.current &&
                                                        stakeHistoryref.current?.length > 0 ? (
                                                        stakeHistoryref.current.map((item, i) => {
                                                            var startdate = moment(item.startDate).format(
                                                                "DD/MM/YYYY"
                                                            );
                                                            var endtdate = moment(item.endDate).format(
                                                                "DD/MM/YYYY"
                                                            );

                                                            var get_time = new Date(item.date).getTime();
                                                            var interest_cycle =
                                                                item.type == "fixed" ? item.stakingPlan : "";

                                                            var added_date =
                                                                get_time +
                                                                +interest_cycle * 24 * 60 * 60 * 1000;

                                                            var claim_date = "";
                                                            if (item.type == "fixed") {
                                                                claim_date = item.endDate;
                                                            } else {
                                                                claim_date = "-";
                                                            }

                                                            claim_date =
                                                                moment(claim_date).format("DD/MM/YYYY");

                                                            return (
                                                                <tr className="history_tabel_bottom_border">
                                                                    <td>
                                                                        <img
                                                                            src={item.currencyImage}
                                                                            width="30px"
                                                                        />{" "}
                                                                        {item.stakeCurrencsymbol}
                                                                    </td>
                                                                    <td>
                                                                        {item.stakeAmont} {item.stakeCurrencsymbol}
                                                                    </td>

                                                                    <td>{item.currentAPY}% </td>
                                                                    <td>{item.type}</td>
                                                                    <td>
                                                                        {item.type == "fixed"
                                                                            ? item.stakingPlan + " days"
                                                                            : "-"}{" "}
                                                                        {/* {item.type == "flexible" ? "-" : "-"}{" "} */}
                                                                    </td>
                                                                    <td>{startdate}</td>

                                                                    <td>
                                                                        {item.type == "fixed" ? endtdate : "-"}
                                                                    </td>
                                                                    <td>
                                                                        {item.type == "fixed" ? claim_date : "-"}
                                                                    </td>
                                                                    <td>
                                                                        {parseFloat(item.totalInterest).toFixed(6)}{" "}
                                                                        {item.stakeCurrencsymbol}
                                                                    </td>
                                                                    <td>
                                                                        {item.type == "fixed" ? (
                                                                            <div className="minimum">
                                                                                <p>
                                                                                    {parseFloat(
                                                                                        item.totalInterest
                                                                                    ).toFixed(6)}{" "}
                                                                                    {item.stakeCurrencsymbol}
                                                                                </p>
                                                                                {item.status == 1 ? (
                                                                                    <button
                                                                                        className="active"
                                                                                        onClick={() => claimNow(item)}
                                                                                    >
                                                                                        Claim Now
                                                                                    </button>
                                                                                ) : item.status == 0 ? (
                                                                                    <button className="notactive">
                                                                                        {" "}
                                                                                        Claim
                                                                                    </button>
                                                                                ) : (
                                                                                    <button className="notactive">
                                                                                        {" "}
                                                                                        Claimed
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                        ) : (
                                                                            <div className="minimum">
                                                                                <p>
                                                                                    {parseFloat(
                                                                                        item.totalInterest
                                                                                    ).toFixed(6)}{" "}
                                                                                    {item.stakeCurrencsymbol}
                                                                                </p>
                                                                                {item.status == 0 ? (
                                                                                    <button
                                                                                        className="active"
                                                                                        onClick={() =>
                                                                                            claimNowFlexible(item)
                                                                                        }
                                                                                    >
                                                                                        Claim Now
                                                                                    </button>
                                                                                ) : (
                                                                                    <button className="notactive">
                                                                                        Claimed
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    ) : (
                                                        <td colSpan={9}>
                                                            <div className="empty_data">
                                                                <div className="empty_data_img">
                                                                    <img src={require("../images/No-data.png")} />
                                                                </div>

                                                                <div className="no_records_text">
                                                                    No Records Found
                                                                </div>
                                                            </div>
                                                        </td>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        {stakeHistoryref.current &&
                                            stakeHistoryref.current.length > 0 ? (
                                            <div className="pagination">
                                                <Stack spacing={2}>
                                                    <Pagination
                                                        count={Math.ceil(total / recordPerPage)}
                                                        page={currentPage}
                                                        onChange={handlePageChange}
                                                        renderItem={(item) => (
                                                            <PaginationItem
                                                                slots={{
                                                                    previous: ArrowBackIcon,
                                                                    next: ArrowForwardIcon,
                                                                }}
                                                                {...item}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
