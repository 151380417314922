import React, { useEffect, useState } from "react";
import Header from "./Header";
import { toast } from "react-toastify";
import { getMethod, postMethod } from "../core/service/common.api";
import { env } from "../core/service/envconfig";
import apiService from "../core/service/detail";
import moment from "moment";

const AboutUs = () => {
  useEffect(() => {
    getDetails();
  }, []);
  const [pageDetails, setpageDetails] = useState("");
  var getDetails = async () => {
    try {
      var obj = {
        key: "About",
      };

      var data = {
        apiUrl: apiService.terms,
        payload: obj,
      };
      var resp = await postMethod(data);
      setpageDetails(resp.data);
    } catch (err) {
      console.log(err, "terms");
    }
  };

  return (
    <div>
      <Header />

      <div className="container">
        <div className="privacy">
          <h2>Welcome to Nexus Pro</h2>
          <p
            dangerouslySetInnerHTML={{
              __html: pageDetails.content_description,
            }}
          ></p>

          {/* <p>
                        At Nexus Pro, we are committed to revolutionizing the cryptocurrency trading experience with a focus on security, innovation, and user satisfaction. Our comprehensive platform offers a range of services tailored to meet the diverse needs of crypto enthusiasts and traders alike.
                    </p>

                    <h3>Our Services</h3>
                    <ul>
                        <li>
                            <strong>Trading and Swapping</strong>
                            <ul>
                                <li><strong>Trade:</strong> Access a wide variety of cryptocurrencies and trade with confidence and ease.</li>
                                <li><strong>Swap:</strong> Swap between different cryptocurrencies quickly and conveniently.</li>
                            </ul>
                        </li>
                        <li>
                            <strong>Staking</strong>
                            <ul>
                                <li><strong>Flexible Staking:</strong> Stake your assets with the flexibility to withdraw at any time.</li>
                                <li><strong>Fixed Staking:</strong> Optimize your returns by staking your assets for a fixed period.</li>
                            </ul>
                        </li>
                        <li>
                            <strong>Launchpad and Launchpool</strong>
                            <ul>
                                <li><strong>Launchpad:</strong> Participate in the launch of exciting new projects in the cryptocurrency space.</li>
                                <li><strong>Launchpool:</strong> Earn rewards by staking your assets in our launchpool initiatives.</li>
                            </ul>
                        </li>
                        <li>
                            <strong>Security</strong>
                            <ul>
                                <li><strong>Advanced Security Features:</strong> We prioritize the security of your funds with robust measures including Two-Factor Authentication (2FA) and Anti-Phishing protections.</li>
                            </ul>
                        </li>
                    </ul>

                    <h3>Our Mission</h3>
                    <p>
                        Our mission at Nexus Pro is to empower individuals and businesses by providing a secure, reliable, and innovative platform for cryptocurrency trading and management. We are dedicated to safeguarding your assets and delivering an unparalleled trading experience.
                    </p>

                    <h3>Our Vision</h3>
                    <p>
                        We envision a future where cryptocurrency is seamlessly integrated into everyday life, accessible to everyone. By continuously enhancing our platform and services, we aim to lead the way in shaping this transformative industry.
                    </p>

                    <h3>Why Choose Nexus Pro?</h3>
                    <p>
                        Whether you're a seasoned trader or new to the world of cryptocurrencies, Nexus Pro is your trusted partner. Join us and discover the future of digital asset trading with unparalleled security, efficiency, and support.
                    </p>

                    <h3>Contact Us</h3>
                    <p>
                        For any inquiries or support, please don't hesitate to reach out to us at <a href="mailto:support@xchange.com">support@xchange.com</a>.
                    </p> */}
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
