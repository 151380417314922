import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { InfinitySpin } from "react-loader-spinner";
import Moment from "moment";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
function Dashboard() {
  const navigate = useNavigate();

  const [siteLoader, setSiteLoader] = useState(false);

  const [allCurrency, setallCurrency, allCurrencyref] = useState([]);
  const [allCrypto, setallCrypto, allCryptoref] = useState([]);
  const [currency, setcurrency, currencyref] = useState("");
  const [cointype, setcointype, cointyperef] = useState("");
  const [balance, setBalance, balanceref] = useState("");
  const [view, setview, viewref] = useState(false);
  const [bankwire, setBankwire] = useState("");
  const [newAddres, setnewAddres] = useState("");
  const [newAddresErr, setnewAddresErr] = useState("");
  const [withdrawHistory, setwithdrawHistory] = useState([]);
  const [currentcurrency, setcurrentcurrency, currentcurrencyref] =
    useState("");
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [withdrawType, setwithdrawType] = useState("");

  const [network_currency, setcur_network, network_currencyref] = useState([]);
  const [network_default, setnet_default, net_defaultref] = useState("");
  const [network_current, setnet_current, network_currentref] = useState("");
  const [show_otp, setshow_otp, show_otpref] = useState(false);

  const bankdetails = () => {};
  const withdrawAction = async (data) => {
    var obj = {
      withdraw_id: data,
    };
    var data = {
      apiUrl: apiService.confirmWithdraw,
      payload: obj,
    };
    var resp = await postMethod(data);
    if (resp.status) {
      showsuccessToast(resp.message, {
        toastId: "3",
      });
      //window.location.href = "/transaction";
      navigate("/withdraw");
    } else {
      showerrorToast(resp.message, {
        toastId: "3",
      });
      navigate("/withdraw");
    }
  };



  const qry_search = useLocation().search;
  const confirmation = new URLSearchParams(qry_search).get("transaction");
  if (confirmation != "" && confirmation != null) {
    withdrawAction(confirmation);
  }

  const initialFormValue = {
    amount: "",
    withAddress: "",
    tfa: "",
    withdraw_otp: "",
  };

  const [formValue, setFormValue] = useState(initialFormValue);
  const [amountValidate, setamountValidate] = useState(false);
  const [withAddressValidate, setwithAddress] = useState(false);
  const [tfaValidate, settfaValidate] = useState(false);
  const [otpValidate, setotpValidate] = useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [sitekycStatus, setsitekycStatus, sitekycStatusref] =
    useState("DeActive");
  const [kycStatus, setkycStatus, kycStatusref] = useState(1);

  const [addresshide, setaddresshide, addresshideref] = useState("Deactive");

  const { amount, withAddress, tfa, withdraw_otp } = formValue;

  useEffect(() => {
    getKYCstatus();
    getAllcurrency();
    getwithdrawHistory(1);
    getAddress();
    // getSiteSettingstatus();
    var status = localStorage.getItem("withdraw_status");
    if (status == null) {
      localStorage.setItem("withdraw_status", false);
    }
    if (status == "false") {
      setalertTab("show");
    } else {
      setalertTab("remove");
    }
  }, [0]);

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    validate(formData);
  };

  const preventInvalidKeys = (event) => {
    console.log("--");
    if (["e", "E", "+", "-"].includes(event.key)) {
      event.preventDefault();
    }
    if (event.key === " " || event.key === "Tab") {
      event.preventDefault();
    }
    if (event.target.value.length > 9) {
      event.preventDefault();
    }
  };
  const addresshides = async (e) => {
    if (addresshideref.current == "Deactive") {
      setaddresshide("Active");
    } else {
      setaddresshide("Deactive");
    }
  };

  const getAllcurrency = async () => {
    var data = {
      apiUrl: apiService.walletcurrency,
    };
    var resp = await getMethod(data);
    if (resp) {
      var currArrayCrypto = [];
      var data = resp.data;
      setallCrypto(data);
      for (var i = 0; i < data.length; i++) {
        if (data[i].withdrawStatus == "Active") {
          var obj = {
            value: data[i]._id,
            label: data[i].currencySymbol,
            coinType: data[i].coinType,
            key: data[i]._id,
            text: data[i].currencySymbol,
            image: { avatar: true, src: data[i].Currency_image },
            erc20token: data[i].erc20token,
            bep20token: data[i].bep20token,
            trc20token: data[i].trc20token,
            rptc20token: data[i].rptc20token,
          };
          currArrayCrypto.push(obj);
        }
      }
      setallCurrency(currArrayCrypto);
    }
  };
  const onSelect1 = async (e, option) => {
    let indexData = allCryptoref.current.findIndex(
      (x) => x._id == option.value
    );

    var currencydata = allCryptoref.current[indexData];

    console.log(currencydata, "currencydata");
    setnewCurrency(currencydata.currencySymbol);

    // setnewCurrency(option.label);
  };
  const onSelect = async (e, option) => {
    console.log(option, "option");
    formValue.withAddress = null;
    setwithdrawAddress("");
    console.log(currAddresref.current, "=-=currAddresref=--=-");
    setnet_default(null);
    setwithdrawAddress(null);
    setcur_network("");
    setnet_current("");
    getAddress();
    let indexData = allCryptoref.current.findIndex(
      (x) => x._id == option.value
    );
    if (option.label == "USD") {
      showerrorToast("Fiat withdraw is not allowed by the site");
    } else {
      if (indexData != -1) {
        var currencydata = allCryptoref.current[indexData];

        console.log(currencydata, "currencydata");

        setcurrency(currencydata.currencySymbol);
        setcointype(currencydata.coinType);
        setcurrentcurrency(currencydata);
        setcur_network([]);
        setnet_default("");
        var network_cur = {};
        var network_names = [];
        if (currencydata.currencyType == "2") {
          if (currencydata.erc20token == "1") {
            network_cur = {
              value: "erc20token",
              label: "erc20token",
              text: "ERC20",
              key: "erc20token",
            };
            network_names.push(network_cur);
          }
          if (currencydata.bep20token == "1") {
            network_cur = {
              value: "bep20token",
              label: "bep20token",
              text: "BEP20",
              key: "bep20token",
            };
            network_names.push(network_cur);
          }
          if (currencydata.trc20token == "1") {
            network_cur = {
              value: "trc20token",
              label: "trc20token",
              text: "TRC20",
              key: "trc20token",
            };
            network_names.push(network_cur);
          }

          if (currencydata.rptc20token == "1") {
            network_cur = {
              value: "rptc20token",
              label: "rptc20token",
              text: "RPTC20",
              key: "rptc20token",
            };
            network_names.push(network_cur);
          }
          setcur_network(network_names);
          // setnet_default(network_currencyref.current[0].label);
        }
        setwithdrawType(currencydata.coinType);
        // if (currencydata.coinType== "1") {
        var obj = {
          currency: currencydata.currencySymbol,
          currId: option.value,
        };

        console.log(obj, "obj");
        var data = {
          apiUrl: apiService.user_balance,
          payload: obj,
        };

        var resp = await postMethod(data);
        if (resp.status) {
          setview(true);
          setBalance(resp.data);

          console.log(resp.data, "Balanceref");
        } else {
        }
        // } else {
        // }
      }
    }
  };

  const nav_page = async (link) => {
    navigate(link);
  };

  const validate = async (values) => {
    const errors = {};
    if (!values.amount) {
      errors.amount = "Amount is a required field";
      setamountValidate(true);
    }

    if (!values.withAddress) {
      errors.withAddress = "Destination address is a required field";
      setwithAddress(true);
    }

    if (!values.tfa) {
      errors.tfa = "2FA is a required field";
      settfaValidate(true);
    }

    if (!values.withdraw_otp) {
      errors.withdraw_otp = "Withdraw OTP is a required field";
      setotpValidate(true);
    }

    setvalidationnErr(errors);
    return errors;
  };
  const validateFiat = async (values) => {
    const errors = {};
    if (!values.amount) {
      errors.amount = "Amount is a required field";
      setamountValidate(true);
    }
    if (!values.tfa) {
      errors.tfa = "2FA is a required field";
      settfaValidate(true);
    }
    if (!values.withdraw_otp) {
      errors.withdraw_otp = "Withdraw OTP is a required field";
      setotpValidate(true);
    }
    setvalidationnErr(errors);
    return errors;
  };

  const validate_preview = async (values) => {
    const errors = {};
    if (!values.amount) {
      errors.amount = "Amount is a required field";
      setamountValidate(true);
    }
    if (!values.withAddress) {
      errors.withAddress = "Destination address is a required field";
      setwithAddress(true);
    } else {
      // Basic address validation logic
      const currency = currencyref.current;
      const address = values.withAddress;

      if (!isValidAddress(address, currency)) {
        errors.withAddress = `Invalid ${currency} address`;
        setwithAddress(true);
      } else {
        setwithAddress(false);
      }
    }

    setvalidationnErr(errors);
    return errors;
  };

  const isValidAddress = (address, currency) => {
    switch (currency) {
      case "BTC":
        return isValidBTCAddress(address);
      case "ETH":
        return isValidETHAddress(address);
      case "TRX":
        return isValidTRXAddress(address);
      default:
        return isValidETHAddress(address);
    }
  };

  // Validate Bitcoin address
  const isValidBTCAddress = (address) => {
    const btcAddressRegex = /^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$/;
    console.log("BTC");
    return btcAddressRegex.test(address);
  };

  // Validate Ethereum address
  const isValidETHAddress = (address) => {
    const ethAddressRegex = /^0x[a-fA-F0-9]{40}$/;
    return ethAddressRegex.test(address);
  };

  // Validate TRON address
  const isValidTRXAddress = (address) => {
    // TRON addresses begin with 'T' and have 34 characters
    const trxAddressRegex = /^T[a-zA-Z0-9]{33}$/;
    return trxAddressRegex.test(address);
  };

  const validateFiat_preview = async (values) => {
    const errors = {};
    if (!values.amount) {
      errors.amount = "Amount is a required field";
      setamountValidate(true);
    }
    setvalidationnErr(errors);
    return errors;
  };

  const validate_submit = async (values) => {
    const errors = {};
    if (!values.tfa) {
      errors.tfa = "2FA is a required field";
      settfaValidate(true);
    }

    if (!values.withdraw_otp) {
      errors.withdraw_otp = "Withdraw OTP is a required field";
      setotpValidate(true);
    }

    setvalidationnErr(errors);
    return errors;
  };
  const validateFiat_submit = async (values) => {
    const errors = {};
    if (!values.tfa) {
      errors.tfa = "2FA is a required field";
      settfaValidate(true);
    }
    if (!values.withdraw_otp) {
      errors.withdraw_otp = "Withdraw OTP is a required field";
      setotpValidate(true);
    }
    setvalidationnErr(errors);
    return errors;
  };

  const getwithdrawHistory = async (page) => {
    var data = {
      apiUrl: apiService.withdraw_history,
      payload: { FilPerpage: 5, FilPage: page },
    };
    var withdraw_history_list = await postMethod(data);
    if (withdraw_history_list) {
      setwithdrawHistory(withdraw_history_list.result);
      setwithdrawTotalpages(withdraw_history_list.pages);
    }
  };



  const withdrawrecordpage = 5;
  const withdrawpagerange = 5;

  const [withdrawcurrentpage, setwithdrawcurrentpage] = useState(1);
  const [withdrawtotalpage, setwithdrawTotalpages] = useState(0);

  const handlepagewithdraw = (event, page) => {
    getwithdrawHistory(page);
    setwithdrawcurrentpage(page);
  };



  const getKYCstatus = async () => {
    var data = {
      apiUrl: apiService.getKYCStatus,
    };

    setSiteLoader(true);
    var getKYC = await getMethod(data);
    setSiteLoader(false);

    if (getKYC.status) {
      setkycStatus(getKYC.Message.kycstatus);
      setBankwire(getKYC.bankdatastatus);
    } else {
      // setkycStatus(0);
    }
  };

  const onSelect_network = async (e, option) => {
    setnet_current(option.label);
  };

  const onSelect_address = async (e) => {
    console.log(
      e.target.value,
      "=-=-=-=option=-=-=-=-=option=-=-=-option=-=-="
    );
    setwithdrawAddress(e.target.value);
    formValue.withAddress = e.target.value;
    setwithAddress(false);
  };

  const navigateKyc = async () => {
    navigate("/kyc");
  };

  const withdrawPreview = async () => {
    try {
      console.log(formValue, "=-=-=v=-formValue-=-formValue=-=-vformValue");
      if (
        currentcurrency.currencySymbol == "USD" ||
        currentcurrency.currencySymbol == "INR"
      ) {
        if (bankwire == 1) {
          if (withdrawType == "2") {
            validateFiat_preview(formValue);
            if (formValue.amount != "") {
              if (+formValue.amount > 0) {
                if (+balanceref.current.balance > +formValue.amount) {
                  if (currentcurrency.minWithdrawLimit > formValue.amount) {
                    showerrorToast(
                      "Please enter greater than " +
                        currentcurrency.minWithdrawLimit +
                        " amount"
                    );
                  } else if (
                    currentcurrency.maxWithdrawLimit < formValue.amount
                  ) {
                    showerrorToast(
                      "Please enter less than " +
                        currentcurrency.maxWithdrawLimit +
                        " amount"
                    );
                  } else {
                    var data = {
                      apiUrl: apiService.send_otp,
                    };
                    setbuttonLoader(true);
                    var resp = await postMethod(data);
                    if (resp.status) {
                      showsuccessToast(resp.message);
                      setbuttonLoader(false);
                      setshow_otp(true);
                    } else {
                      showerrorToast(resp.message);
                      setbuttonLoader(false);
                    }
                  }
                } else {
                  showerrorToast("Insufficient Balance!");
                  setbuttonLoader(false);
                }
              } else {
                showerrorToast("Please give valid withdraw amount!");
                setbuttonLoader(false);
              }
            }
          } else {
            validate_preview(formValue);
            if (formValue.amount != "" && formValue.withAddress != "") {
              if (+formValue.amount > 0) {
                if (+balanceref.current.balance > +formValue.amount) {
                  if (currentcurrency.minWithdrawLimit > formValue.amount) {
                    showerrorToast(
                      "Please enter greater than " +
                        currentcurrency.minWithdrawLimit +
                        " amount"
                    );
                  } else if (
                    currentcurrency.maxWithdrawLimit < formValue.amount
                  ) {
                    showerrorToast(
                      "Please enter less than " +
                        currentcurrency.maxWithdrawLimit +
                        " amount"
                    );
                  } else {
                    var data = {
                      apiUrl: apiService.send_otp,
                    };
                    setbuttonLoader(true);
                    var resp = await postMethod(data);
                    if (resp.status) {
                      showsuccessToast(resp.message);
                      setbuttonLoader(false);
                      setshow_otp(true);
                    } else {
                      showerrorToast(resp.message);
                      setbuttonLoader(false);
                    }
                  }
                } else {
                  showerrorToast("Insufficient Balance");

                  setbuttonLoader(false);
                }
              } else {
                showerrorToast("Please give valid withdraw amount!");
              }
            }
          }
        } else {
          showerrorToast("Kindly update your Bank details");
          navigate("/bankdetails");
        }
      } else {
        if (withdrawType == "2") {
          validateFiat_preview(formValue);
          if (formValue.amount != "") {
            if (+formValue.amount > 0) {
              if (+balanceref.current.balance > +formValue.amount) {
                if (currentcurrency.minWithdrawLimit > formValue.amount) {
                  showerrorToast(
                    "Please enter greater than " +
                      currentcurrency.minWithdrawLimit +
                      " amount"
                  );
                } else if (
                  currentcurrency.maxWithdrawLimit < formValue.amount
                ) {
                  showerrorToast(
                    "Please enter less than " +
                      currentcurrency.maxWithdrawLimit +
                      " amount"
                  );
                } else {
                  var data = {
                    apiUrl: apiService.send_otp,
                  };
                  setbuttonLoader(true);
                  var resp = await postMethod(data);
                  if (resp.status) {
                    showsuccessToast(resp.message);
                    setbuttonLoader(false);
                    setshow_otp(true);
                  } else {
                    showerrorToast(resp.message);
                    setbuttonLoader(false);
                  }
                }
              } else {
                showerrorToast("Insufficient Balance!");

                setwithdrawAddress("");
              }
            } else {
              showerrorToast("Please give valid withdraw amount!");
              setbuttonLoader(false);
            }
          }
        } else {
          validate_preview(formValue);
          if (formValue.amount != "" && formValue.withAddress != "") {
            if (+formValue.amount > 0) {
              if (+balanceref.current.balance > +formValue.amount) {
                if (currentcurrency.minWithdrawLimit > formValue.amount) {
                  showerrorToast(
                    "Please enter greater than " +
                      currentcurrency.minWithdrawLimit +
                      " amount"
                  );
                } else if (
                  currentcurrency.maxWithdrawLimit < formValue.amount
                ) {
                  showerrorToast(
                    "Please enter less than " +
                      currentcurrency.maxWithdrawLimit +
                      " amount"
                  );
                } else {
                  var data = {
                    apiUrl: apiService.send_otp,
                  };
                  setbuttonLoader(true);
                  var resp = await postMethod(data);
                  if (resp.status) {
                    showsuccessToast(resp.message);
                    setbuttonLoader(false);
                    setshow_otp(true);
                  } else {
                    showerrorToast(resp.message);
                    setbuttonLoader(false);
                  }
                }
              } else {
                showerrorToast("Insufficient Balance");

                setbuttonLoader(false);
              }
            } else {
              showerrorToast("Please give valid withdraw amount!");
            }
          }
        }
      }
    } catch (error) {}
  };

  const showsuccessToast = (message) => {
    toast.dismiss();
    toast.success(message);
  };

  const showerrorToast = (message) => {
    toast.dismiss();
    toast.error(message);
  };

  const withdrawSubmit = async () => {
    try {
      if (withdrawType == "2") {
        validateFiat_submit(formValue);

        if (localStorage.getItem("tfa_status") == 1) {
          if (formValue.amount != "" && formValue.tfa != "") {
            if (+formValue.amount > 0) {
              if (currentcurrency.minWithdrawLimit > formValue.amount) {
                showerrorToast(
                  "Please enter greater than " +
                    currentcurrency.minWithdrawLimit +
                    " amount"
                );
              } else if (currentcurrency.maxWithdrawLimit < formValue.amount) {
                showerrorToast(
                  "Please enter less than " +
                    currentcurrency.maxWithdrawLimit +
                    " amount"
                );
              } else {
                var obj = {
                  amount: formValue.amount,
                  tfaCode: formValue.tfa,
                  currency_symbol: currentcurrency.currencySymbol,
                  currId: currentcurrency._id,
                  withdrawOtp: formValue.withdraw_otp,
                  tfa_status: localStorage.getItem("tfa_status"),
                };
                var data = {
                  apiUrl: apiService.submitfiatWithdraw,
                  payload: obj,
                };
                setbuttonLoader(true);
                var resp = await postMethod(data);
                if (resp.status) {
                  showsuccessToast(resp.message);
                  getwithdrawHistory(1);
                  setbuttonLoader(false);
                  setshow_otp(false);
                  formValue.amount = "";
                  setwithdrawAddress("");
                  formValue.tfa = "";
                  formValue.withdraw_otp = "";
                  //window.location.reload(false);
                } else {
                  showerrorToast(resp.message);
                  setbuttonLoader(false);
                  // formValue.amount = "";
                  // formValue.withAddress = "";
                  // formValue.tfa = "";
                  // formValue.withdraw_otp = "";
                  //window.location.reload(false);
                }
              }
            } else {
              showerrorToast("Please give valid withdraw amount!");
            }
          } else {
            showerrorToast("Please give all the fields !");
          }
        } else {
          if (formValue.amount != "") {
            if (+formValue.amount > 0) {
              if (currentcurrency.minWithdrawLimit > formValue.amount) {
                showerrorToast(
                  "Please enter greater than " +
                    currentcurrency.minWithdrawLimit +
                    " amount"
                );
              } else if (currentcurrency.maxWithdrawLimit < formValue.amount) {
                showerrorToast(
                  "Please enter less than " +
                    currentcurrency.maxWithdrawLimit +
                    " amount"
                );
              } else {
                var obj = {
                  amount: formValue.amount,
                  tfaCode: formValue.tfa,
                  currency_symbol: currentcurrency.currencySymbol,
                  currId: currentcurrency._id,
                  withdrawOtp: formValue.withdraw_otp,
                  tfa_status: localStorage.getItem("tfa_status"),
                };
                var data = {
                  apiUrl: apiService.submitfiatWithdraw,
                  payload: obj,
                };
                setbuttonLoader(true);
                var resp = await postMethod(data);
                if (resp.status) {
                  showsuccessToast(resp.message);
                  getwithdrawHistory(1);
                  setbuttonLoader(false);
                  setshow_otp(false);
                  formValue.amount = "";
                  setwithdrawAddress("");
                  formValue.tfa = "";
                  formValue.withdraw_otp = "";
                  //window.location.reload(false);
                } else {
                  showerrorToast(resp.message);
                  setbuttonLoader(false);
                  // formValue.amount = "";
                  // formValue.withAddress = "";
                  // formValue.tfa = "";
                  // formValue.withdraw_otp = "";
                  //window.location.reload(false);
                }
              }
            } else {
              showerrorToast("Please give valid withdraw amount!");
            }
          } else {
            showerrorToast("Please give all the fields !");
          }
        }
      } else {
        validate_submit(formValue);

        if (localStorage.getItem("tfa_status") == 1) {
          if (
            formValue.amount != "" &&
            formValue.withAddress != "" &&
            formValue.tfa != ""
          ) {
            if (+formValue.amount > 0) {
              if (currentcurrency.minWithdrawLimit > formValue.amount) {
                showerrorToast(
                  "Please enter greater than " +
                    currentcurrency.minWithdrawLimit +
                    " amount"
                );
              } else if (currentcurrency.maxWithdrawLimit < formValue.amount) {
                showerrorToast(
                  "Please enter less than " +
                    currentcurrency.maxWithdrawLimit +
                    " amount"
                );
              } else {
                var obj = {
                  amount: formValue.amount,
                  withdraw_address: formValue.withAddress,
                  tfaCode: formValue.tfa,
                  currency_symbol: currentcurrency.currencySymbol,
                  currId: currentcurrency._id,
                  network: network_currentref.current,
                  withdrawOtp: formValue.withdraw_otp,
                  tfa_status: localStorage.getItem("tfa_status"),
                };
                // console.log("submit withdraw params---",obj);
                // return;
                var data = {
                  apiUrl: apiService.submitWithdraw,
                  payload: obj,
                };
                setbuttonLoader(true);
                var resp = await postMethod(data);
                if (resp.status) {
                  showsuccessToast(resp.message);
                  getwithdrawHistory(1);
                  setbuttonLoader(false);
                  setshow_otp(false);
                  formValue.amount = "";
                  setwithdrawAddress("");
                  formValue.tfa = "";
                  formValue.withdraw_otp = "";
                  //window.location.reload(false);
                } else {
                  showerrorToast(resp.message);
                  setbuttonLoader(false);
                  // formValue.amount = "";
                  // formValue.withAddress = "";
                  // formValue.tfa = "";
                  //window.location.reload(false);
                }
              }
            } else {
              showerrorToast("Please give valid withdraw amount!");
            }
          } else {
            showerrorToast("Please give all the fields !");
          }
        } else {
          if (formValue.amount != "" && formValue.withAddress != "") {
            if (+formValue.amount > 0) {
              if (currentcurrency.minWithdrawLimit > formValue.amount) {
                showerrorToast(
                  "Please enter greater than " +
                    currentcurrency.minWithdrawLimit +
                    " amount"
                );
              } else if (currentcurrency.maxWithdrawLimit < formValue.amount) {
                showerrorToast(
                  "Please enter less than " +
                    currentcurrency.maxWithdrawLimit +
                    " amount"
                );
              } else {
                var obj = {
                  amount: formValue.amount,
                  withdraw_address: formValue.withAddress,
                  tfaCode: formValue.tfa,
                  currency_symbol: currentcurrency.currencySymbol,
                  currId: currentcurrency._id,
                  network: network_currentref.current,
                  withdrawOtp: formValue.withdraw_otp,
                  tfa_status: localStorage.getItem("tfa_status"),
                };
                // console.log("submit withdraw params---",obj);
                // return;
                var data = {
                  apiUrl: apiService.submitWithdraw,
                  payload: obj,
                };
                setbuttonLoader(true);
                var resp = await postMethod(data);
                if (resp.status) {
                  showsuccessToast(resp.message);
                  getwithdrawHistory(1);
                  setbuttonLoader(false);
                  setshow_otp(false);
                  formValue.amount = "";
                  setwithdrawAddress("");
                  formValue.tfa = "";
                  formValue.withdraw_otp = "";
                  //window.location.reload(false);
                } else {
                  showerrorToast(resp.message);
                  setbuttonLoader(false);
                  // formValue.amount = "";
                  // formValue.withAddress = "";
                  // formValue.tfa = "";
                  //window.location.reload(false);
                }
              }
            } else {
              showerrorToast("Please give valid withdraw amount!");
            }
          }
        }
      }
    } catch (error) {}
  };

  const [newCurrency, setnewCurrency] = useState("");
  const [newCurrencyErr, setnewCurrencyErr] = useState(false);
  const [newNetwork, setnewNetwork] = useState("");
  const [alertTab, setalertTab] = useState("hide");
  const [allAddress, setallAddress, allAddressref] = useState([]);
  const [currAddres, setcurrAddres, currAddresref] = useState([]);
  const [withdrawAddress, setwithdrawAddress, withdrawAddressref] = useState();

  const getAddress = async () => {
    var data = {
      apiUrl: apiService.getAddress,
    };
    var resp = await getMethod(data);
    if (resp.status == true) {
      setallAddress(resp.data);
      var data = [];
      for (let i = 0; i < resp.data.length; i++) {
        const element = resp.data[i];

        if (element.currency) {
          console.log(element, "element");

          var dropData = {
            value: element.address,
            label: element.currency,
            key: element.currency,
            text: element.address,
          };
          data.push(dropData);
        }
        setcurrAddres(data);
      }
    } else {
      setallAddress([]);
    }
  };
  const Addaddress = async () => {
    if (newAddres == "") {
      setnewAddresErr(true);
    } else if (newCurrency == "") {
      setnewCurrencyErr(true);
      setnewAddresErr(false);
    } else {
      setnewCurrencyErr(false);
      var obj = {
        Address: newAddres,
        currency: newCurrency,
        network: newNetwork,
      };

      console.log(obj, "obj");

      var data = {
        apiUrl: apiService.Addaddress,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status == true) {
        showsuccessToast(resp.message);
        setnewAddres("");
        setaddresshide("Deactive");
        setnewCurrency("");
        setnewNetwork("");
        getAddress();
      } else {
        showerrorToast(resp.message);
      }
    }
  };

  const copy = async (text) => {
    navigator.clipboard.writeText(text);
    showsuccessToast("Address copied");
  };

  const deleteAddress = async (_id) => {
    var data = {
      apiUrl: apiService.removeAddress,
      payload: { _id: _id },
    };
    var resp = await postMethod(data);
    if (resp.status == true) {
      showsuccessToast(resp.message);
      getAddress();
    } else {
      showerrorToast(resp.message);
    }
  };

  const cancel = () => {
    setalertTab("show");
    navigate("/dashboard");
  };
  const agree = () => {
    setalertTab("remove");
    localStorage.setItem("withdraw_status", "true");
  };

  const getSiteSettingstatus = async () => {
    var data = {
      apiUrl: apiService.getSiteDatas,
    };
    var response = await getMethod(data);
    if (response.status) {
      setsitekycStatus(response.data.kycStatus);
    } else {
      // setkycStatus(0);
    }
  };

  return (
    <div>
      <Header />
      <div className="container-fluid innerpages">
        <div className="row">
          {siteLoader == true ? (
            <div className="dashboard_content_section loadercss">
              <InfinitySpin
                height="300"
                width="300"
                color="#12d5d5"
                ariaLabel="infinity-spin-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          ) : (
            <>
              <div className="col-lg-2 col-sm-3">
                <Sidebar />
              </div>
              <div className="col-lg-10 col-md-12">
                <div className="mx-2 dash_container">
                  {kycStatusref.current == 1 ? (
                    <>
                      <span className="core_div_head">Withdraw</span>
                      <div className=" row mt-4 justify-content-center">
                        <div className="col-lg-6 Depositcard">
                          <div className="">
                            <div className="input-section">
                              <div className="d-flex justify-content-between align-items-center select_curreny_text">
                                <h5 className="mt-0">Select currency</h5>

                                {show_otpref.current == false &&
                                viewref.current == true ? (
                                  <div className="balance_balance mb-2">
                                    <p className="p-0 text-white">
                                      Available Balance{" "}
                                      {balanceref.current.balance.toFixed(4)}{" "}
                                      {currencyref.current}
                                    </p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="flex_input_posion">
                                <Dropdown
                                  placeholder="Select Coin"
                                  fluid
                                  selection
                                  options={allCurrencyref.current}
                                  onChange={onSelect}
                                  defaultValue={currencyref.current}
                                  isSearchable={true}
                                />
                              </div>
                            </div>

                            {show_otpref.current == false &&
                            currentcurrencyref.current.currencyType == "2" ? (
                              <div className="input-section">
                                <h5>Choose a Network</h5>
                                <div className="flex_input_posion">
                                  <Dropdown
                                    options={network_currencyref.current}
                                    fluid
                                    selection
                                    placeholder="Select an Network"
                                    onChange={onSelect_network}
                                    defaultValue={net_defaultref.current}
                                    isSearchable={true}
                                  />
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {withdrawType == "1" &&
                            show_otpref.current == false ? (
                              <div className="input-section">
                                <h5>To Address</h5>
                                <div className="flex_input_posion">
                                  <input
                                    type="text"
                                    name="email"
                                    pattern="\S*"
                                    value={withdrawAddressref.current}
                                    onChange={onSelect_address}
                                    className="innerinuput"
                                    placeholder="Enter your address"
                                  />
                                  {/* <Dropdown
                                fluid
                                selection
                                options={currAddresref.current}
                                placeholder="Select an Address"
                                onChange={onSelect_address}
                                // defaultValue={net_defaultref.current }
                                value={withdrawAddressref.current}
                              /> */}
                                </div>
                                <div>
                                  {withAddressValidate == true ? (
                                    <p className="errorcss">
                                      {" "}
                                      {validationnErr.withAddress}{" "}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>{" "}
                              </div>
                            ) : (
                              ""
                            )}

                            {show_otpref.current == false ? (
                              <div className="input-section">
                                <h5>Amount</h5>

                                <div className="flex_input_posion">
                                  <input
                                    type="number"
                                    pattern="^\d+$"
                                    onKeyDown={preventInvalidKeys}
                                    placeholder="Enter Amount"
                                    name="amount"
                                    value={amount}
                                    onChange={handleChange}
                                    className="innerinuput"
                                  />
                                </div>

                                <div>
                                  {amountValidate == true ? (
                                    <p className="errorcss">
                                      {" "}
                                      {validationnErr.amount}{" "}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {show_otpref.current == true ? (
                              <div>
                                {localStorage.getItem("tfa_status") == 0 ||
                                1 ? (
                                  <>
                                    <div className="input-section">
                                      <h5>2FA</h5>

                                      <div className="flex_input_posion">
                                        <input
                                          type="text"
                                          pattern="\S*"
                                          autocomplete="off"
                                          placeholder="Enter 2FA Code"
                                          name="tfa"
                                          value={tfa}
                                          onChange={handleChange}
                                          className="innerinuput"
                                        />
                                      </div>

                                      <div>
                                        {tfaValidate == true ? (
                                          <p className="text-danger">
                                            {" "}
                                            {validationnErr.tfa}{" "}
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}{" "}
                                <>
                                  {" "}
                                  <div className="input-section">
                                    <h5>Withdraw OTP</h5>
                                    <div className="flex_input_posion">
                                      <input
                                        type="text"
                                        pattern="\S*"
                                        autocomplete="off"
                                        placeholder="Enter Withdraw OTP"
                                        name="withdraw_otp"
                                        value={withdraw_otp}
                                        onChange={handleChange}
                                        className="innerinuput"
                                      />
                                    </div>
                                    {otpValidate == true ? (
                                      <p className="text-danger">
                                        {" "}
                                        {validationnErr.withdraw_otp}{" "}
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </>{" "}
                              </div>
                            ) : (
                              ""
                            )}

                            <div>
                              {buttonLoader == false ? (
                                <div className="submit_btn">
                                  {localStorage.getItem("tfa_status") == 0 ? (
                                    <button
                                      className="withbutton"
                                      onClick={() =>
                                        nav_page("/security/create-tfa")
                                      }
                                    >
                                      Enable 2FA
                                    </button>
                                  ) : show_otpref.current == true ? (
                                    <button
                                      className="withbutton"
                                      onClick={() => withdrawSubmit()}
                                    >
                                      Submit
                                    </button>
                                  ) : (
                                    <button
                                      className="withbutton"
                                      onClick={() => withdrawPreview()}
                                    >
                                      Submit
                                    </button>
                                  )}
                                </div>
                              ) : (
                                <div className="submit_btn">
                                  <button className="withbutton">
                                    Loading...
                                  </button>
                                </div>
                              )}
                            </div>

                            {currentcurrencyref.current ? (
                              <div className="withcards">
                                <div className="withlist">
                                  <p>Fees</p>
                                  <p>
                                    {currentcurrencyref.current.withdrawFee} %
                                  </p>
                                </div>
                                <div className="withlist">
                                  <p>Minimum Withdraw</p>
                                  <p>
                                    {" "}
                                    {
                                      currentcurrencyref.current
                                        .minWithdrawLimit
                                    }{" "}
                                  </p>
                                </div>
                                <div className="withlist">
                                  <p>Maximum Withdraw</p>
                                  <p>
                                    {" "}
                                    {
                                      currentcurrencyref.current
                                        .maxWithdrawLimit
                                    }{" "}
                                  </p>
                                </div>
                                {balanceref.current.network != "" ? (
                                  <div className="withlist">
                                    <p>Network</p>
                                    <p>{balanceref.current.network}</p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        <div className="col-lg-6 withleft ">
                          <div className="withcards">
                            <div className=" withlist m-0 px-3">
                              <div className="d-flex align-items-center">
                                {" "}
                                <img
                                  src={require("../images/Warning.png")}
                                  width="15px"
                                  className="mr-3"
                                />{" "}
                                <p>Withdraw Note</p>{" "}
                              </div>
                              {/* <p>
                            <i class="fa-solid fa-angle-down"></i>
                          </p> */}
                            </div>
                            <div className="withwarning px-3">
                              <p>
                                Please double-check the destination address and
                                blockchain networks
                              </p>

                              <p>
                                {" "}
                                If there's an issue with the withdrawal to your
                                destination address, unfortunately, the site
                                cannot process a refund.{" "}
                              </p>

                              <p>
                                Please review carefully, as the amount appears
                                to be double.
                              </p>

                              <p>
                                You can only withdraw funds to approved
                                addresses.
                              </p>
                            </div>
                          </div>

                          {/* <div className="Depositcard">
                        <div className=" withlist m-0 ">
                          <div className="d-flex  align-items-center">
                            <h4>Add Address</h4>{" "}
                          </div>
                          <p className="addbg d-flex justify-content-center" onClick={addresshides}>
                            <span>Add</span>{" "}
                            <i class="fa-solid textg fa-circle-plus"></i>
                          </p>
                        </div>

                        {addresshideref.current == "Active" ? (
                          <>
                            <div className="input-section">
                              <div className="flex_input_posion">
                                <input
                                  type="text"
                                  name="Address"
                                  value={newAddres}
                                  onChange={(e) => setnewAddres(e.target.value)}
                                  className="innerinuput"
                                  placeholder="Enter To Address"
                                />
                              </div>
                            </div>

                            <div className="input-section">
                              <div className="flex_input_posion">
                                <Dropdown
                                  options={allCurrencyref.current}
                                  placeholder="Select An Currency"
                                  fluid
                                  selection
                                  onChange={onSelect1}
                                  defaultValue={currencyref.current}
                                  isSearchable={true}
                                />
                              </div>
                            </div>

                            <div className="input-section">
                              <div className="flex_input_posion">
                                <input
                                  type="text"
                                  placeholder="Enter network (Optional)"
                                  value={newNetwork}
                                  onChange={(e) => setnewNetwork(e.target.value)}
                                  className="innerinuput"
                                />
                              </div>
                            </div>
                            <div>
                              <button className="withbutton m-0" onClick={Addaddress}>
                                Add Address
                              </button>div
                            </div>
                          </>
                        ) : (
                          ""
                        )}



                        <div className=" withlist m-0 Bta ">
                          <div className="d-flex  align-items-center">
                            <h4>Address Book</h4>{" "}
                          </div>
                        </div>

                        <div className="withcards">
                          {allAddressref.current &&
                            allAddressref.current.length > 0 ? (
                            allAddressref.current.map((data, i) => {
                              return (
                                <div className="withlist">
                                  <p>
                                    {data.address} {"  "} ({data.currency})
                                  </p>
                                  <div className="d-flex align-items-center">
                                    <h6
                                      className="copy"
                                      onClick={() => copy(data.address)}
                                    >
                                      Copy
                                    </h6>{" "}
                                    <i
                                      class="fa-regular trash ml-2 fa-trash-can"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => deleteAddress(data._id)}
                                    ></i>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <p className="norecord text-center text-white">
                              No Address Found
                            </p>
                          )}
                        </div>
                      </div> */}
                        </div>
                      </div>

                      <span className="core_div_head m8032">
                        Recent Withdraw
                      </span>
                      <div className="table-history">
                        <div className="table-responsive table-borderless">
                          <table>
                            <thead>
                              <th scope="col">Date</th>
                              <th scope="col">Time</th>
                              <th scope="col">Currency</th>
                              <th scope="col">Amount</th>
                              <th scope="col">Transaction_Id</th>
                              <th scope="col" className="ends">
                                Status
                              </th>
                            </thead>
                            <tbody>
                              {withdrawHistory && withdrawHistory.length > 0 ? (
                                withdrawHistory.map((item, i) => {
                                  return (
                                    <tr className="history_tabel_bottom_border">
                                      <th scope="row">
                                        {" "}
                                        {Moment(item.created_at).format("YYYY-MM-DD")}
                                      </th>
                                      <td>
                                        {Moment(item.created_at).format("HH:mm:ss")}
                                      </td>
                                      <td>{item.currency}</td>
                                      <td>
                                        {parseFloat(item.amount).toFixed(8)}
                                      </td>
                                      <td>
                                        {" "}
                                        {item.txn_id}{" "}
                                        <i
                                          class="fa fa-clone"
                                          aria-hidden="true"
                                          onClick={() => copy(item.txn_id)}
                                          style={{ cursor: "pointer" }}
                                        ></i>
                                      </td>
                                      
                                      <td >
                                        {item.txn_id == "" ||item.txn_id==null ||item.txn_id==undefined ||item.txn_id=="NaN" ||item.txn_id=="--------"? (
                                          <p className="text-white">pending</p>
                                        ) : (
                                          <p className="text-success">completed</p>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan={6} className="text-center py-5">
                                    <div className="empty_data">
                                      <div className="empty_data_img">
                                        <img
                                          src={require("../images/No-data.png")}
                                          width="100px"
                                        />
                                      </div>
                                      <div className="no_records_text">
                                        No Records Found{" "}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {withdrawHistory && withdrawHistory.length > 0 ? (
                  <div className="pagination page">
                    <Stack spacing={2}>
                      <Pagination
                        count={Math.ceil(
                          withdrawtotalpage / withdrawrecordpage
                        )}
                        page={withdrawcurrentpage}
                        onChange={handlepagewithdraw}
                        renderItem={(item) => (
                          <PaginationItem
                            slots={{
                              previous: ArrowBackIcon,
                              next: ArrowForwardIcon,
                            }}
                            {...item}
                          />
                        )}
                      />
                    </Stack>
                  </div>
                ) : (
                  ""
                )}
                    </>
                  ) : (
                    <div className="deposit">
                      <div className="container">
                        <h2 className="depositbuttonh">
                          Please complete the KYC verification process to gain
                          access to the page.
                        </h2>
                        <div
                          onClick={navigateKyc}
                          className="verify_stst_submit py-3"
                        >
                          <span className="land-sign-letter">
                            Verify KYC Now
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
