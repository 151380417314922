import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { Link, useNavigate } from "react-router-dom";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import Moment from "moment";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { InfinitySpin } from "react-loader-spinner";

function Dashboard() {
  const [depositHistory, setdepositHistory] = useState([]);
  const [siteLoader, setSiteLoader] = useState(false);

  const [depositcurrentpage, setdepositcurrentpage] = useState(1);
  const [deposittotalpage, setdepositTotalpages] = useState(0);

  useEffect(() => {
    getdepositHistory(1);
  }, [0]);

  const getdepositHistory = async (page) => {
    var obj = {
      apiUrl: apiService.deposit_history,
      payload: { FilPerpage: 5, FilPage: page },
    };
    setSiteLoader(true);

    var deposit_history_list = await postMethod(obj);
    setSiteLoader(false);

    if (deposit_history_list) {
      setdepositHistory(deposit_history_list.result);
      setdepositTotalpages(deposit_history_list.total);
      console.log("deposit_history_list.pages===", deposittotalpage);
    }
  };

  const depositrecordpage = 5;
  const depositpagerange = 5;
  const handlepagedeposit = (event, page) => {
    getdepositHistory(page);
    setdepositcurrentpage(page);
  };

  const copy = async (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Address copied");
  };

  return (
    <div>
      <Header />
      <div className="container-fluid innerpages">
        <div className="row">
          {siteLoader == true ? (
            <div className="dashboard_content_section loadercss">
              <InfinitySpin
                height="300"
                width="300"
                color="#12d5d5"
                ariaLabel="infinity-spin-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          ) : (
            <>
              <div className="col-lg-2 col-sm-3">
                <Sidebar />
              </div>
              <div className="col-lg-10 col-md-12">
                <div className=" mx-2 dash_container">
                  <span className="core_div_head">Deposit History</span>
                  <div className="table-history mt-5">
                    <div className="table-responsive table-borderless">
                      <table>
                        <thead>
                          <th scope="col" className="starts">
                            Date
                          </th>
                          <th scope="col">Currency</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Transaction Id</th>
                          <th scope="col" className="ends">
                            Status
                          </th>
                        </thead>
                        <tbody>
                          {depositHistory && depositHistory.length > 0 ? (
                            depositHistory.map((item, i) => {
                              return (
                                <tr className="history_tabel_bottom_border">
                                  <td>
                                    {Moment(item.date).format(
                                      "DD.MM.YYYY hh:mm a"
                                    )}
                                  </td>
                                  <td>{item.currencySymbol}</td>
                                  <td>{parseFloat(item.amount).toFixed(8)}</td>
                                  <td>
                                    <span className="hash_code">
                                      {item.txnid != undefined
                                        ? item.txnid.slice(0, 10)
                                        : "-"}{" "}
                                      {""}
                                    </span>
                                    <i
                                      class="fa fa-clone"
                                      aria-hidden="true"
                                      onClick={() => copy(item.txnid)}
                                      style={{
                                        cursor: "pointer",
                                      }}
                                    ></i>
                                  </td>
                                  <td>
                                    <span className="text-green">
                                      Completed
                                    </span>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <td colSpan={5}>
                              <div className="empty_data">
                                <div className="empty_data_img">
                                  <img
                                    src={require("../images/No-data.png")}
                                    width="100px"
                                  />
                                </div>

                                <div className="no_records_text">
                                  No Records Found
                                </div>
                              </div>
                            </td>
                          )}
                        </tbody>
                      </table>
                    </div>
                    {depositHistory && depositHistory.length > 0 ? (
                      <div className="pagination">
                        <Stack spacing={2}>
                          <Pagination
                            count={Math.ceil(
                              deposittotalpage / depositrecordpage
                            )}
                            page={depositcurrentpage}
                            onChange={handlepagedeposit}
                            renderItem={(item) => (
                              <PaginationItem
                                slots={{
                                  previous: ArrowBackIcon,
                                  next: ArrowForwardIcon,
                                }}
                                {...item}
                              />
                            )}
                          />
                        </Stack>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
