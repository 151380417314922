import React, { useState, useEffect } from "react";
import useStateRef from "react-usestateref";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import Moment from "moment";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { InfinitySpin } from "react-loader-spinner";

function Dashboard() {

  const [perpage, setperpage] = useState(5);
  const [page, setpage] = useState(1);
  const [search, setsearch, searchref] = useStateRef("");
  const [balanceDetails, setbalanceDetails] = useState([]);
  const [balance_overallusdt, setbalance_overallusde] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [total, settotal] = useState(0);
  const [totalINRPrice, setToatalINRPrice] = useState(0);
  const [AvailablePrice, setAvailablePrice] = useState(0);
  const [inorderPrice, setinorderPrice] = useState(0);
  const [profileData, setprofileData] = useState("");
  const [lastLogin, setLastLogin] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [siteLoader, setSiteLoader] = useState(false);
  const [refreshStatus, setrefreshStatus] = useState(false);

  const [balanceDatas, setbalanceDatas] = useState([]);
  const recordPerPage = 5;
  const navigate = useNavigate();

  const depositNav = () => {
    navigate("/deposit");
  };
  const withdrawNav = () => {
    navigate("/Withdraw");
  };

  const handleChange = (e) => {
    try {
      // const sanitizedValue = e.target.value.replace(/\s/g, "");
      // console.log(sanitizedValue,"-=-sanitizedValue=-=");
      // setsearch(sanitizedValue);
      // const { name, value } = e.target;
      const { name, value } = e.target;
      const sanitizedValue = value.replace(/\s/g, "");
      setsearch(sanitizedValue);
      if(sanitizedValue){
        searchWalletList();
      }
    } catch (error) {}
  };

  const handlePageChange = (event, value) => {
    console.log(value, "ujbjjnjn");

    setCurrentPage(value);
    var current_page = +value * 5;
    var prev_page = +current_page - 5;
    var resp_balance = [];
    for (var i = prev_page; i < current_page; i++) {
      if(balanceDatas[i] !== undefined){
      resp_balance.push(balanceDatas[i]);
      }
    }
    setbalanceDetails(resp_balance);
  };

  const getProfile = async () => {
    try {
      var data = {
        apiUrl: apiService.getUserDetails,
      };
      setSiteLoader(true);
      var resp = await getMethod(data);
     

  
      if (resp.status == true) {
        setprofileData(resp.data);
        // console.log(resp.data);
        setLastLogin(resp.lastLogin);

        const timestamp = resp.lastLogin.createdDate;
        const date = new Date(timestamp);
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        // const timeZoneCountry = Intl.DateTimeFormat(undefined, {timeZoneName: 'long'}).formatToParts(date)
        //   .find(part => part.type === 'timeZoneName').value;
        setTimeZone(timeZone);

        localStorage.setItem("tfa_status", resp.data.tfastatus);
      }
      setSiteLoader(false);
    } catch (error) {}
  };

  const getUserTotalbalance = async (pages) => {
    var obj = {
      perpage: perpage,
      page: pages,
      search: searchref.current,
    };
    var data = {
      apiUrl: apiService.getUserTotalbalance,
      payload: obj,
    };
    setSiteLoader(true);

    var resp = await postMethod(data);
    setSiteLoader(false);

    if (resp.status == true) {
      var balanceData = resp.balance;
      console.log(balanceData);
      console.log(balanceData.total_balance);
      console.log(balanceData.available_balance);
      console.log(balanceData.inorder_balance);
      setToatalINRPrice(balanceData.total_balance);
      setAvailablePrice(balanceData.available_balance);
      setinorderPrice(balanceData.inorder_balance);
    }
  };

  const getUserbalance = async (pages) => {
    // setSiteLoader(false);

    var obj = {
      perpage: perpage,
      page: pages,
      search: searchref.current,
    };

    var data = {
      apiUrl: apiService.getUserBalance,
      payload: obj,
    };
    // setSiteLoader(true);

    var resp = await postMethod(data);
    // setSiteLoader(false);

    if (resp.status == true) {
      // setSiteLoader(false);
      console.log(resp.Message, "=-=-=-resp.Message=-=-=-");
      var balanceData = resp.Message;
      setbalanceDatas(balanceData);

      var current_page = +resp.current * 5;
      var prev_page = +current_page - 5;
      var resp_balance = [];
      for (var i = prev_page; i < current_page; i++) {
        if(balanceData[i] !== undefined){
          resp_balance.push(balanceData[i]);
        }
      }
      // resp_balance = resp_balance.filter(Boolean);
      setbalanceDetails(resp_balance);
      var totalnumber = resp.total;
      settotal(resp.total);
      console.log(resp.total, "resp.totalresp.total");
      var balanceData = resp.balance;
    } else {
    }
  };

  const searchWalletList = async () => {
    // console.log(searchref.current,"-=-=searchref.current=--");
    if (
      searchref.current !== "" &&
      searchref.current !== undefined &&
      searchref.current !== null
    ) {
      const regexPattern = new RegExp(searchref.current, "i");
      const searchWallet = balanceDatas.filter((data) =>
        regexPattern.test(data.currencysymbol)
      );
      // const searchWallet = balanceDatas.filter(data => data.currencysymbol.toLowerCase() === searchref.current.toLowerCase());
      if (searchWallet.length > 0) {
        setbalanceDetails(searchWallet);
        // setDataHide(false);
        settotal(1);
      } else {
        getUserbalance(1);
      }
    } else {
      getUserbalance(1);
    }
  };

  const getPortfolio = async () => {
    var data = {
      apiUrl: apiService.portfolioBalance,
    };
    setSiteLoader(true);

    var resp = await getMethod(data);
    setSiteLoader(false);

    if (resp.status == true) {
      // console.log(resp, "=-=-=-resp.Message=-=-=-");
      var balanceData = resp.balance;
      setToatalINRPrice(balanceData.total_balance);
      setAvailablePrice(balanceData.available_balance);
      setinorderPrice(balanceData.inorder_balance);
    } else {
    }
  };

  useEffect(() => {
    let token_socket = localStorage.getItem("user_token");
    if (!token_socket) {
      navigate("/login");
    }
    getPortfolio();
    // socket.on("sitesettings", function (res) {
    //   console.log(res);
    // });
    getProfile();
    FindData();
    Kycdata();
    getUserbalance(currentPage);
    getUserTotalbalance(currentPage);
    refreshUse();
    console.log(profileData, "-=profileData=-");
  }, [0]);

  const refresh = () => {
    setrefreshStatus(true);
    refreshUse();
  };

  const refreshUse = async () => {
    var data = {
      apiUrl: apiService.transaction,
    };
    var resp = await getMethod(data);
    if (resp.message == true) {
      getUserbalance(1);
      setrefreshStatus(false);
    }
  };

  const [copied, setCopied] = useState(false);
  const [changeCode, setchangeCode] = useState(false);
  const [getKYCData, setgetKYCData] = useState("");

  const FindData = async () => {
    var data = {
      apiUrl: apiService.findDetails,
    };
    setSiteLoader(true);

    var responce = await postMethod(data);
    setSiteLoader(false);

    if (responce.data != null) {
      if (responce.data.APcode != "") {
        setchangeCode(true);
      } else {
        setchangeCode(false);
      }
    } else {
      setchangeCode(false);
    }
  };

  const Kycdata = async () => {
    var data = {
      apiUrl: apiService.getKYC,
    };
    setSiteLoader(true);

    var resp = await getMethod(data);
    setSiteLoader(false);

    if (resp.status) {
      if (resp.status != "") {
        var kycData = resp.datas.userDetails;
        console.log(kycData, "kycData");
        setgetKYCData(kycData);
      }
    }
  };

  const copyText = () => {
    const profileId = profileData.uuid;
    navigator.clipboard
      .writeText(profileId)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000);
        toast.success("Copied Successfully !");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const [passHide, setPasshide] = useState(false);
  const [inputType, setinputType] = useState(false);

  const passwordHide = (data) => {
    if (data == "hide") {
      setPasshide(true);
      setinputType(true);
    } else {
      setPasshide(false);
      setinputType(false);
    }
  };
  console.log(balanceDetails, "balanceDetails--")

  return (
  

    <div>
      <Header />
      {siteLoader == true ? (
        <div className="dashboard_content_section loadercss">
          <InfinitySpin
            height="300"
            width="300"
            color="#12d5d5"
            ariaLabel="infinity-spin-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <div className="container-fluid">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-2 col-sm-3">
              <Sidebar />
            </div>
            <div className="col-lg-10 col-md-12">
              <div className="container dash_container">
                <span className="core_div_head">
                  Welcome back , {profileData.displayname}{" "}
                  <img
                    src={require("../images/welcome-img (1).png")}
                    className="dash_hai_icon "
                  />
                </span>
                <p className="pop_bot_left1 mt-2">
                  Unlocking Digital Wealth: Your Gateway to the Crypto Universe
                </p>
                <div className="dash_user_main mt-4">
                  <div className="dash_chan_img">
                    {profileData.url == "" ? (
                       <img
                       src={require('../images/dash-user.png')}
                       alt="user profile"
                       className="dash_user_img"
                     />
                    ) : (
                      <img
                      src={profileData.url}
                      alt="user profile"
                      className="dash_user_img"
                    />
                    )}
                 
                  </div>
                
                  <div className="dash_frst_rght">
                    <span className="core_div_head">
                      {profileData.displayname}
                    </span>
                    <div className="req_sgnin_or">
                      <div className="dash_frst_uid">
                        <span className="dash_in_frst">
                          {" "}
                          IP : {lastLogin.ipAddress}{" "}
                        </span>
                      </div>
                      <div className="dash_frst_sts Verified_text">
                        {getKYCData.kycstatus == 1 ? (
                          <>
                            <img
                              src={require("../images/active_toggle.png")}
                              alt="verify status"
                              className="verify_stst_img"
                            />
                            <span className="pop_in_frst">Verified</span>
                          </>
                        ) : (
                          <>
                            <img
                              src={require("../images/deactive_toggle.png")}
                              alt="verify status"
                              className="verify_stst_img"
                            />
                            <span className="pop_in_frst">Unverified</span>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bal_margin">
                  <span className="bal_divs_inhead">Balances</span>
                  <div className="row my-3">
                    <div className="col-lg-3">
                      <div className="dash_bal_card">
                        <span className="slider_thrsleft_innerbot">
                          Portfolio Total Value In (USD)
                        </span>
                        <span className="earn_divs_inhead">
                          $
                          {totalINRPrice == "" ||
                          totalINRPrice == null ||
                          totalINRPrice == undefined
                            ? 0.0
                            : totalINRPrice.toFixed(4)}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="dash_bal_cardscnd">
                        <div className="foot_about">
                          <span className="slider_thrsleft_innerbot">
                            Available Balance In (USD)
                          </span>
                          <span className="earn_divs_inhead">
                            $
                            {AvailablePrice == "" ||
                            AvailablePrice == null ||
                            AvailablePrice == undefined
                              ? 0.0
                              : AvailablePrice.toFixed(4)}{" "}
                          </span>
                        </div>
                        <div className="req_sgnin_or">
                          <div
                            className="land_header_signup"
                            onClick={depositNav}
                          >
                            <span className="land-sign-letter">Deposit</span>
                          </div>
                          <div className="dash_scnd_widr" onClick={withdrawNav}>
                            <span className="pop_in_frst">Withdraw</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="dash_bal_card">
                        <span className="slider_thrsleft_innerbot">
                          Portfolio Total Value In (USD)
                        </span>
                        <span className="earn_divs_inhead">
                          $
                          {inorderPrice == "" ||
                          inorderPrice == null ||
                          inorderPrice == undefined
                            ? 0.0
                            : inorderPrice.toFixed(4)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="asset_dash_main">
                  <span className="trade_divs_inhead">Your Assets</span>
                  <div className="earn_view_ass">
                    <div className="dash_asset_search">
                      <input
                        type="text"
                        placeholder="Search"
                        value={search}
                        className="order-srch-input"
                        onChange={handleChange}
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => searchWalletList()}
                        className="search-icon"
                        viewBox="0 0 256.001 256.001"
                        id="MagnifyingGlass"
                      >
                        <rect width="256" height="256" fill="none"></rect>
                        <circle
                          cx="116"
                          cy="116"
                          r="84"
                          fill="none"
                          stroke="#ffffff"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="16"
                          class="colorStroke000000 svgStroke"
                        ></circle>
                        <line
                          x1="175.394"
                          x2="223.994"
                          y1="175.4"
                          y2="224.001"
                          fill="none"
                          stroke="#ffffff"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="16"
                          class="colorStroke000000 svgStroke"
                        ></line>
                      </svg>
                    </div>
                    <div className="dash_scnd_ref" onClick={refresh}>
                      {refreshStatus == true ? (
                        <i class="fa-solid fa-rotate-right fa-spin search-icon-ref"></i>
                      ) : (
                        <i class="fa-solid fa-rotate-right search-icon-ref"></i>
                      )}

                      {/* <svg xmlns="http://www.w3.org/2000/svg" onClick={refresh} className='search-icon' viewBox="0 0 24 24" id="refresh"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M17.65 6.35c-1.63-1.63-3.94-2.57-6.48-2.31-3.67.37-6.69 3.35-7.1 7.02C3.52 15.91 7.27 20 12 20c3.19 0 5.93-1.87 7.21-4.56.32-.67-.16-1.44-.9-1.44-.37 0-.72.2-.88.53-1.13 2.43-3.84 3.97-6.8 3.31-2.22-.49-4.01-2.3-4.48-4.52C5.31 9.44 8.26 6 12 6c1.66 0 3.14.69 4.22 1.78l-1.51 1.51c-.63.63-.19 1.71.7 1.71H19c.55 0 1-.45 1-1V6.41c0-.89-1.08-1.34-1.71-.71l-.64.65z" fill="#ffffff" class="color000000 svgShape"></path></svg> */}
                    </div>
                  </div>
                </div>
                <div className="table-dash-change mt-3">
                <div className="table-responsive table-borderless">
                  <table>
                    <thead className="assest_head">
                      <th>Asset</th>
                      <th>On Orders</th>
                      <th>Available Balance</th>
                      <th>Total Balance</th>
                    </thead>
                    <tbody>
                      {balanceDetails && balanceDetails.length > 0 ? (
                        balanceDetails.map((item, i) => {
                          return (
                            <tr key={i}>
                              <td>
                                <div className="dash_astb_frst">
                                  <img
                                    src={item?.currencyImage}
                                    alt="Currency logo"
                                    className="dash_curren"
                                  />
                                  <div className="dash_fst_rght">
                                    <span className="hero_left_subhead">
                                      {item?.currencysymbol}
                                    </span>
                                    <span className="bals_divs_insubhead">
                                      {item?.currencyName}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="dash_fst_rght">
                                  {inputType == false ? (
                                    <>
                                      <span className="pop_in_frst">
                                        {parseFloat(item?.holdAmount).toFixed(
                                          4
                                        )}{" "}
                                        {item?.currencysymbol}
                                      </span>
                                      <span className="slider_left_innerbot">
                                        {parseFloat(
                                          item?.estimatedUSDThold
                                        ).toFixed(4)}
                                        $
                                      </span>
                                    </>
                                  ) : (
                                    <span className="pop_in_frst">******</span>
                                  )}
                                </div>
                              </td>
                              <td>
                                <div className="dash_fst_rght">
                                  {inputType == false ? (
                                    <>
                                      <span className="pop_in_frst">
                                        {" "}
                                        {parseFloat(
                                          item?.currencyBalance
                                        ).toFixed(4)}{" "}
                                        {item?.currencysymbol}
                                      </span>
                                      <span className="slider_left_innerbot">
                                        {parseFloat(
                                          item?.estimatedUSDTbalance
                                        ).toFixed(4)}
                                        $
                                      </span>
                                    </>
                                  ) : (
                                    <span className="pop_in_frst">******</span>
                                  )}
                                </div>
                              </td>
                              <td>
                                <div className="dash_fst_rght">
                                  {inputType == false ? (
                                    <>
                                      <span className="pop_in_frst">
                                        {parseFloat(
                                          item?.currencyBalance +
                                            parseFloat(item?.holdAmount)
                                        ).toFixed(4)}{" "}
                                        {item?.currencysymbol}{" "}
                                      </span>
                                      <span className="slider_left_innerbot">
                                        {parseFloat(
                                          item?.estimatedUSDTtotal
                                        ).toFixed(4)}
                                        $
                                      </span>
                                    </>
                                  ) : (
                                    <span className="pop_in_frst">******</span>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={4} className="text-center py-5">
                          <div className="empty_data">
                                  <div className="empty_data_img">
                                  <img
                                    src={require("../images/No-data.png")}
                                    width="100px"
                                  />
                                  </div>
                                  <div className="no_records_text">
                                    No Records Found{" "}
                                  </div>
                                </div>  
                          </td>
                        </tr>
                      )}

                      {/* <tr>
                                            <td>
                                                <div className='dash_astb_frst'>
                                                    <img src={require('../images/Bitcoin-Logo.png')} alt='Currency logo' className='dash_curren' />
                                                    <div className='dash_fst_rght'>
                                                        <span className='hero_left_subhead'>ETH</span>
                                                        <span className='bals_divs_insubhead'>Ethereum</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='dash_fst_rght'>
                                                    <span className='pop_in_frst'>0.00000000 ETH</span>
                                                    <span className='slider_left_innerbot'>0.00000000$</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='dash_fst_rght'>
                                                    <span className='pop_in_frst'>0.00000000 ETH</span>
                                                    <span className='slider_left_innerbot'>0.00000000$</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='dash_fst_rght'>
                                                    <span className='pop_in_frst'>0.00000000 ETH</span>
                                                    <span className='slider_left_innerbot'>0.00000000$</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='dash_astb_frst'>
                                                    <img src={require('../images/Bitcoin-Logo.png')} alt='Currency logo' className='dash_curren' />
                                                    <div className='dash_fst_rght'>
                                                        <span className='hero_left_subhead'>ETH</span>
                                                        <span className='bals_divs_insubhead'>Ethereum</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='dash_fst_rght'>
                                                    <span className='pop_in_frst'>0.00000000 ETH</span>
                                                    <span className='slider_left_innerbot'>0.00000000$</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='dash_fst_rght'>
                                                    <span className='pop_in_frst'>0.00000000 ETH</span>
                                                    <span className='slider_left_innerbot'>0.00000000$</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='dash_fst_rght'>
                                                    <span className='pop_in_frst'>0.00000000 ETH</span>
                                                    <span className='slider_left_innerbot'>0.00000000$</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='dash_astb_frst'>
                                                    <img src={require('../images/Bitcoin-Logo.png')} alt='Currency logo' className='dash_curren' />
                                                    <div className='dash_fst_rght'>
                                                        <span className='hero_left_subhead'>ETH</span>
                                                        <span className='bals_divs_insubhead'>Ethereum</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='dash_fst_rght'>
                                                    <span className='pop_in_frst'>0.00000000 ETH</span>
                                                    <span className='slider_left_innerbot'>0.00000000$</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='dash_fst_rght'>
                                                    <span className='pop_in_frst'>0.00000000 ETH</span>
                                                    <span className='slider_left_innerbot'>0.00000000$</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='dash_fst_rght'>
                                                    <span className='pop_in_frst'>0.00000000 ETH</span>
                                                    <span className='slider_left_innerbot'>0.00000000$</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='dash_astb_frst'>
                                                    <img src={require('../images/Bitcoin-Logo.png')} alt='Currency logo' className='dash_curren' />
                                                    <div className='dash_fst_rght'>
                                                        <span className='hero_left_subhead'>ETH</span>
                                                        <span className='bals_divs_insubhead'>Ethereum</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='dash_fst_rght'>
                                                    <span className='pop_in_frst'>0.00000000 ETH</span>
                                                    <span className='slider_left_innerbot'>0.00000000$</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='dash_fst_rght'>
                                                    <span className='pop_in_frst'>0.00000000 ETH</span>
                                                    <span className='slider_left_innerbot'>0.00000000$</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='dash_fst_rght'>
                                                    <span className='pop_in_frst'>0.00000000 ETH</span>
                                                    <span className='slider_left_innerbot'>0.00000000$</span>
                                                </div>
                                            </td>
                                        </tr> */}
                      {balanceDetails && balanceDetails.length > 0 ? (
                        <tr className="text-center text-white">
                          <td colSpan={4}>
                            <div className="pagination mt-4">
                              <Stack spacing={2}>
                                <Pagination
                                  count={Math.ceil(total / recordPerPage)}
                                  page={currentPage}
                                  onChange={handlePageChange}
                                  renderItem={(item) => (
                                    <PaginationItem
                                      slots={{
                                        previous: ArrowBackIcon,
                                        next: ArrowForwardIcon,
                                      }}
                                      {...item}
                                    />
                                  )}
                                />
                              </Stack>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                    </tbody>
                  </table>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Dashboard;
