import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import Sidebar from "./Sidebar";
import apiService from "../core/service/detail";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getMethod, postMethod } from "../core/service/common.api";
import { InfinitySpin } from "react-loader-spinner";
import { toast } from "react-toastify";
import Moment from "moment";
import { Link, useNavigate } from "react-router-dom";

function Dashboard() {
  const [sessionHistory, setsessionHistory] = useState([]);
  const [logincurrentpage, setlogincurrentpage] = useState(1);
  const [logintotalpage, setloginTotalpages] = useState(0);
  const [siteLoader, setSiteLoader] = useState(false);

  useEffect(() => {
    getLoginHistory(1);
  }, [0]);

  const getLoginHistory = async (page) => {
    try {
      var payload = {
        perpage: loginrecordpage,
        page: page,
      };
      var data = {
        apiUrl: apiService.getSessionHisotry,
        payload: payload,
      };
      setSiteLoader(true);
      var resp = await postMethod(data);
      setSiteLoader(false);
      if (resp.status) {
        setsessionHistory(resp.data.data);
        setloginTotalpages(resp.data.total);
      }
    } catch (error) {}
  };

  const loginrecordpage = 5;
  const loginpagerange = 5;

  const handlepagelogin = (event, pageNumber) => {
    setlogincurrentpage(pageNumber);
    getLoginHistory(pageNumber);
  };

  const copy = async (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Address copied");
  };

  return (
    <div>
      <Header />
      <div className="container-fluid innerpages">
        <div className="row">
          {siteLoader == true ? (
            <div className="dashboard_content_section loadercss">
              <InfinitySpin
                height="300"
                width="300"
                color="#12d5d5"
                ariaLabel="infinity-spin-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          ) : (
            <>
              <div className="col-lg-2 col-sm-3">
                <Sidebar />
              </div>
              <div className="col-lg-10 col-md-12">
                <div className=" mx-2 dash_container">
                  <span className="core_div_head">
                    <Link to="/security">
                      <i class="ri-arrow-left-line"></i>
                    </Link>
                    Login History
                  </span>
                  <div className="table-history mt-5">
                    <div className="table-responsive table-borderless">
                      <table>
                        <thead>
                          <th scope="col">Date</th>
                          <th scope="col">Ip Address</th>
                          <th scope="col" className="ends">
                            Device
                          </th>
                        </thead>
                        <tbody>
                          {sessionHistory.length > 0 && sessionHistory ? (
                            sessionHistory.map((item, i) => {
                              return (
                                <tr className="history_tabel_bottom_border">
                                  <td>
                                    {Moment(item.createdDate).format(
                                      "DD.MM.YYYY hh:mm a"
                                    )}
                                  </td>
                                  <td>{item.ipAddress}</td>
                                  <td>{item.platform}</td>
                                </tr>
                              );
                            })
                          ) : (
                            <td colSpan={3}>
                              <div className="empty_data">
                                <div className="empty_data_img">
                                  <img
                                    src={require("../images/No-data.png")}
                                    width="100px"
                                  />
                                </div>

                                <div className="no_records_text">
                                  No Records Found
                                </div>
                              </div>
                            </td>
                          )}
                        </tbody>
                      </table>
                    </div>
                    {/* {sessionHistory && sessionHistory.length > 0 ? ( */}
                    <>
                      <div className="pagination mt-5">
                        <Stack spacing={2}>
                          <Pagination
                            count={Math.ceil(logintotalpage / loginrecordpage)}
                            page={logincurrentpage}
                            onChange={handlepagelogin}
                            renderItem={(item) => (
                              <PaginationItem
                                slots={{
                                  previous: ArrowBackIcon,
                                  next: ArrowForwardIcon,
                                }}
                                {...item}
                              />
                            )}
                          />
                        </Stack>
                      </div>
                    </>
                    {/* ) : (
                  ""
                )} */}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
