import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { env } from "../core/service/envconfig";
import apiService from "../core/service/detail";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getMethod, postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import Moment from "moment";
import { InfinitySpin } from "react-loader-spinner";

function Dashboard() {
    useEffect(() => {
        getProfile();
    }, []);

    const handleChange = async (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/\s/g, "");
        let formData = { ...profileData, ...{ [name]: sanitizedValue } };

        console.log(formData, "formData");
        setprofileData(formData);
        // validate(formData);
    };

    const showsuccessToast = (message) => {
        toast.dismiss();
        toast.success(message);
      };
      
      const showerrorToast = (message) => {
        toast.dismiss();
        toast.error(message);
      };


    const [profileData, setprofileData, profileDataref] = useState("");
    const [Imagerror, setImagerror, Imagerrorref] = useState("");

    const getProfile = async () => {
        try {
            var data = {
                apiUrl: apiService.getUserDetails,
            };
            var resp = await getMethod(data);

            if (resp.status == true) {
                setprofileData(resp.data);
            }
        } catch (error) { }
    };

    const UpdateProfile = async () => {
        try {
            var data = {
                apiUrl: apiService.UpdateProfile,
                payload: profileDataref.current,
            };
            var resp = await postMethod(data);

            getProfile();

            if (resp.status == true) {
                showsuccessToast(resp.Message);
            } else {
                showerrorToast(resp.Message);
            }
        } catch (error) { }
    };

    const UpdateProfile1 = async () => {
        try {
            if (idproofref.current != "") {
                setImagerror("");

                profileDataref.current.url = idproofref.current;

                var data = {
                    apiUrl: apiService.UpdateProfile,
                    payload: profileDataref.current,
                };
                var resp = await postMethod(data);

                getProfile();

                if (resp.status == true) {
                    showsuccessToast(resp.Message);
                } else {
                    showerrorToast(resp.Message);
                }
            } else {
                setImagerror("Profile image is require");
            }
        } catch (error) { }
    };

    const copy = async (text) => {
        navigator.clipboard.writeText(text);
        showsuccessToast("Copied successfully");
    };

    const [idproof, setidproof, idproofref] = useState("");
    const [valididproof, setvalididproof] = useState(0);
    const [idproofLoad, setidproofLoad] = useState(false);
    const [idproofname, setidproofname, idproofnameref] = useState("");

    const imageUpload = (type, val) => {
        try {
            const fileExtension = val.name.split(".").at(-1);
            const fileSize = val.size;
            const fileName = val.name;
            if (
                fileExtension != "png" &&
                fileExtension != "jpg" &&
                fileExtension != "jpeg"
            ) {
                showerrorToast(
                    "File does not support. You must use .png or .jpg or .jpeg "
                );
            } else if (fileSize > 10000000) {
                showerrorToast("Please upload a file smaller than 1 MB");
            } else {
                console.log("------");
                setidproofLoad(true);
                const data = new FormData();
                data.append("file", val);
                data.append("upload_preset", env.upload_preset);
                data.append("cloud_name", env.cloud_name);
                fetch(
                    "https://api.cloudinary.com/v1_1/" + env.cloud_name + "/auto/upload",
                    { method: "post", body: data }
                )
                    .then((resp) => resp.json())
                    .then((data) => {
                        setidproofLoad(false);
                        setvalididproof(2);
                        if (
                            fileExtension == "pdf" ||
                            fileExtension == "odt" ||
                            fileExtension == "doc"
                        ) {
                            setvalididproof(1);
                        }

                        setidproof(data.secure_url);
                        setidproofname(val.name);
                    })
                    .catch((err) => {
                        console.log(err);
                        showerrorToast("Please try again later");
                    });
            }
        } catch (error) {
            showerrorToast("Please try again later");
        }
    };

    return (
        <div>
            <Header />
            <div className="container-fluid innerpages">
                <div className="row">
                    <div className="col-lg-2 col-sm-3">
                        <Sidebar />
                    </div>
                    <div className="col-lg-10 col-md-12">
                        <div className=" mx-2 dash_container">
                            <div className="profile-bg"></div>
                            <span className="core_div_head mb-4">Profile</span>

                            <div className="Depositcard">
                                <div className=" daj m-0 ">
                                    <div className="dash_chan_img">
                                        {profileDataref.current.url == "" ? (
                                            <img src={require("../images/dash-user.png")} width="50px" className="dash_user_img"/>
                                        ) : (<img src={profileDataref.current.url} width="50px" className="dash_user_img" />)}

                                    </div>
                                    <div>
                                        <button
                                            className="profile-btn"
                                            data-toggle="modal"
                                            data-target="#exampleModal"
                                        >
                                            Change
                                        </button>
                                    </div>
                                </div>

                                <div className=" daj m-0 ">
                                    <div>
                                        <h6>{profileDataref.current.displayname}</h6>
                                        <p>Display Name</p>
                                    </div>
                                    <div>
                                        <button
                                            className="profile-btn"
                                            data-toggle="modal"
                                            data-target="#exampleModal1"
                                        >
                                            Change
                                        </button>
                                    </div>
                                </div>

                                <div className=" daj m-0 ">
                                    <div>
                                        <h6>{profileDataref.current.username}</h6>
                                        <p>User Name</p>
                                    </div>
                                    <div>
                                        <button
                                            className="profile-btn"
                                            data-toggle="modal"
                                            data-target="#exampleModa2"
                                        >
                                            Change
                                        </button>
                                    </div>
                                </div>

                                <div className=" daj m-0 ">
                                    <div>
                                        {profileDataref.current.kycstatus == 1 ? (
                                           <>
                                           <img
                                             src={require("../images/active_toggle.png")}
                                             alt="verify status"
                                             className="verify_stst_img mr-2"
                                           />
                                           <span className="pop_in_frst">Verified</span>
                                         </>
                                        ) : (
                                            <>
                                                <img
                                                src={require("../images/deactive_toggle.png")}
                                                alt="verify status"
                                                className="verify_stst_img mr-2"
                                                />
                                                <span className="pop_in_frst">Unverified</span>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <span className="core_div_head my-4">Info</span>

                            <div className="Depositcard">
                                <div className=" daj m-0 ">
                                    <div>
                                        <h6>User ID</h6>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <h6>{profileDataref.current.uuid}</h6>{" "}
                                        <i
                                            class="fa-regular ml-2 text-white fa-copy cursor-pointer"
                                            onClick={() => copy(profileDataref.current.uuid)}
                                        ></i>{" "}
                                    </div>
                                </div>

                                <div className=" daj m-0 ">
                                    <div>
                                        <h6>E-mail ID</h6>
                                    </div>
                                    <div className="d-flex align-items-center namescroll">
                                        <h6>{profileDataref.current.email}</h6>
                                    </div>
                                </div>
{/* 
                                <div className=" daj m-0 ">
                                    <div>
                                        <h6>Phone Number</h6>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <h6 className="text-prime">Configure</h6>{" "}
                                        <i class="fa-solid ml-2 text-white fa-chevron-right"></i>
                                    </div>
                                </div> */}

                                <div className=" daj m-0 ">
                                    <div>
                                        <h6>Referral Code</h6>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <h6>{profileDataref.current.referralCode}</h6>{" "}
                                        <i
                                            class="fa-regular ml-2 text-white fa-copy cursor-pointer"
                                            onClick={() => copy(profileDataref.current.referralCode)}
                                        ></i>
                                    </div>
                                </div>
                                <div className=" daj m-0 ">
                                    <div>
                                        <h6>Registration Date</h6>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <h6>
                                            {" "}
                                            {Moment(profileDataref.current.createdDate).format(
                                                "lll"
                                            )}{" "}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="modal fade"
                        id="exampleModal"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">
                                        Change Profile
                                    </h5>
                                    <button
                                        type="button"
                                        class="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div className="upl_main">
                                        <div className="iden_upd_main">
                                            <div className="iden_upd_inner">
                                                <div className="iden_upd_top">
                                                    <input
                                                        type="file"
                                                        name="image"
                                                        className="image_upload_kyc"
                                                        onChange={(e) =>
                                                            imageUpload("idproof", e.target.files[0])
                                                        }
                                                    />

                                                    {idproofLoad == false ? (
                                                        valididproof == 0 ? (
                                                            <div>
                                                                <img
                                                                    src={require("../images/upload-image.png")}
                                                                    alt="image-upload"
                                                                    className="up_im_past"
                                                                />
                                                            </div>
                                                        ) : valididproof == 1 ? (
                                                            <span className="">
                                                                <i class="bi bi-file-earmark-bar-graph"></i>
                                                            </span>
                                                        ) : (
                                                            <img
                                                                src={idproofref.current}
                                                                className="up_im_past"
                                                                alt="National Id Front"
                                                            />
                                                        )
                                                    ) : (
                                                        <div
                                                            class="kyc_load_sub"
                                                               role="status"
                                                        >
                                                            <InfinitySpin
                                                            height="80"
                                                            width="80"
                                                            color="#12d5d5"
                                                            ariaLabel="infinity-spin-loading"
                                                            wrapperStyle={{}}
                                                            wrapperClass=""
                                                            visible={true}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="iden_btm_scnd">
                                                    <hr />
                                                    <span className="trade_divs_inhead">
                                                        Upload Profie Image
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {Imagerrorref.current != "" ? (
                                        <p>{Imagerrorref.current}</p>
                                    ) : (
                                        ""
                                    )}

                                    <button
                                        className="withprofilebutton"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        onClick={UpdateProfile1}
                                    >
                                        Change Profile Image{" "}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="modal fade"
                        id="exampleModal1"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">
                                        Change Display Name
                                    </h5>
                                    <button
                                        type="button"
                                        class="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div className="input-section">
                                        <h5 className="mt-0">Display Name</h5>
                                        <div className="flex_input_posion">
                                            <input
                                                type="text"
                                                name="displayname"
                                                value={profileDataref.current.displayname}
                                                onChange={handleChange}
                                                className="innerinuput"
                                                placeholder="Enter Display Name"
                                            />
                                        </div>
                                    </div>{" "}
                                    <button
                                        className="withbutton"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        onClick={UpdateProfile}
                                    >
                                        Change Display Name
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="modal fade"
                        id="exampleModa2"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">
                                        Change User Name{" "}
                                    </h5>
                                    <button
                                        type="button"
                                        class="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div className="input-section">
                                        <h5 className="mt-0">User Name</h5>
                                        <div className="flex_input_posion">
                                            <input
                                                type="text"
                                                name="username"
                                                value={profileDataref.current.username}
                                                onChange={handleChange}
                                                className="innerinuput"
                                                placeholder="Enter the Username"
                                            />
                                        </div>
                                    </div>

                                    <button
                                        className="withbutton"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        onClick={UpdateProfile}
                                    >
                                        Change User Name
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
