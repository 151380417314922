import React, { useEffect } from "react";
import Header from "./Header";
import { Link, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import { setAuthorization } from "../core/service/axios";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";

function Login() {
    const initialFormValue = {
        email: "",
        password: "",
    };

    const navigate = useNavigate();

    const [emailValidate, setemailValidate, emailValidateref] = useState(false);
    const [passwordValidate, setpasswordValidate, passwordValidateref] =
        useState(false);
    const [validationnErr, setvalidationnErr] = useState("");
    const [formValue, setFormValue] = useState(initialFormValue);
    const [buttonLoader, setbuttonLoader] = useState(false);
    const [passHide, setPasshide] = useState(false);
    const [inputType, setinputType] = useState("password");
    const [siteLoader, setSiteLoader] = useState(false);

    const { email, password } = formValue;

    useEffect(() => {
        var token = localStorage.getItem("user_token");
        if (token) {
            navigate("/dashboard");
        }
    }, []);

    const handleChange = async (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/\s/g, "");
        let formData = { ...formValue, ...{ [name]: sanitizedValue } };
        setFormValue(formData);
        validate(formData);
    };

    const validate = async (values) => {
        const errors = {};
        const username = values.email.split("@")[0];

        if (!values.email) {
            errors.email = "Email is a required field!";
            setemailValidate(true);
        } else if (
            !/^[a-zA-Z0-9.]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(values.email)
        ) {
            errors.email = "Invalid email address!";
            setemailValidate(true);
        } else if (username.length < 4 || username.length > 30) {
            errors.email = "Email Username must be between 4-30 characters long";
            setemailValidate(true);
        } else if (!/^[a-zA-Z0-9.]+$/i.test(username)) {
            errors.email =
                " Only letters (a-z), numbers (0-9), and periods (.) are allowed in the username";
            setemailValidate(true);
        } else if (!/[a-zA-Z]/.test(username)) {
            errors.email = "Email username must contain at least one letter (a-z)";
            setemailValidate(true);
        } else if (values.email.length > 50) {
            errors.email = "Email address is too long!";
            setemailValidate(true);
        } else if (values.password == "") {
            setemailValidate(false);
            setpasswordValidate(true);
            errors.password = "password is required !";
        } else if (values.password.length < 5 || values.password.length > 25) {
            setpasswordValidate(true);
            errors.password = "Password should not below 5 above 25 letters !";
        } else if (!values.password.match(/[a-z]/g)) {
            setpasswordValidate(true);
            errors.password = "Please enter at least lower character !";
        } else if (!values.password.match(/[A-Z]/g)) {
            setpasswordValidate(true);
            errors.password = "Please enter at least upper character !";
        } else if (!values.password.match(/[0-9]/g)) {
            setpasswordValidate(true);
            errors.password = "Please enter at One digit character !";
        } else if (!values.password.match(/[!@#$%^&*]/g)) {
            setpasswordValidate(true);
            errors.password = "Please enter at least one special character !";
        } else {
            setpasswordValidate(false);
        }

        setvalidationnErr(errors);
        return errors;
    };

    const passwordHide = (data) => {
        if (data == "hide") {
            setPasshide(true);
            setinputType("text");
        } else {
            setPasshide(false);
            setinputType("password");
        }
    };

    const formSubmit = async () => {
        validate(formValue);
        if (
            emailValidateref.current === false &&
            passwordValidateref.current === false
        ) {
            console.log(formValue);
            var data = {
                apiUrl: apiService.signin,
                payload: formValue,
            };
            setbuttonLoader(true);
            var resp = await postMethod(data);
            setbuttonLoader(false);
            setFormValue(initialFormValue);
            if (resp?.tfa === 1) {
                navigate("/verify-2fa", {
                    state: {
                        socketToken: resp?.socketToken,
                    },
                });
            } else {
                if (resp.status == true) {
                    setFormValue(initialFormValue);
                    toast.success(resp.Message);
                    console.log(resp, "[--=-=resp");
                    await setAuthorization(resp.token);
                    localStorage.setItem("user_token", resp.token);
                    localStorage.setItem("tfa_status", resp.tfa);
                    localStorage.setItem("socket_token", resp.socketToken);
                    localStorage.setItem("jwNkiKmttscotlox", resp.jwNkiKmttscotlox);
                    navigate("/dashboard");
                } else {
                    setbuttonLoader(false);
                    toast.error(resp.Message);
                }
            }
        }
    };

    return (
        <div>
            <Header />
            <div className="reg_main">
                <div className="container">
                    <div className="row reg_row">
                        <div className="col-lg-6 mt-2">
                            <img
                                src={require("../images/login-image.png")}
                                alt="Core products"
                                width="100%"
                            />
                        </div>
                        <div className="col-lg-6 mt-2">
                            <div className="reg_right_main">
                                <span className="reg_left_head">Welcome back</span>
                                <div className="reg_in_main">
                                    <div className="log_in_top">
                                        <div className="reg_infields">
                                            <div className="req_field_sin">
                                                <span className="earn_divs_insubhead">Email</span>
                                                <input
                                                    type="text"
                                                    name="email"
                                                    value={email}
                                                    onChange={handleChange}
                                                    className="reg_inp_field"
                                                    placeholder="Enter your email"
                                                />
                                                {validationnErr && validationnErr.email && (
                                                    <p className="errorcss">{validationnErr.email}</p>
                                                )}
                                            </div>

                                            <div className="req_field_sin">
                                                <span className="earn_divs_insubhead">Password</span>
                                                <div className="flex_input_posion">
                                                    <input
                                                        type={inputType}
                                                        name="password"
                                                        value={password}
                                                        minLength={6}
                                                        maxLength={15}
                                                        onChange={handleChange}
                                                        className="reg_inp_field"
                                                        placeholder="Enter your password"
                                                    />

                                                    {passHide == true ? (
                                                         <i class="fa-regular fa-eye reg_eye" onClick={() => passwordHide("show")}></i>
                                                    ) : (
                                                        <i class="fa-regular fa-eye-slash reg_eye" onClick={() => passwordHide("hide")}></i>
                                                    )}
                                                </div>
                                                {validationnErr && validationnErr.password && (
                                                    <p className="errorcss">{validationnErr.password}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="reg_bot_div">
                                            <div>
                                                <span className="slider_left_innerbot">
                                                    <Link to="/forgot"> Forgot your password ? </Link>
                                                </span>
                                            </div>
                                            <div className="reg_submit mt-3" onClick={formSubmit}>
                                                {buttonLoader == false ? (
                                                    <span
                                                        className="hero_getstarted"
                                                    >
                                                        Log in
                                                    </span>
                                                ) : (
                                                    <span className="hero_getstarted">Loading ...</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="reg_bottm_glg">
                                        <span className="slider_left_innerbot">
                                            Don't have an account ?
                                            <Link to="/signup"> Register </Link>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
